import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Tabs, { TabList, Tab, TabPanel } from '../elements/Tabs';
import Image from '../elements/Image';
import '../../assets/fonts/fonts.css'

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

class SocialProofLaunch extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'features-tabs section center-content',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'features-tabs-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

  
    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            {/* <SectionHeader data={sectionHeader} className="center-content" /> */}
            <h1 className="custom-font-header"> Register like a champion today</h1>
            <p className="some-class">No more hail marys needed for course registration - get notified when a spot in a full class opens</p>
            <Tabs active="tab-a">
              <TabList>
                <Tab tabId="tab-a">
                  <div className="features-tabs-tab-image mb-12">
                    <Image
                      src={require('./../../assets/images/features-tabs-icon-01.svg')}
                      alt="Tab icon 01"
                      width={56}
                      height={56} />
                  </div>
                  <div className="text-color-high text-sm">
                    Proven Success
                  </div>
                </Tab>
                <Tab tabId="tab-b">
                  <div className="features-tabs-tab-image mb-12">
                    <Image
                      src={require('./../../assets/images/features-tabs-icon-02.svg')}
                      alt="Tab icon 02"
                      width={56}
                      height={56} />
                  </div>
                  <div className="text-color-high text-sm">
                    High Satisfaction
                  </div>                  
                </Tab>
                <Tab tabId="tab-c">
                  <div className="features-tabs-tab-image mb-12">
                    <Image
                      src={require('./../../assets/images/features-tabs-icon-03.svg')}
                      alt="Tab icon 03"
                      width={56}
                      height={56} 
                      />
                  </div>
                  <div className="text-color-high text-sm">
                    Trusted by Peers
                  </div>                  
                </Tab>         
              </TabList>
              <TabPanel id="tab-a">
                <Image
                  src={require('./../../assets/images/percentageND-removebg-preview.png')}
                  alt="Features tabs image 01"
                  className={'custom-image'}
                  width={400}
                  height={400} 
                  />
              </TabPanel>
              <TabPanel id="tab-b">
                <Image
                  src={require('./../../assets/images/5star.png')}
                  // className={'custom-image'}
                  alt="Features tabs image 02"
                  width={400}
                  height={400} />

              </TabPanel>
              <TabPanel id="tab-c">
                <a href='https://www.instagram.com/luxmi.mathivannan/' target='_blank'>
              <Image
                  src={require('./../../assets/images/luxmilux.png')}
                  alt="Features tabs image 02"
                  // className={'custom-image'}
                  width={400}
                  height={400} />
                </a>
              </TabPanel>         
            </Tabs>
          </div>
        </div>
      </section>
    );
  }
}

SocialProofLaunch.propTypes = propTypes;
SocialProofLaunch.defaultProps = defaultProps;

export default SocialProofLaunch;