import React from 'react';
import PricingLaunch from '../components/sections/PricingLaunchPage';

class PricingPage extends React.Component {
  render() {
  
    // if (window.sessionStorage.getItem('userSchool')==null) {
    //   window.sessionStorage.setItem('userSchool', 'Notre Dame');
    // }

    return (
      <React.Fragment>
        <PricingLaunch onSelectPlanPage className="has-bg-color-cut" />
      </React.Fragment>
    );
  }
}

export default PricingPage;