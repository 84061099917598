import React, {useEffect, useState} from "react";
import classNames from "classnames";
import {SectionProps} from "../../utils/SectionProps";
import {Link} from "react-router-dom";
import SectionHeader from "./partials/SectionHeader";
import Button from "../elements/Button";
import Select from "../elements/Select";
import Checkbox from "../elements/Checkbox";
import HeroFull from "./HeroFull";
import mixpanel from "mixpanel-browser";
import firebase from "firebase/compat/app";
import PhoneInput from "react-phone-input-2";
import {parsePhoneNumber, isValidNumber} from "libphonenumber-js";
import "react-phone-input-2/lib/style.css";
import StripeCheck from "../sections/StripeCheck";
import {PaymentElement, CardElement, useStripe} from "@stripe/react-stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import Fuse from "fuse.js";
import {uchicagoCourses} from "../../assets/UChicago";
import {notreDameCourses} from "../../assets/Notre Dame";
import {rpiCourses} from "../../assets/RPI";
import {dartmouthCourses} from "../../assets/Dartmouth";
import {northeasternCourses} from "../../assets/Northeastern";
import {westernUniversityCourses} from "../../assets/Western University";
import {umassCourses} from "../../assets/UMass";
import Image from "../elements/Image";
require("firebase/auth");

let isItAddDrop = [true, false, true, true, false, false, false];

window.mobileCheck = function () {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

const firebaseConfig = {
  apiKey: "AIzaSyAQ1DgZyHU2UDmQ_JDgrEg6th8fUuMOFQg",
  authDomain: "uchiprereg.firebaseapp.com",
  projectId: "uchiprereg",
  storageBucket: "uchiprereg.appspot.com",
  messagingSenderId: "963203646451",
  appId: "1:963203646451:web:70d27bc0d01c0704650886",
  measurementId: "G-1FMVGVFPGW",
};

firebase.initializeApp(firebaseConfig);

var mouseDown = false;

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

let freeUsersArray = [
  "9178168373",
  "2245091991",
  "7863505278",
  "7087108441",
  "8033711796",
  "8608374226",
  "4243929994",
  "9706857425",
  "7737987563",
  "7737888004",
  "8477906321",
  "7328815655",
  "4149165211",
  "2138148916",
  "2488029462",
  "8325422188",
  "8608636325",
  "5024456682",
  "9047902120",
  "3099896627",
  "4193200766",
  "7735104851",
  "2027632522",
  "3108099387",
  "3474098111",
  "9175289583",
  "3095306085",
  "4699997362",
  "3472057774",
  "7139279195",
  "4808866096",
  "4804344586",
  "7182003002",
  "5166972124",
  "7879402437",
  "2029998416",
  "9375578085",
  "2036130170",
  "9257259639",
  "5102489778",
  "6307439920",
  "7703095075",
  "6507769083",
  "5122307229",
  "8477785574",
  "+66916963175",
  "6086300910",
  "9085653326",
  "3144794621",
  "3123752925",
  "7739529401",
  "6506002482",
  "3127186343",
  "4084422905",
  "2029265758",
  "4702345428",
  "2028137163",
  "3106999256",
  "6177758386",
  "9145485489",
  "7138909124",
  "7187043243",
  "7084913408",
  "4107035368",
  "2024598041",
  "9734204762",
  "2624758033",
  "6463509253",
  "9085176922",
  "2162690310",
  "7204417279",
  "7602201548",
  "3129786746",
  "4138013717",
  "3098572498",
  "6825530436",
  "6263532428",
  "5104074958",
  "6468412006",
  "9732168750",
  "9546818915",
  "4697664243",
  "9176484872",
  "8486675266",
  "8054555281",
  "7733068421",
  "+8801765100738",
  "9257195571",
  "6502831962",
  "9738767588",
  "8608397388",
  "2032933339",
  "7736336786",
  "6023158203",
  "6154502490",
  "7735447799",
  "5736399003",
  "3802401726",
  "6126707721",
  "6128672132",
  //'7037626410'
];

let schools = [
  "UChicago",
  "Notre Dame",
  "RPI",
  "Dartmouth",
  "Northeastern",
  "Western University",
  "UMass",
];
let coursesData = [
  uchicagoCourses,
  notreDameCourses,
  rpiCourses,
  dartmouthCourses,
  northeasternCourses,
  westernUniversityCourses,
  umassCourses,
];
let termTimes = [
  1709625600, 1709625600, 1709625600, 1709625600, 1709625600, 1709625600,
  1709625600, 1723217440, 1732127393,
];
// let userSchool = window.sessionStorage.getItem('userSchool')
let userSchool = "UChicago";

let classes = [];
let classTitles = [];
let classTitlesNoDuplicates = [];

// for(var g=0;g<uchicagoCourses.subjects.length;g++){
//   for(var p=0;p<uchicagoCourses.subjects[g].course.length;p++){
//     classes.push(uchicagoCourses.subjects[g].course[p])
//     classTitles.push(uchicagoCourses.subjects[g].course[p].number);
//   }
// }

if (userSchool != null && schools.includes(userSchool)) {
  for (var g = 0; g < coursesData[schools.indexOf(userSchool)].length; g++) {
    classes.push(coursesData[schools.indexOf(userSchool)][g].course);
    classTitles.push(
      `${coursesData[schools.indexOf(userSchool)][g].course.subject} - ${
        coursesData[schools.indexOf(userSchool)][g].course.name
      }`
    );
  }
}

classTitlesNoDuplicates = [...new Set(classTitles)];

let numClassesEval = [0];
if (window.sessionStorage.getItem("numClasses") != null) {
  numClassesEval = [];
  for (
    let j = 0;
    j < parseFloat(window.sessionStorage.getItem("numClasses"));
    j++
  ) {
    numClassesEval.push(0);
  }
}

let classSelected1New = [];
let classSelected2New = [];
let classSelected3New = [];
let classSelected4New = [];
let classSelected5New = [];

if (window.sessionStorage.getItem("classSectionsSelected1") != null) {
  classSelected1New.push(
    classes.filter(
      (course) =>
        course.subject.toLowerCase() ==
        window.sessionStorage.getItem("subjectSelected1").toLowerCase()
    )
  );
}

if (window.sessionStorage.getItem("classSectionsSelected2") != null) {
  classSelected2New.push(
    classes.filter(
      (course) =>
        course.subject.toLowerCase() ==
        window.sessionStorage.getItem("subjectSelected2").toLowerCase()
    )
  );
}

if (window.sessionStorage.getItem("classSectionsSelected3") != null) {
  classSelected3New.push(
    classes.filter(
      (course) =>
        course.subject.toLowerCase() ==
        window.sessionStorage.getItem("subjectSelected3").toLowerCase()
    )
  );
}

if (window.sessionStorage.getItem("classSectionsSelected4") != null) {
  classSelected4New.push(
    classes.filter(
      (course) =>
        course.subject.toLowerCase() ==
        window.sessionStorage.getItem("subjectSelected4").toLowerCase()
    )
  );
}

if (window.sessionStorage.getItem("classSectionsSelected5") != null) {
  classSelected5New.push(
    classes.filter(
      (course) =>
        course.subject.toLowerCase() ==
        window.sessionStorage.getItem("subjectSelected5").toLowerCase()
    )
  );
}

let sectionsSelected1New = [];
let sectionsSelected2New = [];
let sectionsSelected3New = [];
let sectionsSelected4New = [];
let sectionsSelected5New = [];

if (window.sessionStorage.getItem("classSectionsSelected1") != null) {
  let sectionsSelected1 = window.sessionStorage
    .getItem("classSectionsSelected1")
    .split(",");

  for (var e = 0; e < sectionsSelected1.length; e++) {
    sectionsSelected1New.push(parseInt(sectionsSelected1[e]));
  }
}

if (window.sessionStorage.getItem("classSectionsSelected2") != null) {
  let sectionsSelected2 = window.sessionStorage
    .getItem("classSectionsSelected2")
    .split(",");

  for (var e = 0; e < sectionsSelected2.length; e++) {
    sectionsSelected2New.push(parseInt(sectionsSelected2[e]));
  }
}

if (window.sessionStorage.getItem("classSectionsSelected3") != null) {
  let sectionsSelected3 = window.sessionStorage
    .getItem("classSectionsSelected3")
    .split(",");

  for (var e = 0; e < sectionsSelected3.length; e++) {
    sectionsSelected3New.push(parseInt(sectionsSelected3[e]));
  }
}

if (window.sessionStorage.getItem("classSectionsSelected4") != null) {
  let sectionsSelected4 = window.sessionStorage
    .getItem("classSectionsSelected4")
    .split(",");

  for (var e = 0; e < sectionsSelected4.length; e++) {
    sectionsSelected4New.push(parseInt(sectionsSelected4[e]));
  }
}

if (window.sessionStorage.getItem("classSectionsSelected5") != null) {
  let sectionsSelected5 = window.sessionStorage
    .getItem("classSectionsSelected5")
    .split(",");

  for (var e = 0; e < sectionsSelected5.length; e++) {
    sectionsSelected5New.push(parseInt(sectionsSelected5[e]));
  }
}

function getCountryCode(phoneNumber) {
  try {
    const parsed = parsePhoneNumber(phoneNumber);
    return parsed?.country || "";
  } catch (error) {
    console.error(error);
    return "";
  }
}

function SignupForm() {
  useEffect(() => {
    const userSchool = sessionStorage.getItem("userSchool");
    if (!userSchool) {
      window.location.href = "/selectschool";
    }
  }, []);

  // let [alreadyUserNames, setAlreadyUserNames] = useState([])
  let [referrerPhone, setReferrerPhone] = useState(
    window.sessionStorage.getItem("referrerPhone") != null
      ? ""
      : window.sessionStorage.getItem("referrerPhone")
  );
  let [referrerName, setReferrerName] = useState(
    window.sessionStorage.getItem("referrerName") != null
      ? ""
      : window.sessionStorage.getItem("referrerName")
  );
  let [currentlyTracking, setCurrentlyTracking] = useState([]);

  let [infoSuccess, setInfoSuccess] = useState(
    window.sessionStorage.getItem("infoSuccess") == null ||
      isItAddDrop[
        schools.indexOf(window.sessionStorage.getItem("userSchool"))
      ] == true
      ? false
      : true
  );

  let [usersAllTerms, setUsersAllTerms] = useState([]);

  let [preOrderedUsers, setPreOrderedUsers] = useState([]);

  useEffect(() => {
    firebase
      .firestore()
      .collection("preOrders")
      .where("dateTime", ">", termTimes[schools.indexOf(userSchool)])
      // .get()
      // .then((querySnapshot) => {
      .onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          setPreOrderedUsers((a) => [...a, doc.data().phone]);
        });
      });
  }, []);

  useEffect(() => {
    firebase
      .firestore()
      .collection("grabLeads")
      .orderBy("dateTime")
      // .get()
      // .then((querySnapshot) => {
      .onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          setUsersAllTerms((a) => [...a, doc.data()]);

          //NEED THIS IF CONDITION TO MAKE SURE THE DATA IS ACCURATE FOR THE QUARTER
          if (
            doc.data().dateTime > termTimes[schools.indexOf(userSchool)] &&
            doc.data().gotClass != true &&
            doc.data().classSections.length > 0
          ) {
            setCurrentlyTracking((a) => [...a, doc.data().classSubject]);
          }

          if (
            window.location.href
              .split("signup")[1]
              .includes(`?referralPhoneInput=${doc.data().phone}`)
          ) {
            setReferrerPhone(doc.data().phone);
            setReferrerName(doc.data().name);
          } else if (
            window.sessionStorage.getItem("referralPhone") != null &&
            window.sessionStorage.getItem("referralPhone") != "null" &&
            window.sessionStorage.getItem("referralPhone") != ""
          ) {
            setReferrerPhone(window.sessionStorage.getItem("referralPhone"));
            setReferrerName(window.sessionStorage.getItem("referralName"));
          }
        });
      });
  }, []);

  const [freeUsers, setFreeUsers] = useState([]);

  useEffect(() => {
    const db = firebase.firestore();
    const unsubscribe = db.collection("freeUsers").onSnapshot((snapshot) => {
      let users = [];
      snapshot.forEach((doc) => {
        if (
          doc.data().dateTime >
            termTimes[
              schools.indexOf(window.sessionStorage.getItem("userSchool"))
            ] &&
          doc.data().school == window.sessionStorage.getItem("userSchool")
        )
          users.push(doc.data());
      });
      setFreeUsers(users);
    });

    // Cleanup function to unsubscribe from changes
    return () => unsubscribe();
  }, []);

  let currentDateInSeconds = new Date().getTime() / 1000;
  let [userName, setUserName] = useState(
    window.sessionStorage.getItem("userName") == null
      ? ""
      : window.sessionStorage.getItem("userName")
  );
  let [userPhone, setUserPhone] = useState(
    window.sessionStorage.getItem("userPhone") == null
      ? ""
      : window.sessionStorage.getItem("userPhone")
  );
  let [userCountry, setUserCountry] = useState(
    window.sessionStorage.getItem("userPhone") == null
      ? ""
      : getCountryCode(window.sessionStorage.getItem("userPhone"))
  );

  let [wantsEmails, setWantsEmails] = useState(
    window.sessionStorage.getItem("wantsEmails") == null
      ? "no"
      : window.sessionStorage.getItem("wantsEmails")
  );
  let [buttonClicked, setButtonClicked] = useState(false);
  let [alreadyPreordered, setAlreadyPreordered] = useState(false);
  let [sharingWorks, setSharingWorks] = useState(true);

  let [numClasses, setNumClasses] = useState(
    window.sessionStorage.getItem("numClasses") == null ? [0] : numClassesEval
  );

  let [classTextInput1, setClassTextInput1] = useState(
    window.sessionStorage.getItem("classTextInput1") == null
      ? ""
      : window.sessionStorage.getItem("classTextInput1")
  );
  let [classSelected1, setClassSelected1] = useState(
    window.sessionStorage.getItem("classSectionsSelected1") == null
      ? []
      : classSelected1New[0]
  );
  let [subjectSelected1, setSubjectSelected1] = useState(
    window.sessionStorage.getItem("subjectSelected1") == null
      ? ""
      : window.sessionStorage.getItem("subjectSelected1")
  );
  let [classSectionsSelected1, setClassSectionsSelected1] = useState(
    window.sessionStorage.getItem("classSectionsSelected1") == null
      ? []
      : sectionsSelected1New
  );

  let [classTextInput2, setClassTextInput2] = useState(
    window.sessionStorage.getItem("classTextInput2") == null
      ? ""
      : window.sessionStorage.getItem("classTextInput2")
  );
  let [classSelected2, setClassSelected2] = useState(
    window.sessionStorage.getItem("classSectionsSelected2") == null
      ? []
      : classSelected2New[0]
  );
  let [subjectSelected2, setSubjectSelected2] = useState(
    window.sessionStorage.getItem("subjectSelected2") == null
      ? ""
      : window.sessionStorage.getItem("subjectSelected2")
  );
  let [classSectionsSelected2, setClassSectionsSelected2] = useState(
    window.sessionStorage.getItem("classSectionsSelected2") == null
      ? []
      : sectionsSelected2New
  );

  let [classTextInput3, setClassTextInput3] = useState(
    window.sessionStorage.getItem("classTextInput3") == null
      ? ""
      : window.sessionStorage.getItem("classTextInput3")
  );
  let [classSelected3, setClassSelected3] = useState(
    window.sessionStorage.getItem("classSectionsSelected3") == null
      ? []
      : classSelected3New[0]
  );
  let [subjectSelected3, setSubjectSelected3] = useState(
    window.sessionStorage.getItem("subjectSelected3") == null
      ? ""
      : window.sessionStorage.getItem("subjectSelected3")
  );
  let [classSectionsSelected3, setClassSectionsSelected3] = useState(
    window.sessionStorage.getItem("classSectionsSelected3") == null
      ? []
      : sectionsSelected3New
  );

  let [classTextInput4, setClassTextInput4] = useState(
    window.sessionStorage.getItem("classTextInput4") == null
      ? ""
      : window.sessionStorage.getItem("classTextInput4")
  );
  let [classSelected4, setClassSelected4] = useState(
    window.sessionStorage.getItem("classSectionsSelected4") == null
      ? []
      : classSelected4New[0]
  );
  let [subjectSelected4, setSubjectSelected4] = useState(
    window.sessionStorage.getItem("subjectSelected4") == null
      ? ""
      : window.sessionStorage.getItem("subjectSelected4")
  );
  let [classSectionsSelected4, setClassSectionsSelected4] = useState(
    window.sessionStorage.getItem("classSectionsSelected4") == null
      ? []
      : sectionsSelected4New
  );

  let [classTextInput5, setClassTextInput5] = useState(
    window.sessionStorage.getItem("classTextInput5") == null
      ? ""
      : window.sessionStorage.getItem("classTextInput5")
  );
  let [classSelected5, setClassSelected5] = useState(
    window.sessionStorage.getItem("classSectionsSelected5") == null
      ? []
      : classSelected5New[0]
  );
  let [subjectSelected5, setSubjectSelected5] = useState(
    window.sessionStorage.getItem("subjectSelected5") == null
      ? ""
      : window.sessionStorage.getItem("subjectSelected5")
  );
  let [classSectionsSelected5, setClassSectionsSelected5] = useState(
    window.sessionStorage.getItem("classSectionsSelected5") == null
      ? []
      : sectionsSelected5New
  );

  let [fuseSearch1, setFuseSearch1] = useState([]);
  let [fuseSearch2, setFuseSearch2] = useState([]);
  let [fuseSearch3, setFuseSearch3] = useState([]);
  let [fuseSearch4, setFuseSearch4] = useState([]);
  let [fuseSearch5, setFuseSearch5] = useState([]);

  let [showFuseList1, setShowFuseList1] = useState(true);
  let [showFuseList2, setShowFuseList2] = useState(true);
  let [showFuseList3, setShowFuseList3] = useState(true);
  let [showFuseList4, setShowFuseList4] = useState(true);
  let [showFuseList5, setShowFuseList5] = useState(true);

  let [consentRequired1, setConsentRequired1] = useState(false);
  let [consentRequired2, setConsentRequired2] = useState(false);
  let [consentRequired3, setConsentRequired3] = useState(false);
  let [consentRequired4, setConsentRequired4] = useState(false);
  let [consentRequired5, setConsentRequired5] = useState(false);

  let className;
  let topOuterDivider;
  let bottomOuterDivider;
  let topDivider;
  let bottomDivider;
  let hasBgColor;
  let invertColor;

  const outerClasses = classNames(
    "signin section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "signin-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const sectionHeader = {
    title: "Sign up",
    //paragraph: isItAddDrop==false?'We will text you when: ':''
  };

  async function submitRequest(event) {
    event.preventDefault();

    if (userName == "") {
      document.getElementById("nameInput").classList.add("form-error");
      document.getElementById("nameBlankHint").style.display = "block";
      document.location.href = "#nameInput";
    }
    // else if (!userName.includes(' ')) {
    //   document.getElementById('nameInput').classList.add("form-error");
    //   document.getElementById('nameSpaceHint').style.display='block';
    //   document.location.href='#nameInput'
    // }
    // else if (userName!='' && (userName.includes('@') || userName.includes('-') || userName.includes('_') || userName.includes('(') || userName.includes(')') || userName.includes('[') || userName.includes(']') || userName.includes('—') || userName.includes('/') || userName.includes(',') || userName.includes('*') || userName.includes('~') || (/\d/.test(userName)==true))) {
    //   document.getElementById('nameInput').classList.add("form-error");
    //   document.getElementById('nameLettersHint').style.display='block';
    //   document.location.href='#nameInput'
    // }
    else if (userPhone == "") {
      // document.getElementById('phoneInput').classList.add("form-error");
      document.getElementById("phoneBlankHint").style.display = "block";
      document.location.href = "#phoneInput";
    } else if (/[a-zA-Z]/g.test(userPhone) == true) {
      // document.getElementById('phoneInput').classList.add("form-error");
      document.getElementById("phoneLetterHint").style.display = "block";
      document.location.href = "#phoneInput";
    } else if (
      userPhone.length < 11 ||
      (isValidNumber(`+${userPhone}`) == false &&
        isValidNumber(userPhone) == false)
    ) {
      // document.getElementById('phoneInput').classList.add("form-error")
      document.getElementById("phoneShortHint").style.display = "block";
      document.location.href = "#phoneInput";
    } else if (
      userPhone.includes("@") ||
      userPhone.includes("-") ||
      userPhone.includes(" ") ||
      userPhone.includes("_") ||
      userPhone.includes("(") ||
      userPhone.includes(")") ||
      userPhone.includes("[") ||
      userPhone.includes("]") ||
      userPhone.includes("—") ||
      userPhone.includes(".") ||
      userPhone.includes("/") ||
      userPhone.includes(",") ||
      userPhone.includes("*") ||
      userPhone.includes("~")
    ) {
      // document.getElementById('phoneInput').classList.add("form-error")
      document
        .getElementById("phoneSymbolHint")
        .classList.add("text-color-error");
      document.location.href = "#phoneInput";
    } else {
      if (buttonClicked == false) {
        setButtonClicked(true);

        window.sessionStorage.setItem("userName", userName);
        if (userPhone.includes("+")) {
          window.sessionStorage.setItem("userPhone", userPhone);
        } else {
          window.sessionStorage.setItem("userPhone", `+${userPhone}`);
        }

        document.getElementById("nameInput").classList.add("form-success");
        // document.getElementById('phoneInput').classList.add("form-success")

        // if(referrerPhone!=''){
        //   document.getElementById('phoneInput').classList.add("form-success")
        // }

        if (referrerPhone != "" && referrerPhone != null) {
          window.sessionStorage.setItem("referrerPhone", referrerPhone);
          window.sessionStorage.setItem("referrerName", referrerName);
        }

        window.sessionStorage.setItem("wantsEmails", wantsEmails);

        if (isItAddDrop[schools.indexOf(userSchool)] == true) {
          if (
            userSchool == "Western University" &&
            freeUsers.length < 10 &&
            !freeUsers.map((a) => a.phone).includes(userPhone) &&
            !freeUsers.map((a) => a.phone).includes(`+${userPhone}`)
          ) {
            await firebase
              .firestore()
              .collection("freeUsers")
              .add({
                name: userName,
                phone: userPhone.includes("+") ? userPhone : `+${userPhone}`,
                school: userSchool,
                email: "",
                referrer: referrerPhone != null ? referrerPhone : "",
                dateTime: currentDateInSeconds,
                dateTimeReverse: -currentDateInSeconds,
                wantsNotifications: true,
              });

            window.sessionStorage.setItem("signupPlan", "standard");
            window.location.href = "/getnotifications";
          } else {
            await firebase
              .firestore()
              .collection("signupLeads")
              .add({
                name: userName,
                phone: userPhone.includes("+") ? userPhone : `+${userPhone}`,
                school: userSchool,
                email: "",
                referrer: referrerPhone != null ? referrerPhone : "",
                dateTime: currentDateInSeconds,
                dateTimeReverse: -currentDateInSeconds,
                wantsNotifications: true,
              });

            window.sessionStorage.setItem("signupPlan", "standard");
            window.location.href = "/getnotifications";
          }

          // else{

          // //if you are not in our system at all
          // if(){
          // window.sessionStorage.setItem('isItAddDrop', 'itIs');
          // window.location.href='/selectplan'
          // }

          // //if you are a paid user this quarter
          // else if(){
          //   window.sessionStorage.setItem('isItAddDrop', 'itIs')
          //   window.location.href=`/preferences#${userPhone}`
          // }

          // //if you are a free user this quarter
          // else if(){
          //   window.sessionStorage.setItem('isItAddDrop', 'itIs')
          //   window.location.href=`/preferences#${userPhone}`
          // }

          // //if you are a paid user in previous quarters
          // else if(){
          //   window.sessionStorage.setItem('isItAddDrop', 'itIs')
          //   window.location.href=`/preferences#${userPhone}`

          // }
          // //if you are a free user in previous quarters
          // else if(){
          //   window.sessionStorage.setItem('isItAddDrop', 'itIs')
          //   window.location.href=`/preferences#${userPhone}`
          // }

          // }
        } else {
          window.sessionStorage.setItem("isItAddDrop", "itIsNot");

          // if(
          // !freeUsersArray.includes(userPhone) || (window.sessionStorage.getItem('signupPlan')=='free')
          // //&& !preOrderedUsers.includes(userPhone)
          // ){

          if (
            preOrderedUsers.includes(userPhone) ||
            preOrderedUsers.includes(`+${userPhone}`)
          ) {
            setAlreadyPreordered(true);
            setButtonClicked(false);
          } else {
            await firebase
              .firestore()
              .collection("preOrders")
              .add({
                name: userName,
                phone: userPhone.includes("+") ? userPhone : `+${userPhone}`,
                school: userSchool,
                email: "",
                referrer: referrerPhone != null ? referrerPhone : "",
                dateTime: currentDateInSeconds,
                dateTimeReverse: -currentDateInSeconds,
                wantsNotifications: true,
              })
              .then(async () => {
                console.log(userPhone);
                await fetch(
                  "https://us-central1-uchiprereg.cloudfunctions.net/server/send-reminders-text",
                  {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                      "Access-Control-Allow-Origin": "*",
                      "Access-Control-Allow-Methods": "GET, POST",
                      "Access-Control-Allow-Headers": "Content-Type",
                      "Access-Control-Max-Age": "3600",
                    },
                    body: JSON.stringify({
                      userName: userName,
                      userPhone: userPhone.includes("+")
                        ? userPhone
                        : `+${userPhone}`,
                    }),
                  }
                );
                // .catch(err => {
                //   console.log(err)
                //   // sendInData()
                // })
              })
              .then(() => {
                setInfoSuccess(true);
                setButtonClicked(false);
              });
          }

          // }else{
          //   window.location.href='/loadingfree/2iojsdf8932bfow09u'
          // }
        }
      }
    }
  }

  function changeReferrerInput(event) {
    setReferrerPhone(event.target.value);
    document.getElementById("referrerInput").classList.remove("form-error");
    document.getElementById("referrerSpaceHint").style.display = "none";
    document.getElementById("referrerCharacterHint").style.display = "none";
    document.getElementById("referrerSavedHint").style.display = "none";
    window.sessionStorage.removeItem("infoSuccess");
  }

  function changeNameInput(event) {
    setUserName(event.target.value);
    document.getElementById("nameInput").classList.remove("form-error");
    document.getElementById("nameBlankHint").style.display = "none";
    document.getElementById("nameSpaceHint").style.display = "none";
    document.getElementById("nameLettersHint").style.display = "none";
    window.sessionStorage.removeItem("infoSuccess");
  }

  function changePhoneInput(phone) {
    setUserPhone(phone);
    // document.getElementById('phoneInput').classList.remove("form-error")
    document.getElementById("phoneBlankHint").style.display = "none";
    document.getElementById("phoneShortHint").style.display = "none";
    document.getElementById("phoneLetterHint").style.display = "none";
    window.sessionStorage.removeItem("infoSuccess");
  }

  function selectNumberOfClasses(event) {
    setNumClasses([]);
    for (let y = 0; y < parseInt(event.target.value); y++) {
      setNumClasses((a) => [...a, 0]);
    }
    document.getElementById("selectNumClasses").classList.remove("form-error");
    document.getElementById("selectEmptyHint").style.display = "none";
  }

  window.onload = function () {
    document.getElementById("nameInput").focus();
  };

  async function copyPasteLink(event) {
    if (window.mobileCheck() == true) {
      if (navigator.share) {
        navigator
          .share({
            title: "ClassClutch",
            text: "This website sends you a text when a spot opens up in a full class",
            url: "https://www.classclutch.com",
          })
          .then(() => console.log("Successful share"))
          .catch((error) => console.log("Error sharing", error));
      } else {
        if (navigator.clipboard) {
          console.log("Web Share not supported");
          await navigator.clipboard.writeText("classclutch.com");
          showCopiedComputer(event);
        }
      }
    } else {
      await navigator.clipboard.writeText("classclutch.com");
      showCopiedComputer(event);
    }
  }

  function clickTooltip(event) {
    if (navigator.share) {
      console.log("sharing with navigator");
    } else {
      if (navigator.clipboard) {
        document.getElementById("preorderTooltipText").style.visibility =
          "visible";
        event.preventDefault();
        document
          .getElementById("preorderTooltipText")
          .classList.remove("fadeOut2Class");
        void document.getElementById("preorderTooltipText").offsetWidth;
        document
          .getElementById("preorderTooltipText")
          .classList.add("fadeOut2Class");
      }
    }
  }

  function showCopiedComputer(event) {
    if (window.mobileCheck() == false) {
      // document.getElementById('preorderTooltipText').style.visibility='hidden'
      document.getElementById("preorderTooltipTextComputer").style.visibility =
        "visible";
      event.preventDefault();
      document
        .getElementById("preorderTooltipTextComputer")
        .classList.remove("fadeOut2Class");
      void document.getElementById("preorderTooltipTextComputer").offsetWidth;
      document
        .getElementById("preorderTooltipTextComputer")
        .classList.add("fadeOut2Class");
      // document.getElementById('preorderTooltipText').style.visibility='visible'
    }
  }

  return (
    <section
      // {...props}
      className={outerClasses}
    >
      <div
        className="container"
        // style={{width:'40%'}}
      >
        <div className={innerClasses}>
          <img src="BeeOnSide.jpeg" class="beeOnSignup reveal-scale-up" />

          {/* <SectionHeader tag="h1" data={sectionHeader} className="center-content" style={{paddingBottom:'24px'}}/> */}

          <div style={{textAlign: "left"}}>
            <h1
              //className="mt-0 mb-16 reveal-from-top"
              className="reveal-from-top widthClassSignup"
              //data-reveal-delay="150"
              style={{wordBreak: "break-word", marginBottom: "0px"}}
            >
              {isItAddDrop[schools.indexOf(userSchool)] == false ? (
                <div
                  style={{
                    display: "inline",
                    textAlign: "left",
                    fontWeight: "lighter",
                    marginLeft: "0px",
                  }}
                >
                  Get reminders about{" "}
                  <div style={{fontWeight: "bold"}}>add/drop</div>
                </div>
              ) : (
                <>
                  {freeUsers.length < 10 &&
                  userSchool == "Western University" ? (
                    <div style={{display: "inline", marginLeft: "12px"}}>
                      Sign up
                      <div
                        style={{
                          marginLeft: "12px",
                          fontSize: "25px",
                          fontWeight: "normal",
                          color: "rgb(0,194,169)",
                        }}
                      >
                        {10 - freeUsers.length} free spots left
                      </div>
                    </div>
                  ) : (
                    <div style={{display: "inline", marginLeft: "12px"}}>
                      Sign up
                    </div>
                  )}

                  <div
                    style={{
                      fontWeight: "normal",
                      fontSize: "20px",
                      marginLeft: "12px",
                    }}
                  >
                    or{" "}
                    <a
                      href="/preferences"
                      style={{
                        display: "inline",
                        color: "rgb(13, 98, 255)",
                        cursor: "pointer",
                      }}
                    >
                      Log In
                    </a>
                  </div>
                </>
              )}
            </h1>

            {isItAddDrop[schools.indexOf(userSchool)] == false ? (
              <div
                className="reveal-from-top subheaderSignup"
                //className="container-xs reveal-from-top"
              >
                <div
                  //className="m-0 mb-32"
                  //data-reveal-delay="300"
                  style={{
                    fontSize: "1rem",
                    paddingBottom: "",
                    textAlign: "left",
                    marginTop: "0.5rem",
                  }}
                >
                  The upcoming term’s add/drop has not started, so you can’t
                  track classes yet. We’ll let you know when you can.
                  <div style={{textAlign: "left", marginTop: "0.7rem"}}>
                    {/* <div style={{lineHeight:'1.8rem'}}>
                      • Pre-reg opens and closes
                    </div>
                    <div style={{lineHeight:'1.8rem'}}>
                      • Courses resolve
                    </div>
                    <div style={{lineHeight:'1.8rem'}}>
                      • Add/drop opens and closes
                    </div>
                    <div style={{lineHeight:'1.8rem'}}>
                      • New features drop
                    </div> */}
                  </div>
                </div>
              </div>
            ) : null}
          </div>

          <div
            // className="tiles-wrap reveal-from-bottom"
            className="reveal-from-bottom"
          >
            <div className="tiles-item" style={{paddingTop: "0px"}}>
              <div className="tiles-item-inner">
                {/* We will text you when a spot in the class opens up. */}
                <form style={{marginTop: "1rem"}}>
                  <fieldset>
                    <div className="mb-12" style={{width: "306px"}}>
                      <input
                        label="Full name"
                        placeholder="Name"
                        labelHidden
                        id="nameInput"
                        required
                        className="form-input"
                        onChange={changeNameInput}
                        value={userName}
                      />
                    </div>
                    <div
                      id="nameBlankHint"
                      style={{display: "none"}}
                      class="form-hint text-color-error"
                    >
                      Please enter your name
                    </div>
                    <div
                      id="nameSpaceHint"
                      style={{display: "none"}}
                      class="form-hint text-color-error"
                    >
                      Please enter your first and last name.
                    </div>
                    <div
                      id="nameLettersHint"
                      style={{display: "none"}}
                      class="form-hint text-color-error"
                    >
                      Please use only letters.
                    </div>
                    <div className="mb-12" style={{width: "306px"}}>
                      <PhoneInput
                        country={userCountry == "" ? "us" : userCountry}
                        value={userPhone}
                        onChange={(phone) => changePhoneInput(phone)}
                        placeholder="Phone number"
                        maxLength="14"
                        enableSearch={true}
                        // inputStyle={{ width: '100%' }}
                        id="phoneInput"
                        required
                        // style={{borderStyle:'dashed'}}
                        class="mb-12"
                      />
                    </div>

                    <div
                      id="phoneBlankHint"
                      style={{display: "none"}}
                      class="form-hint text-color-error"
                    >
                      Please enter your phone number
                    </div>
                    <div
                      id="phoneShortHint"
                      style={{display: "none"}}
                      class="form-hint text-color-error"
                    >
                      Please enter a valid phone number
                    </div>
                    <div
                      id="phoneLetterHint"
                      style={{display: "none"}}
                      class="form-hint text-color-error"
                    >
                      Please enter a valid phone number
                    </div>
                    {userPhone.includes("@") ||
                    userPhone.includes("-") ||
                    userPhone.includes(" ") ||
                    userPhone.includes("_") ||
                    userPhone.includes("(") ||
                    userPhone.includes(")") ||
                    userPhone.includes("[") ||
                    userPhone.includes("]") ||
                    userPhone.includes("—") ||
                    userPhone.includes(".") ||
                    userPhone.includes("/") ||
                    userPhone.includes(",") ||
                    userPhone.includes("*") ||
                    userPhone.includes("~") ? (
                      <div
                        id="phoneSymbolHint"
                        //style={{display:'none'}}
                        class="form-hint text-color-error"
                      >
                        Please enter your number in the format: 7037626410. If
                        you would like to specify country code, use this format:
                        +2551234567890.
                      </div>
                    ) : null}

                    {isItAddDrop[schools.indexOf(userSchool)] == true ? (
                      <div></div>
                    ) : null}

                    {referrerName != null ? (
                      <div
                        style={{
                          textAlign: "center",
                          fontSize: "16px",
                          marginTop: "1rem",
                          borderStyle: "solid",
                          borderWidth: "0.1rem",
                          borderColor: "rgb(218,35,255)",
                          padding: "0.2rem",
                        }}
                      >
                        20% off your purchase thanks to {referrerName}!
                      </div>
                    ) : null}

                    <div className="mt-24 mb-32">
                      <Button
                        type="button"
                        color="primary"
                        onClick={submitRequest}
                        style={{width: "306px"}}
                      >
                        {isItAddDrop[schools.indexOf(userSchool)] == false ? (
                          <div>
                            <img
                              src="/ellipsis-white.gif"
                              class="noSelectText"
                              style={{
                                height: "48px",
                                marginTop: "-13px",
                                display:
                                  buttonClicked == true ? "block" : "none",
                              }}
                            />
                            <div
                              class="noSelectText"
                              style={{
                                pointerEvents: "none",
                                display:
                                  buttonClicked == false ? "block" : "none",
                              }}
                            >
                              Get Add/Drop Reminders
                            </div>
                          </div>
                        ) : (
                          <div>
                            <img
                              src="/ellipsis-white.gif"
                              class="noSelectText"
                              style={{
                                height: "48px",
                                marginTop: "-13px",
                                display:
                                  buttonClicked == true ? "block" : "none",
                              }}
                            />
                            <div
                              class="noSelectText"
                              style={{
                                pointerEvents: "none",
                                display:
                                  buttonClicked == false ? "block" : "none",
                              }}
                            >
                              Next
                            </div>
                          </div>
                        )}
                      </Button>

                      {infoSuccess == true ? (
                        <>
                          <div
                            style={{
                              marginTop: "1rem",
                              backgroundColor: "rgb(0,194,169)",
                              width: "306px",
                            }}
                            // class='widthClassSignup'
                          >
                            <div
                              id="successMessage"
                              style={{
                                textAlign: "center",
                                padding: "1rem",
                                fontWeight: "bold",
                                fontSize: "25px",
                                color: "white",
                                // color:'rgb(0,194,169)',
                                display: "block",
                              }}
                              //class="form-hint text-color-success"
                            >
                              Success — we’ll remind you when add/drop starts
                            </div>
                          </div>

                          <div>
                            <div>
                              {navigator.share || navigator.clipboard ? (
                                <div
                                  style={{
                                    marginRight: "auto",
                                    marginLeft: "auto",
                                    textAlign: "center",
                                    marginTop: "0.5rem",
                                  }}
                                  // class='widthClassSignup'
                                >
                                  <div
                                    style={{
                                      marginBottom: "0.5rem",
                                      // cursor:'pointer', display:'inline', fontSize:'1.2rem', color:'rgb(13, 98, 255)'
                                    }}
                                    class="tooltip"
                                    onTouchStart={clickTooltip}
                                    id="preorderTooltip"
                                    onClick={showCopiedComputer}
                                  >
                                    <div
                                      onClick={copyPasteLink}
                                      // class='copyLinkBox widthClassSignup'
                                      style={{
                                        // cursor:'grab',
                                        textAlign: "center",
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                        //borderStyle:'solid', borderWidth:'0.25rem', borderColor:'rgb(87,94,134)', backgroundColor:'rgb(233,236,248)',
                                        // fontSize:'16px'
                                      }}
                                    >
                                      <div
                                        // style={{fontSize:'16px', fontWeight:'bold', textAlign:'center'}}
                                        class="button"
                                        style={{
                                          background:
                                            "linear-gradient(345deg, #E9ECF8 0px, #FFFFFF 0px, rgb(218,35,255) 0%)",
                                          borderRadius: "10px",
                                          color: "white",
                                          height: "initial",
                                        }}
                                      >
                                        {/* Referral Link */}
                                        <img
                                          style={{
                                            maxWidth: "4rem",
                                            height: "2rem",
                                            width: "2rem",
                                            cursor: "grab",
                                            display: "inline-block",
                                            verticalAlign: "middle",
                                          }}
                                          src="shareIcon.png"
                                        />
                                      </div>

                                      {window.mobileCheck() == true ? (
                                        <span
                                          class="tooltiptext"
                                          id="preorderTooltipText"
                                        >
                                          Copied to clipboard{" "}
                                        </span>
                                      ) : (
                                        <div style={{pointerEvents: "none"}}>
                                          <span
                                            class="tooltiptext"
                                            id="preorderTooltipText"
                                          >
                                            Copy to clipboard{" "}
                                          </span>
                                          <span
                                            class="tooltiptext"
                                            id="preorderTooltipTextComputer"
                                            style={{visibility: "hidden"}}
                                          >
                                            Copied to clipboard{" "}
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </>
                      ) : null}

                      {alreadyPreordered == true ? (
                        <div
                          id="alreadyPreordered"
                          style={{
                            textAlign: "left",
                            fontSize: "20px",
                            color: "rgb(0,194,169)",
                            marginTop: "1rem",
                            width: "306px",
                          }}
                          class="fadeIn2Class"
                          //class="form-hint text-color-success"
                        >
                          You are already on our list, so we’ll keep you in the
                          loop.
                        </div>
                      ) : null}
                    </div>
                  </fieldset>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

SignupForm.propTypes = propTypes;
SignupForm.defaultProps = defaultProps;

export default SignupForm;
