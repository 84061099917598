import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import { SectionProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Switch from '../elements/Switch';
import Button from '../elements/Button';
// import SignupForm from './SignupForm'
import firebase from 'firebase/compat/app';


const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

let className
let topOuterDivider
let bottomOuterDivider
let topDivider
let bottomDivider
let hasBgColor
let invertColor
let pushLeft
let pricingSwitcher
let pricingSlider


const outerClasses = classNames(
  'pricing section',
  topOuterDivider && 'has-top-divider',
  bottomOuterDivider && 'has-bottom-divider',
  hasBgColor && 'has-bg-color',
  invertColor && 'invert-color',
  className
);

const innerClasses = classNames(
  'pricing-inner section-inner',
  topDivider && 'has-top-divider',
  bottomDivider && 'has-bottom-divider'
);

const tilesClasses = classNames(
  'tiles-wrap',
  pushLeft && 'push-left'
);

const sectionHeader = {
  // title: 'Simple, transparent pricing'
  title: 'Advising Payment Plans',
  // paragraph:''
    };


function AdvisingPricing(){

  let schools = ['UChicago', 'Notre Dame', 'RPI', 'Dartmouth', 'Northeastern', 'Western University', 'UMass']
  let isItAddDrop = [true, false, true, true, false, false, false]


    function selectFree(){
      window.sessionStorage.setItem('signupPlan', 'free')
      if(window.sessionStorage.getItem('userSchool')==null){
        window.location.href='/selectschool'
      }
      else if((window.sessionStorage.getItem('userPhone')!=null)&&(window.sessionStorage.getItem('userName')!=null)){
        window.location.href='/getnotifications'
      }else{
        window.location.href='/signup'
      }

    }

    function selectPremium(){
      window.sessionStorage.setItem('signupPlan', 'premium')
      if(window.sessionStorage.getItem('userSchool')==null){
        window.location.href='/selectschool'
      }
      else if((window.sessionStorage.getItem('userPhone')!=null)&&(window.sessionStorage.getItem('userName')!=null)){
        window.location.href='/getnotifications'
      }else{
        window.location.href='/signup'
      }

    }

    function selectUnlimited(){
      window.sessionStorage.setItem('signupPlan', 'unlimited')
      if(window.sessionStorage.getItem('userSchool')==null){
        window.location.href='/selectschool'
      }
      else if((window.sessionStorage.getItem('userPhone')!=null)&&(window.sessionStorage.getItem('userName')!=null)){
        window.location.href='/getnotifications'
      }else{
        window.location.href='/signup'
      }
    }



    return (
      <section
        // {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content reveal-scale-up" />
            <div className={tilesClasses}>

          
              <div className="tiles-item reveal-from-bottom">
                <div className="tiles-item-inner has-shadow">
                  <div className="pricing-item-content">
                    <div className="pricing-item-header pb-24 mb-24" style={{fontWeight:'bold'}}>
                       Personal Advisor 
                      <div className="pricing-item-price mb-4">
                        <span className="pricing-item-price-currency h3">
                        $
                        </span>
                        <span className="pricing-item-price-amount h1">
                          30
                        </span>
                        <span className="pricing-item-price-after text-sm">/month</span>
                      </div>
                    </div>
                    <div className="pricing-item-features mb-40">
                      <div className="pricing-item-features-title h6 text-xs text-color-high mb-24">
                        What’s included
                      </div>
                      <ul className="pricing-item-features-list list-reset text-xs mb-34">
                        <li className="is-checked" >Instant notifications</li>
                        <li className="is-checked" >Tracks 1 class per quarter</li>
                        <li className="is-checked" >Track multiple sections</li>
                      </ul>
                    </div>
                  </div>
                  <div className="pricing-item-cta mb-8">
                  {isItAddDrop==true?
                    <Button type='button' color="primary" wide onClick={selectPremium}>
                      Buy Notifications
                    </Button>
                  :null}
                  </div>
                </div>
              </div>

              <div className="tiles-item reveal-from-bottom">
                <div className="tiles-item-inner has-shadow">
                  <div className="pricing-item-content">
                    <div className="pricing-item-header pb-24 mb-24" style={{fontWeight:'bold'}}>
                      Advisor GPT
                      <div className="pricing-item-price mb-4">
                        <span className="pricing-item-price-currency h3">
                        $
                        </span>
                        <span className="pricing-item-price-amount h1">
                          20
                        </span>
                        <span className="pricing-item-price-after text-sm">/month</span>
                      </div>
                    </div>
                    <div className="pricing-item-features mb-40">
                      <div className="pricing-item-features-title h6 text-xs text-color-high mb-24">
                        What’s included
                      </div>
                      <ul className="pricing-item-features-list list-reset text-xs mb-34">
                        <li className="is-checked" >Instant notifications</li>
                        <li className="is-checked" >Tracks unlimited classes per quarter</li>
                        <li className="is-checked" >Track multiple sections</li>
                      </ul>
                    </div>
                  </div>
                  <div className="pricing-item-cta mb-8">
                  {isItAddDrop==true?
                    <Button type='button' color="primary" wide onClick={selectPremium}>
                      Buy Notifications
                    </Button>
                  :null}
                  </div>
                </div>
              </div>

          


              {/* <div className="tiles-item reveal-from-bottom">
                <div className="tiles-item-inner has-shadow">
                  <div className="pricing-item-content">
                    <div className="pricing-item-header pb-24 mb-24" style={{fontWeight:'bold'}}>
                      Unlimited Subscription
                      <div className="pricing-item-price mb-4">
                        <span className="pricing-item-price-currency h3">
                        $
                        </span>
                        <span className="pricing-item-price-amount h1">
                          <div style={{display:'inline',fontSize:'30px',color:'grey'}}>2̶0̶0̶</div>100
                        </span>
                        <span className="pricing-item-price-after text-sm">/quarter</span>
                      </div>
                    </div>
                    <div className="pricing-item-features mb-40">
                      <div className="pricing-item-features-title h6 text-xs text-color-high mb-24">
                        What’s included
                      </div>
                      <ul className="pricing-item-features-list list-reset text-xs mb-34">
                        <li className="is-checked">Tracks unlimited classes per quarter</li>
                        <li className="is-checked">Text message notifications</li>
                        <li className="is-checked">Checks my.UChicago every few seconds</li>
                      </ul>
                    </div>
                  </div>
                  <div className="pricing-item-cta mb-8">
                    <Button tag="a" color="primary" wide href="/signup">Launch notifications</Button>
                  </div>
                </div>
              </div> */}
             
            </div>
          </div>
        </div>
      </section>
    );
  }

AdvisingPricing.propTypes = propTypes;
AdvisingPricing.defaultProps = defaultProps;

export default AdvisingPricing;