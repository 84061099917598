import React, {useEffect, useState, useRef} from "react";
import {useHistory} from "react-router-dom";
import classNames from "classnames";
import {SectionProps} from "../../utils/SectionProps";
import {Link} from "react-router-dom";
import SectionHeader from "./partials/SectionHeader";
import Button from "../elements/Button";
import Select from "../elements/Select";
import Checkbox from "../elements/Checkbox";
import HeroFull from "./HeroFull";
import mixpanel from "mixpanel-browser";
import firebase from "firebase/compat/app";
import StripeCheck from "../sections/StripeCheck";
import {PaymentElement, CardElement, useStripe} from "@stripe/react-stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import Fuse from "fuse.js";
import {uchicagoCourses} from "../../assets/UChicago";
import {notreDameCourses} from "../../assets/Notre Dame";
import {rpiCourses} from "../../assets/RPI";
import {dartmouthCourses} from "../../assets/Dartmouth";
import {northeasternCourses} from "../../assets/Northeastern";
import {westernUniversityCourses} from "../../assets/Western University";
import {umassCourses} from "../../assets/UMass";
require("firebase/auth");

window.mobileCheck = function () {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

const firebaseConfig = {
  apiKey: "AIzaSyAQ1DgZyHU2UDmQ_JDgrEg6th8fUuMOFQg",
  authDomain: "uchiprereg.firebaseapp.com",
  projectId: "uchiprereg",
  storageBucket: "uchiprereg.appspot.com",
  messagingSenderId: "963203646451",
  appId: "1:963203646451:web:70d27bc0d01c0704650886",
  measurementId: "G-1FMVGVFPGW",
};

firebase.initializeApp(firebaseConfig);

var mouseDown = false;

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

let schools = [
  "UChicago",
  "Notre Dame",
  "RPI",
  "Dartmouth",
  "Northeastern",
  "Western University",
  "UMass",
];
let coursesData = [
  uchicagoCourses,
  notreDameCourses,
  rpiCourses,
  dartmouthCourses,
  northeasternCourses,
  westernUniversityCourses,
  umassCourses,
];
let isItAddDrop = [true, false, true, true, false, false, false];
let termTimes = [
  1709625600, 1709625600, 1709625600, 1709625600, 1709625600, 1709625600,
  1709625600, 1723217440, 1732127393,
];
let userSchool = window.sessionStorage.getItem("userSchool");

let classes = [];
let classTitles = [];
let classTitlesNoDuplicates = [];

if (userSchool != null && schools.includes(userSchool)) {
  for (var g = 0; g < coursesData[schools.indexOf(userSchool)].length; g++) {
    classes.push(coursesData[schools.indexOf(userSchool)][g].course);
    classTitles.push(
      `${coursesData[schools.indexOf(userSchool)][g].course.subject} - ${
        coursesData[schools.indexOf(userSchool)][g].course.name
      }`
    );
  }
}

classTitlesNoDuplicates = [...new Set(classTitles)];

let numClassesEval = [];
if (window.sessionStorage.getItem("numClasses") != null) {
  for (
    let j = 0;
    j < parseFloat(window.sessionStorage.getItem("numClasses"));
    j++
  ) {
    numClassesEval.push(0);
  }
}

let classSelected1New = [];

if (window.sessionStorage.getItem("classSectionsSelected1") != null) {
  classSelected1New.push(
    classes.filter(
      (course) =>
        course.subject.toLowerCase() ==
        window.sessionStorage.getItem("subjectSelected1").toLowerCase()
    )
  );
}

let sectionsSelected1New = [];

if (window.sessionStorage.getItem("classSectionsSelected1") != null) {
  let sectionsSelected1 = window.sessionStorage
    .getItem("classSectionsSelected1")
    .split(",");

  for (var e = 0; e < sectionsSelected1.length; e++) {
    sectionsSelected1New.push(parseInt(sectionsSelected1[e]));
  }
}

function EmailsWidget() {
  useEffect(() => {
    const userSchool = sessionStorage.getItem("userSchool");
    const userPhone = sessionStorage.getItem("userPhone");
    if (!userSchool) {
      window.sessionStorage.setItem("sendToEmailsWidget", "yes");
      window.location.href = "/selectschool";
    }
    if (!userPhone) {
      window.location.href = "/preferences";
    }
  }, []);

  const [standardUsers, setStandardUsers] = useState([]);

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection("grabLeads")
      .where(
        "dateTime",
        ">",
        termTimes[schools.indexOf(window.sessionStorage.getItem("userSchool"))]
      )
      .onSnapshot((querySnapshot) => {
        let standardUsers = [];
        querySnapshot.forEach((doc) => {
          if (doc.data().addDelay == false) {
            standardUsers.push(doc.data());
          }
        });
        setStandardUsers(standardUsers);
      });
    return () => {
      unsubscribe();
    };
  }, []);

  let currentDateInSeconds = new Date().getTime() / 1000;

  // let [userName, setUserName] = useState(window.sessionStorage.getItem('userName')==null?'':window.sessionStorage.getItem('userName'));
  let [userName, setUserName] = useState(
    window.location.href.includes("?")
      ? window.location.href.split("?")[1].split("+").join(" ")
      : window.sessionStorage.getItem("userName") == null
      ? ""
      : window.sessionStorage.getItem("userName")
  );

  let [userPhone, setUserPhone] = useState(
    window.sessionStorage.getItem("userPhone") == null
      ? ""
      : window.sessionStorage.getItem("userPhone")
  );
  let [wantsEmails, setWantsEmails] = useState(
    window.sessionStorage.getItem("wantsEmails") == null
      ? "no"
      : window.sessionStorage.getItem("wantsEmails")
  );
  let [buttonClicked, setButtonClicked] = useState(false);

  let [numClasses, setNumClasses] = useState(
    window.sessionStorage.getItem("numClasses") == null ? [] : numClassesEval
  );
  let [emailTemplate, setEmailTemplate] = useState("");
  let [templateLoading, setTemplateLoading] = useState(false);

  let [classTextInput1, setClassTextInput1] = useState(
    window.sessionStorage.getItem("classTextInput1") == null
      ? ""
      : window.sessionStorage.getItem("classTextInput1")
  );
  let [classSelected1, setClassSelected1] = useState(
    window.sessionStorage.getItem("classSectionsSelected1") == null
      ? []
      : classSelected1New[0]
  );
  let [subjectSelected1, setSubjectSelected1] = useState(
    window.sessionStorage.getItem("subjectSelected1") == null
      ? ""
      : window.sessionStorage.getItem("subjectSelected1")
  );
  let [classSectionsSelected1, setClassSectionsSelected1] = useState(
    window.sessionStorage.getItem("classSectionsSelected1") == null
      ? []
      : sectionsSelected1New
  );

  let [fuseSearch1, setFuseSearch1] = useState([]);

  let [showFuseList1, setShowFuseList1] = useState(true);

  let [submitAlert, setSubmitAlert] = useState("");

  let className;
  let topOuterDivider;
  let bottomOuterDivider;
  let topDivider;
  let bottomDivider;
  let hasBgColor;
  let invertColor;

  const outerClasses = classNames(
    "signin section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "signin-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const sectionHeader = {
    title:
      isItAddDrop[schools.indexOf(userSchool)] == true
        ? "Draft Personalized Emails to Professors"
        : "Draft Personalized Emails to Professors (Last Term)",
    // paragragph: 'The first 100 students get free access for this add period.'
  };

  async function submitRequest(event) {
    setSubmitAlert("");
    event.preventDefault();
    if (userName == "") {
      document.getElementById("nameInput").classList.add("form-error");
      document.getElementById("nameBlankHint").style.display = "block";
      //document.location.href='#nameInput'
    }
    // else if (userPhone == '') {
    //   document.getElementById('phoneInput').classList.add("form-error");
    //   document.getElementById('phoneBlankHint').style.display='block';
    //   //document.location.href='#phoneInput'
    // } else if (/[a-zA-Z]/g.test(userPhone)==true) {
    //   document.getElementById('phoneInput').classList.add("form-error");
    //   document.getElementById('phoneLetterHint').style.display='block';
    //   //document.location.href='#phoneInput'

    // } else if (userPhone.length < 10) {
    //   document.getElementById('phoneInput').classList.add("form-error")
    //   document.getElementById('phoneShortHint').style.display='block';
    //   //document.location.href='#phoneInput'

    // } else if(userPhone.includes('@') || userPhone.includes('-') || userPhone.includes(' ') || userPhone.includes('_') || userPhone.includes('(') || userPhone.includes(')') || userPhone.includes('[') || userPhone.includes(']') || userPhone.includes('—') || userPhone.includes('.') || userPhone.includes('/') || userPhone.includes(',') || userPhone.includes('*') || userPhone.includes('~')){
    //   document.getElementById('phoneInput').classList.add("form-error")
    //   document.getElementById('phoneSymbolHint').style.display='block';
    //   //document.location.href='#phoneInput'

    // }
    else if (classSectionsSelected1.length == 0) {
      document.getElementById("classTitleInput1").classList.add("form-error");
      document.getElementById("classSelectHint1").style.display = "block";
      //document.location.href='#classTitleInput1'
    }
    // else if(!standardUsers.map(a => a.phone).includes(window.sessionStorage.getItem('userPhone'))){
    //   setSubmitAlert('It looks like you are on a free plan. Upgrade from your preferences to access this tool.')
    // }
    else {
      if (buttonClicked == false) {
        setButtonClicked(true);
        document.getElementById("nameInput").classList.add("form-success");
        // document.getElementById('phoneInput').classList.add("form-success")
        setTemplateLoading(true);
        setEmailTemplate("");

        // window.sessionStorage.setItem('userName', userName);
        if (userPhone.includes("+")) {
          window.sessionStorage.setItem("userPhone", userPhone);
        } else {
          window.sessionStorage.setItem("userPhone", `+${userPhone}`);
        }

        window.sessionStorage.setItem("classTextInput1", classTextInput1);
        window.sessionStorage.setItem("subjectSelected1", subjectSelected1);
        window.sessionStorage.setItem(
          "classSectionsSelected1",
          classSectionsSelected1
        );

        await firebase
          .firestore()
          .collection("emailsWidget")
          .add({
            name: userName,
            school: window.sessionStorage.getItem("userSchool"),
            // phone: userPhone,
            dateTime: currentDateInSeconds,
            dateTimeReverse: -currentDateInSeconds,
            classSubject: subjectSelected1,
            classSections: classSectionsSelected1,
          })
          .then(async () => {
            await fetch(
              "https://us-central1-uchiprereg.cloudfunctions.net/templatemail/emails-widget",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Methods": "GET, POST",
                  "Access-Control-Allow-Headers": "Content-Type",
                  "Access-Control-Max-Age": "3600",
                },
                body: JSON.stringify({
                  userName: userName,
                  userPhone: userPhone.includes("+")
                    ? userPhone
                    : `+${userPhone}`,
                  description: classSelected1[0].description,
                  prof: classSelected1[0].sections.filter(
                    (a) => a.sectionNumber == classSectionsSelected1[0]
                  )[0].sectionProf,
                  time: classSelected1[0].sections.filter(
                    (a) => a.sectionNumber == classSectionsSelected1[0]
                  )[0].sectionTime,
                  lab: classSelected1[0].sections.filter(
                    (a) => a.sectionNumber == classSectionsSelected1[0]
                  )[0].lab,

                  // subjectSelected1: subjectSelected1,
                }),
              }
            )
              // .then(res => res.json())
              .then((res) => res.text())
              // .then(awesome => console.log(awesome))
              .then(function (openaiResult) {
                document.getElementById("templateEmailDiv").innerHTML = "";
                //document.getElementById('emailBox').style.display='block'
                let node = document.createElement("div");
                let textNode = document.createTextNode(openaiResult);
                node.appendChild(textNode);
                document.getElementById("templateEmailDiv").appendChild(node);
                setEmailTemplate(openaiResult);
                setTemplateLoading(false);
                setButtonClicked(false);
              })
              .catch((err) => {
                console.log(err);
                // sendInData()
              });

            // await doTheRest()
          });
      }
    }
  }

  function changeNameInput(event) {
    setUserName(event.target.value);
    document.getElementById("nameInput").classList.remove("form-error");
    document.getElementById("nameBlankHint").style.display = "none";
    document.getElementById("nameSpaceHint").style.display = "none";
    document.getElementById("nameLettersHint").style.display = "none";
    window.sessionStorage.removeItem("infoSuccess");
  }

  function changePhoneInput(event) {
    setUserPhone(event.target.value);
    document.getElementById("phoneInput").classList.remove("form-error");
    document.getElementById("phoneBlankHint").style.display = "none";
    document.getElementById("phoneShortHint").style.display = "none";
    document.getElementById("phoneLetterHint").style.display = "none";
    document.getElementById("phoneSymbolHint").style.display = "none";
    window.sessionStorage.removeItem("infoSuccess");
  }

  async function copyPasteLink(event) {
    await navigator.clipboard.writeText(emailTemplate);
    showCopiedComputer(event);
  }

  async function refreshTemplate(event) {
    showCopiedComputer2(event);
    await submitRequest(event);
  }

  // window.onclick = (event) => {console.log(document.getElementById('preorderTooltipText').getBoundingClientRect())};

  window.ontouchmove = (event) => {
    hideTooltip();
  };

  function hideTooltip(event) {
    let leftLim = document
      .getElementById("preorderTooltipText")
      .getBoundingClientRect().left;
    let rightLim = document
      .getElementById("preorderTooltipText")
      .getBoundingClientRect().right;
    let topLim = document
      .getElementById("preorderTooltipText")
      .getBoundingClientRect().top;
    let bottomLim = document
      .getElementById("preorderTooltipText")
      .getBoundingClientRect().bottom;

    // if((event.touches[0].clientX < rightLim)&&(event.touches[0].clientX > leftLim)&&(event.touches[0].clientY < topLim)&&(event.touches[0].clientY > bottomLim)){
    //   event.preventDefault()
    // }else{
    document.getElementById("preorderTooltipText").style.visibility = "hidden";
    // }
  }

  function clickTooltip(event) {
    document.getElementById("preorderTooltipText").style.visibility = "visible";
    event.preventDefault();
    document
      .getElementById("preorderTooltipText")
      .classList.remove("fadeOut2Class");
    void document.getElementById("preorderTooltipText").offsetWidth;
    document
      .getElementById("preorderTooltipText")
      .classList.add("fadeOut2Class");
  }

  function clickTooltip2(event) {
    document.getElementById("preorderTooltipText2").style.visibility =
      "visible";
    event.preventDefault();
    document
      .getElementById("preorderTooltipText2")
      .classList.remove("fadeOut2Class");
    void document.getElementById("preorderTooltipText2").offsetWidth;
    document
      .getElementById("preorderTooltipText2")
      .classList.add("fadeOut2Class");
  }

  function showCopiedComputer(event) {
    if (window.mobileCheck() == false) {
      // document.getElementById('preorderTooltipText').style.visibility='hidden'
      document.getElementById("preorderTooltipTextComputer").style.visibility =
        "visible";
      event.preventDefault();
      document
        .getElementById("preorderTooltipTextComputer")
        .classList.remove("fadeOut2Class");
      void document.getElementById("preorderTooltipTextComputer").offsetWidth;
      document
        .getElementById("preorderTooltipTextComputer")
        .classList.add("fadeOut2Class");
      // document.getElementById('preorderTooltipText').style.visibility='visible'
    }
  }

  function showCopiedComputer2(event) {
    if (window.mobileCheck() == false) {
      document.getElementById("preorderTooltipTextComputer2").style.visibility =
        "visible";
      event.preventDefault();
      document
        .getElementById("preorderTooltipTextComputer2")
        .classList.remove("fadeOut2Class");
      void document.getElementById("preorderTooltipTextComputer2").offsetWidth;
      document
        .getElementById("preorderTooltipTextComputer2")
        .classList.add("fadeOut2Class");
    }
  }

  return (
    <section
      // {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <div
            style={{
              fontWeight: "bold",
              fontSize: "50px",
              position: "absolute",
              top: "0.75rem",
            }}
          >
            <a
              style={{textDecoration: "none"}}
              href={`/preferences#${window.sessionStorage.getItem(
                "userPhone"
              )}`}
            >
              ←{/* <Button color='primary'>Home</Button> */}
            </a>
          </div>

          <SectionHeader
            tag="h1"
            data={sectionHeader}
            className="center-content"
            style={{paddingBottom: "24px", marginTop: "0.75rem"}}
          />
          <div
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              textAlign: "center",
              marginTop: "0px",
            }}
            class="emailsWidgetSub"
          >
            {" "}
            This email will include relevant information about their class. We
            don’t send emails to professors directly.
          </div>

          <div className="tiles-wrap">
            <div className="tiles-item">
              <div className="tiles-item-inner">
                {/* We will text you when a spot in the class opens up. */}
                <form style={{marginTop: "1rem", width: "100%"}}>
                  <fieldset>
                    <div className="mb-12">
                      <input
                        label="Full name"
                        placeholder="Full name"
                        labelHidden
                        id="nameInput"
                        required
                        className="form-input"
                        onChange={changeNameInput}
                        value={userName}
                      />
                    </div>
                    <div
                      id="nameBlankHint"
                      style={{display: "none"}}
                      class="form-hint text-color-error"
                    >
                      Please enter your name
                    </div>
                    <div
                      id="nameSpaceHint"
                      style={{display: "none"}}
                      class="form-hint text-color-error"
                    >
                      Please enter your first and last name.
                    </div>
                    <div
                      id="nameLettersHint"
                      style={{display: "none"}}
                      class="form-hint text-color-error"
                    >
                      Please use only letters.
                    </div>
                    {/* <div className="mb-12">
                        <input
                          type="text"
                          maxlength="14"
                          label="Phone"
                          placeholder="Phone number"
                          className= "form-input"
                          labelHidden
                          required 
                          id = "phoneInput"
                          onChange={changePhoneInput}
                          value={userPhone}
                          />
                      </div>
                      
                      <div id='phoneBlankHint' style={{display:'none'}} class="form-hint text-color-error">
                        Please enter your phone number
                      </div>
                      <div id='phoneShortHint' style={{display:'none'}} class="form-hint text-color-error">
                        Please enter a valid phone number
                      </div>
                      <div id='phoneLetterHint' style={{display:'none'}} class="form-hint text-color-error">
                        Please enter a valid phone number
                      </div>
                      <div id='phoneSymbolHint' style={{display:'none'}} class="form-hint text-color-error">
                        Please enter your number in the format: 7037626410. If you would like to specify country code, use this format: +2551234567890.
                      </div> */}

                    {/* <div className="mb-12">
                        <Input
                          type="password"
                          label="Password"
                          placeholder="Password"
                          labelHidden
                          required />
                      </div> */}

                    <div class="tiles-item reveal-from-bottom is-revealed">
                      <div
                        class="tiles-item-inner has-shadow"
                        style={{padding: "15px"}}
                      >
                        <div>
                          <div style={{marginTop: "0rem", fontWeight: "bold"}}>
                            Class
                          </div>

                          <input
                            id="classTitleInput1"
                            type="text"
                            autocomplete="off"
                            placeholder="Class title"
                            onKeyDown={selectFirstOption1}
                            onMouseDown={() => {
                              setClassTextInput1("");
                              setClassSelected1([]);
                              setSubjectSelected1("");
                              setClassSectionsSelected1([]);
                              setFuseSearch1([]);
                              setShowFuseList1(true);
                            }}
                            value={classTextInput1}
                            onChange={changeClassTitleInput1}
                            className="form-input"
                          />

                          {fuseSearch1 != classTitlesNoDuplicates &&
                          fuseSearch1.length != 0 &&
                          showFuseList1 == true ? (
                            <div>
                              <div style={{height: "0.75rem"}}></div>
                              {fuseSearch1.map((course, courseIndex) => {
                                if (courseIndex < 7) {
                                  return (
                                    <div
                                      onClick={(event) => {
                                        clickFuseElement1(course.item);
                                      }}
                                      id={course.item}
                                      class="selectClass"
                                    >
                                      {course.item}
                                    </div>
                                  );
                                }
                              })}
                            </div>
                          ) : null}
                        </div>

                        <div
                          style={{
                            display:
                              classSelected1.length != 0 ? "block" : "none",
                            marginTop: "0.5rem",
                            fontWeight: "bold",
                          }}
                          class="noSelectText"
                        >
                          Select the section you want
                        </div>
                        {classSelected1.map((course, courseIndex) => {
                          return (
                            <div>
                              {course.sections.map((section, sectionIndex) => {
                                return (
                                  <div
                                    id={section.sectionNumber}
                                    name="classSlotsSelect"
                                    class="slotSelect"
                                    onMouseDown={clickClassSection1}
                                    //onMouseOver={hoverClassSection1}
                                    style={{
                                      backgroundColor:
                                        classSectionsSelected1.includes(
                                          section.sectionNumber
                                        )
                                          ? "#3D5AFE"
                                          : "white",
                                      color: classSectionsSelected1.includes(
                                        section.sectionNumber
                                      )
                                        ? "white"
                                        : "#575E86",
                                      padding: "0.2rem",
                                      marginTop: "0.3rem",
                                      borderStyle: "solid",
                                      borderColor: "black",
                                      borderWidth: "1px",
                                    }}
                                  >
                                    {section.lab == true ? (
                                      <div
                                        style={{
                                          color: "#33CEFF",
                                          float: "right",
                                          fontSize: "16px",
                                          fontWeight: "",
                                          marginRight: "0.2rem",
                                        }}
                                      >
                                        Lab
                                      </div>
                                    ) : null}
                                    {section.sectionProf.length != 0 ? (
                                      <div
                                        class="noSelectText"
                                        style={{
                                          zIndex: 2,
                                          pointerEvents: "none",
                                        }}
                                      >
                                        {section.sectionName.includes(
                                          "Section"
                                        ) ? (
                                          <div
                                            class="noSelectText"
                                            style={{
                                              zIndex: 1,
                                              pointerEvents: "none",
                                            }}
                                          >
                                            {section.sectionName} (
                                            {section.sectionNumber})
                                          </div>
                                        ) : (
                                          <div
                                            class="noSelectText"
                                            style={{
                                              zIndex: 1,
                                              pointerEvents: "none",
                                            }}
                                          >
                                            Section {section.sectionName} (
                                            {section.sectionNumber})
                                          </div>
                                        )}
                                        <div
                                          class="noSelectText"
                                          style={{
                                            zIndex: 1,
                                            pointerEvents: "none",
                                          }}
                                        >
                                          {section.sectionProf.map(
                                            (prof, profIndex) => {
                                              return (
                                                <div
                                                  style={{
                                                    pointerEvents: "none",
                                                    display: "inline",
                                                  }}
                                                >
                                                  {section.sectionProf.length ==
                                                  1 ? (
                                                    <div
                                                      style={{
                                                        pointerEvents: "none",
                                                        display: "inline",
                                                      }}
                                                    >
                                                      {prof}
                                                    </div>
                                                  ) : profIndex !=
                                                      section.sectionProf
                                                        .length -
                                                        1 &&
                                                    section.sectionProf
                                                      .length == 2 ? (
                                                    <div
                                                      style={{
                                                        pointerEvents: "none",
                                                        display: "inline",
                                                      }}
                                                    >
                                                      {prof}&nbsp;
                                                    </div>
                                                  ) : profIndex !=
                                                      section.sectionProf
                                                        .length -
                                                        1 &&
                                                    section.sectionProf.length >
                                                      2 ? (
                                                    <div
                                                      style={{
                                                        pointerEvents: "none",
                                                        display: "inline",
                                                      }}
                                                    >
                                                      {prof},&nbsp;
                                                    </div>
                                                  ) : (
                                                    <div
                                                      style={{
                                                        pointerEvents: "none",
                                                        display: "inline",
                                                      }}
                                                    >
                                                      and&nbsp;{prof}
                                                    </div>
                                                  )}
                                                </div>
                                              );
                                            }
                                          )}
                                        </div>
                                      </div>
                                    ) : section.sectionName.includes(
                                        "Section"
                                      ) ? (
                                      <div
                                        class="noSelectText"
                                        style={{
                                          zIndex: 1,
                                          pointerEvents: "none",
                                        }}
                                      >
                                        {section.sectionName} (
                                        {section.sectionNumber})
                                      </div>
                                    ) : (
                                      <div
                                        class="noSelectText"
                                        style={{
                                          zIndex: 1,
                                          pointerEvents: "none",
                                        }}
                                      >
                                        Section {section.sectionName} (
                                        {section.sectionNumber})
                                      </div>
                                    )}
                                    {section.sectionTime != null ? (
                                      <div
                                        class="noSelectText"
                                        style={{
                                          zIndex: 2,
                                          pointerEvents: "none",
                                        }}
                                      >
                                        {section.sectionTime}
                                      </div>
                                    ) : null}
                                  </div>
                                );
                              })}
                            </div>
                          );

                          function clickClassSection1(event) {
                            document
                              .getElementById("classTitleInput1")
                              .classList.remove("form-error");
                            document.getElementById(
                              "classSelectHint1"
                            ).style.display = "none";

                            // if(classSectionsSelected1.length==0){
                            //   setClassSectionsSelected1([event.target.id])
                            //   document.getElementById(event.target.id).style.backgroundColor='#3D5AFE';
                            //   document.getElementById(event.target.id).style.color='white';
                            // }else if(){

                            // }
                            // else{
                            //   setClassSectionsSelected1([])
                            //   document.getElementById(event.target.id).style.backgroundColor='white';
                            //   document.getElementById(event.target.id).style.color='#575E86';
                            // }

                            if (classSectionsSelected1.length == 0) {
                              setClassSectionsSelected1([
                                parseFloat(event.target.id),
                              ]);
                              document.getElementById(
                                event.target.id
                              ).style.backgroundColor = "#3D5AFE";
                              document.getElementById(
                                event.target.id
                              ).style.color = "white";
                            } else if (
                              classSectionsSelected1[0] == event.target.id
                            ) {
                              setClassSectionsSelected1([]);
                              document.getElementById(
                                event.target.id
                              ).style.backgroundColor = "white";
                              document.getElementById(
                                event.target.id
                              ).style.color = "#575E86";
                            } else {
                              document.getElementById(
                                event.target.id
                              ).style.backgroundColor = "#3D5AFE";
                              document.getElementById(
                                event.target.id
                              ).style.color = "white";
                              setClassSectionsSelected1([
                                parseFloat(event.target.id),
                              ]);
                              document.getElementById(
                                classSectionsSelected1[0]
                              ).style.backgroundColor = "white";
                              document.getElementById(
                                classSectionsSelected1[0]
                              ).style.color = "#575E86";
                            }

                            // if(!classSectionsSelected1.includes(event.target.id)){
                            //   document.getElementById(event.target.id).style.backgroundColor='#3D5AFE';
                            //   document.getElementById(event.target.id).style.color='white';
                            //   setClassSectionsSelected1([...classSectionsSelected1, event.target.id])
                            // }else{
                            //   document.getElementById(event.target.id).style.backgroundColor='white';
                            //   document.getElementById(event.target.id).style.color='#575E86';
                            //   setClassSectionsSelected1([
                            //     ...classSectionsSelected1.slice(0, classSectionsSelected1.indexOf(event.target.id)),
                            //     ...classSectionsSelected1.slice(classSectionsSelected1.indexOf(event.target.id) + 1, classSectionsSelected1.length)
                            //   ]);
                            // }
                          }
                        })}
                        <div
                          id="classSelectHint1"
                          style={{display: "none"}}
                          class="form-hint text-color-error"
                        >
                          Please select the class section you would like a
                          drafted email for
                        </div>
                      </div>
                    </div>

                    <div className="mt-24 mb-32">
                      <img
                        src="/ellipsis-white.gif"
                        style={{
                          position: "absolute",
                          height: "45px",
                          left: "0",
                          right: "0",
                          marginLeft: "auto",
                          marginRight: "auto",
                          display: templateLoading == true ? "block" : "none",
                        }}
                      />

                      <Button
                        type="button"
                        color="primary"
                        wide
                        onClick={submitRequest}
                      >
                        <div
                          class="noSelectText"
                          style={{
                            pointerEvents: "none",
                            display:
                              templateLoading == false ? "block" : "none",
                          }}
                        >
                          Draft Email
                        </div>
                      </Button>

                      <div
                        class="text-color-error"
                        style={{fontSize: "16px", marginTop: "0.5rem"}}
                      >
                        {submitAlert}
                      </div>
                    </div>
                  </fieldset>
                </form>

                <img
                  src="https://firebasestorage.googleapis.com/v0/b/uchiprereg.appspot.com/o/loadingWithText.gif?alt=media&token=8791e865-3578-4d4b-bb16-46ccaa007e41"
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: "0rem",
                    width: "100%",
                    display: templateLoading == true ? "block" : "none",
                  }}
                  id="loadingGraphic"
                />
              </div>
            </div>
          </div>

          <div
            className="has-shadow"
            style={{
              padding: "10px",
              display:
                templateLoading == true || emailTemplate == ""
                  ? "none"
                  : "block",

              //display:window.sessionStorage.getItem('infoSuccess')=='true'?'block':'none'
            }}
            id="emailBox"
          >
            <div
              class="copyLinkBox"
              style={{
                //cursor:'grab',
                textAlign: "left",
                marginLeft: "auto",
                marginRight: "auto",
                //borderStyle:'solid', borderWidth:'0.1rem', borderColor:'rgb(87,94,134)',
                backgroundColor: "white",
                fontSize: "16px",
                width: "100%",
                color: "black",
                paddingTop: "20px",
                paddingRight: "20px",
                paddingLeft: "20px",
              }}
            >
              <div
                style={{
                  //cursor:'grab',
                  display: "inline-block",
                  verticalAlign: "middle",
                }}
                id="templateEmailDiv"
              ></div>

              <div style={{display: "table"}}>
                <div
                  style={{
                    cursor: "pointer",
                    display: "table-cell",
                    fontSize: "1.2rem",
                    color: "rgb(13, 98, 255)",
                  }}
                  class="tooltip"
                  onTouchStart={clickTooltip2}
                  id="preorderTooltip2"
                >
                  <img
                    src="repeatSymbol.png"
                    style={{
                      display: "table-cell",
                      height: "2rem",
                      marginTop: "1rem",
                    }}
                    onClick={refreshTemplate}
                  />
                  {window.mobileCheck() == true ? (
                    <span class="tooltiptext" id="preorderTooltipText2">
                      Getting new email
                    </span>
                  ) : (
                    <div style={{pointerEvents: "none"}}>
                      <span class="tooltiptext" id="preorderTooltipText2">
                        Get another email
                      </span>
                      <span
                        class="tooltiptext"
                        id="preorderTooltipTextComputer2"
                        style={{visibility: "hidden"}}
                      >
                        Getting new email
                      </span>
                    </div>
                  )}
                </div>

                <div
                  style={{
                    cursor: "pointer",
                    display: "table-cell",
                    fontSize: "1.2rem",
                    color: "rgb(13, 98, 255)",
                  }}
                  class="tooltip"
                  onTouchStart={clickTooltip}
                  id="preorderTooltip"
                >
                  <img
                    style={{
                      height: "2rem",
                      cursor: "grab",
                      display: "table-cell",
                      verticalAlign: "middle",
                    }}
                    src="CopyToClip.png"
                    onClick={copyPasteLink}
                  />

                  {window.mobileCheck() == true ? (
                    <span class="tooltiptext" id="preorderTooltipText">
                      Copied to clipboard{" "}
                    </span>
                  ) : (
                    <div style={{pointerEvents: "none"}}>
                      <span class="tooltiptext" id="preorderTooltipText">
                        Copy to clipboard{" "}
                      </span>
                      <span
                        class="tooltiptext"
                        id="preorderTooltipTextComputer"
                        style={{visibility: "hidden"}}
                      >
                        Copied to clipboard{" "}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div style={{height: "2rem"}}></div>
          </div>

          <div
            className="signin-bottom has-top-divider"
            style={{marginTop: "0.7rem"}}
          >
            <div className="pt-32 text-xs center-content text-color-low">
              Want to learn more about Pollinate? <Link to="/">Home</Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );

  function clickFuseElement1(prop1) {
    setClassTextInput1(prop1);
    setClassSelected1([]);
    setSubjectSelected1("");
    setClassSectionsSelected1([]);
    setShowFuseList1(false);

    let classesFilter = classes.filter(
      (course) =>
        `${course.subject.toLowerCase()} - ${course.name.toLowerCase()}` ==
        prop1.toLowerCase()
    );

    setClassSelected1(classesFilter);

    if (classesFilter.length > 0) {
      setSubjectSelected1(classesFilter[0].subject);
    }

    // console.log(classesFilter[0].description)
    // console.log(
    //   classesFilter[0].sections.filter(a=> a.sectionNumber==27094)
    //   //.filter(a=> a.sectionNumber==27094)

    //   //filter(a=> a.sectionNumber==27094)
    //   )

    // console.log(classSelected1[0])
    //classSelected1[0].sections.filter(a=> a.sectionNumber==classSectionsSelected1[0])
  }

  function changeClassTitleInput1(event) {
    const fuse = new Fuse(classTitlesNoDuplicates, {
      // keys: ['title', 'author.firstName']
      threshold: 0.6,
    });

    setFuseSearch1(fuse.search(event.target.value));
    setClassTextInput1(event.target.value);

    document.getElementById("classTitleInput1").classList.remove("form-error");
    document.getElementById("classSelectHint1").style.display = "none";
    window.sessionStorage.removeItem("infoSuccess");
  }

  function selectFirstOption1(event) {
    if (event.key === "Enter") {
      event.preventDefault();
      if (fuseSearch1[0] != null) {
        clickFuseElement1(fuseSearch1[0].item);
      }
      document.getElementById("classTitleInput1").blur();
      document
        .getElementById("classTitleInput1")
        .classList.remove("form-error");
      document.getElementById("classSelectHint1").style.display = "none";
    }
  }
}

EmailsWidget.propTypes = propTypes;
EmailsWidget.defaultProps = defaultProps;

export default EmailsWidget;
