import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Image from '../../elements/Image';

const Logo = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'brand',
    className
  );

  return (
    <div
      {...props}
      className={classes}
    >
      <h1 className="m-0" style={{marginTop:'15%'}}>
        <Link to="/">
          <Image
            src={require('./../../../assets/images/ClassClutchWithText.png')}
            // src={window.sessionStorage.getItem('userSchool')=='UChicago'?require('./../../../assets/images/PollinateLogoUChicago.png'):window.sessionStorage.getItem('userSchool')=='Notre Dame'?require('./../../../assets/images/PollinateLogoNotreDame.png'):require('./../../../assets/images/PollinateSocialNoBackgroundSquare.png')}
            // width={window.sessionStorage.getItem('userSchool')==null?90:200}
            // height={window.sessionStorage.getItem('userSchool')==null?90:200} 
            width={90}
            height={90} 
            alt="Class Clutch Logo"
            />
        </Link>
      </h1>
    </div>
  );
}

export default Logo;