import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {SectionTilesProps} from "../../utils/SectionProps";
import {SectionProps} from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Switch from "../elements/Switch";
import Button from "../elements/Button";
// import SignupForm from './SignupForm'
import firebase from "firebase/compat/app";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

let className;
let topOuterDivider;
let bottomOuterDivider;
let topDivider;
let bottomDivider;
let hasBgColor;
let invertColor;
let pushLeft;
let pricingSwitcher;
let pricingSlider;

const outerClasses = classNames(
  "pricing section",
  topOuterDivider && "has-top-divider",
  bottomOuterDivider && "has-bottom-divider",
  hasBgColor && "has-bg-color",
  invertColor && "invert-color",
  className
);

const innerClasses = classNames(
  "pricing-inner section-inner",
  topDivider && "has-top-divider",
  bottomDivider && "has-bottom-divider"
);

const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

const sectionHeader = {
  // title: 'Simple, transparent pricing'
  // title: 'Notification Payment Plans',
  // paragraph:''
};

function PricingLaunch({showSectionHeader, onSelectPlanPage}) {
  let schools = [
    "UChicago",
    "Notre Dame",
    "RPI",
    "Dartmouth",
    "Northeastern",
    "Western University",
    "UMass",
  ];
  let isItAddDrop = [true, false, true, true, false, false, false];

  let currentDateInSeconds = new Date().getTime() / 1000;

  useEffect(() => {
    if (onSelectPlanPage) {
      const userSchool = sessionStorage.getItem("userSchool");
      if (!userSchool) {
        window.location.href = "/";
      } else if (
        window.sessionStorage.getItem("userPhone") == null ||
        window.sessionStorage.getItem("userName") == null
      ) {
        window.location.href = "/getnotifications";
      }
    }
  }, []);

  // function selectFree(){
  //   window.sessionStorage.setItem('signupPlan', 'free')
  //   if(window.sessionStorage.getItem('userSchool')==null){
  //     window.location.href='/selectschool'
  //   }
  //   else if((window.sessionStorage.getItem('userPhone')!=null)&&(window.sessionStorage.getItem('userName')!=null)){
  //     window.location.href='/getnotifications'
  //   }else{
  //     window.location.href='/signup'
  //   }
  // }

  async function selectStandard() {
    if (onSelectPlanPage) {
      // if(isItAddDrop[schools.indexOf(userSchool)]==true){
      if (
        isItAddDrop[
          schools.indexOf(window.sessionStorage.getItem("userSchool"))
        ] == true
      ) {
        // if(referrerPhone!='' && referrerPhone!=null && referrerPhone!=userPhone){

        fetch(
          `https://us-central1-uchiprereg.cloudfunctions.net/server/create-customer`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "GET, POST",
              "Access-Control-Allow-Headers": "Content-Type",
              "Access-Control-Max-Age": "3600",
            },
            body: JSON.stringify({
              // phone:`+${userPhone}`,
              phone: window.sessionStorage.getItem("userPhone").includes("+")
                ? window.sessionStorage.getItem("userPhone")
                : `+${window.sessionStorage.getItem("userPhone")}`,
            }),
          }
        )
          .then((res) => {
            return res.json();
          })
          .catch((error) => {
            console.log(error);
          })
          .then((customer) => {
            fetch(
              `https://us-central1-uchiprereg.cloudfunctions.net/server/create-setup-intent`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Methods": "GET, POST",
                  "Access-Control-Allow-Headers": "Content-Type",
                  "Access-Control-Max-Age": "3600",
                },
                body: JSON.stringify({
                  cusID: customer.customerID,
                }),
              }
            )
              .then((res) => {
                return res.json();
              })
              .then((data) => (window.location.href = data.url))
              .catch((error) => {
                console.log(error);
              });
          });

        // if(referrerPhone!='' && referrerPhone!=null && referrerPhone!=userPhone){

        // fetch(`https://us-central1-uchiprereg.cloudfunctions.net/server/create-checkout-session-${numClasses.length}-referred`, {
        //   method:'POST',
        //   headers: {
        //     'Content-Type': 'application/json',
        //     'Access-Control-Allow-Origin': '*',
        //     'Access-Control-Allow-Methods': 'GET, POST',
        //     'Access-Control-Allow-Headers': 'Content-Type',
        //     'Access-Control-Max-Age': '3600'
        //   },
        //   body: JSON.stringify({
        //     cusID: customer.customerID,
        //   })
        // })
        // .then(res => {
        //   return res.json()
        // })
        // .then(data => window.location.href=data.url)
        // .catch(function() {
        //   console.log('ahhh2');
        // })

        // }else{
        //   fetch(`https://us-central1-uchiprereg.cloudfunctions.net/server/create-checkout-session-subscription`, {
        //     method:'POST',
        //     headers: {
        //       'Content-Type': 'application/json',
        //       'Access-Control-Allow-Origin': '*',
        //       'Access-Control-Allow-Methods': 'GET, POST',
        //       'Access-Control-Allow-Headers': 'Content-Type',
        //       'Access-Control-Max-Age': '3600'
        //     },
        //     body: JSON.stringify({
        //       cusID: customer.customerID,
        //     })
        //   })
        //   .then(res => {
        //     return res.json()
        //   })
        //   .then(data => window.location.href=data.url)
        //   .catch(function() {
        //     console.log('ahhh4');
        //   })

        // }

        // })
      }
    } else {
      window.sessionStorage.setItem("signupPlan", "standard");
      // if(window.sessionStorage.getItem('userSchool')==null){
      //   window.location.href='/selectschool'
      // }
      // else
      if (
        window.sessionStorage.getItem("userPhone") != null &&
        window.sessionStorage.getItem("userName") != null
      ) {
        window.location.href = "/getnotifications";
      } else {
        window.location.href = "/signup";
      }
    }

    //   if(onSelectPlanPage){
    //     window.sessionStorage.setItem('signupPlan', 'standard')
    //     await firebase.firestore().collection('launchLeads').add({
    //       dateTime:currentDateInSeconds,
    //       dateTimeReverse:-currentDateInSeconds,
    //       name:window.sessionStorage.getItem('userName'),
    //       phone:window.sessionStorage.getItem('userPhone'),
    //       school:window.sessionStorage.getItem('userSchool'),
    //       plan:'standard'
    //     }).then((docRef) => {
    //       const launchDocID = docRef.id;
    //       window.sessionStorage.setItem('launchDocID', launchDocID);
    //     })

    //     .then(() => {
    //       fetch(`https://us-central1-uchiprereg.cloudfunctions.net/server/create-customer`, {
    //         method:'POST',
    //         headers: {
    //           'Content-Type': 'application/json',
    //           'Access-Control-Allow-Origin': '*',
    //           'Access-Control-Allow-Methods': 'GET, POST',
    //           'Access-Control-Allow-Headers': 'Content-Type',
    //           'Access-Control-Max-Age': '3600'
    //         },
    //         body: JSON.stringify({
    //           phone: window.sessionStorage.getItem('userPhone'),
    //         })
    //       })
    //       .then(res => {
    //         return res.json()
    //       })
    //       .catch(function() {
    //         console.log('ahhh1');
    //       })
    //       .then(customer => {

    //         fetch(`https://us-central1-uchiprereg.cloudfunctions.net/server/create-setup-intent`, {
    //           method:'POST',
    //           headers: {
    //             'Content-Type': 'application/json',
    //             'Access-Control-Allow-Origin': '*',
    //             'Access-Control-Allow-Methods': 'GET, POST',
    //             'Access-Control-Allow-Headers': 'Content-Type',
    //             'Access-Control-Max-Age': '3600'
    //           },
    //           body: JSON.stringify({
    //             cusID: customer.customerID,
    //           })
    //         })
    //         .then(res => {
    //           return res.json()
    //         })
    //         .then(data => window.location.href=data.url)
    //         .catch(function() {
    //           console.log('ahhh2');
    //         })

    //       })
    //     })

    //   }else{
    //   window.sessionStorage.setItem('signupPlan', 'standard')
    //   window.location.href='#launch'

    //   // if(window.sessionStorage.getItem('userSchool')==null){
    //   //   window.location.href='/selectschool'
    //   // }
    //   // else if((window.sessionStorage.getItem('userPhone')!=null)&&(window.sessionStorage.getItem('userName')!=null)){
    //   //   window.location.href='/getnotifications'
    //   // }else{
    //   //   window.location.href='/signup'
    //   // }

    // }
  }

  async function selectPremium() {
    if (onSelectPlanPage) {
      window.sessionStorage.setItem("signupPlan", "premium");
      await firebase
        .firestore()
        .collection("launchLeads")
        .add({
          dateTime: currentDateInSeconds,
          dateTimeReverse: -currentDateInSeconds,
          name: window.sessionStorage.getItem("userName"),
          phone: window.sessionStorage.getItem("userPhone"),
          school: window.sessionStorage.getItem("userSchool"),
          plan: "premium",
        })
        .then((docRef) => {
          const launchDocID = docRef.id;
          window.sessionStorage.setItem("launchDocID", launchDocID);
        })

        .then(() => {
          fetch(
            `https://us-central1-uchiprereg.cloudfunctions.net/server/create-customer`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET, POST",
                "Access-Control-Allow-Headers": "Content-Type",
                "Access-Control-Max-Age": "3600",
              },
              body: JSON.stringify({
                phone: window.sessionStorage.getItem("userPhone"),
              }),
            }
          )
            .then((res) => {
              return res.json();
            })
            .catch(function () {
              console.log("ahhh1");
            })
            .then((customer) => {
              fetch(
                `https://us-central1-uchiprereg.cloudfunctions.net/server/create-setup-intent`,
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, POST",
                    "Access-Control-Allow-Headers": "Content-Type",
                    "Access-Control-Max-Age": "3600",
                  },
                  body: JSON.stringify({
                    cusID: customer.customerID,
                  }),
                }
              )
                .then((res) => {
                  return res.json();
                })
                .then((data) => (window.location.href = data.url))
                .catch(function () {
                  console.log("ahhh2");
                });
            });
        });
    } else {
      window.sessionStorage.setItem("signupPlan", "premium");
      window.location.href = "#launch";

      // if(window.sessionStorage.getItem('userSchool')==null){
      //   window.location.href='/selectschool'
      // }
      // else if((window.sessionStorage.getItem('userPhone')!=null)&&(window.sessionStorage.getItem('userName')!=null)){
      //   window.location.href='/getnotifications'
      // }else{
      //   window.location.href='/signup'
      // }
    }
  }

  async function selectPlatinum() {
    if (onSelectPlanPage) {
      window.sessionStorage.setItem("signupPlan", "platinum");
      await firebase
        .firestore()
        .collection("launchLeads")
        .add({
          dateTime: currentDateInSeconds,
          dateTimeReverse: -currentDateInSeconds,
          name: window.sessionStorage.getItem("userName"),
          phone: window.sessionStorage.getItem("userPhone"),
          school: window.sessionStorage.getItem("userSchool"),
          plan: "platinum",
        })
        .then((docRef) => {
          const launchDocID = docRef.id;
          window.sessionStorage.setItem("launchDocID", launchDocID);
        })

        .then(() => {
          fetch(
            `https://us-central1-uchiprereg.cloudfunctions.net/server/create-customer`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET, POST",
                "Access-Control-Allow-Headers": "Content-Type",
                "Access-Control-Max-Age": "3600",
              },
              body: JSON.stringify({
                phone: window.sessionStorage.getItem("userPhone"),
              }),
            }
          )
            .then((res) => {
              return res.json();
            })
            .catch(function () {
              console.log("ahhh1");
            })
            .then((customer) => {
              fetch(
                `https://us-central1-uchiprereg.cloudfunctions.net/server/create-setup-intent`,
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, POST",
                    "Access-Control-Allow-Headers": "Content-Type",
                    "Access-Control-Max-Age": "3600",
                  },
                  body: JSON.stringify({
                    cusID: customer.customerID,
                  }),
                }
              )
                .then((res) => {
                  return res.json();
                })
                .then((data) => (window.location.href = data.url))
                .catch(function () {
                  console.log("ahhh2");
                });
            });
        });
    } else {
      window.sessionStorage.setItem("signupPlan", "platinum");
      window.location.href = "#launch";

      // if(window.sessionStorage.getItem('userSchool')==null){
      //   window.location.href='/selectschool'
      // }
      // else if((window.sessionStorage.getItem('userPhone')!=null)&&(window.sessionStorage.getItem('userName')!=null)){
      //   window.location.href='/getnotifications'
      // }else{
      //   window.location.href='/signup'
      // }
    }
  }

  return (
    <section
      // {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          {/* <h2 style={{textAlign: 'center'}} className={onSelectPlanPage?"mt-0 mb-16":"mt-0 mb-16 reveal-from-top"} data-reveal-delay="150">
          We only charge you when we send notifications   
          </h2>
          <p style={{textAlign: 'center'}} className={onSelectPlanPage?"mt-0 mb-16":"mt-0 mb-16 reveal-from-top"} data-reveal-delay="150">
          Streamlined Billing – Enter your credit card details now for future notification-based payments
          </p> */}
          <div className={tilesClasses}>
            {/* <div className="tiles-item reveal-from-bottom">
                <div className="tiles-item-inner has-shadow">
                  <div className="pricing-item-content">
                    <div className="pricing-item-header pb-24 mb-24" style={{fontWeight:'bold'}}>
                      Free
                      <div className="pricing-item-price mb-4">
                      <span className="pricing-item-price-currency h3">
                        $
                      </span>
                        <span className="pricing-item-price-amount h1">
                          0
                        </span>
                        <span className="pricing-item-price-after text-sm">/week</span>
                      </div>
                    </div>
                    <div className="pricing-item-features mb-40">
                      <div className="pricing-item-features-title h6 text-xs text-color-high mb-24">
                        What’s included
                      </div>
                      <ul className="pricing-item-features-list list-reset text-xs mb-34">
                      <li className="is-not-checked" style={{flexWrap:'wrap'}}><div><s>Instant SMS notifications</s></div><div style={{marginLeft:'40px'}}>SMS notifications delayed 3 hours</div></li>
                      <li className="is-checked" >Track 1 class / term</li>
                      <li className="is-checked" >Track unlimited class sections</li>
                      <li className="is-checked" style={{flexWrap:'wrap'}}><div>SMS ads</div></li>
                      </ul>
                    </div>
                  </div>
                  <div className="pricing-item-cta mb-8">
                  {window.sessionStorage.getItem('userSchool')!=null?
                    isItAddDrop[schools.indexOf(window.sessionStorage.getItem('userSchool'))]==true?
                    <Button type='button' color="primary" wide onClick={selectFree}>
                      Sign Up
                    </Button>
                  :null
                  :null}
                  </div>
                </div>
              </div> */}

            <div
              className={
                onSelectPlanPage
                  ? "tiles-item"
                  : "tiles-item reveal-from-bottom"
              }
            >
              <div className="tiles-item has-shadow" style={{padding: "24px"}}>
                <div className="pricing-item-content">
                  <div
                    className="pricing-item-header pb-24 mb-24"
                    style={{fontWeight: "bold"}}
                  >
                    Unlimited
                    <div className="pricing-item-price mb-4">
                      <span className="pricing-item-price-currency h3">$</span>
                      <span className="pricing-item-price-amount h1">25</span>
                      <span className="pricing-item-price-after text-sm">
                        /quarter
                      </span>
                    </div>
                  </div>
                  <div className="pricing-item-features mb-40">
                    <div className="pricing-item-features-title h6 text-xs text-color-high mb-24">
                      Get notified when any class you want opens up. Our
                      monitoring is instant, so you have will have a huge
                      advantage for registration.
                    </div>
                    <ul className="pricing-item-features-list text-xs mb-34">
                      {/* <li className="is-checked" >7-Day free trial</li> */}
                      <li className="is-checked">
                        Track unlimited classes / term
                      </li>
                      <li className="is-checked">
                        Track unlimited class sections
                      </li>
                      {/* <li className="is-checked" style={{flexWrap:'wrap'}}>
                        <div>
                          Money back guarantee
                        </div>
                        <div style={{marginLeft:'40px', fontSize:'14px'}}>
                          *Refund if you don’t receive texts
                        </div>
                        </li> */}
                      <li className="is-checked">Instant SMS notifications</li>
                      {/* <li className="is-checked" >Instant email notifications</li> */}
                      {/* <li className="is-checked" >AI professor email drafting tool</li> */}
                    </ul>
                  </div>
                </div>
                <div className="pricing-item-cta mb-8">
                  <Button
                    type="button"
                    color="primary"
                    wide
                    onClick={selectStandard}
                  >
                    Sign Up
                  </Button>
                </div>
              </div>
            </div>

            {/* <div className="tiles-item reveal-from-bottom">
                <div className="tiles-item-inner has-shadow">
                  <div className="pricing-item-content">
                    <div className="pricing-item-header pb-24 mb-24" style={{fontWeight:'bold'}}>
                       Premium 
                      <div className="pricing-item-price mb-4">
                        <span className="pricing-item-price-currency h3">
                        $
                        </span>
                        <span className="pricing-item-price-amount h1">
                          25
                        </span>
                        <span className="pricing-item-price-after text-sm">/semester</span>
                      </div>
                    </div>
                    <div className="pricing-item-features mb-40">
                      <div className="pricing-item-features-title h6 text-xs text-color-high mb-24">
                      Unlimited notifications all semester long. Perfect for the active class switcher.
                      </div>
                      <ul className="pricing-item-features-list text-xs mb-34">
                        <li className="is-checked" >Track unlimited classes / term</li>
                        <li className="is-checked" >Track unlimited class sections</li>
                        <li className="is-checked" >Ad free notifications</li>
                        <li className="is-checked" style={{flexWrap:'wrap'}}>
                        <div>
                          Money back guarantee
                        </div>
                        <div style={{marginLeft:'40px', fontSize:'14px'}}>
                          *Refund if you don’t receive texts
                        </div>
                        </li>
                        <li className="is-checked" >Instant SMS notifications</li>
                        <li className="is-checked" >AI professor email drafting tool</li>
                        <li className="is-checked" >Instant email notifications</li>
                        <li className="is-checked" >24/7 customer support </li>
                        <li className="is-checked" >Scheduling optimization consultation</li>
                        <li className="is-checked" >Graduation requirement process tracking</li>
                      </ul>
                    </div>
                  </div>
                  <div className="pricing-item-cta mb-8">
                  <Button type='button' color="primary" wide onClick={selectPremium}>
                    Join Premium Waitlist
                  </Button>
                  </div>
                </div>
              </div>




              <div className="tiles-item reveal-from-bottom">
                <div className="tiles-item-inner has-shadow">
                  <div className="pricing-item-content">
                    <div className="pricing-item-header pb-24 mb-24" style={{fontWeight:'bold'}}>
                       Platinum 
                      <div className="pricing-item-price mb-4">
                        <span className="pricing-item-price-currency h3">
                        $
                        </span>
                        <span className="pricing-item-price-amount h1">
                          50
                        </span>
                        <span className="pricing-item-price-after text-sm">/year</span>
                      </div>
                    </div>
                    <div className="pricing-item-features mb-40">
                      <div className="pricing-item-features-title h6 text-xs text-color-high mb-24">
                      Stay stress-free the entire year with unlimited alerts.
                      </div>
                      <ul className="pricing-item-features-list text-xs mb-34">
                        <li className="is-checked" >Track unlimited classes / year</li>
                        <li className="is-checked" >Track unlimited class sections</li>
                        <li className="is-checked" >Ad free notifications</li>
                        <li className="is-checked" style={{flexWrap:'wrap'}}>
                        <div>
                          Money back guarantee
                        </div>
                        <div style={{marginLeft:'40px', fontSize:'14px'}}>
                          *Refund if you don’t receive texts
                        </div>
                        </li>
                        <li className="is-checked" >Instant SMS notifications</li>
                        <li className="is-checked" >AI professor email drafting tool</li>
                        <li className="is-checked" >Instant email notifications</li>
                        <li className="is-checked" >24/7 customer support </li>
                        <li className="is-checked" >Scheduling optimization consultation</li>
                        <li className="is-checked" >Graduation requirement process tracking</li>
                      </ul>
                    </div>
                  </div>
                  <div className="pricing-item-cta mb-8">
                  <Button type='button' color="primary" wide onClick={selectPlatinum}>
                    Join Platinum Waitlist
                  </Button>
                  </div>
                </div>
              </div> */}
          </div>
        </div>
        {/* <div style={{ fontSize: '16px', textAlign: 'center', padding: '10px' }}>
          By selecting a plan now, you authorize us to securely store your payment details for future charges. No charges will be made until we text you. Choose a plan to proceed to secure checkout.
          </div> */}
        <hr style={{width: "100%", margin: "0 auto"}} />
      </div>
      <div style={{height: "5rem"}}></div> {/* This is the buffer */}
    </section>
  );
}

PricingLaunch.propTypes = propTypes;
PricingLaunch.defaultProps = defaultProps;

export default PricingLaunch;
