import React from 'react';
import FaqLaunch from '../components/sections/FaqLaunch'
import HeroFull from '../components/sections/HeroFullLaunch';
import PricingLaunchPage from '../components/sections/PricingLaunchPage'
import LaunchingPage from '../components/sections/LaunchingPage'
import FeaturesTabsViz from '../components/sections/NotreDameViz'
import Carousel from '../components/elements/Carousel'
import StripeApp from '../components/sections/StripeApp'
import SignCounter from '../components/sections/SignupCounter';
import FivioHundo from '../components/sections/FivioHundo';
import FreeAccess from '../components/sections/FreeAccess'
// import Clients from '../components/sections/Clients';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import PollinateTestimonial from '../components/sections/PollinateTestimonialsLaunch';
import Pricing from '../components/sections/Pricing2';
import AdvisingPricing from '../components/sections/AdvisingPricing';
import Cta from '../components/sections/CtaLaunch';
import Slider from '../components/sections/Slider';
import GetDataForSS from '../components/sections/GetDataForSS';
import FeaturesSplitConsentRequired from '../components/sections/FeaturesSplitConsentRequired';
import DemoWidget from '../components/sections/DemoWidget';
import ReviewBoard from '../components/sections/ReviewBoardLaunch';
import EmbeddedSite from '../components/sections/EmbeddedSite';
import Button from '../components/elements/Button'
import Image from '../components/elements/Image'
import { Link } from 'react-router-dom';

class WeMoved extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      timer: 20,
      redirectMessage: '',
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      this.setState(prevState => ({ timer: prevState.timer - 1 }), () => {
        if (this.state.timer === 0) {
          this.setState({ redirectMessage: "Let's go! 🚀" });
          setTimeout(() => {
            window.location.href = "https://www.classclutch.com";
          }, 1250);
        }
      });
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }


  render() {
    return (
      <React.Fragment>
      <header class="site-header reveal-from-top is-revealed">
      <div class="site-header-inner" style={{marginRight: '1rem'}}>
      <div class="brand" style={{marginLeft: '1rem'}}>
      <h1 class="m-0" style={{marginTop:'15%'}}>

      <Link to="/">
          <Image
            src={require('./../assets/images/PollinateLogoUChicago.png')}
            width={200}
            height={200} 
            alt="Class Clutch Logo"
            />
        </Link>

      {/* <Link to="/">
          <Image
            src={require('./../assets/images/ClassClutchWithText.png')}
            width={90}
            height={90} 
            alt="Class Clutch Logo"
            />
        </Link> */}
      </h1>
      </div>
      </div>
      </header>

      <section class="hero section center-content illustration-section-01">
      <div className="container-sm">
      <div class="hero-inner section-inner" style={{paddingTop:'0.5rem', margin:'0px', paddingBottom:'0rem'}}>
      <div class="hero-content" style={{marginTop:'6rem'}}>

      <h1 className="mt-0 mb-16 phone-header">
          Hey there Maroon{' '}
          <span className="hand-wave" role="img" aria-labelledby="wave">
            👋
          </span>
        </h1>

        {/* <p class="phone-paragraph">
        </p> */}
        <h4 class="phone-paragraph" style={{fontWeight:'normal'}}>
          We’ve moved to classclutch.com
        </h4>


      </div>

      </div>
      </div>
      </section>

        
        <Button tag="a" href="https://www.classclutch.com" color='primary' style={{width:'306px', marginLeft:'auto', marginRight:'auto'}}>
          Take Me There &nbsp;&#10140;
        </Button>

        <div style={{textAlign:'center', marginTop:'1rem'}}>
          or
        </div>
        <div style={{textAlign:'center', marginTop:'1rem'}}>
          Automatically taking you there in:
        </div>
        <h4 style={{textAlign:'center', marginTop:'1rem'}}>
          {this.state.timer > 0 ? this.state.timer + " seconds" : this.state.redirectMessage}
        </h4>

      </React.Fragment>
    );
  }
}

export default WeMoved;