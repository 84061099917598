import React, {useEffect, useState, useRef} from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import { Link } from 'react-router-dom';
import SectionHeader from './partials/SectionHeader';
import Button from '../elements/Button';
import firebase from 'firebase/compat/app';
import StripeCheck from './StripeCheck'
import {PaymentElement, CardElement, useStripe} from '@stripe/react-stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import Fuse from 'fuse.js';
require('firebase/auth');

const UnlimitedWaitlist = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);


  const handleSubmit = async (e) => {
    e.preventDefault();

    const db = firebase.firestore();
    await db.collection('unlimitedWaitlist').add({
      name,
      email,
    });

    // Send the confirmation email using Firebase Extension
    const firstName = name.split(" ")[0];
    await db.collection('mail').add({
      to: email,
      cc: 'jackogle@uchicago.edu',
      message: {
        subject: 'Thank you for joining Unlimited Plan waitlist!',
        html: `Hi ${firstName} 👋 <br><br>
        Thank you for joining our waitlist for the Unlimited Plan! As a waitlist member, you'll be among the first to get updates, special promotions, and early access.<br><br>
        Unlimited Plan:<br>
        - Track Unlimited classes / term<br>
        - $100/term (3-day free trial)<br>
        - Instant SMS and email notifications<br>
        - AI professor email drafting tool<br>
        - Scheduling optimization consultation<br>
        - Graduation requirement process tracking <br>
        - 24/7 customer support<br><br>
        Stay tuned for more exciting news, and thank you for your support!<br><br>
        Cheers 🐝<br>
        Jack from <a href="https://www.pollinate.work">Pollinate.work</a><br> 
        pollinateteam@gmail.com

        `,
      },
    });


    setName('');
    setEmail('');
    setIsSubmitted(true);
  };

  return (
    <div>
    <a href="/" style={{
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        color: 'inherit',
        position: 'absolute',
        top: '30px',
        left: '30px',
        
      }}>
      <span>&larr;</span>
      <span style={{ marginLeft: '5px' }}>Home</span>
    </a>
    <div style={{   display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 0}}>
      <h1 style={{marginTop:'4rem', marginBottom:'0.1em'}}>Unlimited Plan Waitlist</h1>
      <div style= {{textAlign: "left"}}> 
      <ul>
        <li>Track unlimited classes / term</li>
        <li>$100/term (3-day free trial)</li>
        <li>Instant SMS and email notifications</li>
        <li>AI professor email drafting tool</li>
        <li>Scheduling optimization consultation</li>
        <li>Graduation requirement process tracking </li>
        <li>24/7 customer support</li>
      </ul>
      </div>
      {!isSubmitted && (
      <form onSubmit={handleSubmit} style={{ marginTop: '1rem',display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '10px',}}>
        <fieldset>
          <div className="mb-12" style={{ maxWidth: '300px', display: 'flex', justifyContent: 'center'  }}>
            <input
              label="Full name"
              placeholder="Name"
              labelHidden
              id="nameInput"
              required
              className="form-input"
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
          </div>
          <div
            id="nameBlankHint"
            style={{ display: 'none' }}
            className="form-hint text-color-error"
          >
            Please enter your name
          </div>
          <div
            id="nameSpaceHint"
            style={{ display: 'none' }}
            className="form-hint text-color-error"
          >
            Please enter your first and last name.
          </div>
          <div
            id="nameLettersHint"
            style={{ display: 'none' }}
            className="form-hint text-color-error"
          >
            Please use only letters.
          </div>
          <div className="mb-12" style={{ width: '306px' }}>
            <input
              type="email"
              label="Email"
              placeholder="Email"
              className="form-input"
              labelHidden
              required
              id="emailInput"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
          </div>
          <div
            id="emailBlankHint"
            style={{ display: 'none' }}
            className="form-hint text-color-error"
          >
            Please enter your email
          </div>
          <div
            id="emailInvalidHint"
            style={{ display: 'none' }}
            className="form-hint text-color-error"
          >
            Please enter a valid email address
          </div>
        </fieldset>
        <Button type='submit' style={{ width:'300px'}}color='primary'>Submit</Button>
      </form>
      )}
      {isSubmitted && (
        <div style={{   display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'}}>
          <h3>Success!</h3>
          <p style={{   display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        marginLeft: "3rem",
                        marginReft: "4rem"
                    }}>You've been added to the Unlimited waitlist. Check your email for a confirmation message.</p>
            <a href="/" style={{
        
      }}>

      <Button color='primary' style={{ marginLeft: '5px' }}>Return to Home Page</Button>
    </a>
        </div>
            
      )}
    </div>
    </div>
  );
};


export default UnlimitedWaitlist;