import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Logo from './partials/LogoInvert';
import FooterNav from './partials/FooterNav';
import FooterSocial from './partials/FooterSocial';

const propTypes = {
  topOuterDivider: PropTypes.bool,
  topDivider: PropTypes.bool
}

const defaultProps = {
  topOuterDivider: false,
  topDivider: false
}

class Footer extends React.Component {

  render() {
    const {
      className,
      topOuterDivider,
      topDivider,
      ...props
    } = this.props;

    const classes = classNames(
      'site-footer invert-color center-content-mobile',
      topOuterDivider && 'has-top-divider',
      className
    );

    return (
      <footer
        {...props}
        className={classes}
      >
        <div className="container">
          <div className={
            classNames(
              'site-footer-inner',
              topDivider && 'has-top-divider'
            )}>
            <div className="footer-top space-between text-xxs">
              <Logo />
              {/* <FooterSocial /> */}

              <div style={{marginLeft:'auto', marginLeft:'auto'}}>

              {/* <div style={{display:'table', tableLayout:'fixed', 
                  width:'10rem', marginLeft:'auto', marginRight:'auto', borderSpacing:'0.6rem', borderCollapse:'separate'
                  }}>

                    <a style={{display:'table-cell'}} href='https://www.instagram.com/pollinate.work/' target="blank" rel="noopener noreferrer">
                    <img src='/instagramIcon.jpg'/>
                    </a>

                    <a style={{display:'table-cell'}} href='https://www.tiktok.com/@pollinateteam' target="blank" rel="noopener noreferrer">
                    <img src='/tiktokIcon.jpg' />
                    </a>

                    <a style={{display:'table-cell'}} href='https://www.linkedin.com/company/pollinateteam/' target="blank" rel="noopener noreferrer">
                    <img src='/linkedInIcon.jpg' />
                    </a>


                  </div> */}

                  </div>

            </div>
            <div className="footer-bottom space-between text-xxs invert-order-desktop">
              <FooterNav />
              <div className="footer-copyright">&copy; 2023 Class Clutch, all rights reserved</div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;