import React, {useEffect, useState} from "react";
import classNames from "classnames";
import {SectionProps} from "../../utils/SectionProps";
import {Link} from "react-router-dom";
import SectionHeader from "./partials/SectionHeader";
import Button from "../elements/Button";
import Switch from "../elements/Switch";
import Image from "../elements/Image";
import Select from "../elements/Select";
import Checkbox from "../elements/Checkbox";
import HeroFull from "./HeroFull";
import mixpanel from "mixpanel-browser";
import firebase from "firebase/compat/app";
import PhoneInput from "react-phone-input-2";
import {parsePhoneNumber, isValidNumber} from "libphonenumber-js";
import "react-phone-input-2/lib/style.css";
import StripeCheck from "../sections/StripeCheck";
import {PaymentElement, CardElement, useStripe} from "@stripe/react-stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import {uchicagoCourses} from "../../assets/UChicago";
import {notreDameCourses} from "../../assets/Notre Dame";
import {rpiCourses} from "../../assets/RPI";
import {dartmouthCourses} from "../../assets/Dartmouth";
import {northeasternCourses} from "../../assets/Northeastern";
import {westernUniversityCourses} from "../../assets/Western University";
import {umassCourses} from "../../assets/UMass";
import HeaderForLogin from "../layout/HeaderForLogin";
require("firebase/auth");

const firebaseConfig = {
  apiKey: "AIzaSyAQ1DgZyHU2UDmQ_JDgrEg6th8fUuMOFQg",
  authDomain: "uchiprereg.firebaseapp.com",
  projectId: "uchiprereg",
  storageBucket: "uchiprereg.appspot.com",
  messagingSenderId: "963203646451",
  appId: "1:963203646451:web:70d27bc0d01c0704650886",
  measurementId: "G-1FMVGVFPGW",
};

firebase.initializeApp(firebaseConfig);

var mouseDown = false;

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

window.mobileCheck = function () {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

let freeUsersArray = [
  "+19178168373",
  "+12245091991",
  "+17863505278",
  "+17087108441",
  "+18033711796",
  "+18608374226",
  "+14243929994",
  "+19706857425",
  "+17737987563",
  "+17737888004",
  "+18477906321",
  "+17328815655",
  "+14149165211",
  "+12138148916",
  "+12488029462",
  "+18325422188",
  "+18608636325",
  "+15024456682",
  "+19047902120",
  "+13099896627",
  "+14193200766",
  "+17735104851",
  "+12027632522",
  "+13108099387",
  "+13474098111",
  "+19175289583",
  "+13095306085",
  "+14699997362",
  "+13472057774",
  "+17139279195",
  "+14808866096",
  "+14804344586",
  "+17182003002",
  "+15166972124",
  "+17879402437",
  "+12029998416",
  "+19375578085",
  "+12036130170",
  "+19257259639",
  "+15102489778",
  "+16307439920",
  "+17703095075",
  "+16507769083",
  "+15122307229",
  "+18477785574",
  "+66916963175",
  "+16086300910",
  "+19085653326",
  "+13144794621",
  "+13123752925",
  "+17739529401",
  "+16506002482",
  "+13127186343",
  "+14084422905",
  "+12029265758",
  "+14702345428",
  "+12028137163",
  "+13106999256",
  "+16177758386",
  "+19145485489",
  "+17138909124",
  "+17187043243",
  "+17084913408",
  "+14107035368",
  "+12024598041",
  "+19734204762",
  "+12624758033",
  "+16463509253",
  "+19085176922",
  "+12162690310",
  "+17204417279",
  "+17602201548",
  "+13129786746",
  "+14138013717",
  "+13098572498",
  "+16825530436",
  "+16263532428",
  "+15104074958",
  "+16468412006",
  "+19732168750",
  "+19546818915",
  "+14697664243",
  "+19176484872",
  "+18486675266",
  "+18054555281",
  "+17733068421",
  "+8801765100738",
  "+19257195571",
  "+16502831962",
  "+19738767588",
  "+18608397388",
  "+12032933339",
  "+17736336786",
  "+16023158203",
  "+16154502490",
  "+17735447799",
  "+15736399003",
  "+13802401726",
  "+16126707721",
  "+16128672132",
  "+18184217246",
  //'+17037626410'
];

let schools = [
  "UChicago",
  "Notre Dame",
  "RPI",
  "Dartmouth",
  "Northeastern",
  "Western University",
  "UMass",
];
let coursesData = [
  uchicagoCourses,
  notreDameCourses,
  rpiCourses,
  dartmouthCourses,
  northeasternCourses,
  westernUniversityCourses,
  umassCourses,
];
let termTimes = [
  1709625600, 1709625600, 1709625600, 1709625600, 1709625600, 1709625600,
  1709625600, 1723217440, 1732127393,
];
let isItAddDrop = [true, false, true, true, false, false, false];

function getCountryCode(phoneNumber) {
  try {
    const parsed = parsePhoneNumber(phoneNumber);
    return parsed?.country || "";
  } catch (error) {
    console.error(error);
    return "";
  }
}

function PreferencesForm() {
  let [userName, setUserName] = useState(
    window.sessionStorage.getItem("userName") == null
      ? ""
      : window.sessionStorage.getItem("userName")
  );
  let [userPhone, setUserPhone] = useState(
    window.sessionStorage.getItem("userPhone") == null
      ? ""
      : window.sessionStorage.getItem("userPhone")
  );
  let [userCountry, setUserCountry] = useState(
    window.sessionStorage.getItem("userPhone") == null
      ? ""
      : getCountryCode(window.sessionStorage.getItem("userPhone"))
  );
  let [userSchool, setUserSchool] = useState(
    window.sessionStorage.getItem("userSchool") == null
      ? ""
      : window.sessionStorage.getItem("userSchool")
  );

  let [buttonClicked2, setButtonClicked2] = useState(false);
  let [buttonClicked3, setButtonClicked3] = useState(false);

  let [allData, setAllData] = useState([]);
  let [allDataIDs, setAllDataIDs] = useState([]);
  let [allSubjects, setAllSubjects] = useState([]);
  let [allDataGotClass, setAllDataGotClass] = useState([]);
  let [usersArray, setUsersArray] = useState([]);

  let [usersPhoneArray, setUsersPhoneArray] = useState([]);

  let [lastQuarterClasses, setLastQuarterClasses] = useState(false);
  let [thisQuarterClasses, setThisQuarterClasses] = useState(false);

  let className;
  let topOuterDivider;
  let bottomOuterDivider;
  let topDivider;
  let bottomDivider;
  let hasBgColor;
  let invertColor;

  const outerClasses = classNames(
    "signin section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "signin-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const sectionHeader = {
    title:
      window.location.href.includes("#") && userName != ""
        ? `${userName.split(" ", 1)}’s Account`
        : `Log in to your account`,
    // paragragph: 'The first 100 students get free access for this add period.'
  };

  let currentDateInSeconds = new Date().getTime() / 1000;
  let [buttonClicked, setButtonClicked] = useState(false);
  let [demoLaunched, setDemoLaunched] = useState(false);

  let [step1, setStep1] = useState(false);
  let [step2, setStep2] = useState(false);
  let [step3, setStep3] = useState(false);

  let [afterSuccess, setAfterSuccess] = useState(
    window.sessionStorage.getItem("infoSuccessAddDrop") != null
      ? window.sessionStorage.getItem("infoSuccessAddDrop") == "true"
      : false
  );
  let [finishedLoading, setFinishedLoading] = useState(false);

  let [demoNumbers, setDemoNumbers] = useState([]);

  useEffect(() => {
    firebase
      .firestore()
      .collection("demoLaunches")
      .orderBy("dateTime")
      // .get()
      // .then((querySnapshot) => {
      .onSnapshot((querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            if (
              window.sessionStorage.getItem("userPhone") ==
              change.doc.data().phone
            ) {
              setStep1(change.doc.data().step1);
              setStep2(change.doc.data().step2);
              setStep3(change.doc.data().step3);
            }
          }

          if (change.type === "modified") {
            if (
              window.sessionStorage.getItem("userPhone") ==
              change.doc.data().phone
            ) {
              setStep1(change.doc.data().step1);
              setStep2(change.doc.data().step2);
              setStep3(change.doc.data().step3);

              // if((change.doc.data().step2==true) && (change.doc.data().step3==false)){
              //   document.location.href='#step2'
              // }

              if (change.doc.data().step3 == true) {
                document
                  .getElementById(window.sessionStorage.getItem("demoButton"))
                  .classList.remove("loading");
                // document.location.href='#step3'
              }
            }
          }
        });
      });
  }, [userPhone]);

  useEffect(() => {
    firebase
      .firestore()
      .collection("demoLaunches")
      .orderBy("dateTime")
      // .get()
      // .then((querySnapshot) => {
      .onSnapshot((querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            setDemoNumbers((a) => [...a, change.doc.data().phone]);
          }
        });
      });
  }, []);

  let [usersAllTerms, setUsersAllTerms] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const grabLeadsRef = firebase
        .firestore()
        .collection("grabLeads")
        .orderBy("dateTime");
      const snapshot = await grabLeadsRef.get();

      const promises = snapshot.docs.map(async (doc) => {
        setUsersAllTerms((a) => [...a, doc.data()]);

        if (
          doc.data().dateTime > termTimes[schools.indexOf(userSchool)] &&
          doc.data().gotClass != true &&
          doc.data().classSections.length > 0 &&
          doc.data().classSubject != null
        ) {
          setAllSubjects((a) => [...a, doc.data().classSubject]);
        }

        if (window.location.href.split("#").length > 1) {
          if (
            window.location.href.split("#")[1].includes(doc.data().phone) &&
            doc.data().classSubject != null
          ) {
            setUserName(doc.data().name);
            setUserPhone(doc.data().phone);
            setUserSchool(doc.data().school);
            window.sessionStorage.setItem("userName", doc.data().name);
            window.sessionStorage.setItem("userPhone", doc.data().phone);
            window.sessionStorage.setItem("userSchool", doc.data().school);
            window.sessionStorage.removeItem("classTextInput1");
            window.sessionStorage.removeItem("classTextInput2");
            window.sessionStorage.removeItem("classTextInput3");
            window.sessionStorage.removeItem("classTextInput4");
            window.sessionStorage.removeItem("classTextInput5");
            window.sessionStorage.removeItem("subjectSelected1");
            window.sessionStorage.removeItem("subjectSelected2");
            window.sessionStorage.removeItem("subjectSelected3");
            window.sessionStorage.removeItem("subjectSelected4");
            window.sessionStorage.removeItem("subjectSelected5");
            window.sessionStorage.removeItem("classSectionsSelected1");
            window.sessionStorage.removeItem("classSectionsSelected2");
            window.sessionStorage.removeItem("classSectionsSelected3");
            window.sessionStorage.removeItem("classSectionsSelected4");
            window.sessionStorage.removeItem("classSectionsSelected5");
            window.sessionStorage.removeItem("labSelected1");
            window.sessionStorage.removeItem("labSelected2");
            window.sessionStorage.removeItem("labSelected3");
            window.sessionStorage.removeItem("labSelected4");
            window.sessionStorage.removeItem("labSelected5");

            setAllData((a) => [...a, doc.data()]);
            setAllDataIDs((a) => [...a, doc.id]);
            setAllDataGotClass((a) => [...a, doc.data().gotClass]);

            if (
              doc.data().dateTime > termTimes[schools.indexOf(userSchool)] &&
              doc.data().gotClass != null
            ) {
              setThisQuarterClasses(true);
            }

            if (
              doc.data().dateTime <= termTimes[schools.indexOf(userSchool)] &&
              doc.data().gotClass != null
            ) {
              setLastQuarterClasses(true);
            }
          }
        }
      });

      await Promise.all(promises).then(() => {
        setFinishedLoading(true);
      });
      console.log("All processes completed");
    };

    fetchData();
  }, []);

  async function submitDemo(event, subject, section, lab) {
    event.preventDefault();

    if (buttonClicked == false) {
      window.sessionStorage.setItem("demoButton", event.target.id);
      document.getElementById(event.target.id).classList.add("loading");
      if (userPhone.includes("+")) {
        window.sessionStorage.setItem("userPhone", userPhone);
      } else {
        window.sessionStorage.setItem("userPhone", `+${userPhone}`);
      }
      setButtonClicked(true);
      window.sessionStorage.removeItem("infoSuccessAddDrop");
      setAfterSuccess(false);

      await firebase
        .firestore()
        .collection("demoLaunches")
        .add({
          phone: userPhone.includes("+") ? userPhone : `+${userPhone}`,
          school: window.sessionStorage.getItem("userSchool"),
          dateTime: currentDateInSeconds,
          dateTimeReverse: -currentDateInSeconds,
          classSubject: subject,
          classSections: section,
          labSelected: lab,
          step1: true,
          step2: false,
          step3: false,
          isDemo: false,
        })
        .then(async (docRef) => {
          // window.sessionStorage.setItem('yourDemoID', docRef.id)
          setDemoLaunched(true);
          setButtonClicked(false);
        });
      // .then(() => {

      //   // remove animation
      //   // document.location.href='#demoAnimation'

      // })
    }
  }

  function changePhoneInput(phone) {
    setUserPhone(phone);
    // document.getElementById('phoneInput').classList.remove("form-error")
    document.getElementById("phoneBlankHint").style.display = "none";
    document.getElementById("phoneShortHint").style.display = "none";
    document.getElementById("phoneLetterHint").style.display = "none";
    document.getElementById("phoneSymbolHint").style.display = "none";
    document.getElementById("phoneNotRegisteredHint").style.display = "none";
    document.getElementById("noNotificationsHint").style.display = "none";
  }

  function pressEnter(event) {
    if (event.key == "Enter") {
      event.preventDefault();
      submitPhone(event);
    }
  }

  function rNum() {
    let number = Math.floor(Math.random() * (9 - 0) + 0);
    return number;
  }

  async function submitPhone(event) {
    event.preventDefault();
    console.log(userPhone);
    if (userPhone == "") {
      // document.getElementById('phoneInput').classList.add("form-error");
      document.getElementById("phoneBlankHint").style.display = "block";
      // document.location.href='#phoneInput'
    } else if (/[a-zA-Z]/g.test(userPhone) == true) {
      // document.getElementById('phoneInput').classList.add("form-error");
      document.getElementById("phoneLetterHint").style.display = "block";
      // document.location.href='#phoneInput'
    } else if (
      userPhone.length < 11 ||
      (isValidNumber(`+${userPhone}`) == false &&
        isValidNumber(userPhone) == false)
    ) {
      // document.getElementById('phoneInput').classList.add("form-error")
      document.getElementById("phoneShortHint").style.display = "block";
      // document.location.href='#phoneInput'
    } else if (
      userPhone.includes("@") ||
      userPhone.includes("-") ||
      userPhone.includes(" ") ||
      userPhone.includes("_") ||
      userPhone.includes("(") ||
      userPhone.includes(")") ||
      userPhone.includes("[") ||
      userPhone.includes("]") ||
      userPhone.includes("—") ||
      userPhone.includes(".") ||
      userPhone.includes("/") ||
      userPhone.includes(",") ||
      userPhone.includes("*") ||
      userPhone.includes("~")
    ) {
      // document.getElementById('phoneInput').classList.add("form-error")
      document.getElementById("phoneSymbolHint").style.display = "block";
      // document.location.href='#phoneInput'
    } else if (
      !freeUsersArray.includes(userPhone) &&
      !usersAllTerms.map((a) => a.phone).includes(`+${userPhone}`)
    ) {
      // document.getElementById('phoneInput').classList.add("form-error")
      document.getElementById("phoneNotRegisteredHint").style.display = "block";
      // document.location.href='#phoneInput'
    }
    // else if(allData.length==0){
    //   document.getElementById('phoneInput').classList.add("form-error")
    //   document.getElementById('noNotificationsHint').style.display='block';
    //   // document.location.href='#phoneInput'
    // }
    else {
      if (buttonClicked2 == false) {
        // document.getElementById('mainResult').style.display='none';
        // document.getElementById('loadingGraphic').style.display='block';
        setButtonClicked2(true);

        if (userPhone.includes("+")) {
          window.sessionStorage.setItem("userPhone", userPhone);
        } else {
          window.sessionStorage.setItem("userPhone", `+${userPhone}`);
        }

        // document.getElementById('phoneInput').classList.add("form-success")
        window.location.href = `/preferences#+${userPhone}`;

        // window.location.reload();
        // document.getElementById('mainResult').style.display='block';
        // document.getElementById('loadingGraphic').style.display='block';
      }
    }
  }

  async function copyPasteLink() {
    await navigator.clipboard.writeText(
      `www.pollinate.work/signup?referralPhoneInput=${
        window.location.href.split("#")[1]
      }`
    );
  }

  // window.onclick = (event) => {console.log(document.getElementById('preorderTooltipText').getBoundingClientRect())};

  window.ontouchmove = (event) => {
    hideTooltip();
  };

  function hideTooltip(event) {
    let leftLim = document
      .getElementById("preorderTooltipText")
      .getBoundingClientRect().left;
    let rightLim = document
      .getElementById("preorderTooltipText")
      .getBoundingClientRect().right;
    let topLim = document
      .getElementById("preorderTooltipText")
      .getBoundingClientRect().top;
    let bottomLim = document
      .getElementById("preorderTooltipText")
      .getBoundingClientRect().bottom;

    // if((event.touches[0].clientX < rightLim)&&(event.touches[0].clientX > leftLim)&&(event.touches[0].clientY < topLim)&&(event.touches[0].clientY > bottomLim)){
    //   event.preventDefault()
    // }else{
    document.getElementById("preorderTooltipText").style.visibility = "hidden";
    // }
  }

  function clickTooltip(event) {
    document.getElementById("preorderTooltipText").style.visibility = "visible";
    event.preventDefault();
    document
      .getElementById("preorderTooltipText")
      .classList.remove("fadeOut2Class");
    void document.getElementById("preorderTooltipText").offsetWidth;
    document
      .getElementById("preorderTooltipText")
      .classList.add("fadeOut2Class");
  }

  function showCopiedComputer(event) {
    if (window.mobileCheck() == false) {
      // document.getElementById('preorderTooltipText').style.visibility='hidden'
      document.getElementById("preorderTooltipTextComputer").style.visibility =
        "visible";
      event.preventDefault();
      document
        .getElementById("preorderTooltipTextComputer")
        .classList.remove("fadeOut2Class");
      void document.getElementById("preorderTooltipTextComputer").offsetWidth;
      document
        .getElementById("preorderTooltipTextComputer")
        .classList.add("fadeOut2Class");
      // document.getElementById('preorderTooltipText').style.visibility='visible'
    }
  }

  function closeSuccess() {
    if (afterSuccess == true) {
      setAfterSuccess(false);
      window.sessionStorage.removeItem("infoSuccessAddDrop");
    }
  }

  function upgradePlan() {
    if (allData[0].classSections.length == 0) {
      document.getElementById("upgradeAlert" + 0).style.display =
        "inline-block";
    } else if (buttonClicked3 == false) {
      setButtonClicked3(true);

      window.sessionStorage.setItem("signupPlan", "premium");
      window.sessionStorage.setItem("upgradingFromPreferences", "true");
      window.sessionStorage.setItem("upgradingID1", allData[0]);
      window.sessionStorage.setItem("numClasses", 1);
      window.sessionStorage.setItem("wantsEmails", "no");
      window.sessionStorage.setItem(
        "subjectSelected1",
        allData[0].classSubject
      );
      window.sessionStorage.setItem(
        "classSectionsSelected1",
        allData[0].classSections
      );
      window.sessionStorage.setItem("labSelected1", allData[0].labSelected);

      fetch(
        `https://us-central1-uchiprereg.cloudfunctions.net/server/create-customer`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST",
            "Access-Control-Allow-Headers": "Content-Type",
            "Access-Control-Max-Age": "3600",
          },
          body: JSON.stringify({
            phone: window.sessionStorage.getItem("userPhone"),
          }),
        }
      )
        .then((res) => {
          return res.json();
        })
        .catch(function () {
          console.log("ahhh1");
        })
        .then((customer) => {
          // console.log(customer.customerID)

          fetch(
            `https://us-central1-uchiprereg.cloudfunctions.net/server/create-checkout-session-1`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET, POST",
                "Access-Control-Allow-Headers": "Content-Type",
                "Access-Control-Max-Age": "3600",
              },
              body: JSON.stringify({
                cusID: customer.customerID,
              }),
            }
          )
            .then((res) => {
              return res.json();
            })
            .then((data) => (window.location.href = data.url))
            .catch(function () {
              console.log("ahhh2");
            });
        });
    }
  }

  // console.log(userPhone)
  //     console.log(usersAllTerms.map(a => a.phone).includes(userPhone))
  // console.log(usersAllTerms.map(a => a.phone).includes(window.sessionStorage.getItem('userPhone')))

  function sendToSignup() {
    window.sessionStorage.setItem("signupPlan", "standard");
    window.location.href = "/getnotifications";
  }

  console.log(allData);

  return (
    <section
      // {...props}
      className={outerClasses}
      // class='enableBlur'
      // style={{
      //   filter:'blur(0.5rem)'
      // }}
      style={{
        backgroundColor: afterSuccess == true ? "rgb(0,0,0,0.5)" : "",
        paddingTop: "40px",
      }}
    >
      <div
        style={{
          //Make sure to comment this line below back in! Jon, I am serious
          display:
            afterSuccess == true ||
            window.sessionStorage.getItem("infoSuccessNotAddDrop") == "true"
              ? "block"
              : "none",
          marginRight: "auto",
          marginLeft: "auto",
          width: "100%",
          position: "fixed",
          zIndex: "101",
          // height:'2rem',
          backgroundColor: "rgb(0,194,169)",
        }}
        class="widthClassSignup disableBlur"
      >
        <div
          id="successMessage"
          style={{
            textAlign: "center",
            padding: "1rem",
            fontWeight: "bold",
            fontSize: "25px",
            color: "white",
            // color:'rgb(0,194,169)',
            //remember to comment this back in!
            display: afterSuccess == true ? "block" : "none",
          }}
          //class="form-hint text-color-success"
        >
          Success — you have launched notifications
        </div>
      </div>

      <HeaderForLogin style={{zIndex: "100"}} />

      <div className="container" onClick={closeSuccess}>
        <div
          style={{
            height: "5rem",
            display: afterSuccess == true ? "block" : "none",
          }}
        ></div>

        <div className={innerClasses} style={{paddingTop: "2rem"}}>
          <div
            class={afterSuccess == true ? "enableBlur" : "disableBlur"}
            style={{marginBottom: "1rem"}}
          ></div>

          {afterSuccess == false ? (
            <>
              {/* {allData.length>0?
            allData[0].addDelay==false?
          <div style={{float:'right', marginTop:'1rem', marginRight:'2rem', position:'absolute', right:'0', zIndex:'100'}}>
          <Button tag='a' href='/emailswidget' color='primary'>
            Draft AI Emails
          </Button>
          
          <div style={{height:'1rem'}}></div>
          </div>
          :null
          :null} */}

              {/* {window.mobileCheck()==true?
            <div style={{height:'4rem'}}></div>:null} */}
              <SectionHeader
                tag="h1"
                data={sectionHeader}
                className={
                  afterSuccess == true
                    ? "center-content enableBlur"
                    : "center-content disableBlur"
                }
                style={{paddingBottom: "0.5rem", width: "fit"}}
              />
            </>
          ) : null}

          <div className="tiles-wrap">
            <div className="tiles-item">
              <div className="tiles-item-inner">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/uchiprereg.appspot.com/o/loadingWithText.gif?alt=media&token=8791e865-3578-4d4b-bb16-46ccaa007e41"
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: "0rem",
                    width: "100%",
                    display: "none",
                  }}
                  id="loadingGraphic"
                />
                <div id="mainResult">
                  {
                    // // freeUsersArray.some(number => window.location.href.includes(number))
                    // // ||
                    // // usersPhoneArray.some(element => window.location.href.includes(element))
                    // // // || usersPhoneArray.includes(window.location.href.split('#')[1])

                    // allData.length!=0
                    // // &&!freeUsersArray.includes(userPhone)&&!usersPhoneArray.includes(userPhone)

                    window.location.href.split("#").length > 1 ? (
                      allData.length == 0 ? (
                        finishedLoading == true ? (
                          isItAddDrop[schools.indexOf(userSchool)] == true ? (
                            <div>
                              <div
                                class={
                                  afterSuccess == true
                                    ? "enableBlur"
                                    : "disableBlur"
                                }
                                style={{
                                  filter:
                                    afterSuccess == true
                                      ? "blur(0.3rem)"
                                      : "blur(0)",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                  textAlign: "left",
                                }}
                              >
                                We do not have you registered to track any
                                classes. If you believe this is a mistake,
                                please text Jack at (612) 670-7721.
                              </div>
                              <div
                                style={{
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                  textAlign: "center",
                                  marginTop: "1rem",
                                }}
                              >
                                <Button
                                  //tag='a'
                                  onClick={sendToSignup}
                                  //href='/selectplan'
                                  type="button"
                                  color="primary"
                                >
                                  Launch Notifications
                                </Button>
                              </div>
                              <div style={{height: "1rem"}}></div>
                            </div>
                          ) : (
                            <div>
                              <>
                                <div>
                                  There are currently no classes available to
                                  track, but we can remind you when add/drop
                                  begins next term.
                                </div>
                                <Button
                                  style={{
                                    marginBottom: "1rem",
                                    marginTop: "1rem",
                                  }}
                                  // type='button'
                                  color="primary"
                                  wide
                                  tag="a"
                                  href="/signup"
                                >
                                  Get Reminders
                                </Button>
                              </>
                            </div>
                          )
                        ) : (
                          <div>
                            <div
                              class={
                                afterSuccess == true
                                  ? "enableBlur"
                                  : "disableBlur"
                              }
                              style={{
                                filter:
                                  afterSuccess == true
                                    ? "blur(0.3rem)"
                                    : "blur(0)",
                                marginLeft: "auto",
                                marginRight: "auto",
                                textAlign: "center",
                              }}
                            >
                              Loading...
                            </div>
                            <div style={{height: "1rem"}}></div>
                          </div>
                        )
                      ) : (
                        <div>
                          <div
                            class={
                              afterSuccess == true
                                ? "enableBlur"
                                : "disableBlur"
                            }
                            style={{
                              filter:
                                afterSuccess == true
                                  ? "blur(0.3rem)"
                                  : "blur(0)",
                            }}
                          >
                            {/* <div>
                Current Plan: {freeUsersArray.includes(window.sessionStorage.getItem('userPhone'))?'Free unlimited (instant notifications)':allData[0].addDelay==false?'Paid (instant notifications)': 'Free (1 hour delayed notifications)'}
              </div> */}

                            {afterSuccess == false ? (
                              allData.length > 0 &&
                              isItAddDrop[schools.indexOf(userSchool)] ==
                                true ? (
                                <Button
                                  tag="a"
                                  href="/getnotifications"
                                  color="primary"
                                  style={{
                                    marginTop: "0.5rem",
                                    marginBottom: "0.5rem",
                                  }}
                                  class={
                                    afterSuccess == true
                                      ? "enableBlur"
                                      : "disableBlur"
                                  }
                                >
                                  Track More Classes
                                </Button>
                              ) : null
                            ) : null}

                            {/* {allData[0].addDelay==true?
                <>
                <Button type='button' color="primary" onClick={upgradePlan} style={{marginTop:'0.5rem', marginBottom:'0.5rem'}} class={afterSuccess==true?'enableBlur':'disableBlur'}>
                  Upgrade to Instant
                </Button>
                <div class={afterSuccess==true?'enableBlur text-color-error':'disableBlur text-color-error'} id={'upgradeAlert'+0} style={{display:'none', width:'250px', marginRight:'0px'}}>
                  First, turn on notifications for at least one section
                </div>
                </>
                :
                allData.length<5?
                <>
                <Button tag="a" href="/getnotifications" color="primary" style={{marginTop:'0.5rem', marginBottom:'0.5rem'}} class={afterSuccess==true?'enableBlur':'disableBlur'}>
                  Add More Classes
                </Button>

                <Button tag="a" href="/premiumwaitlist" color="primary" style={{marginTop:'0.5rem', marginBottom:'0.5rem'}} class={afterSuccess==true?'enableBlur':'disableBlur'}>
                  Join the Premium Waitlist
                </Button>
                </>
                :
                <>
                <div style ={{fontSize:'16px'}}>
                  *You are tracking the maximum number of classes on the Standard Plan
                </div>
                <Button tag="a" href="/premiumwaitlist" color="primary" style={{marginTop:'0.5rem', marginBottom:'0.5rem'}} class={afterSuccess==true?'enableBlur':'disableBlur'}>
                  Join the Premium Waitlist
                </Button>
                </>
                } */}

                            {allData.length != 0 && finishedLoading == true ? (
                              isItAddDrop[schools.indexOf(userSchool)] ==
                              true ? (
                                <>
                                  {/* <div>
              <>
                <Button style={{marginBottom:'1rem', marginTop:'1rem'}} 
                // type='button' 
                color="primary" wide
                tag="a" href="/getnotifications"
                >
                  Get more notifications
                </Button>
                </>
              </div> */}
                                </>
                              ) : (
                                <div>
                                  <>
                                    <div>
                                      There are currently no classes available
                                      to track, but we can remind you when
                                      add/drop begins next term.
                                    </div>
                                    <Button
                                      style={{
                                        marginBottom: "1rem",
                                        marginTop: "1rem",
                                      }}
                                      // type='button'
                                      color="primary"
                                      wide
                                      tag="a"
                                      href="/signup"
                                    >
                                      Get Reminders
                                    </Button>
                                  </>
                                </div>
                              )
                            ) : null}
                          </div>

                          {allData.map((request, requestIndex) => {
                            function collapseSections() {
                              document.getElementById(
                                "collapse" + requestIndex
                              ).style.display = "none";
                              document.getElementById(
                                "expand" + requestIndex
                              ).style.display = "block";
                              document.getElementById(
                                "sectionCards" + requestIndex
                              ).style.display = "none";
                            }

                            function expandSections() {
                              document.getElementById(
                                "collapse" + requestIndex
                              ).style.display = "block";
                              document.getElementById(
                                "expand" + requestIndex
                              ).style.display = "none";
                              document.getElementById(
                                "sectionCards" + requestIndex
                              ).style.display = "block";
                            }

                            function upgradePlan() {
                              if (request.classSections.length == 0) {
                                document.getElementById(
                                  "upgradeAlert" + requestIndex
                                ).style.display = "inline-block";
                              } else if (buttonClicked3 == false) {
                                setButtonClicked3(true);

                                window.sessionStorage.setItem(
                                  "signupPlan",
                                  "premium"
                                );
                                window.sessionStorage.setItem(
                                  "upgradingFromPreferences",
                                  "true"
                                );
                                window.sessionStorage.setItem(
                                  "upgradingID1",
                                  allDataIDs[requestIndex]
                                );
                                window.sessionStorage.setItem("numClasses", 1);
                                window.sessionStorage.setItem(
                                  "wantsEmails",
                                  "no"
                                );
                                window.sessionStorage.setItem(
                                  "subjectSelected1",
                                  request.classSubject
                                );
                                window.sessionStorage.setItem(
                                  "classSectionsSelected1",
                                  request.classSections
                                );
                                window.sessionStorage.setItem(
                                  "labSelected1",
                                  allData[requestIndex].labSelected
                                );

                                fetch(
                                  `https://us-central1-uchiprereg.cloudfunctions.net/server/create-customer`,
                                  {
                                    method: "POST",
                                    headers: {
                                      "Content-Type": "application/json",
                                      "Access-Control-Allow-Origin": "*",
                                      "Access-Control-Allow-Methods":
                                        "GET, POST",
                                      "Access-Control-Allow-Headers":
                                        "Content-Type",
                                      "Access-Control-Max-Age": "3600",
                                    },
                                    body: JSON.stringify({
                                      phone:
                                        window.sessionStorage.getItem(
                                          "userPhone"
                                        ),
                                    }),
                                  }
                                )
                                  .then((res) => {
                                    return res.json();
                                  })
                                  .catch(function () {
                                    console.log("ahhh1");
                                  })
                                  .then((customer) => {
                                    // console.log(customer.customerID)

                                    fetch(
                                      `https://us-central1-uchiprereg.cloudfunctions.net/server/create-checkout-session-1`,
                                      {
                                        method: "POST",
                                        headers: {
                                          "Content-Type": "application/json",
                                          "Access-Control-Allow-Origin": "*",
                                          "Access-Control-Allow-Methods":
                                            "GET, POST",
                                          "Access-Control-Allow-Headers":
                                            "Content-Type",
                                          "Access-Control-Max-Age": "3600",
                                        },
                                        body: JSON.stringify({
                                          cusID: customer.customerID,
                                        }),
                                      }
                                    )
                                      .then((res) => {
                                        return res.json();
                                      })
                                      .then(
                                        (data) =>
                                          (window.location.href = data.url)
                                      )
                                      .catch(function () {
                                        console.log("ahhh2");
                                      });
                                  });
                              }
                            }

                            return (
                              <div>
                                {request.dateTime >
                                termTimes[schools.indexOf(userSchool)] ? (
                                  <div>
                                    {afterSuccess == false ? (
                                      <div
                                        style={{
                                          filter:
                                            afterSuccess == true
                                              ? "blur(0.3rem)"
                                              : "blur(0)",
                                          backgroundColor:
                                            "rgb(218,35,255,0.2)",
                                          borderRadius: "10px",
                                          padding: "0.4rem",
                                          marginTop: "0.3rem",
                                          color: "black",
                                        }}
                                      >
                                        <div
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "1.3rem",
                                            width: "fit-content",
                                          }}
                                          class={
                                            afterSuccess == true
                                              ? "enableBlur"
                                              : "disableBlur"
                                          }
                                        >
                                          {userSchool != "" &&
                                          schools.indexOf(userSchool) !== -1
                                            ? (() => {
                                                const filteredCourses =
                                                  coursesData[
                                                    schools.indexOf(userSchool)
                                                  ].filter(
                                                    (a) =>
                                                      a.course.subject ==
                                                      request.classSubject
                                                  );
                                                return filteredCourses.length >
                                                  0
                                                  ? filteredCourses[0].course
                                                      .name
                                                  : "Course not offered this term";
                                              })()
                                            : null}
                                        </div>

                                        <div
                                          style={{
                                            fontSize: "1rem",
                                            width: "fit-content",
                                          }}
                                          class={
                                            afterSuccess == true
                                              ? "enableBlur"
                                              : "disableBlur"
                                          }
                                        >
                                          {userSchool != "" &&
                                          schools.indexOf(userSchool) !== -1
                                            ? (() => {
                                                const filteredCourses =
                                                  coursesData[
                                                    schools.indexOf(userSchool)
                                                  ].filter(
                                                    (a) =>
                                                      a.course.subject ==
                                                      request.classSubject
                                                  );
                                                return filteredCourses.length >
                                                  0
                                                  ? filteredCourses[0].course
                                                      .subject
                                                  : "";
                                              })()
                                            : null}
                                        </div>

                                        <div
                                          id={"expand" + requestIndex}
                                          style={{
                                            marginTop: "-1.5rem",
                                            float: "right",
                                            marginRight: "0.2rem",
                                            cursor: "pointer",
                                            display: "none",
                                          }}
                                          onClick={expandSections}
                                        >
                                          <Image
                                            src={require("./../../assets/images/downArrowIcon.png")}
                                            width={30}
                                            height={30}
                                          />
                                        </div>

                                        <div
                                          id={"collapse" + requestIndex}
                                          style={{
                                            marginTop: "-1.5rem",
                                            float: "right",
                                            marginRight: "0.2rem",
                                            cursor: "pointer",
                                          }}
                                          onClick={collapseSections}
                                        >
                                          <Image
                                            src={require("./../../assets/images/upArrowIcon.png")}
                                            width={30}
                                            height={30}
                                          />
                                        </div>
                                      </div>
                                    ) : null}

                                    {afterSuccess == false ? (
                                      allSubjects.filter((x) =>
                                        allData[
                                          requestIndex
                                        ].classSubject.includes(x)
                                      ).length == 0 ? (
                                        <div
                                          style={{
                                            display: "inline",
                                            marginTop: "0.5rem",
                                            color: "rgb(218,35,255)",
                                          }}
                                          class={
                                            afterSuccess == true
                                              ? "enableBlur"
                                              : "disableBlur"
                                          }
                                        >
                                          No one is tracking this class
                                        </div>
                                      ) : allSubjects.filter((x) =>
                                          allData[
                                            requestIndex
                                          ].classSubject.includes(x)
                                        ).length > 0 ? (
                                        <div
                                          style={{
                                            marginTop: "0.5rem",
                                            fontSize: "1rem",
                                            color: "rgb(218,35,255)",
                                          }}
                                          class={
                                            afterSuccess == true
                                              ? "enableBlur"
                                              : "disableBlur"
                                          }
                                        >
                                          {allSubjects.filter((x) =>
                                            allData[
                                              requestIndex
                                            ].classSubject.includes(x)
                                          ).length == 1 ? (
                                            <div
                                              style={{
                                                display: "inline",
                                                color: "rgb(218,35,255)",
                                              }}
                                              class={
                                                afterSuccess == true
                                                  ? "enableBlur"
                                                  : "disableBlur"
                                              }
                                            >
                                              You’re the only one tracking this
                                              class
                                            </div>
                                          ) : allSubjects.filter((x) =>
                                              allData[
                                                requestIndex
                                              ].classSubject.includes(x)
                                            ).length > 10 ? (
                                            <div
                                              style={{
                                                display: "inline",
                                                color: "rgb(246, 142, 23)",
                                              }}
                                              class={
                                                afterSuccess == true
                                                  ? "enableBlur"
                                                  : "disableBlur"
                                              }
                                            >
                                              {
                                                allSubjects.filter((x) =>
                                                  allData[
                                                    requestIndex
                                                  ].classSubject.includes(x)
                                                ).length
                                              }{" "}
                                              people are tracking this class
                                            </div>
                                          ) : allSubjects.filter((x) =>
                                              allData[
                                                requestIndex
                                              ].classSubject.includes(x)
                                            ).length > 5 ? (
                                            <div
                                              style={{
                                                display: "inline",
                                                color: "rgb(13, 98, 255)",
                                              }}
                                              class={
                                                afterSuccess == true
                                                  ? "enableBlur"
                                                  : "disableBlur"
                                              }
                                            >
                                              {
                                                allSubjects.filter((x) =>
                                                  allData[
                                                    requestIndex
                                                  ].classSubject.includes(x)
                                                ).length
                                              }{" "}
                                              people are tracking this class
                                            </div>
                                          ) : (
                                            <div
                                              style={{
                                                display: "inline",
                                                color: "rgb(0,194,169)",
                                              }}
                                              class={
                                                afterSuccess == true
                                                  ? "enableBlur"
                                                  : "disableBlur"
                                              }
                                            >
                                              {
                                                allSubjects.filter((x) =>
                                                  allData[
                                                    requestIndex
                                                  ].classSubject.includes(x)
                                                ).length
                                              }{" "}
                                              people are tracking this class
                                            </div>
                                          )}
                                        </div>
                                      ) : null
                                    ) : null}

                                    <div id={"sectionCards" + requestIndex}>
                                      {userSchool != "" &&
                                      schools.indexOf(userSchool) !== -1
                                        ? (() => {
                                            const filteredCourses = coursesData[
                                              schools.indexOf(userSchool)
                                            ].filter(
                                              (a) =>
                                                a.course.subject ==
                                                request.classSubject
                                            );
                                            if (filteredCourses.length > 0) {
                                              const course =
                                                filteredCourses[0].course;

                                              // Ensure course.sections exists before mapping over it
                                              if (
                                                course.sections &&
                                                course.sections.length > 0
                                              ) {
                                                return course.sections.map(
                                                  (section, sectionIndex) => {
                                                    return (
                                                      <div key={sectionIndex}>
                                                        <div
                                                          id={
                                                            section.sectionNumber
                                                          }
                                                          style={{
                                                            padding: "0.2rem",
                                                            marginTop: "0.3rem",
                                                            borderStyle:
                                                              "solid",
                                                            borderColor:
                                                              "black",
                                                            borderWidth:
                                                              afterSuccess ==
                                                              true
                                                                ? "0px"
                                                                : "1px",
                                                            fontSize: "16px",
                                                            lineHeight:
                                                              "normal",
                                                          }}
                                                        >
                                                          {afterSuccess ==
                                                          false ? (
                                                            <div
                                                              style={{
                                                                color: "black",
                                                                fontSize:
                                                                  "25px",
                                                                fontWeight:
                                                                  "bold",
                                                                filter:
                                                                  afterSuccess ==
                                                                  true
                                                                    ? "blur(0.3rem)"
                                                                    : "blur(0)",
                                                              }}
                                                              class={
                                                                afterSuccess ==
                                                                true
                                                                  ? "enableBlur"
                                                                  : "disableBlur"
                                                              }
                                                            >
                                                              Section Info
                                                            </div>
                                                          ) : null}

                                                          {section.lab ==
                                                          true ? (
                                                            <div
                                                              style={{
                                                                color:
                                                                  "#33CEFF",
                                                                float: "right",
                                                                fontSize:
                                                                  "16px",
                                                                marginRight:
                                                                  "0.2rem",
                                                                filter:
                                                                  afterSuccess ==
                                                                  true
                                                                    ? "blur(0.3rem)"
                                                                    : "blur(0)",
                                                              }}
                                                              class={
                                                                afterSuccess ==
                                                                true
                                                                  ? "enableBlur"
                                                                  : "disableBlur"
                                                              }
                                                            >
                                                              Lab
                                                            </div>
                                                          ) : null}

                                                          {section.sectionProf
                                                            .length != 0 ? (
                                                            <div
                                                              class={
                                                                afterSuccess ==
                                                                true
                                                                  ? "enableBlur"
                                                                  : "disableBlur"
                                                              }
                                                              style={{
                                                                zIndex: 2,
                                                                pointerEvents:
                                                                  "none",
                                                              }}
                                                            >
                                                              {section.sectionName.includes(
                                                                "Section"
                                                              ) ? (
                                                                <div
                                                                  style={{
                                                                    zIndex: 1,
                                                                    pointerEvents:
                                                                      "none",
                                                                  }}
                                                                >
                                                                  {
                                                                    section.sectionName
                                                                  }{" "}
                                                                  (
                                                                  {
                                                                    section.sectionNumber
                                                                  }
                                                                  )
                                                                </div>
                                                              ) : (
                                                                <div
                                                                  class={
                                                                    afterSuccess ==
                                                                    true
                                                                      ? "enableBlur"
                                                                      : "disableBlur"
                                                                  }
                                                                  style={{
                                                                    zIndex: 1,
                                                                    pointerEvents:
                                                                      "none",
                                                                  }}
                                                                >
                                                                  Section{" "}
                                                                  {
                                                                    section.sectionName
                                                                  }{" "}
                                                                  (
                                                                  {
                                                                    section.sectionNumber
                                                                  }
                                                                  )
                                                                </div>
                                                              )}
                                                              <div
                                                                style={{
                                                                  zIndex: 1,
                                                                  pointerEvents:
                                                                    "none",
                                                                }}
                                                              >
                                                                {section.sectionProf.map(
                                                                  (
                                                                    prof,
                                                                    profIndex
                                                                  ) => {
                                                                    return (
                                                                      <div
                                                                        key={
                                                                          profIndex
                                                                        }
                                                                        style={{
                                                                          pointerEvents:
                                                                            "none",
                                                                          display:
                                                                            "inline",
                                                                        }}
                                                                      >
                                                                        {section
                                                                          .sectionProf
                                                                          .length ==
                                                                        1 ? (
                                                                          <div
                                                                            style={{
                                                                              pointerEvents:
                                                                                "none",
                                                                              display:
                                                                                "inline",
                                                                            }}
                                                                          >
                                                                            {
                                                                              prof
                                                                            }
                                                                          </div>
                                                                        ) : profIndex !=
                                                                            section
                                                                              .sectionProf
                                                                              .length -
                                                                              1 &&
                                                                          section
                                                                            .sectionProf
                                                                            .length ==
                                                                            2 ? (
                                                                          <div
                                                                            style={{
                                                                              pointerEvents:
                                                                                "none",
                                                                              display:
                                                                                "inline",
                                                                            }}
                                                                          >
                                                                            {
                                                                              prof
                                                                            }
                                                                            &nbsp;
                                                                          </div>
                                                                        ) : profIndex !=
                                                                            section
                                                                              .sectionProf
                                                                              .length -
                                                                              1 &&
                                                                          section
                                                                            .sectionProf
                                                                            .length >
                                                                            2 ? (
                                                                          <div
                                                                            style={{
                                                                              pointerEvents:
                                                                                "none",
                                                                              display:
                                                                                "inline",
                                                                            }}
                                                                          >
                                                                            {
                                                                              prof
                                                                            }
                                                                            ,&nbsp;
                                                                          </div>
                                                                        ) : (
                                                                          <div
                                                                            style={{
                                                                              pointerEvents:
                                                                                "none",
                                                                              display:
                                                                                "inline",
                                                                            }}
                                                                          >
                                                                            and&nbsp;
                                                                            {
                                                                              prof
                                                                            }
                                                                          </div>
                                                                        )}
                                                                      </div>
                                                                    );
                                                                  }
                                                                )}
                                                              </div>
                                                            </div>
                                                          ) : section.sectionName.includes(
                                                              "Section"
                                                            ) ? (
                                                            <div
                                                              class={
                                                                afterSuccess ==
                                                                true
                                                                  ? "enableBlur"
                                                                  : "disableBlur"
                                                              }
                                                              style={{
                                                                zIndex: 2,
                                                                pointerEvents:
                                                                  "none",
                                                              }}
                                                            >
                                                              {
                                                                section.sectionName
                                                              }{" "}
                                                              (
                                                              {
                                                                section.sectionNumber
                                                              }
                                                              )
                                                            </div>
                                                          ) : (
                                                            <div
                                                              class={
                                                                afterSuccess ==
                                                                true
                                                                  ? "enableBlur"
                                                                  : "disableBlur"
                                                              }
                                                              style={{
                                                                zIndex: 1,
                                                                pointerEvents:
                                                                  "none",
                                                                filter:
                                                                  afterSuccess ==
                                                                  true
                                                                    ? "blur(0.3rem)"
                                                                    : "blur(0)",
                                                              }}
                                                            >
                                                              Section{" "}
                                                              {
                                                                section.sectionName
                                                              }{" "}
                                                              (
                                                              {
                                                                section.sectionNumber
                                                              }
                                                              )
                                                            </div>
                                                          )}

                                                          {section.sectionTime !=
                                                          null ? (
                                                            <div
                                                              class={
                                                                afterSuccess ==
                                                                true
                                                                  ? "enableBlur"
                                                                  : "disableBlur"
                                                              }
                                                              style={{
                                                                zIndex: 2,
                                                                pointerEvents:
                                                                  "none",
                                                                filter:
                                                                  afterSuccess ==
                                                                  true
                                                                    ? "blur(0.3rem)"
                                                                    : "blur(0)",
                                                              }}
                                                            >
                                                              {
                                                                section.sectionTime
                                                              }
                                                            </div>
                                                          ) : null}
                                                        </div>
                                                      </div>
                                                    );
                                                  }
                                                );
                                              } else {
                                                return (
                                                  <div>
                                                    No sections available
                                                  </div>
                                                );
                                              }
                                            } else {
                                              return (
                                                <div>No courses found</div>
                                              );
                                            }
                                          })()
                                        : null}
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            );
                          })}

                          <div style={{height: "1rem"}}></div>

                          {/* {((window.location.href.split('#').length>1) && (thisQuarterClasses==true))?

              <div style={{marginRight:'auto',marginLeft:'auto', textAlign:'center'}} 
              class={afterSuccess==true?'enableBlur':'disableBlur'}
              // class='widthClassSignup'
              >


              <div style={{
                marginBottom:'0.5rem'
                // cursor:'pointer', display:'inline', fontSize:'1.2rem', color:'rgb(13, 98, 255)'
                }} class='tooltip' onTouchStart={clickTooltip} id='preorderTooltip' onClick={showCopiedComputer}> 
              <div 
              onClick={copyPasteLink}
              // class='copyLinkBox widthClassSignup'
              style={{
                // cursor:'grab',
                textAlign:'center', marginLeft:'auto', marginRight:'auto', 
                //borderStyle:'solid', borderWidth:'0.25rem', borderColor:'rgb(87,94,134)', backgroundColor:'rgb(233,236,248)',  
                // fontSize:'16px'
              }}>
              <div 
              // style={{fontSize:'16px', fontWeight:'bold', textAlign:'center'}}
              class='button'
              style={{background:'linear-gradient(345deg, #E9ECF8 0px, #FFFFFF 0px, rgb(218,35,255) 0%)', borderRadius:'10px', color:'white'}}
              >
              Referral Link
              <img 
              style={{
                maxWidth:'4rem',
                cursor:'grab', 
                display:'inline-block',
                verticalAlign:'middle'
              }}
              src='CopyToClipWhite.png'/>
              </div>
              
              
              {window.mobileCheck()==true?
              <span class='tooltiptext' id='preorderTooltipText'>Copied to clipboard </span>
              :
              <div style={{pointerEvents:'none'}}>
              <span class='tooltiptext' id='preorderTooltipText'>Copy to clipboard </span>
              <span class='tooltiptext' id='preorderTooltipTextComputer' style={{visibility:'hidden'}}>Copied to clipboard </span>
              </div>
              }
              </div>

              </div>


              <div style={{fontSize:'16px', fontWeight:'bold', textAlign:'center'}}>
              Give 20% and Get 20% Off Your Last Purchase
              </div>

              </div>

              :null} */}

                          {lastQuarterClasses == true ? (
                            <div>
                              <div
                                style={{
                                  height:
                                    thisQuarterClasses == true ? "1rem" : "0",
                                }}
                              ></div>
                              <div
                                style={{fontWeight: "bold", fontSize: "1.6rem"}}
                              >
                                Past Quarters
                              </div>
                              <div style={{height: "0.5rem"}}></div>
                            </div>
                          ) : null}

                          {allData.map((request, requestIndex) => {
                            return (
                              <div>
                                {request.dateTime <
                                termTimes[schools.indexOf(userSchool)] ? (
                                  <div>
                                    <div>{request.classSubject}</div>
                                  </div>
                                ) : null}
                              </div>
                            );
                          })}
                        </div>
                      )
                    ) : (
                      <div>
                        {/* <form> */}

                        <div style={{fontSize: "0.95rem"}}>
                          Log in to update and test notifications
                        </div>
                        <div style={{height: "0.75rem"}}></div>
                        <div className="mb-12">
                          <PhoneInput
                            country={userCountry == "" ? "us" : userCountry}
                            value={userPhone}
                            onChange={(phone) => changePhoneInput(phone)}
                            placeholder="Phone number"
                            maxLength="14"
                            enableSearch={true}
                            // inputStyle={{ width: '100%' }}
                            id="phoneInput"
                            required
                            // style={{marginTop:'0.5rem'}}
                            // style={{borderStyle:'dashed'}}
                            class="mb-12"
                          />
                        </div>

                        <div
                          id="phoneBlankHint"
                          style={{display: "none"}}
                          class="form-hint text-color-error"
                        >
                          Please enter your phone number
                        </div>
                        <div
                          id="phoneShortHint"
                          style={{display: "none"}}
                          class="form-hint text-color-error"
                        >
                          Please enter a valid phone number
                        </div>
                        <div
                          id="phoneLetterHint"
                          style={{display: "none"}}
                          class="form-hint text-color-error"
                        >
                          Please enter a valid phone number
                        </div>
                        <div
                          id="phoneSymbolHint"
                          style={{display: "none"}}
                          class="form-hint text-color-error"
                        >
                          Please enter your number in the format: 7037626410. If
                          you have specified your country code, use this format:
                          +2551234567890.
                        </div>
                        <div
                          id="phoneNotRegisteredHint"
                          style={{display: "none"}}
                          class="form-hint text-color-error"
                        >
                          Shoot! We don’t have your number in our system. If you
                          think this is a mistake, text Jack at (612) 670-7721.
                          If you are new or never launched notifications (even
                          if you signed up), <Link to="/signup">sign up</Link>.
                        </div>
                        <div
                          id="noNotificationsHint"
                          style={{display: "none"}}
                          class="form-hint text-color-error"
                        >
                          Shoot! We don’t have any notifications registered for
                          you this quarter. If you think this is a mistake, text
                          Jack at (612) 670-7721.
                        </div>

                        <div className="mt-24 mb-32">
                          <img
                            src="/ellipsis-white.gif"
                            style={{
                              position: "absolute",
                              height: "45px",
                              left: "0",
                              right: "0",
                              marginLeft: "auto",
                              marginRight: "auto",
                              display:
                                buttonClicked2 == true ? "block" : "none",
                            }}
                          />

                          <Button
                            type="button"
                            color="primary"
                            wide
                            onClick={submitPhone}
                          >
                            <div
                              style={{
                                pointerEvents: "none",
                                display:
                                  buttonClicked2 == false ? "block" : "none",
                              }}
                            >
                              Login
                            </div>
                          </Button>
                        </div>

                        {/* </form> */}
                      </div>
                    )
                  }
                </div>
                <div style={{height: "2rem"}}></div>

                <div
                  className="signin-bottom has-top-divider"
                  class={afterSuccess == true ? "enableBlur" : "disableBlur"}
                >
                  <div className="pt-32 text-xs center-content text-color-low">
                    Don't have an account? <Link to="/signup">Sign up</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

PreferencesForm.propTypes = propTypes;
PreferencesForm.defaultProps = defaultProps;

export default PreferencesForm;
