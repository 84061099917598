import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

class PollinateTestimonial extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'testimonial section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    // const innerClasses = classNames(
    //   'testimonial-inner section-inner',
    //   topDivider && 'has-top-divider',
    //   bottomDivider && 'has-bottom-divider'
    // );

    const innerClasses = classNames(
      'testimonial-inner section-inner',
      topDivider,
      bottomDivider && 'has-bottom-divider'
    );


    const tilesClasses = classNames(
      'tiles-wrap',
      pushLeft && 'push-left'
    );

    const sectionHeader = {
      title: 'Customer testimonials',
      // paragraph: 'We love our users!'
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container" 
        //style={{marginTop:'1rem'}}
        >
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content reveal-from-top" />
            <div className={tilesClasses}>

              <div className="tiles-item reveal-rotate-from-left" data-reveal-container=".tiles-wrap">
                <div className="tiles-item-inner has-shadow">
                  <div className="testimonial-item-header mb-16">
                    <div className="testimonial-item-image">
                    <a style={{textDecoration:'none'}} target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/zane.gillan/"><Image
                        src={require('./../../assets/images/ZaneFBPhoto.png')}
                        alt="Testimonial 01"
                        width={100}
                        height={100} /></a>
                    </div>
                  </div>
                  <div className="testimonial-item-content">
                    <p className="text-sm mb-0">
                    “My class schedule was in shambles and I though that there was no chance I’d get into any classes I wanted, but Class Clutch did the unthinkable - I got STAT 220.”
                    </p>
                  </div>
                  <div className="testimonial-item-footer text-xs fw-500 mt-32 mb-0 has-top-divider">
                    <span className="testimonial-item-name">
                    <a style={{textDecoration:'underline'}} target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/zane.gillan/">Zane G. — UChicago ’25</a>
                    </span>
                    <span className="text-color-low"></span>
                    {/* <span className="testimonial-item-link">
                      <a href="#0">AppName</a>
                    </span> */}
                  </div>
                </div>
              </div>

              <div className="tiles-item reveal-rotate-from-left" data-reveal-container=".tiles-wrap" data-reveal-delay="200">
                <div className="tiles-item-inner has-shadow">
                  <div className="testimonial-item-header mb-16">
                    <div className="testimonial-item-image">
                    <a style={{textDecoration:'none'}} target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/luxmi.mathivannan/"><Image
                        src={require('./../../assets/images/LuxmiFBPhoto.jpeg')}
                        alt="Testimonial 02"
                        width={100}
                        height={100} />
                      </a>
                    </div>
                  </div>
                  <div className="testimonial-item-content">
                    <p className="text-sm mb-0">
                    “I was worried about graduating on time because I got one class from pre-reg, but Class Clutch texts literally made my year.”
                    </p>
                  </div>
                  <div className="testimonial-item-footer text-xs fw-500 mt-32 mb-0 has-top-divider">
                    <span className="testimonial-item-name">
                    <a style={{textDecoration:'underline'}} target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/luxmi.mathivannan/">Luxmi M. — UChicago ’23</a>
                    </span>
                    <span className="text-color-low"></span>
                    {/* <span className="testimonial-item-link">
                      <a href="#0">AppName</a>
                    </span> */}
                  </div>
                </div>
              </div>





              <div className="tiles-item reveal-rotate-from-left" data-reveal-container=".tiles-wrap" data-reveal-delay="400">
                <div className="tiles-item-inner has-shadow">
                  <div className="testimonial-item-header mb-16">
                    <div className="testimonial-item-image">
                    <a style={{textDecoration:'none'}} target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/xander_hastings/"><Image
                        src={require('./../../assets/images/XanderTestimonialPhoto.png')}
                        alt="Testimonial 03"
                        width={100}
                        height={100}/>
                      </a>
                    </div>
                  </div>
                  <div className="testimonial-item-content">
                    <p className="text-sm mb-0">
                    “Class Clutch got me into Global Warming and saved my quarter. It’s a lifesaver for anyone who needs a full class.”
                    </p>
                  </div>
                  <div className="testimonial-item-footer text-xs fw-500 mt-32 mb-0 has-top-divider">
                    <span className="testimonial-item-name">
                     <a style={{textDecoration:'underline'}} target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/xander_hastings/">Xander H. — UChicago ’23</a>
                    </span>
                    <span className="text-color-low"></span>
                    {/* <span className="testimonial-item-link">
                      <a href="#0">AppName</a>
                    </span> */}
                  </div>
                </div>
              </div>

              <div className="tiles-item reveal-rotate-from-left" data-reveal-container=".tiles-wrap" data-reveal-delay="400">
                <div className="tiles-item-inner has-shadow">
                  <div className="testimonial-item-header mb-16">
                    <div className="testimonial-item-image">
                    <a style={{textDecoration:'none'}} target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/filippa_kol/"><Image
                        src={require('./../../assets/images/FilippaFBPhoto.jpeg')}
                        alt="Testimonial 04"
                        width={100}
                        height={100}/>
                      </a>
                    </div>
                  </div>
                  <div className="testimonial-item-content">
                    <p className="text-sm mb-0">
                    “I was super happy with the level of service the founders provided. You could tell that they really cared about helping other students.”
                    </p>
                  </div>
                  <div className="testimonial-item-footer text-xs fw-500 mt-32 mb-0 has-top-divider">
                    <span className="testimonial-item-name">
                     <a style={{textDecoration:'underline'}} target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/filippa_kol/">Filippa K. — UChicago ’23</a>
                    </span>
                    <span className="text-color-low"></span>
                    {/* <span className="testimonial-item-link">
                      <a href="#0">AppName</a>
                    </span> */}
                  </div>
                </div>
              </div>

              

            </div>
          </div>
        </div>
        <div style={{ height: '5rem' }}></div>  {/* This is the buffer */}
      </section>
    );
  }
}

PollinateTestimonial.propTypes = propTypes;
PollinateTestimonial.defaultProps = defaultProps;

export default PollinateTestimonial;