import React from 'react';
import Faq from '../components/sections/Faq'
import HeroFull from '../components/sections/HeroFull';
import FeaturesTabsViz from '../components/sections/NotreDameViz'
import Carousel from '../components/elements/Carousel'
import StripeApp from '../components/sections/StripeApp'
import SignCounter from '../components/sections/SignupCounter';
import FivioHundo from '../components/sections/FivioHundo';
import FreeAccess from '../components/sections/FreeAccess'
// import Clients from '../components/sections/Clients';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import PollinateTestimonial from '../components/sections/PollinateTestimonials';
import PricingLaunch from '../components/sections/PricingLaunchPage';
import AdvisingPricing from '../components/sections/AdvisingPricing';
import Cta from '../components/sections/Cta';
import GetDataForSS from '../components/sections/GetDataForSS';
import FeaturesSplitConsentRequired from '../components/sections/FeaturesSplitConsentRequired';
import DemoWidget from '../components/sections/DemoWidget';
import ReviewBoard from '../components/sections/ReviewBoard';

class WaitlistPlans extends React.Component {
  render() {

    return (
      <React.Fragment>
        <PricingLaunch showSectionHeader topDivider pricingSlider className="has-bg-color-cut" />
      </React.Fragment>
    );
  }
}

export default WaitlistPlans;
