import React, {useEffect, useState} from "react";
import classNames from "classnames";
import {SectionProps} from "../../utils/SectionProps";
import {Link} from "react-router-dom";
import SectionHeader from "./partials/SectionHeader";
import Timeline from "../elements/Timeline";
import TimelineItem from "../elements/TimelineItem";
import Button from "../elements/Button";
import Select from "../elements/Select";
import Checkbox from "../elements/Checkbox";
import HeroFull from "./HeroFull";
import mixpanel from "mixpanel-browser";
import firebase from "firebase/compat/app";
import StripeCheck from "../sections/StripeCheck";
import {PaymentElement, CardElement, useStripe} from "@stripe/react-stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import Fuse from "fuse.js";
import PhoneInput from "react-phone-input-2";
import {parsePhoneNumber, isValidNumber} from "libphonenumber-js";
import "react-phone-input-2/lib/style.css";
import {uchicagoCourses} from "../../assets/UChicago";
import {notreDameCourses} from "../../assets/Notre Dame";
import {rpiCourses} from "../../assets/RPI";
import {dartmouthCourses} from "../../assets/Dartmouth";
import {northeasternCourses} from "../../assets/Northeastern";
import {westernUniversityCourses} from "../../assets/Western University";
import {umassCourses} from "../../assets/UMass";
require("firebase/auth");

window.mobileCheck = function () {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

// window.sessionStorage.setItem('classSubjectSelected1', 'ACCT 20100')
// window.sessionStorage.setItem('classSectionsSelected1', [17312,14036])

let schools = [
  "UChicago",
  "Notre Dame",
  "RPI",
  "Dartmouth",
  "Northeastern",
  "Western University",
  "UMass",
];
let coursesData = [
  uchicagoCourses,
  notreDameCourses,
  rpiCourses,
  dartmouthCourses,
  northeasternCourses,
  westernUniversityCourses,
  umassCourses,
];
let termTimes = [
  1709625600, 1709625600, 1709625600, 1709625600, 1709625600, 1709625600,
  1709625600, 1723217440, 1732127393,
];

const firebaseConfig = {
  apiKey: "AIzaSyAQ1DgZyHU2UDmQ_JDgrEg6th8fUuMOFQg",
  authDomain: "uchiprereg.firebaseapp.com",
  projectId: "uchiprereg",
  storageBucket: "uchiprereg.appspot.com",
  messagingSenderId: "963203646451",
  appId: "1:963203646451:web:70d27bc0d01c0704650886",
  measurementId: "G-1FMVGVFPGW",
};

firebase.initializeApp(firebaseConfig);

var mouseDown = false;

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

let freeUsersArray = [
  "+19178168373",
  "+12245091991",
  "+17863505278",
  "+17087108441",
  "+18033711796",
  "+18608374226",
  "+14243929994",
  "+19706857425",
  "+17737987563",
  "+17737888004",
  "+18477906321",
  "+17328815655",
  "+14149165211",
  "+12138148916",
  "+12488029462",
  "+18325422188",
  "+18608636325",
  "+15024456682",
  "+19047902120",
  "+13099896627",
  "+14193200766",
  "+17735104851",
  "+12027632522",
  "+13108099387",
  "+13474098111",
  "+19175289583",
  "+13095306085",
  "+14699997362",
  "+13472057774",
  "+17139279195",
  "+14808866096",
  "+14804344586",
  "+17182003002",
  "+15166972124",
  "+17879402437",
  "+12029998416",
  "+19375578085",
  "+12036130170",
  "+19257259639",
  "+15102489778",
  "+16307439920",
  "+17703095075",
  "+16507769083",
  "+15122307229",
  "+18477785574",
  "+66916963175",
  "+16086300910",
  "+19085653326",
  "+13144794621",
  "+13123752925",
  "+17739529401",
  "+16506002482",
  "+13127186343",
  "+14084422905",
  "+12029265758",
  "+14702345428",
  "+12028137163",
  "+13106999256",
  "+16177758386",
  "+19145485489",
  "+17138909124",
  "+17187043243",
  "+17084913408",
  "+14107035368",
  "+12024598041",
  "+19734204762",
  "+12624758033",
  "+16463509253",
  "+19085176922",
  "+12162690310",
  "+17204417279",
  "+17602201548",
  "+13129786746",
  "+14138013717",
  "+13098572498",
  "+16825530436",
  "+16263532428",
  "+15104074958",
  "+16468412006",
  "+19732168750",
  "+19546818915",
  "+14697664243",
  "+19176484872",
  "+18486675266",
  "+18054555281",
  "+17733068421",
  "+8801765100738",
  "+19257195571",
  "+16502831962",
  "+19738767588",
  "+18608397388",
  "+12032933339",
  "+17736336786",
  "+16023158203",
  "+16154502490",
  "+17735447799",
  "+15736399003",
  "+13802401726",
  "+16126707721",
  "+16128672132",
  "+18184217246",
  //'+17037626410'
];

let classes = [];
let classTitles = [];

let classSelected1New = [];
let sectionsSelected1New = [];

// if(window.sessionStorage.getItem('userSchool')!=null){

if (window.sessionStorage.getItem("classSectionsSelected1") != null) {
  console.log(
    coursesData[schools.indexOf("UChicago")].filter(
      (course) =>
        course.course.subject.toLowerCase() ==
        window.sessionStorage.getItem("subjectSelected1").toLowerCase()
    )
  );

  classSelected1New.push([
    coursesData[schools.indexOf("UChicago")].filter(
      (course) =>
        course.course.subject.toLowerCase() ==
        window.sessionStorage.getItem("subjectSelected1").toLowerCase()
    )[0].course,
  ]);
}

if (window.sessionStorage.getItem("classSectionsSelected1") != null) {
  let sectionsSelected1 = window.sessionStorage
    .getItem("classSectionsSelected1")
    .split(",");

  for (var e = 0; e < sectionsSelected1.length; e++) {
    if (e == 0) {
      sectionsSelected1New.push(parseInt(sectionsSelected1[e]));
    }
  }
}

// }

// window.sessionStorage.removeItem('step1')
// window.sessionStorage.removeItem('step2')
// window.sessionStorage.removeItem('step3')

let isItAddDrop = [true, false, true, true, false, false, false];

function getCountryCode(phoneNumber) {
  try {
    const parsed = parsePhoneNumber(phoneNumber);
    return parsed?.country || "";
  } catch (error) {
    console.error(error);
    return "";
  }
}

function DemoWidget() {
  let currentDateInSeconds = new Date().getTime() / 1000;

  // let [userName, setUserName] = useState(window.sessionStorage.getItem('userName')==null?'':window.sessionStorage.getItem('userName'));
  let [userName, setUserName] = useState(
    window.location.href.includes("?")
      ? window.location.href.split("?")[1].split("+").join(" ")
      : window.sessionStorage.getItem("userName") == null
      ? ""
      : window.sessionStorage.getItem("userName")
  );

  let [userPhone, setUserPhone] = useState(
    window.sessionStorage.getItem("userPhone") == null
      ? ""
      : window.sessionStorage.getItem("userPhone")
  );
  let [userCountry, setUserCountry] = useState(
    window.sessionStorage.getItem("userPhone") == null
      ? ""
      : getCountryCode(window.sessionStorage.getItem("userPhone"))
  );
  let [wantsEmails, setWantsEmails] = useState(
    window.sessionStorage.getItem("wantsEmails") == null
      ? "no"
      : window.sessionStorage.getItem("wantsEmails")
  );
  let [buttonClicked, setButtonClicked] = useState(false);
  // let [userSchool, setUserSchool] = useState(window.sessionStorage.getItem('userSchool')==null?'':window.sessionStorage.getItem('userSchool'))
  let [userSchool, setUserSchool] = useState("UChicago");
  let [school, setSchool] = useState("");
  const [userSchoolAlert, setUserSchoolAlert] = useState("");

  let [addSchool, setAddSchool] = useState(false);
  let [newSchool, setNewSchool] = useState("");
  let [newSchoolError, setNewSchoolError] = useState("");
  let [submittedNewSchool, setSubmittedNewSchool] = useState(false);
  let [buttonClicked2, setButtonClicked2] = useState(false);

  // let [classTitlesNoDuplicates, setClassTitlesNoDuplicates] = useState(userSchool==''?[]:coursesData[schools.indexOf(userSchool)].map(a => `${a.course.subject} - ${a.course.name}`))

  let [classTitlesNoDuplicates, setClassTitlesNoDuplicates] = useState(
    coursesData[schools.indexOf("UChicago")].map(
      (a) => `${a.course.subject} - ${a.course.name}`
    )
  );

  let [emailTemplate, setEmailTemplate] = useState("");
  let [demoLaunched, setDemoLaunched] = useState(false);

  let [step1, setStep1] = useState(false);
  let [step2, setStep2] = useState(false);
  let [step3, setStep3] = useState(false);

  let [classTextInput1, setClassTextInput1] = useState(
    window.sessionStorage.getItem("classTextInput1") == null
      ? ""
      : window.sessionStorage.getItem("classTextInput1")
  );
  let [classSelected1, setClassSelected1] = useState(
    window.sessionStorage.getItem("classSectionsSelected1") == null
      ? []
      : classSelected1New[0]
  );
  let [subjectSelected1, setSubjectSelected1] = useState(
    window.sessionStorage.getItem("subjectSelected1") == null
      ? ""
      : window.sessionStorage.getItem("subjectSelected1")
  );
  let [classSectionsSelected1, setClassSectionsSelected1] = useState(
    window.sessionStorage.getItem("classSectionsSelected1") == null
      ? []
      : sectionsSelected1New
  );

  let [fuseSearch1, setFuseSearch1] = useState([]);
  let [showFuseList1, setShowFuseList1] = useState(true);
  const [allUsers, setAllUsers] = useState([]);

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection("grabLeads")
      .onSnapshot((querySnapshot) => {
        let allUsers = [];
        querySnapshot.forEach((doc) => {
          allUsers.push(doc.data());
        });
        setAllUsers(allUsers);
      });
    return () => {
      unsubscribe();
    };
  }, []);

  let className;
  let topOuterDivider;
  let bottomOuterDivider;
  let topDivider;
  let bottomDivider;
  let hasBgColor;
  let invertColor;

  const outerClasses = classNames(
    "signin section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "signin-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const sectionHeader = {
    title: "Seems too good to be true...?",
    // paragragph: 'The first 100 students get free access for this add period.'
  };

  let [demoNumbers, setDemoNumbers] = useState([]);

  useEffect(() => {
    firebase
      .firestore()
      .collection("demoLaunches")
      .orderBy("dateTime")
      // .get()
      // .then((querySnapshot) => {
      .onSnapshot((querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            if (
              window.sessionStorage.getItem("userPhone") ==
              change.doc.data().phone
            ) {
              setStep1(change.doc.data().step1);
              setStep2(change.doc.data().step2);
              setStep3(change.doc.data().step3);
            }
          }

          if (change.type === "modified") {
            if (
              window.sessionStorage.getItem("userPhone") ==
              change.doc.data().phone
            ) {
              setStep1(change.doc.data().step1);
              setStep2(change.doc.data().step2);
              setStep3(change.doc.data().step3);

              if (
                change.doc.data().step2 == true &&
                change.doc.data().step3 == false
              ) {
                if (!window.location.href.includes("preferences")) {
                  document.location.href = "#step2";
                }
              }

              if (change.doc.data().step3 == true) {
                if (!window.location.href.includes("preferences")) {
                  document.location.href = "#step3";
                }
              }
            }
          }
        });
      });
  }, [userPhone]);

  useEffect(() => {
    firebase
      .firestore()
      .collection("demoLaunches")
      .orderBy("dateTime")
      // .where('dateTime', '>', termTimes[schools.indexOf(userSchool)])
      // .get()
      // .then((querySnapshot) => {
      .onSnapshot((querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            setDemoNumbers((a) => [...a, change.doc.data().phone]);
          }
        });
      });
  }, []);

  async function submitRequest(event) {
    console.log(userPhone.replace(/\+/g, ""));
    //comment in below here

    event.preventDefault();
    // if(userName == '') {
    //   document.getElementById('nameInput').classList.add("form-error");
    //   document.getElementById('nameBlankHint').style.display='block';
    //   //document.location.href='#nameInput'
    // }
    // else
    if (userPhone == "") {
      // document.getElementById('phoneInput').classList.add("form-error");
      document.getElementById("phoneBlankHint").style.display = "block";
      //document.location.href='#phoneInput'
    } else if (/[a-zA-Z]/g.test(userPhone) == true) {
      // document.getElementById('phoneInput').classList.add("form-error");
      document.getElementById("phoneLetterHint").style.display = "block";
      //document.location.href='#phoneInput'
    } else if (
      userPhone.length < 11 ||
      (isValidNumber(`+${userPhone}`) == false &&
        isValidNumber(userPhone) == false)
    ) {
      // document.getElementById('phoneInput').classList.add("form-error")
      document.getElementById("phoneShortHint").style.display = "block";
      //document.location.href='#phoneInput'
    } else if (
      userPhone.includes("@") ||
      userPhone.includes("-") ||
      userPhone.includes(" ") ||
      userPhone.includes("_") ||
      userPhone.includes("(") ||
      userPhone.includes(")") ||
      userPhone.includes("[") ||
      userPhone.includes("]") ||
      userPhone.includes("—") ||
      userPhone.includes(".") ||
      userPhone.includes("/") ||
      userPhone.includes(",") ||
      userPhone.includes("*") ||
      userPhone.includes("~")
    ) {
      // document.getElementById('phoneInput').classList.add("form-error")
      document
        .getElementById("phoneSymbolHint")
        .classList.add("text-color-error");
    } else if (classSectionsSelected1.length == 0) {
      document.getElementById("classTitleInput1").classList.add("form-error");
      document.getElementById("classSelectHint1").style.display = "block";
      //document.location.href='#classTitleInput1'
    } else if (demoNumbers.filter((a) => a == userPhone).length > 2) {
      document.getElementById("0demosLeftDiv").classList.remove("shakeClass");
      void document.getElementById("0demosLeftDiv").offsetWidth;
      document.getElementById("0demosLeftDiv").classList.add("shakeClass");
    } else {
      if (buttonClicked == false) {
        if (userPhone.includes("+")) {
          window.sessionStorage.setItem("userPhone", userPhone);
        } else {
          window.sessionStorage.setItem("userPhone", `+${userPhone}`);
        }
        if (allUsers.map((a) => a.phone).includes(userPhone)) {
          window.sessionStorage.setItem(
            "userName",
            allUsers.map((a) => a.name)[
              allUsers.map((a) => a.phone).indexOf(userPhone)
            ]
          );
        }

        setButtonClicked(true);

        // document.getElementById('nameInput').classList.add("form-success")
        // document.getElementById('phoneInput').classList.add("form-success")

        // setEmailTemplate('')

        // window.sessionStorage.setItem('userName', userName);
        // window.sessionStorage.setItem('userPhone', userPhone);

        // window.sessionStorage.setItem('classTextInput1', classTextInput1);
        // window.sessionStorage.setItem('subjectSelected1', subjectSelected1);
        // window.sessionStorage.setItem('classSectionsSelected1', classSectionsSelected1);

        await firebase
          .firestore()
          .collection("demoLaunches")
          .add({
            phone: userPhone.includes("+") ? userPhone : `+${userPhone}`,
            // school: window.sessionStorage.getItem("userSchool"),
            school: "UChicago",
            dateTime: currentDateInSeconds,
            dateTimeReverse: -currentDateInSeconds,
            classSubject: subjectSelected1,
            classSections: classSectionsSelected1,
            labSelected: coursesData[schools.indexOf("UChicago")]
              .filter((a) => a.course.subject == subjectSelected1)[0]
              .course.sections.filter((a) => a.lab == true)
              .map((a) => a.sectionNumber)
              .some((a) => classSectionsSelected1.includes(a)),
            step1: true,
            step2: false,
            step3: false,
            isDemo: true,
          })
          .then(async (docRef) => {
            // window.sessionStorage.setItem('yourDemoID', docRef.id)
            setDemoLaunched(true);
            setButtonClicked(false);
          })
          .then(() => {
            document.location.href = "#demoAnimation";
          });

        // .then(async () => {
        //     await fetch('https://us-central1-uchiprereg.cloudfunctions.net/templatemail/emails-widget', {
        //       method:'POST',
        //       headers: {
        //         'Content-Type': 'application/json',
        //         'Access-Control-Allow-Origin': '*',
        //         'Access-Control-Allow-Methods': 'GET, POST',
        //         'Access-Control-Allow-Headers': 'Content-Type',
        //         'Access-Control-Max-Age': '3600',
        //       },
        //       body: JSON.stringify({
        //         userName: userName,
        //         userPhone: userPhone,
        //         description: classSelected1[0].description,
        //         prof:classSelected1[0].sections.filter(a=> a.sectionNumber==classSectionsSelected1[0])[0].sectionProf,
        //         time:classSelected1[0].sections.filter(a=> a.sectionNumber==classSectionsSelected1[0])[0].sectionTime,
        //         lab:classSelected1[0].sections.filter(a=> a.sectionNumber==classSectionsSelected1[0])[0].lab,

        //         // subjectSelected1: subjectSelected1,

        //       })
        //     })
        //     // .then(res => res.json())
        //     .then(res => res.text())
        //     // .then(awesome => console.log(awesome))
        //     .then(function(openaiResult){
        //     document.getElementById('templateEmailDiv').innerHTML = ""
        //     //document.getElementById('emailBox').style.display='block'
        //     let node = document.createElement("div");
        //     let textNode = document.createTextNode(openaiResult);
        //     node.appendChild(textNode);
        //     document.getElementById('templateEmailDiv').appendChild(node);
        //     setEmailTemplate(openaiResult)
        //     setTemplateLoading(false)
        //     setButtonClicked(false)
        //     })
        //     .catch(err => {
        //       console.log(err)
        //       // sendInData()
        //     })

        //   // await doTheRest()

        // })
      }
    }

    //comment above here
  }

  function changeNameInput(event) {
    setUserName(event.target.value);
    document.getElementById("nameInput").classList.remove("form-error");
    document.getElementById("nameBlankHint").style.display = "none";
    document.getElementById("nameSpaceHint").style.display = "none";
    document.getElementById("nameLettersHint").style.display = "none";
    window.sessionStorage.removeItem("infoSuccess");
  }

  function changePhoneInput(phone) {
    setUserPhone(phone);
    // document.getElementById('phoneInput').classList.remove("form-error")
    document.getElementById("phoneBlankHint").style.display = "none";
    document.getElementById("phoneShortHint").style.display = "none";
    document.getElementById("phoneLetterHint").style.display = "none";
    window.sessionStorage.removeItem("infoSuccess");
  }

  function changeNewSchool(event) {
    setNewSchool(event.target.value);
    document.getElementById("newSchoolInput").classList.remove("form-error");
    setNewSchoolError("");
  }

  async function submitNewSchool() {
    if (newSchool == "") {
      setNewSchoolError("Please enter your school");
      document.getElementById("newSchoolInput").classList.add("form-error");
    } else if (buttonClicked2 == false) {
      setNewSchoolError("");
      setButtonClicked2(true);
      await firebase
        .firestore()
        .collection("schoolRequests")
        .add({
          school: newSchool,
          dateTime: currentDateInSeconds,
          dateTimeReverse: -currentDateInSeconds,
        })
        .then(() => {
          setSubmittedNewSchool(true);
          setButtonClicked2(false);
        });
    }
  }

  async function copyPasteLink(event) {
    await navigator.clipboard.writeText(emailTemplate);
    showCopiedComputer(event);
  }

  async function refreshTemplate(event) {
    showCopiedComputer2(event);
    await submitRequest(event);
  }

  // window.onclick = (event) => {console.log(document.getElementById('preorderTooltipText').getBoundingClientRect())};

  window.ontouchmove = (event) => {
    hideTooltip();
  };

  function hideTooltip(event) {
    let leftLim = document
      .getElementById("preorderTooltipText")
      .getBoundingClientRect().left;
    let rightLim = document
      .getElementById("preorderTooltipText")
      .getBoundingClientRect().right;
    let topLim = document
      .getElementById("preorderTooltipText")
      .getBoundingClientRect().top;
    let bottomLim = document
      .getElementById("preorderTooltipText")
      .getBoundingClientRect().bottom;

    // if((event.touches[0].clientX < rightLim)&&(event.touches[0].clientX > leftLim)&&(event.touches[0].clientY < topLim)&&(event.touches[0].clientY > bottomLim)){
    //   event.preventDefault()
    // }else{
    document.getElementById("preorderTooltipText").style.visibility = "hidden";
    // }
  }

  function clickTooltip(event) {
    document.getElementById("preorderTooltipText").style.visibility = "visible";
    event.preventDefault();
    document
      .getElementById("preorderTooltipText")
      .classList.remove("fadeOut2Class");
    void document.getElementById("preorderTooltipText").offsetWidth;
    document
      .getElementById("preorderTooltipText")
      .classList.add("fadeOut2Class");
  }

  function clickTooltip2(event) {
    document.getElementById("preorderTooltipText2").style.visibility =
      "visible";
    event.preventDefault();
    document
      .getElementById("preorderTooltipText2")
      .classList.remove("fadeOut2Class");
    void document.getElementById("preorderTooltipText2").offsetWidth;
    document
      .getElementById("preorderTooltipText2")
      .classList.add("fadeOut2Class");
  }

  function showCopiedComputer(event) {
    if (window.mobileCheck() == false) {
      // document.getElementById('preorderTooltipText').style.visibility='hidden'
      document.getElementById("preorderTooltipTextComputer").style.visibility =
        "visible";
      event.preventDefault();
      document
        .getElementById("preorderTooltipTextComputer")
        .classList.remove("fadeOut2Class");
      void document.getElementById("preorderTooltipTextComputer").offsetWidth;
      document
        .getElementById("preorderTooltipTextComputer")
        .classList.add("fadeOut2Class");
      // document.getElementById('preorderTooltipText').style.visibility='visible'
    }
  }

  function showCopiedComputer2(event) {
    if (window.mobileCheck() == false) {
      document.getElementById("preorderTooltipTextComputer2").style.visibility =
        "visible";
      event.preventDefault();
      document
        .getElementById("preorderTooltipTextComputer2")
        .classList.remove("fadeOut2Class");
      void document.getElementById("preorderTooltipTextComputer2").offsetWidth;
      document
        .getElementById("preorderTooltipTextComputer2")
        .classList.add("fadeOut2Class");
    }
  }

  const sectionHeader2 = {
    title: "Currently processing your request...",
    paragraph: "Take a look under the hood — in realtime ",
  };

  function submitSchool() {
    if (school == "") {
      setUserSchoolAlert(
        "You must select a school to use the Class Clutch demo"
      );
    } else {
      window.sessionStorage.removeItem("classTextInput1");
      window.sessionStorage.removeItem("classTextInput2");
      window.sessionStorage.removeItem("classTextInput3");
      window.sessionStorage.removeItem("classTextInput4");
      window.sessionStorage.removeItem("classTextInput5");
      window.sessionStorage.removeItem("subjectSelected1");
      window.sessionStorage.removeItem("subjectSelected2");
      window.sessionStorage.removeItem("subjectSelected3");
      window.sessionStorage.removeItem("subjectSelected4");
      window.sessionStorage.removeItem("subjectSelected5");
      window.sessionStorage.removeItem("classSectionsSelected1");
      window.sessionStorage.removeItem("classSectionsSelected2");
      window.sessionStorage.removeItem("classSectionsSelected3");
      window.sessionStorage.removeItem("classSectionsSelected4");
      window.sessionStorage.removeItem("classSectionsSelected5");
      window.sessionStorage.removeItem("labSelected1");
      window.sessionStorage.removeItem("labSelected2");
      window.sessionStorage.removeItem("labSelected3");
      window.sessionStorage.removeItem("labSelected4");
      window.sessionStorage.removeItem("labSelected5");

      setClassTextInput1("");
      setClassSelected1([]);
      setSubjectSelected1("");
      setClassSectionsSelected1([]);

      window.sessionStorage.setItem("userSchool", school);
      setUserSchool(school);

      classes = [];
      classTitles = [];

      if (school != null && schools.includes(school)) {
        for (var g = 0; g < coursesData[schools.indexOf(school)].length; g++) {
          classes.push(coursesData[schools.indexOf(school)][g].course);
          classTitles.push(
            `${coursesData[schools.indexOf(school)][g].course.subject} - ${
              coursesData[schools.indexOf(school)][g].course.name
            }`
          );
        }
      }

      setClassTitlesNoDuplicates([...new Set(classTitles)]);
    }
  }

  return (
    <div>
      <section
        // {...props}
        className={outerClasses}
        //class='demoSection'
        // className="has-bg-color-cut"
        // style={{position:'relative'}}
        style={{paddingTop: "0px"}}
      >
        <div className="container" id="demo">
          <div
            className={innerClasses}
            //style={{paddingTop:'0'}}
          >
            <SectionHeader
              tag="h1"
              data={sectionHeader}
              style={{paddingBottom: "24px", marginTop: "0.75rem"}}
              className="center-content reveal-from-top"
            />
            <div
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                textAlign: "center",
                marginTop: "0px",
              }}
              className="emailsWidgetSub reveal-from-top"
            >
              Find out for yourself — our free demo will text you the current
              open/closed status for any class
            </div>

            <div className="tiles-wrap reveal-from-bottom">
              <div className="tiles-item">
                <div
                  className="tiles-item-inner has-shadow"
                  style={{marginTop: "1rem", color: "rgb(87,94,134)"}}
                >
                  {/* We will text you when a spot in the class opens up. */}

                  {userSchool == "" ? (
                    <>
                      {addSchool == false ? (
                        <div class="tiles-item">
                          {/* <div class='tiles-item-inner has-shadow' style={{padding:'15px'}}> */}
                          <div style={{marginTop: "2rem", fontWeight: "bold"}}>
                            Select Your School
                          </div>

                          <select
                            name="school"
                            id="school"
                            class="form-select"
                            value={school}
                            style={{marginTop: "0.5rem"}}
                            onChange={(event) => {
                              setSchool(event.target.value);
                            }}
                          >
                            <option value="">Select Option...</option>
                            {schools.map((school) => (
                              <option key={school} value={school}>
                                {school}
                              </option>
                            ))}
                          </select>
                          <div
                            class="text-color-error fadeIn2Class"
                            style={{fontSize: "16px"}}
                          >
                            {userSchoolAlert}
                          </div>

                          <Button
                            type="button"
                            color="primary"
                            wide
                            style={{marginTop: "1rem"}}
                            onClick={submitSchool}
                          >
                            <div
                              class="noSelectText"
                              style={{pointerEvents: "none"}}
                            >
                              Next
                            </div>
                          </Button>

                          <div
                            style={{
                              marginTop: "2rem",
                              fontWeight: "bold",
                              fontSize: "1rem",
                            }}
                          >
                            Don’t see your school?
                          </div>

                          <Button
                            type="button"
                            //color='primary'
                            onClick={() => setAddSchool(true)}
                            style={{width: "306px", marginTop: "1rem"}}
                          >
                            Request Your School
                          </Button>
                        </div>
                      ) : (
                        <>
                          {submittedNewSchool == false ? (
                            <>
                              <div class="tiles-item">
                                <div
                                  style={{
                                    marginTop: "2rem",
                                    fontWeight: "bold",
                                    fontSize: "1rem",
                                  }}
                                >
                                  Request your school
                                </div>

                                <input
                                  label="Full name"
                                  placeholder="Enter Your School"
                                  labelHidden
                                  id="newSchoolInput"
                                  required
                                  className="form-input"
                                  onChange={changeNewSchool}
                                  value={newSchool}
                                  style={{marginTop: "0.5rem"}}
                                />
                                <div
                                  //style={{marginTop:'0.5rem'}}
                                  class="form-hint text-color-error"
                                  style={{fontSize: "16px"}}
                                >
                                  {newSchoolError}
                                </div>

                                <div className="mt-24 mb-32">
                                  <Button
                                    type="button"
                                    color="primary"
                                    onClick={submitNewSchool}
                                    style={{width: "306px"}}
                                  >
                                    Submit
                                  </Button>
                                </div>
                              </div>
                            </>
                          ) : (
                            <div class="tiles-item">
                              <div>
                                Thanks for submitting your school! Contact us at
                                (612) 670-7721 for any questions.
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  ) : isItAddDrop[schools.indexOf(userSchool)] == false ? (
                    <>
                      <div
                        style={{
                          fontSize: "16px",
                          marginTop: "0.5rem",
                          marginLeft: "0.5rem",
                        }}
                      >
                        School: {userSchool}&nbsp;
                        <a
                          style={{cursor: "pointer"}}
                          onClick={(event) => {
                            setUserSchool("");
                          }}
                        >
                          Switch Schools
                        </a>
                      </div>
                      <div style={{padding: "20px"}}>
                        It is not a period where students can add into classes
                        at {userSchool}. If this is a mistake, please email us
                        at classclutchteam@gmail.com
                      </div>
                    </>
                  ) : (
                    <>
                      {/* <div 
                style={{fontSize:'16px', marginTop:'0.5rem', marginLeft:'0.5rem'}}
                >
                      School: {userSchool}&nbsp;
                <a style={{cursor:'pointer'}} onClick={event => {setUserSchool('')}}>
                  Switch Schools
                </a>
                </div> */}
                      <form style={{marginTop: "1rem", width: "100%"}}>
                        <fieldset>
                          {/* <div className="mb-12">
                        <input
                          label="Full name"
                          placeholder="Full name" 
                          labelHidden
                          id = "nameInput"
                          required 
                          className= "form-input"
                          onChange={changeNameInput}
                          value={userName}
                          />
                      </div>
                      <div id='nameBlankHint' style={{display:'none'}} class="form-hint text-color-error">
                        Please enter your name
                      </div>
                      <div id='nameSpaceHint' style={{display:'none'}} class="form-hint text-color-error">
                        Please enter your first and last name.
                      </div>
                      <div id='nameLettersHint' style={{display:'none'}} class="form-hint text-color-error">
                        Please use only letters.
                      </div> */}

                          <div class="tiles-item reveal-from-bottom is-revealed">
                            <div
                              class="tiles-item-inner has-shadow"
                              style={{padding: "15px"}}
                            >
                              <div>
                                <div
                                  style={{
                                    marginTop: "0rem",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Class
                                </div>

                                <input
                                  id="classTitleInput1"
                                  type="text"
                                  autocomplete="off"
                                  placeholder="Class title"
                                  onKeyDown={selectFirstOption1}
                                  onMouseDown={() => {
                                    setClassTextInput1("");
                                    setClassSelected1([]);
                                    setSubjectSelected1("");
                                    setClassSectionsSelected1([]);
                                    setFuseSearch1([]);
                                    setShowFuseList1(true);
                                  }}
                                  value={classTextInput1}
                                  onChange={changeClassTitleInput1}
                                  className="form-input"
                                />

                                {fuseSearch1 != classTitlesNoDuplicates &&
                                fuseSearch1.length != 0 &&
                                showFuseList1 == true ? (
                                  <div>
                                    <div style={{height: "0.75rem"}}></div>
                                    {fuseSearch1.map((course, courseIndex) => {
                                      if (courseIndex < 7) {
                                        return (
                                          <div
                                            onClick={(event) => {
                                              clickFuseElement1(course.item);
                                            }}
                                            id={course.item}
                                            class="selectClass"
                                          >
                                            {course.item}
                                          </div>
                                        );
                                      }
                                    })}
                                  </div>
                                ) : null}
                              </div>

                              <div
                                style={{
                                  display:
                                    classSelected1.length != 0
                                      ? "block"
                                      : "none",
                                  marginTop: "0.5rem",
                                  fontWeight: "bold",
                                }}
                                class="noSelectText"
                              >
                                Select the section you want
                              </div>
                              {classSelected1.map((course, courseIndex) => {
                                return (
                                  <div>
                                    {course.sections.map(
                                      (section, sectionIndex) => {
                                        return (
                                          <div
                                            id={section.sectionNumber}
                                            name="classSlotsSelect"
                                            class="slotSelect"
                                            onMouseDown={clickClassSection1}
                                            //onMouseOver={hoverClassSection1}
                                            style={{
                                              backgroundColor:
                                                classSectionsSelected1.includes(
                                                  section.sectionNumber
                                                )
                                                  ? "#3D5AFE"
                                                  : "white",
                                              color:
                                                classSectionsSelected1.includes(
                                                  section.sectionNumber
                                                )
                                                  ? "white"
                                                  : "#575E86",
                                              padding: "0.2rem",
                                              marginTop: "0.3rem",
                                              borderStyle: "solid",
                                              borderColor: "black",
                                              borderWidth: "1px",
                                            }}
                                          >
                                            {section.lab == true ? (
                                              <div
                                                style={{
                                                  color: "#33CEFF",
                                                  float: "right",
                                                  fontSize: "16px",
                                                  fontWeight: "",
                                                  marginRight: "0.2rem",
                                                }}
                                              >
                                                Lab
                                              </div>
                                            ) : null}
                                            {section.sectionProf.length != 0 ? (
                                              <div
                                                class="noSelectText"
                                                style={{
                                                  zIndex: 2,
                                                  pointerEvents: "none",
                                                }}
                                              >
                                                {section.sectionName.includes(
                                                  "Section"
                                                ) ? (
                                                  <div
                                                    class="noSelectText"
                                                    style={{
                                                      zIndex: 1,
                                                      pointerEvents: "none",
                                                    }}
                                                  >
                                                    {section.sectionName} (
                                                    {section.sectionNumber})
                                                  </div>
                                                ) : (
                                                  <div
                                                    class="noSelectText"
                                                    style={{
                                                      zIndex: 1,
                                                      pointerEvents: "none",
                                                    }}
                                                  >
                                                    Section{" "}
                                                    {section.sectionName} (
                                                    {section.sectionNumber})
                                                  </div>
                                                )}

                                                <div
                                                  class="noSelectText"
                                                  style={{
                                                    zIndex: 1,
                                                    pointerEvents: "none",
                                                  }}
                                                >
                                                  {section.sectionProf.map(
                                                    (prof, profIndex) => {
                                                      return (
                                                        <div
                                                          style={{
                                                            pointerEvents:
                                                              "none",
                                                            display: "inline",
                                                          }}
                                                        >
                                                          {section.sectionProf
                                                            .length == 1 ? (
                                                            <div
                                                              style={{
                                                                pointerEvents:
                                                                  "none",
                                                                display:
                                                                  "inline",
                                                              }}
                                                            >
                                                              {prof}
                                                            </div>
                                                          ) : profIndex !=
                                                              section
                                                                .sectionProf
                                                                .length -
                                                                1 &&
                                                            section.sectionProf
                                                              .length == 2 ? (
                                                            <div
                                                              style={{
                                                                pointerEvents:
                                                                  "none",
                                                                display:
                                                                  "inline",
                                                              }}
                                                            >
                                                              {prof}&nbsp;
                                                            </div>
                                                          ) : profIndex !=
                                                              section
                                                                .sectionProf
                                                                .length -
                                                                1 &&
                                                            section.sectionProf
                                                              .length > 2 ? (
                                                            <div
                                                              style={{
                                                                pointerEvents:
                                                                  "none",
                                                                display:
                                                                  "inline",
                                                              }}
                                                            >
                                                              {prof},&nbsp;
                                                            </div>
                                                          ) : (
                                                            <div
                                                              style={{
                                                                pointerEvents:
                                                                  "none",
                                                                display:
                                                                  "inline",
                                                              }}
                                                            >
                                                              and&nbsp;{prof}
                                                            </div>
                                                          )}
                                                        </div>
                                                      );
                                                    }
                                                  )}
                                                </div>
                                              </div>
                                            ) : section.sectionName.includes(
                                                "Section"
                                              ) ? (
                                              <div
                                                class="noSelectText"
                                                style={{
                                                  zIndex: 2,
                                                  pointerEvents: "none",
                                                }}
                                              >
                                                {section.sectionName} (
                                                {section.sectionNumber})
                                              </div>
                                            ) : (
                                              <div
                                                class="noSelectText"
                                                style={{
                                                  zIndex: 2,
                                                  pointerEvents: "none",
                                                }}
                                              >
                                                Section {section.sectionName} (
                                                {section.sectionNumber})
                                              </div>
                                            )}
                                            {section.sectionTime != null ? (
                                              <div
                                                class="noSelectText"
                                                style={{
                                                  zIndex: 2,
                                                  pointerEvents: "none",
                                                }}
                                              >
                                                {section.sectionTime}
                                              </div>
                                            ) : null}
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                );

                                function clickClassSection1(event) {
                                  document
                                    .getElementById("classTitleInput1")
                                    .classList.remove("form-error");
                                  document.getElementById(
                                    "classSelectHint1"
                                  ).style.display = "none";

                                  // if(classSectionsSelected1.length==0){
                                  //   setClassSectionsSelected1([event.target.id])
                                  //   document.getElementById(event.target.id).style.backgroundColor='#3D5AFE';
                                  //   document.getElementById(event.target.id).style.color='white';
                                  // }else if(){

                                  // }
                                  // else{
                                  //   setClassSectionsSelected1([])
                                  //   document.getElementById(event.target.id).style.backgroundColor='white';
                                  //   document.getElementById(event.target.id).style.color='#575E86';
                                  // }

                                  if (classSectionsSelected1.length == 0) {
                                    setClassSectionsSelected1([
                                      parseFloat(event.target.id),
                                    ]);
                                    document.getElementById(
                                      event.target.id
                                    ).style.backgroundColor = "#3D5AFE";
                                    document.getElementById(
                                      event.target.id
                                    ).style.color = "white";
                                  } else if (
                                    classSectionsSelected1[0] == event.target.id
                                  ) {
                                    setClassSectionsSelected1([]);
                                    document.getElementById(
                                      event.target.id
                                    ).style.backgroundColor = "white";
                                    document.getElementById(
                                      event.target.id
                                    ).style.color = "#575E86";
                                  } else {
                                    document.getElementById(
                                      event.target.id
                                    ).style.backgroundColor = "#3D5AFE";
                                    document.getElementById(
                                      event.target.id
                                    ).style.color = "white";
                                    setClassSectionsSelected1([
                                      parseFloat(event.target.id),
                                    ]);
                                    document.getElementById(
                                      classSectionsSelected1[0]
                                    ).style.backgroundColor = "white";
                                    document.getElementById(
                                      classSectionsSelected1[0]
                                    ).style.color = "#575E86";
                                  }

                                  // if(!classSectionsSelected1.includes(event.target.id)){
                                  //   document.getElementById(event.target.id).style.backgroundColor='#3D5AFE';
                                  //   document.getElementById(event.target.id).style.color='white';
                                  //   setClassSectionsSelected1([...classSectionsSelected1, event.target.id])
                                  // }else{
                                  //   document.getElementById(event.target.id).style.backgroundColor='white';
                                  //   document.getElementById(event.target.id).style.color='#575E86';
                                  //   setClassSectionsSelected1([
                                  //     ...classSectionsSelected1.slice(0, classSectionsSelected1.indexOf(event.target.id)),
                                  //     ...classSectionsSelected1.slice(classSectionsSelected1.indexOf(event.target.id) + 1, classSectionsSelected1.length)
                                  //   ]);
                                  // }
                                }
                              })}
                              <div
                                id="classSelectHint1"
                                style={{display: "none"}}
                                class="form-hint text-color-error"
                              >
                                Please select the class section you want to demo
                              </div>
                            </div>
                          </div>

                          <div style={{padding: "12px"}}>
                            <div className="mb-12">
                              <PhoneInput
                                country={userCountry == "" ? "us" : userCountry}
                                value={userPhone}
                                onChange={(phone) => changePhoneInput(phone)}
                                placeholder="Phone number"
                                maxLength="14"
                                enableSearch={true}
                                // inputStyle={{ width: '100%' }}
                                id="phoneInput"
                                required
                                // style={{borderStyle:'dashed'}}
                                class="mb-12"
                              />
                            </div>

                            <div
                              id="phoneBlankHint"
                              style={{display: "none", fontSize: "16px"}}
                              class="form-hint text-color-error"
                            >
                              Please enter your phone number
                            </div>
                            <div
                              id="phoneShortHint"
                              style={{display: "none", fontSize: "16px"}}
                              class="form-hint text-color-error"
                            >
                              Please enter a valid phone number
                            </div>
                            <div
                              id="phoneLetterHint"
                              style={{display: "none", fontSize: "16px"}}
                              class="form-hint text-color-error"
                            >
                              Please enter a valid phone number
                            </div>
                            {userPhone.includes("@") ||
                            userPhone.includes("-") ||
                            userPhone.includes(" ") ||
                            userPhone.includes("_") ||
                            userPhone.includes("(") ||
                            userPhone.includes(")") ||
                            userPhone.includes("[") ||
                            userPhone.includes("]") ||
                            userPhone.includes("—") ||
                            userPhone.includes(".") ||
                            userPhone.includes("/") ||
                            userPhone.includes(",") ||
                            userPhone.includes("*") ||
                            userPhone.includes("~") ? (
                              <div
                                id="phoneSymbolHint"
                                class="form-hint text-color-mid"
                                style={{fontSize: "16px"}}
                              >
                                Please enter your number in the format:
                                7037626410. If you would like to specify country
                                code, use this format: +2551234567890.
                              </div>
                            ) : null}

                            {/* <div className="mb-12">
                        <Input
                          type="password"
                          label="Password"
                          placeholder="Password"
                          labelHidden
                          required />
                      </div> */}

                            <div className="mt-24 mb-32">
                              <img
                                src="/ellipsis-white.gif"
                                style={{
                                  position: "absolute",
                                  height: "45px",
                                  left: "0",
                                  right: "0",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                  display:
                                    buttonClicked == true ? "block" : "none",
                                }}
                              />

                              <Button
                                type="button"
                                color="primary"
                                wide
                                onClick={submitRequest}
                              >
                                <div
                                  class="noSelectText"
                                  style={{
                                    pointerEvents: "none",
                                    display:
                                      buttonClicked == false ? "block" : "none",
                                  }}
                                >
                                  Try me for free
                                </div>
                              </Button>
                            </div>
                            {demoNumbers.includes(userPhone) ? (
                              demoNumbers.filter((a) => a == userPhone).length <
                              2 ? (
                                <div
                                  id="2demosLeftDiv"
                                  class="form-hint text-color-secondary fadeIn2Class"
                                  style={{
                                    pointerEvents: "none",
                                    textAlign: "center",
                                    marginTop: "1rem",
                                    fontSize: "1rem",
                                  }}
                                >
                                  You have{" "}
                                  {3 -
                                    demoNumbers.filter((a) => a == userPhone)
                                      .length}{" "}
                                  demos left
                                </div>
                              ) : demoNumbers.filter((a) => a == userPhone)
                                  .length == 2 ? (
                                <div
                                  id="1demoLeftDiv"
                                  class="form-hint text-color-error fadeIn2Class"
                                  style={{
                                    pointerEvents: "none",
                                    textAlign: "center",
                                    marginTop: "1rem",
                                    fontSize: "1rem",
                                  }}
                                >
                                  You have 1 demo left
                                </div>
                              ) : (
                                <div
                                  id="0demosLeftDiv"
                                  class="form-hint text-color-error fadeIn2Class"
                                  style={{
                                    pointerEvents: "none",
                                    textAlign: "center",
                                    marginTop: "1rem",
                                    fontSize: "1rem",
                                  }}
                                >
                                  You have used all your demos. If you would
                                  like to learn more or have questions about our
                                  software, please reach out to Jack at (612)
                                  670-721.
                                </div>
                              )
                            ) : null}
                          </div>
                        </fieldset>
                      </form>
                    </>
                  )}

                  {/* <div className="has-shadow" style={{
          display:templateLoading==true||emailTemplate==''?'none':'block'
          
          //display:window.sessionStorage.getItem('infoSuccess')=='true'?'block':'none'
          }} id='emailBox'>

         

          <div 
          
          class='copyLinkBox'
          style={{
            //cursor:'grab',
            textAlign:'left', marginLeft:'auto', marginRight:'auto', 
            //borderStyle:'solid', borderWidth:'0.1rem', borderColor:'rgb(87,94,134)', 
            backgroundColor:'white',  
            fontSize:'16px', width:'100%', color:'black',
            paddingTop:'20px',paddingRight:'20px',paddingLeft:'20px'
          }}>
          
          <div style={{
            //cursor:'grab', 
          display:'inline-block',
          verticalAlign:'middle'}} id='templateEmailDiv'>
          
          </div>



          <div style={{display:'table'}}>

          <div style={{cursor:'pointer', display:'table-cell', fontSize:'1.2rem', color:'rgb(13, 98, 255)'}} class='tooltip' onTouchStart={clickTooltip2} id='preorderTooltip2'> 
          <img src='repeatSymbol.png' style={{display:'table-cell', height:'2rem',marginTop:'1rem'}} onClick={refreshTemplate}/>
          {window.mobileCheck()==true?
          <span class='tooltiptext' id='preorderTooltipText2'>Getting new email</span>
          :
          <div style={{pointerEvents:'none'}}>
          <span class='tooltiptext' id='preorderTooltipText2'>Get another email</span>
          <span class='tooltiptext' id='preorderTooltipTextComputer2' style={{visibility:'hidden'}}>Getting new email</span>
          </div>
          }
          </div>


          <div style={{cursor:'pointer', display:'table-cell', fontSize:'1.2rem', color:'rgb(13, 98, 255)'}} class='tooltip' onTouchStart={clickTooltip} id='preorderTooltip'> 

           <img 
          style={{
            height:'2rem', 
            cursor:'grab', 
            display:'table-cell',
            verticalAlign:'middle',
            
          }}
           src='CopyToClip.png' onClick={copyPasteLink}/>
          
           
          {window.mobileCheck()==true?
          <span class='tooltiptext' id='preorderTooltipText'>Copied to clipboard </span>
          :
          <div style={{pointerEvents:'none'}}>
          <span class='tooltiptext' id='preorderTooltipText'>Copy to clipboard </span>
          <span class='tooltiptext' id='preorderTooltipTextComputer' style={{visibility:'hidden'}}>Copied to clipboard </span>
          </div>
          }
          </div>

          </div>


          </div>

          
          </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div id="demoAnimation">
        <div
          style={{
            display: demoLaunched == true ? "block" : "none",
          }}
          className="container"
        >
          <div
            className={innerClasses}
            style={{paddingTop: "1rem", paddingBottom: "1rem"}}
          >
            <SectionHeader
              data={sectionHeader2}
              className={
                demoLaunched == true
                  ? "center-content fadeIn2Class"
                  : "center-content"
              }
            />
            <div>
              <Timeline>
                {/* {step1==true? */}
                <TimelineItem
                  style={{
                    display: step1 == true ? "block" : "none",
                  }}
                  title="1 - Adding"
                  className={
                    step1 == true && step2 == false && step3 == false
                      ? "timeline-item-inner fadeIn3Class"
                      : "timeline-item-inner"
                  }
                  id="step1"
                >
                  <div>Adding your class and number to our secure database</div>
                  <img
                    src="databaseWireframe.png"
                    style={{
                      width: "3rem",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  />
                </TimelineItem>
                {/* :null} */}
                {/* {step2==true? */}
                <TimelineItem
                  style={{
                    display: step2 == true ? "block" : "none",
                  }}
                  title="2 - Searching"
                  className={
                    step2 == true && step3 == false
                      ? "timeline-item-inner fadeIn3Class"
                      : "timeline-item-inner"
                  }
                  id="step2"
                >
                  <div>
                    Searching through all the UChicago courses to find your
                    class
                  </div>
                  <img
                    src="searchBarWireframe.png"
                    style={{
                      width: "15rem",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  />
                </TimelineItem>
                {/* :null} */}
                {/* {step3==true? */}
                <TimelineItem
                  style={{
                    display: step3 == true ? "block" : "none",
                  }}
                  title="3 - Texting"
                  className={
                    step3 == true
                      ? "timeline-item-inner fadeIn2Class"
                      : "timeline-item-inner"
                  }
                  id="step3"
                >
                  <div>
                    Texting your number — letting you know if it's open or
                    closed
                  </div>
                  <img
                    src="iPhoneWireframe.png"
                    style={{
                      width: "3rem",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  />
                </TimelineItem>
                {/* :null} */}
              </Timeline>
              {/* {step3==true? */}
              <div
                style={{
                  display: step3 == true ? "block" : "none",
                }}
              >
                <div style={{height: "1rem"}}></div>
                <h2
                  style={{textAlign: "center", marginBottom: "1rem"}}
                  //className='reveal-from-top'
                  class={step3 == true ? "fadeIn2Class" : ""}
                >
                  Like what you see?
                </h2>
                <div
                  style={{
                    textAlign: "center",
                    width: "15rem",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  We’ll text you when the open/close status changes for any
                  class
                </div>
                <div
                  style={{textAlign: "center"}}
                  class={step3 == true ? "fadeIn2Class" : ""}
                  //className='reveal-from-top'
                >
                  {isItAddDrop[schools.indexOf("UChicago")] == true ? (
                    freeUsersArray.includes(
                      window.sessionStorage.getItem("userPhone")
                    ) ? (
                      <Button tag="a" href="/getnotifications" color="primary">
                        Launch Notifications &nbsp;&#10140;
                      </Button>
                    ) : allUsers
                        .map((a) => a.phone)
                        .includes(
                          window.sessionStorage.getItem("userPhone")
                        ) ? (
                      <Button
                        tag="a"
                        href={`/preferences#${window.sessionStorage.getItem(
                          "userPhone"
                        )}`}
                        color="primary"
                      >
                        Get Notifications &nbsp;&#10140;
                      </Button>
                    ) : (
                      <Button tag="a" href="/signup" color="primary">
                        Get Notifications &nbsp;&#10140;
                      </Button>
                    )
                  ) : (
                    <Button tag="a" href="/signup" color="primary">
                      Sign Up &nbsp;&#10140;
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div style={{height: step3 == false ? "38rem" : "20rem"}}></div>
        </div>
      </div>
    </div>
  );

  function clickFuseElement1(prop1) {
    setClassTextInput1(prop1);
    setClassSelected1([]);
    setSubjectSelected1("");
    setClassSectionsSelected1([]);
    setShowFuseList1(false);

    let classesFilter = [
      coursesData[schools.indexOf("UChicago")].filter(
        (course) =>
          `${course.course.subject.toLowerCase()} - ${course.course.name.toLowerCase()}` ==
          prop1.toLowerCase()
      )[0].course,
    ];

    setClassSelected1(classesFilter);

    if (classesFilter.length > 0) {
      setSubjectSelected1(classesFilter[0].subject);
    }

    // console.log(classesFilter[0].description)
    // console.log(
    //   classesFilter[0].sections.filter(a=> a.sectionNumber==27094)
    //   //.filter(a=> a.sectionNumber==27094)

    //   //filter(a=> a.sectionNumber==27094)
    //   )

    // console.log(classSelected1[0])
    //classSelected1[0].sections.filter(a=> a.sectionNumber==classSectionsSelected1[0])
  }

  function changeClassTitleInput1(event) {
    const fuse = new Fuse(classTitlesNoDuplicates, {
      // keys: ['title', 'author.firstName']
      threshold: 0.6,
    });

    setFuseSearch1(fuse.search(event.target.value));
    setClassTextInput1(event.target.value);

    document.getElementById("classTitleInput1").classList.remove("form-error");
    document.getElementById("classSelectHint1").style.display = "none";
    window.sessionStorage.removeItem("infoSuccess");
  }

  function selectFirstOption1(event) {
    if (event.key === "Enter") {
      event.preventDefault();
      clickFuseElement1(fuseSearch1[0].item);
      document.getElementById("classTitleInput1").blur();
      document
        .getElementById("classTitleInput1")
        .classList.remove("form-error");
      document.getElementById("classSelectHint1").style.display = "none";
    }
  }
}

DemoWidget.propTypes = propTypes;
DemoWidget.defaultProps = defaultProps;

export default DemoWidget;
