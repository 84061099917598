import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import { SectionProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Switch from '../elements/Switch';
import Button from '../elements/Button';
// import SignupForm from '../sections/SignupForm'
import firebase from 'firebase/compat/app';


const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

let className
let topOuterDivider
let bottomOuterDivider
let topDivider
let bottomDivider
let hasBgColor
let invertColor
let pushLeft
let pricingSwitcher
let pricingSlider


const outerClasses = classNames(
  'pricing section',
  topOuterDivider && 'has-top-divider',
  bottomOuterDivider && 'has-bottom-divider',
  hasBgColor && 'has-bg-color',
  invertColor && 'invert-color',
  className
);

const innerClasses = classNames(
  'pricing-inner section-inner',
  topDivider && 'has-top-divider',
  bottomDivider && 'has-bottom-divider'
);

const tilesClasses = classNames(
  'tiles-wrap',
  pushLeft && 'push-left'
);

const sectionHeader = {
  // title: 'Simple, transparent pricing'
  title: 'Notification Payment Plans',
  // paragraph:''
    };


function Pricing({ showSectionHeader, onSelectPlanPage }){

  let schools = ['UChicago', 'Notre Dame', 'RPI', 'Dartmouth', 'Northeastern', 'Western University', 'UMass']
  let isItAddDrop = [true, false, true, true, false, false, false]


    function selectFree(){
      window.sessionStorage.setItem('signupPlan', 'free')
      if(window.sessionStorage.getItem('userSchool')==null){
        window.location.href='/selectschool'
      }
      else if((window.sessionStorage.getItem('userPhone')!=null)&&(window.sessionStorage.getItem('userName')!=null)){
        window.location.href='/getnotifications'
      }else{
        window.location.href='/signup'
      }
    }


    function selectStandard(){

      if(onSelectPlanPage){
        window.sessionStorage.setItem('signupPlan', 'standard')
        window.location.href='/getnotifications'
      }else{
      window.sessionStorage.setItem('signupPlan', 'standard')
      if(window.sessionStorage.getItem('userSchool')==null){
        window.location.href='/selectschool'
      }
      else if((window.sessionStorage.getItem('userPhone')!=null)&&(window.sessionStorage.getItem('userName')!=null)){
        window.location.href='/getnotifications'
      }else{
        window.location.href='/signup'
      }
    }
    }


    function selectPremium(){
      window.sessionStorage.setItem('signupPlan', 'premium')
      window.location.href='/premiumwaitlist'
    }

    function selectUnlimited(){
      window.sessionStorage.setItem('signupPlan', 'premium')
      window.location.href='/unlimitedwaitlist'
    }


    return (
      <section
        // {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
          {showSectionHeader && (
            <SectionHeader data={sectionHeader} className="center-content reveal-scale-up" />
          )}
            <div className={tilesClasses}>
            <div className="tiles-item reveal-from-bottom">
                <div className="tiles-item-inner has-shadow">
                  <div className="pricing-item-content">
                    <div className="pricing-item-header pb-24 mb-24" style={{fontWeight:'bold'}}>
                      Free
                      <div className="pricing-item-price mb-4">
                      <span className="pricing-item-price-currency h3">
                        $
                      </span>
                        <span className="pricing-item-price-amount h1">
                          0
                        </span>
                        <span className="pricing-item-price-after text-sm">/week</span>
                      </div>
                    </div>
                    <div className="pricing-item-features mb-40">
                      <div className="pricing-item-features-title h6 text-xs text-color-high mb-24">
                        What’s included
                      </div>
                      <ul className="pricing-item-features-list list-reset text-xs mb-34">
                      <li className="is-not-checked" style={{flexWrap:'wrap'}}><div><s>Instant SMS notifications</s></div><div style={{marginLeft:'40px'}}>SMS notifications delayed 3 hours</div></li>
                      <li className="is-checked" >Track 1 class / term</li>
                      <li className="is-checked" >Track unlimited class sections</li>
                      <li className="is-checked" style={{flexWrap:'wrap'}}><div>SMS ads</div></li>
                      </ul>
                    </div>
                  </div>
                  <div className="pricing-item-cta mb-8">
                  {window.sessionStorage.getItem('userSchool')!=null?
                    isItAddDrop[schools.indexOf(window.sessionStorage.getItem('userSchool'))]==true?
                    <Button type='button' color="primary" wide onClick={selectFree}>
                      Sign Up
                    </Button>
                  :null
                  :null}
                  </div>
                </div>
              </div>



              <div className="tiles-item reveal-from-bottom">
                <div className="tiles-item-inner has-shadow">
                  <div className="pricing-item-content">
                    <div className="pricing-item-header pb-24 mb-24" style={{fontWeight:'bold'}}>
                      Standard
                      <div className="pricing-item-price mb-4">
                        <span className="pricing-item-price-currency h3">
                        $
                        </span>
                        <span className="pricing-item-price-amount h1">
                          15
                        </span>
                        <span className="pricing-item-price-after text-sm">/week</span>
                      </div>
                    </div>
                    <div className="pricing-item-features mb-40">
                      <div className="pricing-item-features-title h6 text-xs text-color-high mb-24">
                        What’s included
                      </div>
                      <ul className="pricing-item-features-list list-reset text-xs mb-34">
                        <li className="is-checked" >Track 5 classes / term</li>
                        <li className="is-checked" >3-day free trial</li>
                        <li className="is-checked" >Track unlimited class sections</li>
                        <li className="is-checked" >Ad free notifications</li>
                        <li className="is-checked" style={{flexWrap:'wrap'}}>
                        <div>
                          Money back guarantee
                        </div>
                        <div style={{marginLeft:'40px', fontSize:'14px'}}>
                          *Refund for weeks you don’t receive texts
                        </div>
                        </li>
                        <li className="is-checked" >Instant SMS notifications</li>
                        <li className="is-checked" >AI professor email drafting tool</li>
                      </ul>
                    </div>
                  </div>
                  <div className="pricing-item-cta mb-8">
                  {window.sessionStorage.getItem('userSchool')!=null?
                    isItAddDrop[schools.indexOf(window.sessionStorage.getItem('userSchool'))]==true?
                    <Button type='button' color="primary" wide onClick={selectStandard}>
                      Subscribe Now
                    </Button>
                  :null
                  :null}
                  </div>
                </div>
              </div>

              <div className="tiles-item reveal-from-bottom">
                <div className="tiles-item-inner has-shadow">
                  <div className="pricing-item-content">
                    <div className="pricing-item-header pb-24 mb-24" style={{fontWeight:'bold'}}>
                       Premium
                      <div className="pricing-item-price mb-4">
                        <span className="pricing-item-price-currency h3">
                        $
                        </span>
                        <span className="pricing-item-price-amount h1">
                          25
                        </span>
                        <span className="pricing-item-price-after text-sm">/week</span>
                      </div>
                    </div>
                    <div className="pricing-item-features mb-40">
                      <div className="pricing-item-features-title h6 text-xs text-color-high mb-24">
                        What’s included
                      </div>
                      <ul className="pricing-item-features-list list-reset text-xs mb-34">
                        <li className="is-checked" >Track unlimited classes / term</li>
                        <li className="is-checked" >3-day free trial</li>
                        <li className="is-checked" >Track unlimited class sections</li>
                        <li className="is-checked" >Ad free notifications</li>
                        <li className="is-checked" style={{flexWrap:'wrap'}}>
                        <div>
                          Money back guarantee
                        </div>
                        <div style={{marginLeft:'40px', fontSize:'14px'}}>
                          *Refund for weeks you don’t receive texts
                        </div>
                        </li>
                        <li className="is-checked" >Instant SMS notifications</li>
                        <li className="is-checked" >AI professor email drafting tool</li>
                        <li className="is-checked" >Instant email notifications</li>
                        <li className="is-checked" >24/7 customer support </li>
                        <li className="is-checked" >Scheduling optimization consultation</li>
                        <li className="is-checked" >Graduation requirement process tracking</li>
                      </ul>
                    </div>
                  </div>
                  <div className="pricing-item-cta mb-8">
                  {isItAddDrop[schools.indexOf(window.sessionStorage.getItem('userSchool'))]==true?
                    <Button type='button' color="primary" wide onClick={selectPremium}>
                      Join the Waitlist
                    </Button>
                  :null}
                  </div>
                </div>
              </div>




              {/* <div className="tiles-item reveal-from-bottom">
                <div className="tiles-item-inner has-shadow">
                  <div className="pricing-item-content">
                    <div className="pricing-item-header pb-24 mb-24" style={{fontWeight:'bold'}}>
                       Unlimited
                      <div className="pricing-item-price mb-4">
                        <span className="pricing-item-price-currency h3">
                        $
                        </span>
                        <span className="pricing-item-price-amount h1">
                          100
                        </span>
                        <span className="pricing-item-price-after text-sm">/term</span>
                      </div>
                    </div>
                    <div className="pricing-item-features mb-40">
                      <div className="pricing-item-features-title h6 text-xs text-color-high mb-24">
                        What’s included
                      </div>
                      <ul className="pricing-item-features-list list-reset text-xs mb-34">
                        <li className="is-checked" >Track unlimited classes / term</li>
                        <li className="is-checked" >3-day free trial</li>
                        <li className="is-checked" >Track unlimited class sections</li>
                        <li className="is-checked" >Ad free notifications</li>
                        <li className="is-checked" style={{flexWrap:'wrap'}}>
                        <div>
                          Money back guarantee
                        </div>
                        <div style={{marginLeft:'40px', fontSize:'14px'}}>
                          *Refund for weeks you don’t receive texts
                        </div>
                        </li>
                        <li className="is-checked" >Instant SMS notifications</li>
                        <li className="is-checked" >AI professor email drafting tool</li>
                        <li className="is-checked" >Instant email notifications</li>
                        <li className="is-checked" >24/7 customer support </li>
                      </ul>
                    </div>
                  </div>
                  <div className="pricing-item-cta mb-8">
                  {isItAddDrop[schools.indexOf(window.sessionStorage.getItem('userSchool'))]==true?
                    <Button type='button' color="primary" wide onClick={selectUnlimited}>
                      Join the Waitlist
                    </Button>
                  :null}
                  </div>
                </div>
              </div> */}






            </div>
          </div>
        </div>
      </section>
    );
  }

Pricing.propTypes = propTypes;
Pricing.defaultProps = defaultProps;

export default Pricing;