import React, {useState, useEffect} from "react";
import {useHistory} from "react-router-dom";
import mixpanel from "mixpanel-browser";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

// const urlParams = new URLSearchParams(window.location.search);
// const customerId = urlParams.get('customerId');
// const sessionId = urlParams.get('session_id')

let freeUsersArray = [
  "+19178168373",
  "+12245091991",
  "+17863505278",
  "+17087108441",
  "+18033711796",
  "+18608374226",
  "+14243929994",
  "+19706857425",
  "+17737987563",
  "+17737888004",
  "+18477906321",
  "+17328815655",
  "+14149165211",
  "+12138148916",
  "+12488029462",
  "+18325422188",
  "+18608636325",
  "+15024456682",
  "+19047902120",
  "+13099896627",
  "+14193200766",
  "+17735104851",
  "+12027632522",
  "+13108099387",
  "+13474098111",
  "+19175289583",
  "+13095306085",
  "+14699997362",
  "+13472057774",
  "+17139279195",
  "+14808866096",
  "+14804344586",
  "+17182003002",
  "+15166972124",
  "+17879402437",
  "+12029998416",
  "+19375578085",
  "+12036130170",
  "+19257259639",
  "+15102489778",
  "+16307439920",
  "+17703095075",
  "+16507769083",
  "+15122307229",
  "+18477785574",
  "+66916963175",
  "+16086300910",
  "+19085653326",
  "+13144794621",
  "+13123752925",
  "+17739529401",
  "+16506002482",
  "+13127186343",
  "+14084422905",
  "+12029265758",
  "+14702345428",
  "+12028137163",
  "+13106999256",
  "+16177758386",
  "+19145485489",
  "+17138909124",
  "+17187043243",
  "+17084913408",
  "+14107035368",
  "+12024598041",
  "+19734204762",
  "+12624758033",
  "+16463509253",
  "+19085176922",
  "+12162690310",
  "+17204417279",
  "+17602201548",
  "+13129786746",
  "+14138013717",
  "+13098572498",
  "+16825530436",
  "+16263532428",
  "+15104074958",
  "+16468412006",
  "+19732168750",
  "+19546818915",
  "+14697664243",
  "+19176484872",
  "+18486675266",
  "+18054555281",
  "+17733068421",
  "+8801765100738",
  "+19257195571",
  "+16502831962",
  "+19738767588",
  "+18608397388",
  "+12032933339",
  "+17736336786",
  "+16023158203",
  "+16154502490",
  "+17735447799",
  "+15736399003",
  "+13802401726",
  "+16126707721",
  "+16128672132",
  "+18184217246",
  //'+17037626410'
];

if (window.location.href.includes("loading/")) {
  sendInData();
}

async function sendInData() {
  let currentDateInSeconds = new Date().getTime() / 1000;

  // if((freeUsersArray.includes(window.sessionStorage.getItem('userPhone')))

  // // ||(window.sessionStorage.getItem('freeUser')!=null)

  // ){
  //   await saveData({paymentId:'free'})
  // }else{
  //   if (customerId && sessionId) {
  //     // if (customerId && paymentId) {
  //       // // Save to sessionStorage
  //       // sessionStorage.setItem('customerId', customerId);

  //       fetch(`https://us-central1-uchiprereg.cloudfunctions.net/server/attach-payment-method`, {
  //         method:'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //           'Access-Control-Allow-Origin': '*',
  //           'Access-Control-Allow-Methods': 'GET, POST',
  //           'Access-Control-Allow-Headers': 'Content-Type',
  //           'Access-Control-Max-Age': '3600'
  //         },
  //         body: JSON.stringify({
  //           sessionID: sessionId,
  //           cusID: customerId
  //         })
  //       })
  //       .then(res => {
  //         return res.json()
  //       })
  //       .then(async (attachData) => {
  //         await saveData(attachData)
  //       })
  //     }
  // }

  await saveData();

  // async function saveData(attachData){
  async function saveData() {
    if (window.sessionStorage.getItem("isItAddDrop") == "itIsNot") {
      await firebase
        .firestore()
        .collection("preOrders")
        .add({
          name: window.sessionStorage.getItem("userName"),
          phone: window.sessionStorage.getItem("userPhone"),
          school: window.sessionStorage.getItem("userSchool"),
          email: "",
          referrer:
            window.sessionStorage.getItem("referrerPhone") != null
              ? window.sessionStorage.getItem("referrerPhone")
              : "",
          dateTime: currentDateInSeconds,
          dateTimeReverse: -currentDateInSeconds,
          wantsEmails: false,
        })
        .then(async () => {
          await fetch(
            "https://us-central1-uchiprereg.cloudfunctions.net/server/send-preorder-text",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET, POST",
                "Access-Control-Allow-Headers": "Content-Type",
                "Access-Control-Max-Age": "3600",
              },
              body: JSON.stringify({
                userName: window.sessionStorage.getItem("userName"),
                userPhone: window.sessionStorage.getItem("userPhone"),
              }),
            }
          );
          // .catch(err => {
          //   console.log(err)
          //   // sendInData()
          // })
          await doTheRest();
        });
    } else {
      if (parseInt(window.sessionStorage.getItem("numClasses")) > 0) {
        let sectionsSelected1 = window.sessionStorage
          .getItem("classSectionsSelected1")
          .split(",");
        let sectionsSelected1New = [];

        for (var e = 0; e < sectionsSelected1.length; e++) {
          sectionsSelected1New.push(parseInt(sectionsSelected1[e]));
        }

        // if((window.sessionStorage.getItem('upgradingFromPreferences')=='true')||(window.sessionStorage.getItem('upgradingID1')!=null)){
        //   await firebase
        //   .firestore()
        //   .collection("grabLeads")
        //   .doc(window.sessionStorage.getItem('upgradingID1'))
        //   .update({
        //     addDelay:false
        //   })
        //   .then(async () => {
        //     if (parseInt(window.sessionStorage.getItem("numClasses")) == 1) {
        //       await fetch(
        //         "https://us-central1-uchiprereg.cloudfunctions.net/server/send-confirmation-text-1",
        //         {
        //           method: "POST",
        //           headers: {
        //             "Content-Type": "application/json",
        //             "Access-Control-Allow-Origin": "*",
        //             "Access-Control-Allow-Methods": "GET, POST",
        //             "Access-Control-Allow-Headers": "Content-Type",
        //             "Access-Control-Max-Age": "3600",
        //           },
        //           body: JSON.stringify({
        //             userName: window.sessionStorage.getItem("userName"),
        //             userPhone: window.sessionStorage.getItem("userPhone"),
        //             // wantsEmails:window.sessionStorage.getItem('wantsEmails'),
        //             subjectSelected1:
        //               window.sessionStorage.getItem("subjectSelected1"),
        //           }),
        //         }
        //       );
        //       // .catch(err => {
        //       //   console.log(err)
        //       //   // sendInData()
        //       // })

        //       await doTheRest();
        //     }
        //   });

        // }else{

        // console.log(window.sessionStorage.getItem("userName"))
        // console.log(window.sessionStorage.getItem("userPhone"))
        // console.log(window.sessionStorage.getItem("userSchool"))

        await firebase
          .firestore()
          .collection("grabLeads")
          .add({
            name: window.sessionStorage.getItem("userName"),
            phone: window.sessionStorage.getItem("userPhone"),
            school: window.sessionStorage.getItem("userSchool"),
            referrerPhone:
              window.sessionStorage.getItem("referrerPhone") != null
                ? window.sessionStorage.getItem("referrerPhone")
                : "",
            referrerName:
              window.sessionStorage.getItem("referrerName") != null
                ? window.sessionStorage.getItem("referrerName")
                : "",
            dateTime: currentDateInSeconds,
            dateTimeReverse: -currentDateInSeconds,
            classSubject: window.sessionStorage.getItem("subjectSelected1"),
            classSections: sectionsSelected1New,
            gotClass: false,
            wantsEmail: false,
            // wantsEmails:
            //   window.sessionStorage.getItem("wantsEmails") == "yes"
            //     ? true
            //     : false,
            email: "",
            labSelected:
              window.sessionStorage.getItem("labSelected1") != null
                ? window.sessionStorage.getItem("labSelected1") == "true" ||
                  window.sessionStorage.getItem("labSelected1") == true
                : false,
            addDelay: false,
            // addDelay: window.sessionStorage.getItem("addDelay") == 'yes'?true:false
          })
          // .then(async (docRef) => {
          //   const docID = docRef.id;
          //   if(freeUsersArray.includes(window.sessionStorage.getItem('userPhone'))){
          //     await firebase.firestore().collection("grabLeads").doc(docID).update({
          //       customerId: 'free',
          //       paymentId: 'free'
          //     })
          //   }else{
          //   // window.sessionStorage.setItem('docID1', docID);
          //   await firebase.firestore().collection("grabLeads").doc(docID).update({
          //     customerId: customerId,
          //     paymentId: attachData.paymentId
          //   })
          //   }
          // })
          .then(async () => {
            // //PUTTING IN FOR NOW

            await fetch(
              "https://us-central1-uchiprereg.cloudfunctions.net/server/send-confirmation-text-1",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Methods": "GET, POST",
                  "Access-Control-Allow-Headers": "Content-Type",
                  "Access-Control-Max-Age": "3600",
                },
                body: JSON.stringify({
                  userName: window.sessionStorage.getItem("userName"),
                  userPhone: "+16126707721",
                  // wantsEmails:window.sessionStorage.getItem('wantsEmails'),
                  subjectSelected1:
                    window.sessionStorage.getItem("subjectSelected1"),
                }),
              }
            );

            await fetch(
              "https://us-central1-uchiprereg.cloudfunctions.net/server/send-confirmation-text-1",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Methods": "GET, POST",
                  "Access-Control-Allow-Headers": "Content-Type",
                  "Access-Control-Max-Age": "3600",
                },
                body: JSON.stringify({
                  userName: window.sessionStorage.getItem("userName"),
                  userPhone: "+17037626410",
                  // wantsEmails:window.sessionStorage.getItem('wantsEmails'),
                  subjectSelected1:
                    window.sessionStorage.getItem("subjectSelected1"),
                }),
              }
            );

            //PUTTING IN FOR NOW

            if (parseInt(window.sessionStorage.getItem("numClasses")) == 1) {
              await fetch(
                "https://us-central1-uchiprereg.cloudfunctions.net/server/send-confirmation-text-1",
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, POST",
                    "Access-Control-Allow-Headers": "Content-Type",
                    "Access-Control-Max-Age": "3600",
                  },
                  body: JSON.stringify({
                    userName: window.sessionStorage.getItem("userName"),
                    userPhone: window.sessionStorage.getItem("userPhone"),
                    // wantsEmails:window.sessionStorage.getItem('wantsEmails'),
                    subjectSelected1:
                      window.sessionStorage.getItem("subjectSelected1"),
                  }),
                }
              );
              // .catch(err => {
              //   console.log(err)
              //   // sendInData()
              // })

              await doTheRest();
            }
          });
        // }
      }

      if (
        parseInt(window.sessionStorage.getItem("numClasses")) > 1

        // &&(window.sessionStorage.getItem('upgradingFromPreferences')!='true')
      ) {
        let sectionsSelected2 = window.sessionStorage
          .getItem("classSectionsSelected2")
          .split(",");
        let sectionsSelected2New = [];

        for (var e = 0; e < sectionsSelected2.length; e++) {
          sectionsSelected2New.push(parseInt(sectionsSelected2[e]));
        }

        // if(window.sessionStorage.getItem('upgradingID2')!=null){

        //   await firebase
        //   .firestore()
        //   .collection("grabLeads")
        //   .doc(window.sessionStorage.getItem('upgradingID2'))
        //   .update({
        //     addDelay:false
        //   })
        //   .then(async () => {
        //     if (parseInt(window.sessionStorage.getItem("numClasses")) == 2) {
        //       await fetch(
        //         "https://us-central1-uchiprereg.cloudfunctions.net/server/send-confirmation-text-2",
        //         {
        //           method: "POST",
        //           headers: {
        //             "Content-Type": "application/json",
        //             "Access-Control-Allow-Origin": "*",
        //             "Access-Control-Allow-Methods": "GET, POST",
        //             "Access-Control-Allow-Headers": "Content-Type",
        //             "Access-Control-Max-Age": "3600",
        //           },
        //           body: JSON.stringify({
        //             userName: window.sessionStorage.getItem("userName"),
        //             userPhone: window.sessionStorage.getItem("userPhone"),
        //             // wantsEmails:window.sessionStorage.getItem('wantsEmails'),
        //             subjectSelected1:
        //               window.sessionStorage.getItem("subjectSelected1"),
        //             subjectSelected2:
        //               window.sessionStorage.getItem("subjectSelected2"),
        //           }),
        //         }
        //       );
        //       // .catch(err => {
        //       //   console.log(err)
        //       //   // sendInData()
        //       // })

        //       await doTheRest();
        //     }
        //   });

        // }else{

        await firebase
          .firestore()
          .collection("grabLeads")
          .add({
            name: window.sessionStorage.getItem("userName"),
            phone: window.sessionStorage.getItem("userPhone"),
            school: window.sessionStorage.getItem("userSchool"),
            referrerPhone:
              window.sessionStorage.getItem("referrerPhone") != null
                ? window.sessionStorage.getItem("referrerPhone")
                : "",
            referrerName:
              window.sessionStorage.getItem("referrerName") != null
                ? window.sessionStorage.getItem("referrerName")
                : "",
            dateTime: currentDateInSeconds,
            dateTimeReverse: -currentDateInSeconds,
            classSubject: window.sessionStorage.getItem("subjectSelected2"),
            classSections: sectionsSelected2New,
            gotClass: false,
            wantsEmail: false,
            // wantsEmails:
            //   window.sessionStorage.getItem("wantsEmails") == "yes"
            //     ? true
            //     : false,
            email: "",
            labSelected:
              window.sessionStorage.getItem("labSelected2") != null
                ? window.sessionStorage.getItem("labSelected2") == "true" ||
                  window.sessionStorage.getItem("labSelected2") == true
                : false,
            addDelay: false,
            // addDelay: window.sessionStorage.getItem("addDelay") == 'yes'?true:false
          })
          // .then(async (docRef) => {
          //   const docID = docRef.id;
          //   if(freeUsersArray.includes(window.sessionStorage.getItem('userPhone'))){
          //     await firebase.firestore().collection("grabLeads").doc(docID).update({
          //       customerId: 'free',
          //       paymentId: 'free'
          //     })
          //   }else{
          //   // window.sessionStorage.setItem('docID2', docID);
          //   await firebase.firestore().collection("grabLeads").doc(docID).update({
          //     customerId: customerId,
          //     paymentId: attachData.paymentId
          //   })
          //   }
          // })
          .then(async () => {
            if (parseInt(window.sessionStorage.getItem("numClasses")) == 2) {
              await fetch(
                "https://us-central1-uchiprereg.cloudfunctions.net/server/send-confirmation-text-2",
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, POST",
                    "Access-Control-Allow-Headers": "Content-Type",
                    "Access-Control-Max-Age": "3600",
                  },
                  body: JSON.stringify({
                    userName: window.sessionStorage.getItem("userName"),
                    userPhone: window.sessionStorage.getItem("userPhone"),
                    // wantsEmails:window.sessionStorage.getItem('wantsEmails'),
                    subjectSelected1:
                      window.sessionStorage.getItem("subjectSelected1"),
                    subjectSelected2:
                      window.sessionStorage.getItem("subjectSelected2"),
                  }),
                }
              );
              // .catch(err => {
              //   console.log(err)
              //   // sendInData()
              // })

              await doTheRest();
            }
          });
        // }
      }

      if (
        parseInt(window.sessionStorage.getItem("numClasses")) > 2
        // &&(window.sessionStorage.getItem('upgradingFromPreferences')!='true')
      ) {
        let sectionsSelected3 = window.sessionStorage
          .getItem("classSectionsSelected3")
          .split(",");
        let sectionsSelected3New = [];

        for (var e = 0; e < sectionsSelected3.length; e++) {
          sectionsSelected3New.push(parseInt(sectionsSelected3[e]));
        }

        // if(window.sessionStorage.getItem('upgradingID3')!=null){

        //   await firebase
        //   .firestore()
        //   .collection("grabLeads")
        //   .doc(window.sessionStorage.getItem('upgradingID3'))
        //   .update({
        //     addDelay:false
        //   })
        //   .then(async () => {
        //     if (parseInt(window.sessionStorage.getItem("numClasses")) == 3) {
        //       await fetch(
        //         "https://us-central1-uchiprereg.cloudfunctions.net/server/send-confirmation-text-3",
        //         {
        //           method: "POST",
        //           headers: {
        //             "Content-Type": "application/json",
        //             "Access-Control-Allow-Origin": "*",
        //             "Access-Control-Allow-Methods": "GET, POST",
        //             "Access-Control-Allow-Headers": "Content-Type",
        //             "Access-Control-Max-Age": "3600",
        //           },
        //           body: JSON.stringify({
        //             userName: window.sessionStorage.getItem("userName"),
        //             userPhone: window.sessionStorage.getItem("userPhone"),
        //             // wantsEmails:window.sessionStorage.getItem('wantsEmails'),
        //             subjectSelected1:
        //               window.sessionStorage.getItem("subjectSelected1"),
        //             subjectSelected2:
        //               window.sessionStorage.getItem("subjectSelected2"),
        //             subjectSelected3:
        //               window.sessionStorage.getItem("subjectSelected3"),
        //           }),
        //         }
        //       );
        //       // .catch(err => {
        //       //   console.log(err)
        //       //   // sendInData()
        //       // })

        //       await doTheRest();
        //     }
        //   });

        // }else{

        await firebase
          .firestore()
          .collection("grabLeads")
          .add({
            name: window.sessionStorage.getItem("userName"),
            phone: window.sessionStorage.getItem("userPhone"),
            school: window.sessionStorage.getItem("userSchool"),
            referrerPhone:
              window.sessionStorage.getItem("referrerPhone") != null
                ? window.sessionStorage.getItem("referrerPhone")
                : "",
            referrerName:
              window.sessionStorage.getItem("referrerName") != null
                ? window.sessionStorage.getItem("referrerName")
                : "",
            dateTime: currentDateInSeconds,
            dateTimeReverse: -currentDateInSeconds,
            classSubject: window.sessionStorage.getItem("subjectSelected3"),
            classSections: sectionsSelected3New,
            gotClass: false,
            wantsEmail: false,
            // wantsEmails:
            //   window.sessionStorage.getItem("wantsEmails") == "yes"
            //     ? true
            //     : false,
            email: "",
            labSelected:
              window.sessionStorage.getItem("labSelected3") != null
                ? window.sessionStorage.getItem("labSelected3") == "true" ||
                  window.sessionStorage.getItem("labSelected3") == true
                : false,
            addDelay: false,
            // addDelay: window.sessionStorage.getItem("addDelay") == 'yes'?true:false
          })
          // .then(async (docRef) => {
          //   const docID = docRef.id;
          //   if(freeUsersArray.includes(window.sessionStorage.getItem('userPhone'))){
          //     await firebase.firestore().collection("grabLeads").doc(docID).update({
          //       customerId: 'free',
          //       paymentId: 'free'
          //     })
          //   }else{
          //   // window.sessionStorage.setItem('docID3', docID);
          //   await firebase.firestore().collection("grabLeads").doc(docID).update({
          //     customerId: customerId,
          //     paymentId: attachData.paymentId
          //   })
          //   }
          // })
          .then(async () => {
            if (parseInt(window.sessionStorage.getItem("numClasses")) == 3) {
              await fetch(
                "https://us-central1-uchiprereg.cloudfunctions.net/server/send-confirmation-text-3",
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, POST",
                    "Access-Control-Allow-Headers": "Content-Type",
                    "Access-Control-Max-Age": "3600",
                  },
                  body: JSON.stringify({
                    userName: window.sessionStorage.getItem("userName"),
                    userPhone: window.sessionStorage.getItem("userPhone"),
                    // wantsEmails:window.sessionStorage.getItem('wantsEmails'),
                    subjectSelected1:
                      window.sessionStorage.getItem("subjectSelected1"),
                    subjectSelected2:
                      window.sessionStorage.getItem("subjectSelected2"),
                    subjectSelected3:
                      window.sessionStorage.getItem("subjectSelected3"),
                  }),
                }
              );
              //   .catch(err => {
              //   console.log(err)
              //   // sendInData()
              // })

              await doTheRest();
            }
          });
        // }
      }

      if (
        parseInt(window.sessionStorage.getItem("numClasses")) > 3
        // &&(window.sessionStorage.getItem('upgradingFromPreferences')!='true')
      ) {
        let sectionsSelected4 = window.sessionStorage
          .getItem("classSectionsSelected4")
          .split(",");
        let sectionsSelected4New = [];

        for (var e = 0; e < sectionsSelected4.length; e++) {
          sectionsSelected4New.push(parseInt(sectionsSelected4[e]));
        }

        // if(window.sessionStorage.getItem('upgradingID4')!=null){

        //   await firebase
        //   .firestore()
        //   .collection("grabLeads")
        //   .doc(window.sessionStorage.getItem('upgradingID4'))
        //   .update({
        //     addDelay:false
        //   })
        //   .then(async () => {
        //     if (parseInt(window.sessionStorage.getItem("numClasses")) == 4) {
        //       await fetch(
        //         "https://us-central1-uchiprereg.cloudfunctions.net/server/send-confirmation-text-4",
        //         {
        //           method: "POST",
        //           headers: {
        //             "Content-Type": "application/json",
        //             "Access-Control-Allow-Origin": "*",
        //             "Access-Control-Allow-Methods": "GET, POST",
        //             "Access-Control-Allow-Headers": "Content-Type",
        //             "Access-Control-Max-Age": "3600",
        //           },
        //           body: JSON.stringify({
        //             userName: window.sessionStorage.getItem("userName"),
        //             userPhone: window.sessionStorage.getItem("userPhone"),
        //             // wantsEmails:window.sessionStorage.getItem('wantsEmails'),
        //             subjectSelected1:
        //               window.sessionStorage.getItem("subjectSelected1"),
        //             subjectSelected2:
        //               window.sessionStorage.getItem("subjectSelected2"),
        //             subjectSelected3:
        //               window.sessionStorage.getItem("subjectSelected3"),
        //             subjectSelected4:
        //               window.sessionStorage.getItem("subjectSelected4"),
        //           }),
        //         }
        //       );
        //       // .catch(err => {
        //       //   console.log(err)
        //       //   // sendInData()
        //       // })

        //       await doTheRest();
        //     }
        //   });

        // }else{

        await firebase
          .firestore()
          .collection("grabLeads")
          .add({
            name: window.sessionStorage.getItem("userName"),
            phone: window.sessionStorage.getItem("userPhone"),
            school: window.sessionStorage.getItem("userSchool"),
            referrerPhone:
              window.sessionStorage.getItem("referrerPhone") != null
                ? window.sessionStorage.getItem("referrerPhone")
                : "",
            referrerName:
              window.sessionStorage.getItem("referrerName") != null
                ? window.sessionStorage.getItem("referrerName")
                : "",
            dateTime: currentDateInSeconds,
            dateTimeReverse: -currentDateInSeconds,
            classSubject: window.sessionStorage.getItem("subjectSelected4"),
            classSections: sectionsSelected4New,
            gotClass: false,
            wantsEmails: false,
            // wantsEmails:
            //   window.sessionStorage.getItem("wantsEmails") == "yes"
            //     ? true
            //     : false,
            email: "",
            labSelected:
              window.sessionStorage.getItem("labSelected4") != null
                ? window.sessionStorage.getItem("labSelected4") == "true" ||
                  window.sessionStorage.getItem("labSelected4") == true
                : false,
            addDelay: false,
            // addDelay: window.sessionStorage.getItem("addDelay") == 'yes'?true:false
          })
          // .then(async (docRef) => {
          //   const docID = docRef.id;
          //   if(freeUsersArray.includes(window.sessionStorage.getItem('userPhone'))){
          //     await firebase.firestore().collection("grabLeads").doc(docID).update({
          //       customerId: 'free',
          //       paymentId: 'free'
          //     })
          //   }else{
          //   // window.sessionStorage.setItem('docID4', docID);
          //   await firebase.firestore().collection("grabLeads").doc(docID).update({
          //     customerId: customerId,
          //     paymentId: attachData.paymentId
          //   })
          //   }
          // })
          .then(async () => {
            if (parseInt(window.sessionStorage.getItem("numClasses")) == 4) {
              await fetch(
                "https://us-central1-uchiprereg.cloudfunctions.net/server/send-confirmation-text-4",
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, POST",
                    "Access-Control-Allow-Headers": "Content-Type",
                    "Access-Control-Max-Age": "3600",
                  },
                  body: JSON.stringify({
                    userName: window.sessionStorage.getItem("userName"),
                    userPhone: window.sessionStorage.getItem("userPhone"),
                    // wantsEmails:window.sessionStorage.getItem('wantsEmails'),
                    subjectSelected1:
                      window.sessionStorage.getItem("subjectSelected1"),
                    subjectSelected2:
                      window.sessionStorage.getItem("subjectSelected2"),
                    subjectSelected3:
                      window.sessionStorage.getItem("subjectSelected3"),
                    subjectSelected4:
                      window.sessionStorage.getItem("subjectSelected4"),
                  }),
                }
              );
              //   .catch(err => {
              //   console.log(err)
              //   // sendInData()
              // })

              await doTheRest();
            }
          });
        // }
      }

      if (
        parseInt(window.sessionStorage.getItem("numClasses")) > 4
        // &&(window.sessionStorage.getItem('upgradingFromPreferences')!='true')
      ) {
        let sectionsSelected5 = window.sessionStorage
          .getItem("classSectionsSelected5")
          .split(",");
        let sectionsSelected5New = [];

        for (var e = 0; e < sectionsSelected5.length; e++) {
          sectionsSelected5New.push(parseInt(sectionsSelected5[e]));
        }

        // if(window.sessionStorage.getItem('upgradingID5')!=null){

        //   await firebase
        //   .firestore()
        //   .collection("grabLeads")
        //   .doc(window.sessionStorage.getItem('upgradingID5'))
        //   .update({
        //     addDelay:false
        //   })
        //   .then(async () => {
        //     if (parseInt(window.sessionStorage.getItem("numClasses")) == 5) {
        //       await fetch(
        //         "https://us-central1-uchiprereg.cloudfunctions.net/server/send-confirmation-text-5",
        //         {
        //           method: "POST",
        //           headers: {
        //             "Content-Type": "application/json",
        //             "Access-Control-Allow-Origin": "*",
        //             "Access-Control-Allow-Methods": "GET, POST",
        //             "Access-Control-Allow-Headers": "Content-Type",
        //             "Access-Control-Max-Age": "3600",
        //           },
        //           body: JSON.stringify({
        //             userName: window.sessionStorage.getItem("userName"),
        //             userPhone: window.sessionStorage.getItem("userPhone"),
        //             // wantsEmails:window.sessionStorage.getItem('wantsEmails'),
        //             subjectSelected1:
        //               window.sessionStorage.getItem("subjectSelected1"),
        //             subjectSelected2:
        //               window.sessionStorage.getItem("subjectSelected2"),
        //             subjectSelected3:
        //               window.sessionStorage.getItem("subjectSelected3"),
        //             subjectSelected4:
        //               window.sessionStorage.getItem("subjectSelected4"),
        //             subjectSelected5:
        //               window.sessionStorage.getItem("subjectSelected5"),
        //           }),
        //         }
        //       );
        //       // .catch(err => {
        //       //   console.log(err)
        //       //   // sendInData()
        //       // })

        //       await doTheRest();
        //     }
        //   });

        // }else{

        await firebase
          .firestore()
          .collection("grabLeads")
          .add({
            name: window.sessionStorage.getItem("userName"),
            phone: window.sessionStorage.getItem("userPhone"),
            school: window.sessionStorage.getItem("userSchool"),
            referrerPhone:
              window.sessionStorage.getItem("referrerPhone") != null
                ? window.sessionStorage.getItem("referrerPhone")
                : "",
            referrerName:
              window.sessionStorage.getItem("referrerName") != null
                ? window.sessionStorage.getItem("referrerName")
                : "",
            dateTime: currentDateInSeconds,
            dateTimeReverse: -currentDateInSeconds,
            classSubject: window.sessionStorage.getItem("subjectSelected5"),
            classSections: sectionsSelected5New,
            gotClass: false,
            wantsEmail: false,
            // wantsEmails:
            //   window.sessionStorage.getItem("wantsEmails") == "yes"
            //     ? true
            //     : false,
            email: "",
            labSelected:
              window.sessionStorage.getItem("labSelected5") != null
                ? window.sessionStorage.getItem("labSelected5") == "true" ||
                  window.sessionStorage.getItem("labSelected5") == true
                : false,
            addDelay: false,
            // addDelay: window.sessionStorage.getItem("addDelay") == 'yes'?true:false
          })
          // .then(async (docRef) => {
          //   const docID = docRef.id;
          //   if(freeUsersArray.includes(window.sessionStorage.getItem('userPhone'))){
          //     await firebase.firestore().collection("grabLeads").doc(docID).update({
          //       customerId: 'free',
          //       paymentId: 'free'
          //     })
          //   }else{
          //   // window.sessionStorage.setItem('docID5', docID);
          //   await firebase.firestore().collection("grabLeads").doc(docID).update({
          //     customerId: customerId,
          //     paymentId: attachData.paymentId
          //   })
          //   }
          // })
          .then(async () => {
            if (parseInt(window.sessionStorage.getItem("numClasses")) == 5) {
              await fetch(
                "https://us-central1-uchiprereg.cloudfunctions.net/server/send-confirmation-text-5",
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, POST",
                    "Access-Control-Allow-Headers": "Content-Type",
                    "Access-Control-Max-Age": "3600",
                  },
                  body: JSON.stringify({
                    userName: window.sessionStorage.getItem("userName"),
                    userPhone: window.sessionStorage.getItem("userPhone"),
                    // wantsEmails:window.sessionStorage.getItem('wantsEmails'),
                    subjectSelected1:
                      window.sessionStorage.getItem("subjectSelected1"),
                    subjectSelected2:
                      window.sessionStorage.getItem("subjectSelected2"),
                    subjectSelected3:
                      window.sessionStorage.getItem("subjectSelected3"),
                    subjectSelected4:
                      window.sessionStorage.getItem("subjectSelected4"),
                    subjectSelected5:
                      window.sessionStorage.getItem("subjectSelected5"),
                  }),
                }
              );
              // .catch(err => {
              //   console.log(err)
              //   // sendInData()
              // })

              await doTheRest();
            }
          });
        // }
      }
    }
  }

  async function doTheRest() {
    window.sessionStorage.setItem("infoSuccess", true);

    if (
      window.sessionStorage.getItem("referrerPhone") != "" &&
      window.sessionStorage.getItem("referrerPhone") != null &&
      window.sessionStorage.getItem("referrerPhone") != "null"
    ) {
      await fetch(
        "https://us-central1-uchiprereg.cloudfunctions.net/server/search-customers",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST",
            "Access-Control-Allow-Headers": "Content-Type",
            "Access-Control-Max-Age": "3600",
          },
          body: JSON.stringify({
            refundPhone: window.sessionStorage.getItem("referrerPhone"),
          }),
        }
      )
        .catch(async (error) => {
          console.log(error);
          console.log("error step 1");
          console.log("IOU incoming");

          await firebase
            .firestore()
            .collection("IOUs")
            .add({
              name: window.sessionStorage.getItem("userName"),
              phone: window.sessionStorage.getItem("userPhone"),
              school: window.sessionStorage.getItem("userSchool"),
              referrerPhone:
                window.sessionStorage.getItem("referrerPhone") != null
                  ? window.sessionStorage.getItem("referrerPhone")
                  : "",
              referrerName:
                window.sessionStorage.getItem("referrerName") != null
                  ? window.sessionStorage.getItem("referrerName")
                  : "",
              dateTime: currentDateInSeconds,
              dateTimeReverse: -currentDateInSeconds,
              step: "stripeCustomersSearch",
            });

          window.sessionStorage.removeItem("referrerPhone");
          window.sessionStorage.removeItem("referrerName");
        })
        .then((res) => res.json())
        .then(async (results) => {
          for (let x = 0; x < results.customerInfo.length; x++) {
            if (results.customerInfo[x].email != null) {
              await fetch(
                "https://us-central1-uchiprereg.cloudfunctions.net/server/search-charges",
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, POST",
                    "Access-Control-Allow-Headers": "Content-Type",
                    "Access-Control-Max-Age": "3600",
                  },
                  body: JSON.stringify({
                    refundID: results.customerInfo[x].id,
                  }),
                }
              )
                .catch(async (error) => {
                  console.log(error);
                  console.log("error step 2");
                  console.log("IOU incoming");

                  await firebase
                    .firestore()
                    .collection("IOUs")
                    .add({
                      name: window.sessionStorage.getItem("userName"),
                      phone: window.sessionStorage.getItem("userPhone"),
                      school: window.sessionStorage.getItem("userSchool"),
                      referrerPhone:
                        window.sessionStorage.getItem("referrerPhone") != null
                          ? window.sessionStorage.getItem("referrerPhone")
                          : "",
                      referrerName:
                        window.sessionStorage.getItem("referrerName") != null
                          ? window.sessionStorage.getItem("referrerName")
                          : "",
                      dateTime: currentDateInSeconds,
                      dateTimeReverse: -currentDateInSeconds,
                      step: "stripeChargesSearch",
                    });

                  window.sessionStorage.removeItem("referrerPhone");
                  window.sessionStorage.removeItem("referrerName");
                })
                .then((res) => res.json())
                .then(async (charges) => {
                  console.log(charges);

                  if (
                    charges.chargesInfo[0].amount ==
                    charges.chargesInfo[0].amount_refunded
                  ) {
                    console.log("already refunded fully");
                    window.sessionStorage.removeItem("referrerPhone");
                    window.sessionStorage.removeItem("referrerName");
                  } else {
                    await fetch(
                      "https://us-central1-uchiprereg.cloudfunctions.net/server/refund-20-percent",
                      {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                          "Access-Control-Allow-Origin": "*",
                          "Access-Control-Allow-Methods": "GET, POST",
                          "Access-Control-Allow-Headers": "Content-Type",
                          "Access-Control-Max-Age": "3600",
                        },
                        body: JSON.stringify({
                          inputPI: charges.chargesInfo[0].payment_intent,
                          refundAmount: 0.2 * charges.chargesInfo[0].amount,
                        }),
                      }
                    )
                      .catch(async (error) => {
                        console.log(error);
                        console.log("error step 3");
                        console.log("IOU incoming");

                        await firebase
                          .firestore()
                          .collection("IOUs")
                          .add({
                            name: window.sessionStorage.getItem("userName"),
                            phone: window.sessionStorage.getItem("userPhone"),
                            school: window.sessionStorage.getItem("userSchool"),
                            referrerPhone:
                              window.sessionStorage.getItem("referrerPhone") !=
                              null
                                ? window.sessionStorage.getItem("referrerPhone")
                                : "",
                            referrerName:
                              window.sessionStorage.getItem("referrerName") !=
                              null
                                ? window.sessionStorage.getItem("referrerName")
                                : "",
                            step: "stripeRefund",
                          });

                        window.sessionStorage.removeItem("referrerPhone");
                        window.sessionStorage.removeItem("referrerName");
                      })
                      .then(
                        () => {
                          window.sessionStorage.removeItem("referrerPhone");
                          window.sessionStorage.removeItem("referrerName");
                        }
                        // res => res.json()
                      );
                  }
                });

              break;
            }
          }
        });
    }

    mixpanel.init("40ab5c82e6fe247fdf59ec5f26d8ee55", {debug: true});
    mixpanel.track("Sign up");

    window.sessionStorage.removeItem("classTextInput1");
    window.sessionStorage.removeItem("classSectionsSelected1");
    window.sessionStorage.removeItem("subjectSelected1");
    window.sessionStorage.removeItem("labSelected1");
    window.sessionStorage.removeItem("classTextInput2");
    window.sessionStorage.removeItem("classSectionsSelected2");
    window.sessionStorage.removeItem("subjectSelected2");
    window.sessionStorage.removeItem("labSelected2");
    window.sessionStorage.removeItem("classTextInput3");
    window.sessionStorage.removeItem("classSectionsSelected3");
    window.sessionStorage.removeItem("subjectSelected3");
    window.sessionStorage.removeItem("labSelected3");
    window.sessionStorage.removeItem("classTextInput4");
    window.sessionStorage.removeItem("classSectionsSelected4");
    window.sessionStorage.removeItem("subjectSelected4");
    window.sessionStorage.removeItem("labSelected4");
    window.sessionStorage.removeItem("classTextInput5");
    window.sessionStorage.removeItem("classSectionsSelected5");
    window.sessionStorage.removeItem("subjectSelected5");
    window.sessionStorage.removeItem("labSelected5");

    window.sessionStorage.removeItem("userName");
    //window.sessionStorage.removeItem('userPhone')
    window.sessionStorage.removeItem("wantsEmails");
    window.sessionStorage.removeItem("numClasses");
    window.sessionStorage.removeItem("signupPlan");
    window.sessionStorage.removeItem("upgradingFromPreferences");
    window.sessionStorage.removeItem("upgradingID1");
    window.sessionStorage.removeItem("upgradingID2");
    window.sessionStorage.removeItem("upgradingID3");
    window.sessionStorage.removeItem("upgradingID4");
    window.sessionStorage.removeItem("upgradingID5");
    window.sessionStorage.removeItem("freeUser");

    if (window.sessionStorage.getItem("isItAddDrop") == "itIsNot") {
      window.sessionStorage.removeItem("isItAddDrop");
      window.location.href = "/signup";
    } else {
      window.sessionStorage.removeItem("isItAddDrop");
      window.location.href = `/preferences#${window.sessionStorage.getItem(
        "userPhone"
      )}`;
    }

    console.log("yup");
  }
}

function Loading() {
  // const history = useHistory();

  // useEffect(() => {
  //   const referrer = document.referrer;
  //   const stripeReferrer = 'https://checkout.stripe.com'; // Replace with the actual Stripe referrer URL

  //   if (referrer.startsWith(stripeReferrer)) {
  //     // Run your functions for the Stripe redirect case
  //   } else {
  //     // Redirect the user to another page
  //     history.push('/getnotifications');
  //   }
  // }, [history]);

  // useEffect(() => {
  //   // const urlParams = new URLSearchParams(window.location.search);
  //   // const customerId = urlParams.get('customerId');
  //   // const sessionId = urlParams.get('session_id')
  //   // const paymentId = urlParams.get('paymentId');
  //     // .then(data => window.location.href=data.url)

  //     // Add a new document with a generated id.

  //     // if(window.sessionStorage.getItem('docID1')!=null){
  //     //   await firebase.firestore().collection("grabLeads").doc(window.sessionStorage.getItem('docID1')).update({
  //     //     customerId: customerId,
  //     //     paymentId: data.paymentId
  //     //     // paymentId: paymentId
  //     //     // Other fields...
  //     // })
  //     // }
  //     // if(window.sessionStorage.getItem('docID2')!=null){
  //     //   await firebase.firestore().collection("grabLeads").doc(window.sessionStorage.getItem('docID2')).update({
  //     //     customerId: customerId,
  //     //     paymentId: data.paymentId
  //     //     // paymentId: paymentId
  //     //     // Other fields...
  //     // })
  //     // }
  //     // if(window.sessionStorage.getItem('docID3')!=null){
  //     //   await firebase.firestore().collection("grabLeads").doc(window.sessionStorage.getItem('docID3')).update({
  //     //     customerId: customerId,
  //     //     paymentId: data.paymentId
  //     //     // paymentId: paymentId
  //     //     // Other fields...
  //     // })
  //     // }
  //     // if(window.sessionStorage.getItem('docID4')!=null){
  //     //   await firebase.firestore().collection("grabLeads").doc(window.sessionStorage.getItem('docID4')).update({
  //     //     customerId: customerId,
  //     //     paymentId: data.paymentId
  //     //     // paymentId: paymentId
  //     //     // Other fields...
  //     // })
  //     // }
  //     // if(window.sessionStorage.getItem('docID5')!=null){
  //     //   await firebase.firestore().collection("grabLeads").doc(window.sessionStorage.getItem('docID5')).update({
  //     //     customerId: customerId,
  //     //     paymentId: data.paymentId
  //     //     // paymentId: paymentId
  //     //     // Other fields...
  //     // })
  //     // }

  //     // .then(() => {
  //     //   window.sessionStorage.setItem('successfulLaunch', 'yes')
  //     //     window.location.href='/#launch'
  //     // })
  //     // .catch((error) => {
  //     //     console.error("Error adding document: ", error);
  //     // });

  // }, []);

  return (
    <div>
      <img
        src="https://firebasestorage.googleapis.com/v0/b/uchiprereg.appspot.com/o/loadingWithText.gif?alt=media&token=8791e865-3578-4d4b-bb16-46ccaa007e41"
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "0rem",
          width: "50%",
        }}
      />
    </div>
  );
}

export default Loading;
