import React, {useEffect, useState, useRef} from "react";
import {useHistory} from "react-router-dom";
import classNames from "classnames";
import {SectionProps} from "../../utils/SectionProps";
import {Link} from "react-router-dom";
import SectionHeader from "./partials/SectionHeader";
import PhoneInput from "react-phone-input-2";
import {parsePhoneNumber, isValidNumber} from "libphonenumber-js";
import "react-phone-input-2/lib/style.css";
import Button from "../elements/Button";
import Logo from "../layout/partials/Logo";
import Image from "../elements/Image";
import Select from "../elements/Select";
import Checkbox from "../elements/Checkbox";
import HeroFull from "./HeroFull";
import mixpanel from "mixpanel-browser";
import firebase from "firebase/compat/app";
import ReviewBoard from "./ReviewBoard";
import ReviewBoardLaunch from "./ReviewBoardLaunch";
import SocialProofLaunch from "./SocialProofLaunch";
import PricingLaunch from "./PricingLaunch";
import Pricing from "./Pricing";
import PollinateTestimonials from "./PollinateTestimonials";
import SignupCounter from "./SignupCounter";
import FaqLaunch from "./FaqLaunch";
import StripeCheck from "../sections/StripeCheck";
import {PaymentElement, CardElement, useStripe} from "@stripe/react-stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import Fuse from "fuse.js";
require("firebase/auth");

window.mobileCheck = function () {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

let freeUsersArray = [
  "+19178168373",
  "+12245091991",
  "+17863505278",
  "+17087108441",
  "+18033711796",
  "+18608374226",
  "+14243929994",
  "+19706857425",
  "+17737987563",
  "+17737888004",
  "+18477906321",
  "+17328815655",
  "+14149165211",
  "+12138148916",
  "+12488029462",
  "+18325422188",
  "+18608636325",
  "+15024456682",
  "+19047902120",
  "+13099896627",
  "+14193200766",
  "+17735104851",
  "+12027632522",
  "+13108099387",
  "+13474098111",
  "+19175289583",
  "+13095306085",
  "+14699997362",
  "+13472057774",
  "+17139279195",
  "+14808866096",
  "+14804344586",
  "+17182003002",
  "+15166972124",
  "+17879402437",
  "+12029998416",
  "+19375578085",
  "+12036130170",
  "+19257259639",
  "+15102489778",
  "+16307439920",
  "+17703095075",
  "+16507769083",
  "+15122307229",
  "+18477785574",
  "+66916963175",
  "+16086300910",
  "+19085653326",
  "+13144794621",
  "+13123752925",
  "+17739529401",
  "+16506002482",
  "+13127186343",
  "+14084422905",
  "+12029265758",
  "+14702345428",
  "+12028137163",
  "+13106999256",
  "+16177758386",
  "+19145485489",
  "+17138909124",
  "+17187043243",
  "+17084913408",
  "+14107035368",
  "+12024598041",
  "+19734204762",
  "+12624758033",
  "+16463509253",
  "+19085176922",
  "+12162690310",
  "+17204417279",
  "+17602201548",
  "+13129786746",
  "+14138013717",
  "+13098572498",
  "+16825530436",
  "+16263532428",
  "+15104074958",
  "+16468412006",
  "+19732168750",
  "+19546818915",
  "+14697664243",
  "+19176484872",
  "+18486675266",
  "+18054555281",
  "+17733068421",
  "+8801765100738",
  "+19257195571",
  "+16502831962",
  "+19738767588",
  "+18608397388",
  "+12032933339",
  "+17736336786",
  "+16023158203",
  "+16154502490",
  "+17735447799",
  "+15736399003",
  "+13802401726",
  "+16126707721",
  "+16128672132",
  "+18184217246",
  //'+17037626410'
];

const firebaseConfig = {
  apiKey: "AIzaSyAQ1DgZyHU2UDmQ_JDgrEg6th8fUuMOFQg",
  authDomain: "uchiprereg.firebaseapp.com",
  projectId: "uchiprereg",
  storageBucket: "uchiprereg.appspot.com",
  messagingSenderId: "963203646451",
  appId: "1:963203646451:web:70d27bc0d01c0704650886",
  measurementId: "G-1FMVGVFPGW",
};

firebase.initializeApp(firebaseConfig);

var mouseDown = false;

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

let schools = [
  "UChicago",
  "Notre Dame",
  "RPI",
  "Dartmouth",
  "Northeastern",
  "Western University",
  "UMass",
];
let isItAddDrop = [true, false, true, true, false, false, false];

function getCountryCode(phoneNumber) {
  try {
    const parsed = parsePhoneNumber(phoneNumber);
    return parsed?.country || "";
  } catch (error) {
    console.error(error);
    return "";
  }
}

function LaunchingPage() {
  let currentDateInSeconds = new Date().getTime() / 1000;

  let [userName, setUserName] = useState(
    window.sessionStorage.getItem("userName") == null
      ? ""
      : window.sessionStorage.getItem("userName")
  );
  let [userPhone, setUserPhone] = useState(
    window.sessionStorage.getItem("userPhone") == null
      ? ""
      : window.sessionStorage.getItem("userPhone")
  );

  let [userCountry, setUserCountry] = useState(
    window.sessionStorage.getItem("userPhone") == null
      ? ""
      : getCountryCode(window.sessionStorage.getItem("userPhone"))
  );

  let [buttonClicked, setButtonClicked] = useState(false);
  let [alreadySignedUp, setAlreadySignedUp] = useState(false);
  let [freeUsers, setFreeUsers] = useState([]);
  let [success, setSuccess] = useState(
    window.sessionStorage.getItem("successfulLaunch") == null ? false : true
  );

  const [school, setSchool] = useState("");
  const [schoolAlert, setSchoolAlert] = useState("");
  const [selectingSchool, setSelectingSchool] = useState(true);

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection("freeUsers")
      .onSnapshot((querySnapshot) => {
        let freeUsers = [];
        querySnapshot.forEach((doc) => {
          freeUsers.push(doc.data());
        });
        setFreeUsers(freeUsers);
      });
    return () => {
      unsubscribe();
    };
  }, []);

  let className;
  let topOuterDivider;
  let bottomOuterDivider;
  let topDivider;
  let bottomDivider;
  let hasBgColor;
  let invertColor;

  const outerClasses = classNames(
    "signin section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "signin-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const sectionHeader = {
    title: "Welcome to Class Clutch",
    // paragragph: 'The first 100 students get free access for this add period.'
  };

  // window.onclick = (event) => {console.log(document.getElementById('preorderTooltipText').getBoundingClientRect())};

  function changeNameInput(event) {
    setUserName(event.target.value);
    setSuccess(false);
    document.getElementById("nameInput").classList.remove("form-error");
    document.getElementById("nameBlankHint").style.display = "none";
    document.getElementById("nameSpaceHint").style.display = "none";
    document.getElementById("nameLettersHint").style.display = "none";
    window.sessionStorage.removeItem("infoSuccess");
  }

  function changePhoneInput(phone) {
    setUserPhone(phone);
    setSuccess(false);
    // document.getElementById('phoneInput').classList.remove("form-error")
    document.getElementById("phoneBlankHint").style.display = "none";
    document.getElementById("phoneShortHint").style.display = "none";
    document.getElementById("phoneLetterHint").style.display = "none";
    window.sessionStorage.removeItem("infoSuccess");
  }

  function submitSchool() {
    if (school == "") {
      setSchoolAlert("You must select a school to sign up for Pollinate");
    } else {
      setSelectingSchool(false);

      // window.sessionStorage.setItem('userSchool', school)

      // if(window.sessionStorage.getItem('sendToEmailsWidget')=='yes'){
      //   window.location.href='/emailswidget'
      //   window.sessionStorage.removeItem('sendToEmailsWidget')
      // }
      // else if(isItAddDrop[schools.indexOf(school)]==true){
      //   if(window.sessionStorage.getItem('userPhone')==null){
      //     window.location.href='/signup'
      //     window.sessionStorage.removeItem('sendToEmailsWidget')
      //   }else if((freeUsersArray.includes(window.sessionStorage.getItem('userPhone')))){
      //     window.sessionStorage.setItem('userSchool', 'UChicago')
      //     window.location.href='/getnotifications'
      //     window.sessionStorage.removeItem('sendToEmailsWidget')
      //   }else if((window.sessionStorage.getItem('userName')!=null)){
      //     window.location.href='/selectplan'
      //     window.sessionStorage.removeItem('sendToEmailsWidget')
      //   }else{
      //     window.location.href='/signup'
      //     window.sessionStorage.removeItem('sendToEmailsWidget')
      //   }
      // }else{
      //   window.location.href='/signup'
      //   window.sessionStorage.removeItem('sendToEmailsWidget')
      // }
    }
  }

  async function signUp(event) {
    event.preventDefault();

    if (userName == "") {
      document.getElementById("nameInput").classList.add("form-error");
      document.getElementById("nameBlankHint").style.display = "block";
      // document.location.href='#nameInput'
    }
    // else if (!userName.includes(' ')) {
    //   document.getElementById('nameInput').classList.add("form-error");
    //   document.getElementById('nameSpaceHint').style.display='block';
    //   document.location.href='#nameInput'
    // }
    // else if (userName!='' && (userName.includes('@') || userName.includes('-') || userName.includes('_') || userName.includes('(') || userName.includes(')') || userName.includes('[') || userName.includes(']') || userName.includes('—') || userName.includes('/') || userName.includes(',') || userName.includes('*') || userName.includes('~') || (/\d/.test(userName)==true))) {
    //   document.getElementById('nameInput').classList.add("form-error");
    //   document.getElementById('nameLettersHint').style.display='block';
    //   document.location.href='#nameInput'
    // }
    else if (userPhone == "") {
      // document.getElementById('phoneInput').classList.add("form-error");
      document.getElementById("phoneBlankHint").style.display = "block";
      // document.location.href='#phoneInput'
    } else if (/[a-zA-Z]/g.test(userPhone) == true) {
      // document.getElementById('phoneInput').classList.add("form-error");
      document.getElementById("phoneLetterHint").style.display = "block";
      // document.location.href='#phoneInput'
    } else if (
      userPhone.length < 11 ||
      (isValidNumber(`+${userPhone}`) == false &&
        isValidNumber(userPhone) == false)
    ) {
      // document.getElementById('phoneInput').classList.add("form-error")
      document.getElementById("phoneShortHint").style.display = "block";
      // document.location.href='#phoneInput'
    } else if (
      userPhone.includes("@") ||
      userPhone.includes("-") ||
      userPhone.includes(" ") ||
      userPhone.includes("_") ||
      userPhone.includes("(") ||
      userPhone.includes(")") ||
      userPhone.includes("[") ||
      userPhone.includes("]") ||
      userPhone.includes("—") ||
      userPhone.includes(".") ||
      userPhone.includes("/") ||
      userPhone.includes(",") ||
      userPhone.includes("*") ||
      userPhone.includes("~")
    ) {
      // document.getElementById('phoneInput').classList.add("form-error")
      document
        .getElementById("phoneSymbolHint")
        .classList.add("text-color-error");
      // document.location.href='#phoneInput'
    } else if (
      freeUsers.map((a) => a.phone).includes(userPhone) ||
      freeUsersArray.includes(userPhone)
    ) {
      setAlreadySignedUp(true);
      setButtonClicked(false);
    }
    // else if(freeUsers.filter(a => a.school == school).length>=10){
    //   // document.location.href='#phoneInput'
    // }
    else {
      if (buttonClicked == false) {
        setButtonClicked(true);

        window.sessionStorage.setItem("userName", userName);
        if (userPhone.includes("+")) {
          window.sessionStorage.setItem("userPhone", userPhone);
        } else {
          window.sessionStorage.setItem("userPhone", `+${userPhone}`);
        }
        window.sessionStorage.setItem("userSchool", school);

        document.getElementById("nameInput").classList.add("form-success");
        // document.getElementById('phoneInput').classList.add("form-success")

        if (window.sessionStorage.getItem("signupPlan") == null) {
          await firebase
            .firestore()
            .collection("launchLeads")
            .add({
              dateTime: currentDateInSeconds,
              dateTimeReverse: -currentDateInSeconds,
              name: userName,
              phone: userPhone.includes("+") ? userPhone : `+${userPhone}`,
              school: school,
              plan: "None Selected",
            });

          window.location.href = "/selectplan";
        } else {
          await firebase
            .firestore()
            .collection("launchLeads")
            .add({
              dateTime: currentDateInSeconds,
              dateTimeReverse: -currentDateInSeconds,
              name: userName,
              phone: userPhone.includes("+") ? userPhone : `+${userPhone}`,
              school: school,
              plan: window.sessionStorage.getItem("signupPlan"),
            })
            .then((docRef) => {
              const launchDocID = docRef.id;
              window.sessionStorage.setItem("launchDocID", launchDocID);
            })

            .then(() => {
              fetch(
                `https://us-central1-uchiprereg.cloudfunctions.net/server/create-customer`,
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, POST",
                    "Access-Control-Allow-Headers": "Content-Type",
                    "Access-Control-Max-Age": "3600",
                  },
                  body: JSON.stringify({
                    phone: userPhone.includes("+")
                      ? userPhone
                      : `+${userPhone}`,
                  }),
                }
              )
                .then((res) => {
                  return res.json();
                })
                .catch(function () {
                  console.log("ahhh1");
                })
                .then((customer) => {
                  fetch(
                    `https://us-central1-uchiprereg.cloudfunctions.net/server/create-setup-intent`,
                    {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods": "GET, POST",
                        "Access-Control-Allow-Headers": "Content-Type",
                        "Access-Control-Max-Age": "3600",
                      },
                      body: JSON.stringify({
                        cusID: customer.customerID,
                      }),
                    }
                  )
                    .then((res) => {
                      return res.json();
                    })
                    .then((data) => (window.location.href = data.url))
                    .catch(function () {
                      console.log("ahhh2");
                    });
                });
            });
        }

        // .then(async () => {
        //   await fetch(
        //     "https://us-central1-uchiprereg.cloudfunctions.net/server/free-access-granted",
        //     {
        //       method: "POST",
        //       headers: {
        //         "Content-Type": "application/json",
        //         "Access-Control-Allow-Origin": "*",
        //         "Access-Control-Allow-Methods": "GET, POST",
        //         "Access-Control-Allow-Headers": "Content-Type",
        //         "Access-Control-Max-Age": "3600",
        //       },
        //       body: JSON.stringify({
        //         userName: userName,
        //         userPhone: userPhone,
        //         userSchool: school
        //       }),
        //     }
        //   );
        //   // .catch(err => {
        //   //   console.log(err)
        //   //   // sendInData()
        //   // })

        // })

        // .then(() => {
        //   setButtonClicked(false)
        //   setSuccess(true)
        // })
      }
    }
  }

  return (
    <section
      // {...props}
      className={outerClasses}
    >
      {/* <Logo/> */}
      {/* <div className={innerClasses}> */}
      {/* <a href='/' 
      // className='reveal-from-top'
       style={{position:'absolute',left:window.mobileCheck()==true?'0.5rem':'2rem'}}>
      <Image
            // src={require('./../../../assets/images/PollinateSocialNoBackgroundSquare.png')}
            src={require('./../../assets/images/PollinateSocialNoBackgroundSquare.png')}
            width={90}
            height={90} 
            alt="Surface"
            style={{}}
            />
      </a>
      <h1 
      // className='mt-0 mb-16 reveal-from-top' 
      data-reveal-delay="150" style={{textAlign:'center', 
      width:'90%', 
      marginLeft:'auto', marginRight:'auto', 
      marginTop:window.mobileCheck()==true?'6rem':'5rem'
      // margin:'0 0 0 0'
      }}>
        Getting classes shouldn’t be this hard
      </h1> */}
      {/* <SectionHeader tag="h1" data={sectionHeader} className="center-content" style={{paddingBottom:'24px', marginTop:'0.75rem'}}/> */}
      {/* <div style={{textAlign:'center'}} data-reveal-delay="300" 
    // className='reveal-from-top'
    >
    We make it easy — we text you when a spot in a full class opens
    </div> */}
      {/* <img src={require('./../../assets/images/andcigs.png')} style={{height:'25rem', marginLeft:'auto', marginRight:'auto'}}/>
       */}
      {/* <Image 
        //className="has-shadow"
        className='phone'
        src={require('./../../assets/images/andcigs.png')}
        alt="Hero"
        // width={896}
        // height={504} 
        width={800}
        height={450} 
        style={{marginLeft:'auto', marginRight:'auto'}}  
        /> */}
      {/* <Pricing showSectionHeader topDivider pricingSlider className="has-bg-color-cut" />
    <PricingLaunch showSectionHeader topDivider pricingSlider className="has-bg-color-cut"  /> 
    <ReviewBoardLaunch />
    <PollinateTestimonials topDivider bottomOuterDivider className="gradient-section" />
    <FaqLaunch /> */}
      {/* <div style={{textAlign:'center', marginTop:'1rem'}}>
      This service is $15 / class...
    </div>

    <div style={{textAlign:'center', marginTop:'1rem'}}>
      We are offering free access to the first 10 users who sign up here from each school
    </div> */}
      {/* <div style={{marginLeft:'auto', marginRight:'auto', textAlign:'center', marginTop:'0px'}} class='emailsWidgetSub'> This email will include relevant information about their class. We don’t send emails to professors directly.</div> */}
      <div style={{textAlign: "center"}} id="launch">
        <h1
          className="mt-0 mb-16 reveal-from-top"
          data-reveal-delay="150"
          style={{width: "80%", marginLeft: "auto", marginRight: "auto"}}
        >
          Be the first to know when a spot opens
        </h1>
        <p
          className="m-0 mb-32 reveal-from-top"
          data-reveal-delay="300"
          style={{fontSize: "24px", marginLeft: "10px"}}
        >
          Secure your spot by joining our waitlist for real-time SMS and email
          alerts
        </p>
      </div>
      <div className="tiles-wrap">
        <div className="tiles-item" style={{paddingTop: "0"}}>
          {selectingSchool == true ? (
            <div>
              <div style={{marginTop: "2rem"}}>Select Your School</div>

              <div>
                {/* We will text you when a spot in the class opens up. */}
                <form
                  style={{
                    //marginTop:'1rem',
                    width: "100%",
                  }}
                >
                  <fieldset>
                    <div className="mb-12"></div>
                    <select
                      name="school"
                      id="school"
                      class="form-select"
                      value={school}
                      onChange={(event) => {
                        setSchool(event.target.value);
                      }}
                    >
                      <option selected value="">
                        Select One...
                      </option>
                      <option value="Berkeley">Berkeley</option>
                      <option value="Boston University">
                        Boston University
                      </option>
                      <option value="Brandeis">Brandeis</option>
                      <option value="Brown">Brown</option>
                      <option value="Case Western">Case Western</option>
                      <option value="Columbia">Columbia</option>
                      <option value="Cornell">Cornell</option>
                      <option value="Dartmouth">Dartmouth</option>
                      <option value="Duke">Duke</option>
                      <option value="Emory">Emory</option>
                      <option value="Georgetown">Georgetown</option>
                      <option value="Georgia Tech">Georgia Tech</option>
                      <option value="Harvard">Harvard</option>
                      <option value="Johns Hopkins">Johns Hopkins</option>
                      <option value="Northwestern">Northwestern</option>
                      <option value="Northeastern">Northeastern</option>
                      <option value="Notre Dame">Notre Dame</option>
                      <option value="NYU">NYU</option>
                      <option value="Ohio State">Ohio State</option>
                      <option value="Purdue">Purdue</option>
                      <option value="Rice">Rice</option>
                      <option value="RPI">RPI</option>
                      <option value="Rutgers">Rutgers</option>
                      <option value="Santa Clara">Santa Clara</option>
                      <option value="Stanford">Stanford</option>
                      <option value="Tufts">Tufts</option>
                      <option value="Tulane">Tulane</option>
                      <option value="UC Davis">UC Davis</option>
                      <option value="UC Irvine">UC Irvine</option>
                      <option value="UC Los Angeles">UC Los Angeles</option>
                      <option value="UC Santa Barbara">UC Santa Barbara</option>
                      <option value="UC San Diego">UC San Diego</option>
                      <option value="UChicago">UChicago</option>
                      <option value="UGA">UGA</option>
                      <option value="UIUC">UIUC</option>
                      <option value="UMiami">UMiami</option>
                      <option value="UMich">UMich</option>
                      <option value="UNC">UNC</option>
                      <option value="University of Florida">
                        University of Florida
                      </option>
                      <option value="University of Maryland">
                        University of Maryland
                      </option>
                      <option value="University of Rochester">
                        University of Rochester
                      </option>
                      <option value="University of Southern California">
                        University of Southern California
                      </option>
                      <option value="UPenn">UPenn</option>
                      <option value="UT Austin">UT Austin</option>
                      <option value="UVA">UVA</option>
                      <option value="UW Madison">UW Madison</option>
                      <option value="Vanderbilt">Vanderbilt</option>
                      <option value="Villanova">Villanova</option>
                      <option value="Wake Forest">Wake Forest</option>
                      <option value="WashU">WashU</option>
                      <option value="William and Mary">William and Mary</option>
                      <option value="Yale">Yale</option>
                    </select>
                    <div
                      class="text-color-error fadeIn2Class"
                      style={{fontSize: "16px"}}
                    >
                      {schoolAlert}
                    </div>

                    <Button
                      type="button"
                      color="primary"
                      wide
                      style={{marginTop: "1rem"}}
                      onClick={submitSchool}
                    >
                      <div class="noSelectText" style={{pointerEvents: "none"}}>
                        Next
                      </div>
                    </Button>
                  </fieldset>
                </form>
              </div>

              {success == true ? (
                <div
                  style={{
                    marginTop: "1rem",
                    backgroundColor: "rgb(0,194,169)",
                    // width:'306px'
                  }}
                  // class='widthClassSignup'
                >
                  <div
                    id="successMessage"
                    style={{
                      textAlign: "center",
                      padding: "1rem",
                      fontWeight: "bold",
                      fontSize: "25px",
                      color: "white",
                      // display:'none'
                      // color:'rgb(0,194,169)',
                      // display:window.sessionStorage.getItem('infoSuccessNotAddDrop')=='true'?'block':'none'
                    }}
                    //class="form-hint text-color-success"
                  >
                    Success — you are on our list!
                  </div>
                </div>
              ) : null}
            </div>
          ) : (
            <div>
              {/* <div style={{marginTop:'2rem', textAlign:'center', color:'orange', fontSize:'30px'}}>
                {10 - freeUsers.filter(a => a.school==school).length} free sign ups left at {school}
                </div> */}

              <form style={{marginTop: "1rem"}}>
                <fieldset>
                  <div className="mb-12" style={{width: "306px"}}>
                    <input
                      label="Full name"
                      placeholder="Name"
                      labelHidden
                      id="nameInput"
                      required
                      className="form-input"
                      onChange={changeNameInput}
                      value={userName}
                    />
                  </div>
                  <div
                    id="nameBlankHint"
                    style={{display: "none"}}
                    class="form-hint text-color-error"
                  >
                    Please enter your name
                  </div>
                  <div
                    id="nameSpaceHint"
                    style={{display: "none"}}
                    class="form-hint text-color-error"
                  >
                    Please enter your first and last name.
                  </div>
                  <div
                    id="nameLettersHint"
                    style={{display: "none"}}
                    class="form-hint text-color-error"
                  >
                    Please use only letters.
                  </div>
                  <div className="mb-12" style={{width: "306px"}}>
                    <PhoneInput
                      country={userCountry == "" ? "us" : userCountry}
                      value={userPhone}
                      onChange={(phone) => changePhoneInput(phone)}
                      placeholder="Phone number"
                      maxLength="14"
                      enableSearch={true}
                      // inputStyle={{ width: '100%' }}
                      id="phoneInput"
                      required
                      // style={{borderStyle:'dashed'}}
                      class="mb-12"
                    />
                    {/* <input
                          type="text"
                          maxlength="14"
                          label="Phone"
                          placeholder="Phone number"
                          className= "form-input"
                          labelHidden
                          required 
                          id = "phoneInput"
                          onChange={changePhoneInput}
                          value={userPhone}
                          /> */}
                  </div>

                  <div
                    id="phoneBlankHint"
                    style={{display: "none"}}
                    class="form-hint text-color-error"
                  >
                    Please enter your phone number
                  </div>
                  <div
                    id="phoneShortHint"
                    style={{display: "none"}}
                    class="form-hint text-color-error"
                  >
                    Please enter a valid phone number
                  </div>
                  <div
                    id="phoneLetterHint"
                    style={{display: "none"}}
                    class="form-hint text-color-error"
                  >
                    Please enter a valid phone number
                  </div>
                  {userPhone.includes("@") ||
                  userPhone.includes("-") ||
                  userPhone.includes(" ") ||
                  userPhone.includes("_") ||
                  userPhone.includes("(") ||
                  userPhone.includes(")") ||
                  userPhone.includes("[") ||
                  userPhone.includes("]") ||
                  userPhone.includes("—") ||
                  userPhone.includes(".") ||
                  userPhone.includes("/") ||
                  userPhone.includes(",") ||
                  userPhone.includes("*") ||
                  userPhone.includes("~") ? (
                    <div
                      id="phoneSymbolHint"
                      //style={{display:'none'}}
                      class="form-hint text-color-error"
                    >
                      Please enter your number in the format: +17037626410.
                    </div>
                  ) : null}

                  <div className="mt-24 mb-32">
                    <Button
                      type="button"
                      color="primary"
                      onClick={signUp}
                      style={{width: "306px"}}
                    >
                      <div>
                        <img
                          src="/ellipsis-white.gif"
                          class="noSelectText"
                          style={{
                            height: "48px",
                            marginTop: "-13px",
                            display: buttonClicked == true ? "block" : "none",
                          }}
                        />
                        <div
                          class="noSelectText"
                          style={{
                            pointerEvents: "none",
                            display: buttonClicked == false ? "block" : "none",
                          }}
                        >
                          Join the waitlist now
                        </div>
                      </div>
                    </Button>

                    {alreadySignedUp == true ? (
                      <div
                        id="alreadySignedUp"
                        style={{
                          textAlign: "left",
                          fontSize: "20px",
                          color: "rgb(0,194,169)",
                          marginTop: "1rem",
                          width: "306px",
                        }}
                        class="fadeIn2Class"
                        //class="form-hint text-color-success"
                      >
                        You are already on our list, so we’ll keep you in the
                        loop.
                      </div>
                    ) : null}

                    {freeUsers.filter((a) => a.school == school).length >=
                    10 ? (
                      <div
                        id="freeUserLimit"
                        style={{
                          textAlign: "left",
                          fontSize: "20px",
                          color: "red",
                          marginTop: "1rem",
                          width: "306px",
                        }}
                        class="fadeIn2Class"
                        //class="form-hint text-color-success"
                      >
                        Sorry, we have reached our free users limit at {school}.
                        Text Jack at (612) 670-7721 if you would like to be
                        updated when you can enter your fall classes.
                      </div>
                    ) : null}
                  </div>
                </fieldset>
              </form>
            </div>
          )}

          {/* <div className="signin-bottom has-top-divider" style={{marginTop:'0.7rem'}}>
                    <div className="pt-32 text-xs center-content text-color-low">
                      Want to learn more about Pollinate? <Link to="/">
                       Home
                      </Link>
                    </div>
                  </div> */}
        </div>
      </div>
      <div style={{height: "7rem"}}></div> {/* This is the buffer */}
      {/* </div> */}
    </section>
  );
}

LaunchingPage.propTypes = propTypes;
LaunchingPage.defaultProps = defaultProps;

export default LaunchingPage;
