import React from 'react';
import { withRouter, Switch } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';
import LayoutLaunch from './layouts/LayoutLaunch';
import LayoutAlternative from './layouts/LayoutAlternative';
import LayoutSignin from './layouts/LayoutSignin';

// Views 
import Home from './views/HomeLaunch';
import Secondary from './views/Secondary';
import Login from './views/Login';
import SignupForm from './components/sections/SignupForm';
import Loading from './views/Loading';
import UpdatePreferences from './views/UpdatePreferences';
import testMyUChicago from './views/testMyUChicago';
import EmailsWidget from './components/sections/EmailsWidget';
import CRMDash from './components/sections/CRMDash'
import GetNotifications from './components/sections/GetNotifications';
import SelectPlan from './components/sections/SelectPlan';
import SelectSchool from './components/sections/SelectSchool';
import PremiumWaitlist from './components/sections/PremiumWaitlist'
import UnlimitedWaitlist from './components/sections/UnlimitedWaitlist'
import LaunchingPage from './components/sections/LaunchingPage';
import WaitlistPlans from './views/WaitlistPlans';
import PricingPage from './views/PricingPage';
import WeMoved from './views/WeMoved'

class App extends React.Component {

  componentDidMount() {
    document.body.classList.add('is-loaded')
    this.refs.scrollReveal.init();
  }

  // Route change
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.refs.scrollReveal.init();
    }
  }

  render() {
    return (
      <ScrollReveal
        ref="scrollReveal"
        children={() => (
          <Switch>

            {/* pollinate.work */}
            {/* <AppRoute exact path="/" component={WeMoved} 
            // layout={LayoutDefault}
             /> */}

            {/* classclutch.com */}
            <AppRoute exact path="/" component={Home} 
            layout={LayoutDefault}
             />
            <AppRoute exact path="/home" component={Home} 
            layout={LayoutDefault}
             />
            <AppRoute exact path="/signup" component={SignupForm} layout={LayoutSignin} />
            <AppRoute exact path="/selectplan" component={SelectPlan} 
            layout={LayoutLaunch} 
            />
            <AppRoute exact path="/selectschool" component={SelectSchool} layout={LayoutSignin} />
            <AppRoute exact path="/getnotifications" component={GetNotifications} layout={LayoutSignin} />
            <AppRoute exact path="/pricing" component={PricingPage}/>
            <AppRoute exact path="/loading/33oi4p3u29340392" component={Loading}/>
            <AppRoute exact path='/preferences' component={UpdatePreferences}/>
            <AppRoute exact path='/emailswidget' component={EmailsWidget}/>
            <AppRoute exact path='/crmdash' component={CRMDash}/>
            <AppRoute exact path='/premiumwaitlist' component={PremiumWaitlist}/>
            <AppRoute exact path='/unlimitedwaitlist' component={UnlimitedWaitlist}/> 
            <AppRoute exact path='/waitlistplans' component={WaitlistPlans}  layout={LayoutLaunch}/>




          </Switch>
        )} />
    );
  }
}

export default withRouter(props => <App {...props} />);