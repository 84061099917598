import React from 'react';
import PreferencesForm from '../components/sections/PreferencesForm';

class UpdatePreferences extends React.Component {
  render() {
    return (
      <PreferencesForm className="illustration-section-01" />
    );
  }
}

export default UpdatePreferences;