import React, {useEffect, useState} from "react";
import classNames from "classnames";
import {SectionProps} from "../../utils/SectionProps";
import {Link} from "react-router-dom";
import SectionHeader from "./partials/SectionHeader";
import Button from "../elements/Button";
import Select from "../elements/Select";
import Checkbox from "../elements/Checkbox";
import HeroFull from "./HeroFull";
import mixpanel from "mixpanel-browser";
import firebase from "firebase/compat/app";
import StripeCheck from "../sections/StripeCheck";
import {PaymentElement, CardElement, useStripe} from "@stripe/react-stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import Fuse from "fuse.js";
import {uchicagoCourses} from "../../assets/UChicago";
import {notreDameCourses} from "../../assets/Notre Dame";
import {rpiCourses} from "../../assets/RPI";
import {dartmouthCourses} from "../../assets/Dartmouth";
import {northeasternCourses} from "../../assets/Northeastern";
import {westernUniversityCourses} from "../../assets/Western University";
import {umassCourses} from "../../assets/UMass";
require("firebase/auth");

let isItAddDrop = [true, false, true, true, false, false, false];

window.mobileCheck = function () {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

const firebaseConfig = {
  apiKey: "AIzaSyAQ1DgZyHU2UDmQ_JDgrEg6th8fUuMOFQg",
  authDomain: "uchiprereg.firebaseapp.com",
  projectId: "uchiprereg",
  storageBucket: "uchiprereg.appspot.com",
  messagingSenderId: "963203646451",
  appId: "1:963203646451:web:70d27bc0d01c0704650886",
  measurementId: "G-1FMVGVFPGW",
};

firebase.initializeApp(firebaseConfig);

var mouseDown = false;

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

let freeUsersArray = [
  "+19178168373",
  "+12245091991",
  "+17863505278",
  "+17087108441",
  "+18033711796",
  "+18608374226",
  "+14243929994",
  "+19706857425",
  "+17737987563",
  "+17737888004",
  "+18477906321",
  "+17328815655",
  "+14149165211",
  "+12138148916",
  "+12488029462",
  "+18325422188",
  "+18608636325",
  "+15024456682",
  "+19047902120",
  "+13099896627",
  "+14193200766",
  "+17735104851",
  "+12027632522",
  "+13108099387",
  "+13474098111",
  "+19175289583",
  "+13095306085",
  "+14699997362",
  "+13472057774",
  "+17139279195",
  "+14808866096",
  "+14804344586",
  "+17182003002",
  "+15166972124",
  "+17879402437",
  "+12029998416",
  "+19375578085",
  "+12036130170",
  "+19257259639",
  "+15102489778",
  "+16307439920",
  "+17703095075",
  "+16507769083",
  "+15122307229",
  "+18477785574",
  "+66916963175",
  "+16086300910",
  "+19085653326",
  "+13144794621",
  "+13123752925",
  "+17739529401",
  "+16506002482",
  "+13127186343",
  "+14084422905",
  "+12029265758",
  "+14702345428",
  "+12028137163",
  "+13106999256",
  "+16177758386",
  "+19145485489",
  "+17138909124",
  "+17187043243",
  "+17084913408",
  "+14107035368",
  "+12024598041",
  "+19734204762",
  "+12624758033",
  "+16463509253",
  "+19085176922",
  "+12162690310",
  "+17204417279",
  "+17602201548",
  "+13129786746",
  "+14138013717",
  "+13098572498",
  "+16825530436",
  "+16263532428",
  "+15104074958",
  "+16468412006",
  "+19732168750",
  "+19546818915",
  "+14697664243",
  "+19176484872",
  "+18486675266",
  "+18054555281",
  "+17733068421",
  "+8801765100738",
  "+19257195571",
  "+16502831962",
  "+19738767588",
  "+18608397388",
  "+12032933339",
  "+17736336786",
  "+16023158203",
  "+16154502490",
  "+17735447799",
  "+15736399003",
  "+13802401726",
  "+16126707721",
  "+16128672132",
  "+18184217246",
  //'+17037626410'
];

let schools = [
  "UChicago",
  "Notre Dame",
  "RPI",
  "Dartmouth",
  "Northeastern",
  "Western University",
  "UMass",
];
let coursesData = [
  uchicagoCourses,
  notreDameCourses,
  rpiCourses,
  dartmouthCourses,
  northeasternCourses,
  westernUniversityCourses,
  umassCourses,
];
let termTimes = [
  1709625600, 1709625600, 1709625600, 1709625600, 1709625600, 1709625600,
  1709625600, 1723217440, 1732127393,
];
let userSchool = window.sessionStorage.getItem("userSchool");

let classes = [];
let classTitles = [];
let classTitlesNoDuplicates = [];

// for(var g=0;g<uchicagoCourses.subjects.length;g++){
//   for(var p=0;p<uchicagoCourses.subjects[g].course.length;p++){
//     classes.push(uchicagoCourses.subjects[g].course[p])
//     classTitles.push(uchicagoCourses.subjects[g].course[p].number);
//   }
// }

if (userSchool != null && schools.includes(userSchool)) {
  for (var g = 0; g < coursesData[schools.indexOf(userSchool)].length; g++) {
    classes.push(coursesData[schools.indexOf(userSchool)][g].course);
    classTitles.push(
      `${coursesData[schools.indexOf(userSchool)][g].course.subject} - ${
        coursesData[schools.indexOf(userSchool)][g].course.name
      }`
    );
  }
}

classTitlesNoDuplicates = [...new Set(classTitles)];

let numClassesEval = [0];
if (window.sessionStorage.getItem("numClasses") != null) {
  numClassesEval = [];
  for (
    let j = 0;
    j < parseFloat(window.sessionStorage.getItem("numClasses"));
    j++
  ) {
    numClassesEval.push(0);
  }
}

let classSelected1New = [];
let classSelected2New = [];
let classSelected3New = [];
let classSelected4New = [];
let classSelected5New = [];

if (window.sessionStorage.getItem("classSectionsSelected1") != null) {
  classSelected1New.push(
    classes.filter(
      (course) =>
        course.subject.toLowerCase() ==
        window.sessionStorage.getItem("subjectSelected1").toLowerCase()
    )
  );
}

if (window.sessionStorage.getItem("classSectionsSelected2") != null) {
  classSelected2New.push(
    classes.filter(
      (course) =>
        course.subject.toLowerCase() ==
        window.sessionStorage.getItem("subjectSelected2").toLowerCase()
    )
  );
}

if (window.sessionStorage.getItem("classSectionsSelected3") != null) {
  classSelected3New.push(
    classes.filter(
      (course) =>
        course.subject.toLowerCase() ==
        window.sessionStorage.getItem("subjectSelected3").toLowerCase()
    )
  );
}

if (window.sessionStorage.getItem("classSectionsSelected4") != null) {
  classSelected4New.push(
    classes.filter(
      (course) =>
        course.subject.toLowerCase() ==
        window.sessionStorage.getItem("subjectSelected4").toLowerCase()
    )
  );
}

if (window.sessionStorage.getItem("classSectionsSelected5") != null) {
  classSelected5New.push(
    classes.filter(
      (course) =>
        course.subject.toLowerCase() ==
        window.sessionStorage.getItem("subjectSelected5").toLowerCase()
    )
  );
}

let sectionsSelected1New = [];
let sectionsSelected2New = [];
let sectionsSelected3New = [];
let sectionsSelected4New = [];
let sectionsSelected5New = [];

if (window.sessionStorage.getItem("classSectionsSelected1") != null) {
  let sectionsSelected1 = window.sessionStorage
    .getItem("classSectionsSelected1")
    .split(",");

  for (var e = 0; e < sectionsSelected1.length; e++) {
    sectionsSelected1New.push(parseInt(sectionsSelected1[e]));
  }
}

if (window.sessionStorage.getItem("classSectionsSelected2") != null) {
  let sectionsSelected2 = window.sessionStorage
    .getItem("classSectionsSelected2")
    .split(",");

  for (var e = 0; e < sectionsSelected2.length; e++) {
    sectionsSelected2New.push(parseInt(sectionsSelected2[e]));
  }
}

if (window.sessionStorage.getItem("classSectionsSelected3") != null) {
  let sectionsSelected3 = window.sessionStorage
    .getItem("classSectionsSelected3")
    .split(",");

  for (var e = 0; e < sectionsSelected3.length; e++) {
    sectionsSelected3New.push(parseInt(sectionsSelected3[e]));
  }
}

if (window.sessionStorage.getItem("classSectionsSelected4") != null) {
  let sectionsSelected4 = window.sessionStorage
    .getItem("classSectionsSelected4")
    .split(",");

  for (var e = 0; e < sectionsSelected4.length; e++) {
    sectionsSelected4New.push(parseInt(sectionsSelected4[e]));
  }
}

if (window.sessionStorage.getItem("classSectionsSelected5") != null) {
  let sectionsSelected5 = window.sessionStorage
    .getItem("classSectionsSelected5")
    .split(",");

  for (var e = 0; e < sectionsSelected5.length; e++) {
    sectionsSelected5New.push(parseInt(sectionsSelected5[e]));
  }
}

function GetNotifications() {
  useEffect(() => {
    const userSchool = sessionStorage.getItem("userSchool");
    if (!userSchool) {
      window.location.href = "/selectschool";
    }
  }, []);

  // let [alreadyUserNames, setAlreadyUserNames] = useState([])
  let [referrerPhone, setReferrerPhone] = useState(
    window.sessionStorage.getItem("referrerPhone") != null
      ? ""
      : window.sessionStorage.getItem("referrerPhone")
  );
  let [referrerName, setReferrerName] = useState(
    window.sessionStorage.getItem("referrerName") != null
      ? ""
      : window.sessionStorage.getItem("referrerName")
  );
  let [currentlyTracking, setCurrentlyTracking] = useState([]);
  let [allData, setAllData] = useState([]);
  let [allDataIDs, setAllDataIDs] = useState([]);
  let [subscribersThisQuarter, setSubscribersThisQuarter] = useState([]);

  // const [standardDocs, setStandardDocs] = useState([]);

  // useEffect(() => {
  //   const unsubscribe = firebase.firestore().collection('grabLeads')
  //   .where('dateTime', '>', termTimes[schools.indexOf(window.sessionStorage.getItem('userSchool'))])
  //   .onSnapshot((querySnapshot) => {
  //     let standardDocs = [];
  //     querySnapshot.forEach((doc) => {

  //       // if(doc.data().phone==window.sessionStorage.getItem('userPhone')){
  //       // window.location.href=`/preferences#${window.sessionStorage.getItem('userPhone')}`

  //       if(doc.data().addDelay==false){
  //         standardDocs.push(doc.data());
  //       }

  //       // if(standardDocs.map(a => a.phone==window.sessionStorage.getItem('userPhone')).length==5){
  //       //   window.location.href=`/preferences#${window.sessionStorage.getItem('userPhone')}`
  //       // }

  //     });
  //     setStandardDocs(standardDocs);
  //   });
  //   return () => {
  //     unsubscribe();
  //   };
  // }, []);

  // console.log(standardDocs)

  useEffect(() => {
    firebase
      .firestore()
      .collection("grabLeads")
      .orderBy("dateTime")
      // .get()
      // .then((querySnapshot) => {
      .onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          if (doc.data().dateTime > termTimes[schools.indexOf(userSchool)]) {
            setAllData((a) => [...a, doc.data()]);
            setAllDataIDs((a) => [...a, doc.id]);
            setSubscribersThisQuarter((a) => [...a, doc.data().phone]);
          }

          //NEED THIS IF CONDITION TO MAKE SURE THE DATA IS ACCURATE FOR THE QUARTER
          if (
            doc.data().dateTime > termTimes[schools.indexOf(userSchool)] &&
            doc.data().gotClass != true &&
            doc.data().classSections.length > 0
          ) {
            setCurrentlyTracking((a) => [...a, doc.data().classSubject]);
          }
          // if(window.location.href.split('signup')[1].includes(`?referralPhoneInput=${doc.data().phone}`)){
          //   setReferrerPhone(doc.data().phone)
          //   setReferrerName(doc.data().name)

          // }else

          if (window.sessionStorage.getItem("userName") == null) {
            if (
              window.sessionStorage
                .getItem("userPhone")
                .includes(doc.data().phone)
            ) {
              setUserName(doc.data().name);
            }
          }

          if (
            window.sessionStorage.getItem("referralPhone") != null &&
            window.sessionStorage.getItem("referralPhone") != "null" &&
            window.sessionStorage.getItem("referralPhone") != ""
          ) {
            setReferrerPhone(window.sessionStorage.getItem("referralPhone"));
            setReferrerName(window.sessionStorage.getItem("referralName"));
          }
        });

        // querySnapshot.docChanges().forEach((change) => {
        //   if (change.type === "added") {
        //     // setAlreadyUserNames(a =>[...a,change.doc.data().userPhone]);
        //     if(window.location.href.split('phoneInput=')[1].includes(change.doc.data().phone)){
        //       setReferrerPhone(change.doc.data().phone)
        //       console.log(change.doc.data().phone)
        //     }

        //   }
        // })
      });
  }, []);

  const [freeUsers, setFreeUsers] = useState([]);

  useEffect(() => {
    const db = firebase.firestore();
    const unsubscribe = db.collection("freeUsers").onSnapshot((snapshot) => {
      let users = [];
      snapshot.forEach((doc) => {
        if (
          doc.data().dateTime >
            termTimes[
              schools.indexOf(window.sessionStorage.getItem("userSchool"))
            ] &&
          doc.data().school == window.sessionStorage.getItem("userSchool")
        )
          users.push(doc.data());
      });
      setFreeUsers(users);
    });

    // Cleanup function to unsubscribe from changes
    return () => unsubscribe();
  }, []);

  let currentDateInSeconds = new Date().getTime() / 1000;
  let [userName, setUserName] = useState(
    window.sessionStorage.getItem("userName") == null
      ? ""
      : window.sessionStorage.getItem("userName")
  );
  let [userPhone, setUserPhone] = useState(
    window.sessionStorage.getItem("userPhone") == null
      ? ""
      : window.sessionStorage.getItem("userPhone")
  );
  let [wantsEmails, setWantsEmails] = useState(
    window.sessionStorage.getItem("wantsEmails") == null
      ? "no"
      : window.sessionStorage.getItem("wantsEmails")
  );
  let [buttonClicked, setButtonClicked] = useState(false);

  let [numClasses, setNumClasses] = useState(
    window.sessionStorage.getItem("numClasses") == null ? [0] : numClassesEval
  );

  let [classTextInput1, setClassTextInput1] = useState(
    window.sessionStorage.getItem("classTextInput1") == null
      ? ""
      : window.sessionStorage.getItem("classTextInput1")
  );
  let [classSelected1, setClassSelected1] = useState(
    window.sessionStorage.getItem("classSectionsSelected1") == null
      ? []
      : classSelected1New[0]
  );
  let [subjectSelected1, setSubjectSelected1] = useState(
    window.sessionStorage.getItem("subjectSelected1") == null
      ? ""
      : window.sessionStorage.getItem("subjectSelected1")
  );
  let [classSectionsSelected1, setClassSectionsSelected1] = useState(
    window.sessionStorage.getItem("classSectionsSelected1") == null
      ? []
      : sectionsSelected1New
  );

  let [classTextInput2, setClassTextInput2] = useState(
    window.sessionStorage.getItem("classTextInput2") == null
      ? ""
      : window.sessionStorage.getItem("classTextInput2")
  );
  let [classSelected2, setClassSelected2] = useState(
    window.sessionStorage.getItem("classSectionsSelected2") == null
      ? []
      : classSelected2New[0]
  );
  let [subjectSelected2, setSubjectSelected2] = useState(
    window.sessionStorage.getItem("subjectSelected2") == null
      ? ""
      : window.sessionStorage.getItem("subjectSelected2")
  );
  let [classSectionsSelected2, setClassSectionsSelected2] = useState(
    window.sessionStorage.getItem("classSectionsSelected2") == null
      ? []
      : sectionsSelected2New
  );

  let [classTextInput3, setClassTextInput3] = useState(
    window.sessionStorage.getItem("classTextInput3") == null
      ? ""
      : window.sessionStorage.getItem("classTextInput3")
  );
  let [classSelected3, setClassSelected3] = useState(
    window.sessionStorage.getItem("classSectionsSelected3") == null
      ? []
      : classSelected3New[0]
  );
  let [subjectSelected3, setSubjectSelected3] = useState(
    window.sessionStorage.getItem("subjectSelected3") == null
      ? ""
      : window.sessionStorage.getItem("subjectSelected3")
  );
  let [classSectionsSelected3, setClassSectionsSelected3] = useState(
    window.sessionStorage.getItem("classSectionsSelected3") == null
      ? []
      : sectionsSelected3New
  );

  let [classTextInput4, setClassTextInput4] = useState(
    window.sessionStorage.getItem("classTextInput4") == null
      ? ""
      : window.sessionStorage.getItem("classTextInput4")
  );
  let [classSelected4, setClassSelected4] = useState(
    window.sessionStorage.getItem("classSectionsSelected4") == null
      ? []
      : classSelected4New[0]
  );
  let [subjectSelected4, setSubjectSelected4] = useState(
    window.sessionStorage.getItem("subjectSelected4") == null
      ? ""
      : window.sessionStorage.getItem("subjectSelected4")
  );
  let [classSectionsSelected4, setClassSectionsSelected4] = useState(
    window.sessionStorage.getItem("classSectionsSelected4") == null
      ? []
      : sectionsSelected4New
  );

  let [classTextInput5, setClassTextInput5] = useState(
    window.sessionStorage.getItem("classTextInput5") == null
      ? ""
      : window.sessionStorage.getItem("classTextInput5")
  );
  let [classSelected5, setClassSelected5] = useState(
    window.sessionStorage.getItem("classSectionsSelected5") == null
      ? []
      : classSelected5New[0]
  );
  let [subjectSelected5, setSubjectSelected5] = useState(
    window.sessionStorage.getItem("subjectSelected5") == null
      ? ""
      : window.sessionStorage.getItem("subjectSelected5")
  );
  let [classSectionsSelected5, setClassSectionsSelected5] = useState(
    window.sessionStorage.getItem("classSectionsSelected5") == null
      ? []
      : sectionsSelected5New
  );

  let [fuseSearch1, setFuseSearch1] = useState([]);
  let [fuseSearch2, setFuseSearch2] = useState([]);
  let [fuseSearch3, setFuseSearch3] = useState([]);
  let [fuseSearch4, setFuseSearch4] = useState([]);
  let [fuseSearch5, setFuseSearch5] = useState([]);

  let [showFuseList1, setShowFuseList1] = useState(true);
  let [showFuseList2, setShowFuseList2] = useState(true);
  let [showFuseList3, setShowFuseList3] = useState(true);
  let [showFuseList4, setShowFuseList4] = useState(true);
  let [showFuseList5, setShowFuseList5] = useState(true);

  let [consentRequired1, setConsentRequired1] = useState(false);
  let [consentRequired2, setConsentRequired2] = useState(false);
  let [consentRequired3, setConsentRequired3] = useState(false);
  let [consentRequired4, setConsentRequired4] = useState(false);
  let [consentRequired5, setConsentRequired5] = useState(false);

  let className;
  let topOuterDivider;
  let bottomOuterDivider;
  let topDivider;
  let bottomDivider;
  let hasBgColor;
  let invertColor;

  const outerClasses = classNames(
    "signin section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "signin-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const sectionHeader = {
    title: "Sign up",
    //paragraph: isItAddDrop==false?'We will text you when: ':''
  };

  async function submitRequest(event) {
    event.preventDefault();

    if (
      allData
        .filter(
          (a) => a.classSubject == subjectSelected1 && a.addDelay == false
        )
        .map((a) => a.phone)
        .includes(userPhone)
    ) {
      document.getElementById("classTitleInput1").classList.add("form-error");
      document.getElementById("classAlreadyHint1").style.display = "block";
      document.location.href = "#classTitleInput1";
    } else if (
      allData
        .filter(
          (a) => a.classSubject == subjectSelected2 && a.addDelay == false
        )
        .map((a) => a.phone)
        .includes(userPhone)
    ) {
      document.getElementById("classTitleInput2").classList.add("form-error");
      document.getElementById("classAlreadyHint2").style.display = "block";
      document.location.href = "#classTitleInput2";
    } else if (
      allData
        .filter(
          (a) => a.classSubject == subjectSelected3 && a.addDelay == false
        )
        .map((a) => a.phone)
        .includes(userPhone)
    ) {
      document.getElementById("classTitleInput3").classList.add("form-error");
      document.getElementById("classAlreadyHint3").style.display = "block";
      document.location.href = "#classTitleInput3";
    } else if (
      allData
        .filter(
          (a) => a.classSubject == subjectSelected4 && a.addDelay == false
        )
        .map((a) => a.phone)
        .includes(userPhone)
    ) {
      document.getElementById("classTitleInput4").classList.add("form-error");
      document.getElementById("classAlreadyHint4").style.display = "block";
      document.location.href = "#classTitleInput4";
    } else if (
      allData
        .filter(
          (a) => a.classSubject == subjectSelected5 && a.addDelay == false
        )
        .map((a) => a.phone)
        .includes(userPhone)
    ) {
      document.getElementById("classTitleInput5").classList.add("form-error");
      document.getElementById("classAlreadyHint5").style.display = "block";
      document.location.href = "#classTitleInput5";
    } else if (
      isItAddDrop[schools.indexOf(userSchool)] == true &&
      numClasses.length == 0
    ) {
      document.getElementById("selectNumClasses").classList.add("form-error");
      document.getElementById("selectEmptyHint").style.display = "block";
      document.location.href = "#selectEmptyHint";
    } else if (
      isItAddDrop[schools.indexOf(userSchool)] &&
      numClasses.length > 0 &&
      classSectionsSelected1.length == 0
    ) {
      document.getElementById("classTitleInput1").classList.add("form-error");
      document.getElementById("classSelectHint1").style.display = "block";
      document.location.href = "#classTitleInput1";
    } else if (
      isItAddDrop[schools.indexOf(userSchool)] == true &&
      numClasses.length > 1 &&
      classSectionsSelected2.length == 0
    ) {
      document.getElementById("classTitleInput2").classList.add("form-error");
      document.getElementById("classSelectHint2").style.display = "block";
      document.location.href = "#classTitleInput2";
    } else if (
      isItAddDrop[schools.indexOf(userSchool)] &&
      numClasses.length > 2 &&
      classSectionsSelected3.length == 0
    ) {
      document.getElementById("classTitleInput3").classList.add("form-error");
      document.getElementById("classSelectHint3").style.display = "block";
      document.location.href = "#classTitleInput3";
    } else if (
      isItAddDrop[schools.indexOf(userSchool)] &&
      numClasses.length > 3 &&
      classSectionsSelected4.length == 0
    ) {
      document.getElementById("classTitleInput4").classList.add("form-error");
      document.getElementById("classSelectHint4").style.display = "block";
      document.location.href = "#classTitleInput4";
    } else if (
      isItAddDrop[schools.indexOf(userSchool)] &&
      numClasses.length > 4 &&
      classSectionsSelected5.length == 0
    ) {
      document.getElementById("classTitleInput5").classList.add("form-error");
      document.getElementById("classSelectHint5").style.display = "block";
      document.location.href = "#classTitleInput5";
    } else {
      if (buttonClicked == false) {
        setButtonClicked(true);
        window.sessionStorage.removeItem("upgradingFromPreferences");

        // if(referrerPhone!=''){
        //   document.getElementById('phoneInput').classList.add("form-success")
        // }

        window.sessionStorage.setItem("userName", userName);
        if (userPhone.includes("+")) {
          window.sessionStorage.setItem("userPhone", userPhone);
        } else {
          window.sessionStorage.setItem("userPhone", `+${userPhone}`);
        }

        if (referrerPhone != "" && referrerPhone != null) {
          window.sessionStorage.setItem("referrerPhone", referrerPhone);
          window.sessionStorage.setItem("referrerName", referrerName);
        }

        window.sessionStorage.setItem("wantsEmails", wantsEmails);

        if (isItAddDrop[schools.indexOf(userSchool)] == true) {
          window.sessionStorage.setItem("isItAddDrop", "itIs");
          window.sessionStorage.setItem("numClasses", numClasses.length);

          // if(allData.filter(a => (a.classSubject==subjectSelected1)).map(a => a.phone).includes(userPhone)){
          //   window.sessionStorage.setItem('upgradingID1', allDataIDs[allData.findIndex(a => (a.phone == userPhone) && (a.classSubject === subjectSelected1))])
          // }

          // if(allData.filter(a => (a.classSubject==subjectSelected2)).map(a => a.phone).includes(userPhone)){
          //   window.sessionStorage.setItem('upgradingID2', allDataIDs[allData.findIndex(a => (a.phone == userPhone) && (a.classSubject === subjectSelected2))])
          // }

          // if(allData.filter(a => (a.classSubject==subjectSelected3)).map(a => a.phone).includes(userPhone)){
          //   window.sessionStorage.setItem('upgradingID3', allDataIDs[allData.findIndex(a => (a.phone == userPhone) && (a.classSubject === subjectSelected3))])
          // }

          // if(allData.filter(a => (a.classSubject==subjectSelected4)).map(a => a.phone).includes(userPhone)){
          //   window.sessionStorage.setItem('upgradingID4', allDataIDs[allData.findIndex(a => (a.phone == userPhone) && (a.classSubject === subjectSelected4))])
          // }

          // if(allData.filter(a => (a.classSubject==subjectSelected5)).map(a => a.phone).includes(userPhone)){
          //   window.sessionStorage.setItem('upgradingID5', allDataIDs[allData.findIndex(a => (a.phone == userPhone) && (a.classSubject === subjectSelected5))])
          // }

          window.sessionStorage.setItem("classTextInput1", classTextInput1);
          window.sessionStorage.setItem("subjectSelected1", subjectSelected1);
          window.sessionStorage.setItem(
            "classSectionsSelected1",
            classSectionsSelected1
          );
          window.sessionStorage.setItem(
            "labSelected1",
            classes
              .filter((a) => a.subject == subjectSelected1)[0]
              .sections.filter((a) => a.lab == true)
              .map((a) => a.sectionNumber)
              .some((a) => classSectionsSelected1.includes(a))
          );

          if (numClasses.length > 1) {
            window.sessionStorage.setItem("classTextInput2", classTextInput2);
            window.sessionStorage.setItem("subjectSelected2", subjectSelected2);
            window.sessionStorage.setItem(
              "classSectionsSelected2",
              classSectionsSelected2
            );
            window.sessionStorage.setItem(
              "labSelected2",
              classes
                .filter((a) => a.subject == subjectSelected2)[0]
                .sections.filter((a) => a.lab == true)
                .map((a) => a.sectionNumber)
                .some((a) => classSectionsSelected2.includes(a))
            );
          }

          if (numClasses.length > 2) {
            window.sessionStorage.setItem("classTextInput3", classTextInput3);
            window.sessionStorage.setItem("subjectSelected3", subjectSelected3);
            window.sessionStorage.setItem(
              "classSectionsSelected3",
              classSectionsSelected3
            );
            window.sessionStorage.setItem(
              "labSelected3",
              classes
                .filter((a) => a.subject == subjectSelected3)[0]
                .sections.filter((a) => a.lab == true)
                .map((a) => a.sectionNumber)
                .some((a) => classSectionsSelected3.includes(a))
            );
          }

          if (numClasses.length > 3) {
            window.sessionStorage.setItem("classTextInput4", classTextInput4);
            window.sessionStorage.setItem("subjectSelected4", subjectSelected4);
            window.sessionStorage.setItem(
              "classSectionsSelected4",
              classSectionsSelected4
            );
            window.sessionStorage.setItem(
              "labSelected4",
              classes
                .filter((a) => a.subject == subjectSelected4)[0]
                .sections.filter((a) => a.lab == true)
                .map((a) => a.sectionNumber)
                .some((a) => classSectionsSelected4.includes(a))
            );
          }

          if (numClasses.length > 4) {
            window.sessionStorage.setItem("classTextInput5", classTextInput5);
            window.sessionStorage.setItem("subjectSelected5", subjectSelected5);
            window.sessionStorage.setItem(
              "classSectionsSelected5",
              classSectionsSelected5
            );
            window.sessionStorage.setItem(
              "labSelected5",
              classes
                .filter((a) => a.subject == subjectSelected5)[0]
                .sections.filter((a) => a.lab == true)
                .map((a) => a.sectionNumber)
                .some((a) => classSectionsSelected5.includes(a))
            );
          }
        } else {
          window.sessionStorage.setItem("isItAddDrop", "itIsNot");
        }

        // if(
        // !freeUsersArray.includes(userPhone) && (window.sessionStorage.getItem('signupPlan')!='free') && (!freeUsers.map(a => a.phone).includes(userPhone)) && (!freeUsers.map(a => a.phone).includes(`+${userPhone}`))
        // //&& !preOrderedUsers.includes(userPhone)
        // ){

        if (
          !freeUsersArray.includes(userPhone) &&
          !freeUsersArray.includes(`+${userPhone}`) &&
          !subscribersThisQuarter.includes(userPhone) &&
          !subscribersThisQuarter.includes(`+${userPhone}`)
        ) {
          //Logic that is now on the pricing component in the case that onSelectPlanPage is true

          // window.location.href='/pricing'

          fetch(
            `https://us-central1-uchiprereg.cloudfunctions.net/server/create-customer`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET, POST",
                "Access-Control-Allow-Headers": "Content-Type",
                "Access-Control-Max-Age": "3600",
              },
              body: JSON.stringify({
                phone: window.sessionStorage.getItem("userPhone"),
              }),
            }
          )
            .then((res) => {
              return res.json();
            })
            .catch(function () {
              console.log("ahhh1");
            })
            .then((customer) => {
              // console.log(customer.customerID)

              fetch(
                `https://us-central1-uchiprereg.cloudfunctions.net/server/create-checkout-session-100-dollars`,
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, POST",
                    "Access-Control-Allow-Headers": "Content-Type",
                    "Access-Control-Max-Age": "3600",
                  },
                  body: JSON.stringify({
                    cusID: customer.customerID,
                  }),
                }
              )
                .then((res) => {
                  return res.json();
                })
                .then((data) => (window.location.href = data.url))
                .catch(function () {
                  console.log("ahhh2");
                });
            });
        } else {
          window.sessionStorage.setItem("freeUser", "yes");
          window.location.href = "/loading/33oi4p3u29340392";
        }
      }
    }
  }

  window.onload = function () {
    document.getElementById("classTitleInput1").focus();
    if (
      (window.sessionStorage.getItem("userName") == null ||
        window.sessionStorage.getItem("userPhone") == null) &&
      (!freeUsersArray.includes(userPhone) ||
        !freeUsersArray.includes(`+${userPhone}`))
    ) {
      window.location.href = "/";
    }
  };

  return (
    <section
      // {...props}
      className={outerClasses}
    >
      <div
        className="container"
        // style={{width:'40%'}}
      >
        <div
          className={innerClasses}
          //style={{marginLeft:'24px'}}
        >
          <img src="BeeOnSide.jpeg" class="beeOnSignup reveal-scale-up" />

          <div style={{textAlign: "left"}}>
            <h1 class="reveal-from-top">Select Your Classes</h1>
            {/* <h1 
              //className="mt-0 mb-16 reveal-from-top"  
              className="reveal-from-top widthClassSignup"  
              //data-reveal-delay="150"
              style={{wordBreak:'break-word', marginBottom:'0px'}}
              >
              {freeUsersArray.includes(userPhone)||window.sessionStorage.getItem('signupPlan')=='free'?
              <>
                <div style={{display:'inline'}}>
                  Launch Notifications
                </div>
              {freeUsersArray.includes(userPhone)?
                <div style={{display:'block', fontSize:'20px', fontWeight:'normal', backgroundColor:'rgb(25, 224, 15, 0.8)', width:'fit-content', paddingLeft:'0.8rem', paddingRight:'0.8rem', paddingTop:'0.2rem', paddingBottom:'0.2rem', borderRadius:'2rem'}}>
                  Instant (Free Unlimited User)
                </div>
                :
                <div style={{display:'block', fontSize:'20px', fontWeight:'normal', backgroundColor:'rgb(250, 234, 9, 0.8)', width:'fit-content', paddingLeft:'0.8rem', paddingRight:'0.8rem', paddingTop:'0.2rem', paddingBottom:'0.2rem', borderRadius:'2rem', lineHeight:'50px'}}>
                  3 Hour Delayed with SMS Ads
                  <div>
                    (Free Plan)
                  </div> 
                </div>
              }
              </>
              :
              <>
              <div style={{display:'inline'}}>
                  Standard Plan
              </div>
              <ul className="pricing-item-features-list list-reset text-xs mb-34">
                        <li>Track 5 classes / term</li> 
                        <li>Instant SMS notifications</li>
                        <li>Track unlimited class sections</li>
                        <li>Ad free notifications</li>
                        <li style={{flexWrap:'wrap'}}>
                        <div>
                          Money back guarantee
                        </div>
                        <div style={{fontSize:'14px', marginLeft:'42px'}}>
                          *Refund for weeks you don’t receive texts
                        </div>
                        </li>
                        <li>$15/week (3-day free trial)</li>
                        <li>AI professor email drafting tool</li>
              </ul>
              </>
              }
                
                </h1> */}
          </div>

          <div
            // className="tiles-wrap reveal-from-bottom"
            className="reveal-from-bottom"
          >
            <div className="tiles-item" style={{padding: "0px"}}>
              <div className="tiles-item-inner">
                {/* We will text you when a spot in the class opens up. */}
                <form style={{marginTop: "1rem"}}>
                  <fieldset>
                    <div>
                      {numClasses.length >= 0 ? (
                        <div
                          class="tiles-item reveal-from-bottom is-revealed"
                          style={{padding: "0px"}}
                        >
                          <div
                            class="tiles-item-inner has-shadow"
                            style={{padding: "15px"}}
                          >
                            <div>
                              {consentRequired1 == true ? (
                                <div
                                  style={{
                                    color: "red",
                                    float: "right",
                                    fontSize: "25px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  *
                                </div>
                              ) : null}
                              <div
                                style={{marginTop: "0rem", fontWeight: "bold"}}
                              >
                                Class 1
                                {numClasses.length > 1 ? (
                                  <div
                                    id={"removeButton" + 1}
                                    style={{
                                      float: "right",
                                      marginRight: "0rem",
                                      marginLeft: "auto",
                                      fontStyle: "bold",
                                      textAlign: "center",
                                      borderColor: "rgb(233,236,248)",
                                      borderStyle: "solid",
                                      borderWidth: "1px",
                                      fontWeight: "bold",
                                      cursor: "pointer",
                                      width: "2rem",
                                      display: "inline-block",
                                    }}
                                    className="has-shadow noSelectText"
                                    onClick={(event) => {
                                      removeAClass(event);
                                    }}
                                  >
                                    —
                                  </div>
                                ) : null}
                              </div>

                              <input
                                id="classTitleInput1"
                                type="text"
                                autocomplete="off"
                                placeholder="Class title"
                                onKeyDown={selectFirstOption1}
                                onMouseDown={() => {
                                  setClassTextInput1("");
                                  setClassSelected1([]);
                                  setSubjectSelected1("");
                                  setClassSectionsSelected1([]);
                                  setFuseSearch1([]);
                                  setShowFuseList1(true);
                                  setConsentRequired1(false);
                                }}
                                value={classTextInput1}
                                onChange={changeClassTitleInput1}
                                className="form-input"
                                style={{marginTop: "0.5rem"}}
                              />
                              {subjectSelected1 != "" &&
                              currentlyTracking.filter((x) =>
                                subjectSelected1.includes(x)
                              ).length == 0 ? (
                                <div
                                  style={{
                                    display: "inline",
                                    marginTop: "0.5rem",
                                    color: "rgb(218,35,255)",
                                  }}
                                >
                                  No one is tracking this class
                                </div>
                              ) : currentlyTracking.filter((x) =>
                                  subjectSelected1.includes(x)
                                ).length > 0 ? (
                                <div
                                  style={{
                                    marginTop: "0.5rem",
                                    fontSize: "1rem",
                                    color: "rgb(218,35,255)",
                                  }}
                                >
                                  {currentlyTracking.filter((x) =>
                                    subjectSelected1.includes(x)
                                  ).length == 1 ? (
                                    <div
                                      style={{
                                        display: "inline",
                                        color: "rgb(13, 98, 255)",
                                      }}
                                    >
                                      1 person is tracking this class
                                    </div>
                                  ) : currentlyTracking.filter((x) =>
                                      subjectSelected1.includes(x)
                                    ).length > 10 ? (
                                    <div
                                      style={{
                                        display: "inline",
                                        color: "rgb(246, 142, 23)",
                                      }}
                                    >
                                      {
                                        currentlyTracking.filter((x) =>
                                          subjectSelected1.includes(x)
                                        ).length
                                      }{" "}
                                      people are tracking this class
                                    </div>
                                  ) : currentlyTracking.filter((x) =>
                                      subjectSelected1.includes(x)
                                    ).length > 5 ? (
                                    <div
                                      style={{
                                        display: "inline",
                                        color: "rgb(13, 98, 255)",
                                      }}
                                    >
                                      {
                                        currentlyTracking.filter((x) =>
                                          subjectSelected1.includes(x)
                                        ).length
                                      }{" "}
                                      people are tracking this class
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        display: "inline",
                                        color: "rgb(0,194,169)",
                                      }}
                                    >
                                      {
                                        currentlyTracking.filter((x) =>
                                          subjectSelected1.includes(x)
                                        ).length
                                      }{" "}
                                      people are tracking this class
                                    </div>
                                  )}
                                </div>
                              ) : null}

                              {fuseSearch1 != classTitlesNoDuplicates &&
                              fuseSearch1.length != 0 &&
                              showFuseList1 == true ? (
                                <div>
                                  <div style={{height: "0.75rem"}}></div>
                                  {fuseSearch1.map((course, courseIndex) => {
                                    if (courseIndex < 7) {
                                      return (
                                        <div
                                          onClick={(event) => {
                                            clickFuseElement1(course.item);
                                          }}
                                          id={course.item}
                                          class="selectClass"
                                        >
                                          {course.item}
                                        </div>
                                      );
                                    }
                                  })}
                                </div>
                              ) : null}
                            </div>
                            <div
                              style={{
                                display:
                                  classSelected1.length != 0 ? "block" : "none",
                                marginTop: "0.5rem",
                                fontWeight: "bold",
                              }}
                              class="noSelectText"
                            >
                              Select the section(s) you want
                            </div>
                            {classSelected1.map((course, courseIndex) => {
                              return (
                                <div>
                                  {course.sections.map(
                                    (section, sectionIndex) => {
                                      return (
                                        <div
                                          id={section.sectionNumber}
                                          class="slotSelect"
                                          onMouseDown={clickClassSection1}
                                          onMouseOver={hoverClassSection1}
                                          style={{
                                            backgroundColor:
                                              classSectionsSelected1.includes(
                                                section.sectionNumber
                                              )
                                                ? "#3D5AFE"
                                                : "white",
                                            color:
                                              classSectionsSelected1.includes(
                                                section.sectionNumber
                                              )
                                                ? "white"
                                                : "#575E86",
                                            padding: "0.2rem",
                                            marginTop: "0.3rem",
                                            borderStyle: "solid",
                                            borderColor: "black",
                                            borderWidth: "1px",
                                          }}
                                        >
                                          {section.lab == true ? (
                                            <div
                                              style={{
                                                color: "#33CEFF",
                                                float: "right",
                                                fontSize: "16px",
                                                fontWeight: "",
                                                marginRight: "0.2rem",
                                              }}
                                            >
                                              Lab
                                            </div>
                                          ) : null}
                                          {section.sectionProf.length != 0 ? (
                                            <div
                                              class="noSelectText"
                                              style={{
                                                zIndex: 2,
                                                pointerEvents: "none",
                                              }}
                                            >
                                              {section.sectionName.includes(
                                                "Section"
                                              ) ? (
                                                <div
                                                  class="noSelectText"
                                                  style={{
                                                    zIndex: 1,
                                                    pointerEvents: "none",
                                                  }}
                                                >
                                                  {section.sectionName} (
                                                  {section.sectionNumber})
                                                </div>
                                              ) : (
                                                <div
                                                  class="noSelectText"
                                                  style={{
                                                    zIndex: 1,
                                                    pointerEvents: "none",
                                                  }}
                                                >
                                                  Section {section.sectionName}{" "}
                                                  ({section.sectionNumber})
                                                </div>
                                              )}
                                              <div
                                                class="noSelectText"
                                                style={{
                                                  zIndex: 1,
                                                  pointerEvents: "none",
                                                }}
                                              >
                                                {section.sectionProf.map(
                                                  (prof, profIndex) => {
                                                    return (
                                                      <div
                                                        style={{
                                                          pointerEvents: "none",
                                                          display: "inline",
                                                        }}
                                                      >
                                                        {section.sectionProf
                                                          .length == 1 ? (
                                                          <div
                                                            style={{
                                                              pointerEvents:
                                                                "none",
                                                              display: "inline",
                                                            }}
                                                          >
                                                            {prof}
                                                          </div>
                                                        ) : profIndex !=
                                                            section.sectionProf
                                                              .length -
                                                              1 &&
                                                          section.sectionProf
                                                            .length == 2 ? (
                                                          <div
                                                            style={{
                                                              pointerEvents:
                                                                "none",
                                                              display: "inline",
                                                            }}
                                                          >
                                                            {prof}&nbsp;
                                                          </div>
                                                        ) : profIndex !=
                                                            section.sectionProf
                                                              .length -
                                                              1 &&
                                                          section.sectionProf
                                                            .length > 2 ? (
                                                          <div
                                                            style={{
                                                              pointerEvents:
                                                                "none",
                                                              display: "inline",
                                                            }}
                                                          >
                                                            {prof},&nbsp;
                                                          </div>
                                                        ) : (
                                                          <div
                                                            style={{
                                                              pointerEvents:
                                                                "none",
                                                              display: "inline",
                                                            }}
                                                          >
                                                            and&nbsp;{prof}
                                                          </div>
                                                        )}
                                                      </div>
                                                    );
                                                  }
                                                )}
                                              </div>
                                            </div>
                                          ) : section.sectionName.includes(
                                              "Section"
                                            ) ? (
                                            <div
                                              class="noSelectText"
                                              style={{
                                                zIndex: 2,
                                                pointerEvents: "none",
                                              }}
                                            >
                                              {section.sectionName} (
                                              {section.sectionNumber})
                                            </div>
                                          ) : (
                                            <div
                                              class="noSelectText"
                                              style={{
                                                zIndex: 2,
                                                pointerEvents: "none",
                                              }}
                                            >
                                              Section {section.sectionName} (
                                              {section.sectionNumber})
                                            </div>
                                          )}
                                          {section.sectionTime != null ? (
                                            <div
                                              class="noSelectText"
                                              style={{
                                                zIndex: 2,
                                                pointerEvents: "none",
                                              }}
                                            >
                                              {section.sectionTime}
                                            </div>
                                          ) : null}
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              );

                              function clickClassSection1(event) {
                                document
                                  .getElementById("classTitleInput1")
                                  .classList.remove("form-error");
                                document.getElementById(
                                  "classSelectHint1"
                                ).style.display = "none";
                                if (
                                  !classSectionsSelected1.includes(
                                    event.target.id
                                  )
                                ) {
                                  document.getElementById(
                                    event.target.id
                                  ).style.backgroundColor = "#3D5AFE";
                                  document.getElementById(
                                    event.target.id
                                  ).style.color = "white";
                                  setClassSectionsSelected1([
                                    ...classSectionsSelected1,
                                    event.target.id,
                                  ]);
                                } else {
                                  document.getElementById(
                                    event.target.id
                                  ).style.backgroundColor = "white";
                                  document.getElementById(
                                    event.target.id
                                  ).style.color = "#575E86";
                                  setClassSectionsSelected1([
                                    ...classSectionsSelected1.slice(
                                      0,
                                      classSectionsSelected1.indexOf(
                                        event.target.id
                                      )
                                    ),
                                    ...classSectionsSelected1.slice(
                                      classSectionsSelected1.indexOf(
                                        event.target.id
                                      ) + 1,
                                      classSectionsSelected1.length
                                    ),
                                  ]);
                                }
                              }

                              function hoverClassSection1(event) {
                                const listener = (event) => {
                                  mouseDown = true;
                                };
                                const listener2 = (event) => {
                                  mouseDown = false;
                                };
                                document.addEventListener(
                                  "mousedown",
                                  listener
                                );
                                document.addEventListener("mouseup", listener2);

                                if (mouseDown == true) {
                                  document
                                    .getElementById("classTitleInput1")
                                    .classList.remove("form-error");
                                  document.getElementById(
                                    "classSelectHint1"
                                  ).style.display = "none";
                                  if (
                                    !classSectionsSelected1.includes(
                                      event.target.id
                                    )
                                  ) {
                                    document.getElementById(
                                      event.target.id
                                    ).style.backgroundColor = "#3D5AFE";
                                    document.getElementById(
                                      event.target.id
                                    ).style.color = "white";
                                    setClassSectionsSelected1([
                                      ...classSectionsSelected1,
                                      event.target.id,
                                    ]);
                                  } else {
                                    document.getElementById(
                                      event.target.id
                                    ).style.backgroundColor = "white";
                                    document.getElementById(
                                      event.target.id
                                    ).style.color = "#575E86";
                                    setClassSectionsSelected1([
                                      ...classSectionsSelected1.slice(
                                        0,
                                        classSectionsSelected1.indexOf(
                                          event.target.id
                                        )
                                      ),
                                      ...classSectionsSelected1.slice(
                                        classSectionsSelected1.indexOf(
                                          event.target.id
                                        ) + 1,
                                        classSectionsSelected1.length
                                      ),
                                    ]);
                                  }
                                }
                              }
                            })}
                            <div
                              id="classSelectHint1"
                              style={{display: "none"}}
                              class="form-hint text-color-error"
                            >
                              Please select the class section(s) you need
                            </div>
                            <div
                              id="classAlreadyHint1"
                              style={{display: "none"}}
                              class="form-hint text-color-error"
                            >
                              You have already gotten notifications for this
                              class.{" "}
                              <a href={`/preferences#${userPhone}`}>Log in</a>{" "}
                              to update your preferences
                            </div>
                          </div>
                        </div>
                      ) : null}

                      {numClasses.length > 1 ? (
                        <div
                          class="tiles-item reveal-from-bottom is-revealed"
                          style={{padding: "0px", marginTop: "1rem"}}
                        >
                          <div
                            class="tiles-item-inner has-shadow"
                            style={{padding: "15px"}}
                          >
                            <div>
                              {consentRequired2 == true ? (
                                <div
                                  style={{
                                    color: "red",
                                    float: "right",
                                    fontSize: "25px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  *
                                </div>
                              ) : null}
                              <div
                                style={{marginTop: "0rem", fontWeight: "bold"}}
                              >
                                Class 2
                                <div
                                  id={"removeButton" + 10}
                                  style={{
                                    float: "right",
                                    marginRight: "0rem",
                                    marginLeft: "auto",
                                    fontStyle: "bold",
                                    textAlign: "center",
                                    borderColor: "rgb(233,236,248)",
                                    borderStyle: "solid",
                                    borderWidth: "1px",
                                    fontWeight: "bold",
                                    cursor: "pointer",
                                    width: "2rem",
                                    display: "inline-block",
                                  }}
                                  className="has-shadow noSelectText"
                                  onClick={(event) => {
                                    removeAClass(event);
                                  }}
                                >
                                  —
                                </div>
                              </div>

                              <input
                                id="classTitleInput2"
                                type="text"
                                autocomplete="off"
                                placeholder="Class title"
                                onKeyDown={selectFirstOption2}
                                onMouseDown={() => {
                                  setClassTextInput2("");
                                  setClassSelected2([]);
                                  setSubjectSelected2("");
                                  setClassSectionsSelected2([]);
                                  setFuseSearch2([]);
                                  setShowFuseList2(true);
                                  setConsentRequired2(false);
                                }}
                                value={classTextInput2}
                                onChange={changeClassTitleInput2}
                                className="form-input"
                                style={{marginTop: "0.5rem"}}
                              />

                              {subjectSelected2 != "" &&
                              currentlyTracking.filter((x) =>
                                subjectSelected2.includes(x)
                              ).length == 0 ? (
                                <div
                                  style={{
                                    display: "inline",
                                    marginTop: "0.5rem",
                                    color: "rgb(218,35,255)",
                                  }}
                                >
                                  No one is tracking this class
                                </div>
                              ) : currentlyTracking.filter((x) =>
                                  subjectSelected2.includes(x)
                                ).length > 0 ? (
                                <div
                                  style={{
                                    marginTop: "0.5rem",
                                    fontSize: "1rem",
                                    color: "rgb(218,35,255)",
                                  }}
                                >
                                  {currentlyTracking.filter((x) =>
                                    subjectSelected2.includes(x)
                                  ).length == 1 ? (
                                    <div
                                      style={{
                                        display: "inline",
                                        color: "rgb(13, 98, 255)",
                                      }}
                                    >
                                      1 person is tracking this class
                                    </div>
                                  ) : currentlyTracking.filter((x) =>
                                      subjectSelected2.includes(x)
                                    ).length > 10 ? (
                                    <div
                                      style={{
                                        display: "inline",
                                        color: "rgb(246, 142, 23)",
                                      }}
                                    >
                                      {
                                        currentlyTracking.filter((x) =>
                                          subjectSelected2.includes(x)
                                        ).length
                                      }{" "}
                                      people are tracking this class
                                    </div>
                                  ) : currentlyTracking.filter((x) =>
                                      subjectSelected2.includes(x)
                                    ).length > 5 ? (
                                    <div
                                      style={{
                                        display: "inline",
                                        color: "rgb(13, 98, 255)",
                                      }}
                                    >
                                      {
                                        currentlyTracking.filter((x) =>
                                          subjectSelected2.includes(x)
                                        ).length
                                      }{" "}
                                      people are tracking this class
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        display: "inline",
                                        color: "rgb(0,194,169)",
                                      }}
                                    >
                                      {
                                        currentlyTracking.filter((x) =>
                                          subjectSelected2.includes(x)
                                        ).length
                                      }{" "}
                                      people are tracking this class
                                    </div>
                                  )}
                                </div>
                              ) : null}

                              {fuseSearch2 != classTitlesNoDuplicates &&
                              fuseSearch2.length != 0 &&
                              showFuseList2 == true ? (
                                <div>
                                  <div style={{height: "0.75rem"}}></div>
                                  {fuseSearch2.map((course, courseIndex) => {
                                    if (courseIndex < 7) {
                                      return (
                                        <div
                                          onClick={(event) => {
                                            clickFuseElement2(course.item);
                                          }}
                                          id={course.item}
                                          class="selectClass"
                                        >
                                          {course.item}
                                        </div>
                                      );
                                    }
                                  })}
                                </div>
                              ) : null}
                            </div>

                            <div
                              style={{
                                display:
                                  classSelected2.length != 0 ? "block" : "none",
                                marginTop: "0.5rem",
                                fontWeight: "bold",
                              }}
                              class="noSelectText"
                            >
                              Select the section(s) you want
                            </div>
                            {classSelected2.map((course, courseIndex) => {
                              return (
                                <div>
                                  {course.sections.map(
                                    (section, sectionIndex) => {
                                      return (
                                        <div
                                          id={section.sectionNumber}
                                          class="slotSelect"
                                          onMouseDown={clickClassSection2}
                                          onMouseOver={hoverClassSection2}
                                          style={{
                                            backgroundColor:
                                              classSectionsSelected2.includes(
                                                section.sectionNumber
                                              )
                                                ? "#3D5AFE"
                                                : "white",
                                            color:
                                              classSectionsSelected2.includes(
                                                section.sectionNumber
                                              )
                                                ? "white"
                                                : "#575E86",
                                            padding: "0.2rem",
                                            marginTop: "0.3rem",
                                            borderStyle: "solid",
                                            borderColor: "black",
                                            borderWidth: "1px",
                                          }}
                                        >
                                          {section.lab == true ? (
                                            <div
                                              style={{
                                                color: "#33CEFF",
                                                float: "right",
                                                fontSize: "16px",
                                                fontWeight: "",
                                                marginRight: "0.2rem",
                                              }}
                                            >
                                              Lab
                                            </div>
                                          ) : null}
                                          {section.sectionProf.length != 0 ? (
                                            <div
                                              class="noSelectText"
                                              style={{
                                                zIndex: 2,
                                                pointerEvents: "none",
                                              }}
                                            >
                                              {section.sectionName.includes(
                                                "Section"
                                              ) ? (
                                                <div
                                                  class="noSelectText"
                                                  style={{
                                                    zIndex: 1,
                                                    pointerEvents: "none",
                                                  }}
                                                >
                                                  {section.sectionName} (
                                                  {section.sectionNumber})
                                                </div>
                                              ) : (
                                                <div
                                                  class="noSelectText"
                                                  style={{
                                                    zIndex: 1,
                                                    pointerEvents: "none",
                                                  }}
                                                >
                                                  Section {section.sectionName}{" "}
                                                  ({section.sectionNumber})
                                                </div>
                                              )}
                                              <div
                                                class="noSelectText"
                                                style={{
                                                  zIndex: 1,
                                                  pointerEvents: "none",
                                                }}
                                              >
                                                {section.sectionProf.map(
                                                  (prof, profIndex) => {
                                                    return (
                                                      <div
                                                        style={{
                                                          pointerEvents: "none",
                                                          display: "inline",
                                                        }}
                                                      >
                                                        {section.sectionProf
                                                          .length == 1 ? (
                                                          <div
                                                            style={{
                                                              pointerEvents:
                                                                "none",
                                                              display: "inline",
                                                            }}
                                                          >
                                                            {prof}
                                                          </div>
                                                        ) : profIndex !=
                                                            section.sectionProf
                                                              .length -
                                                              1 &&
                                                          section.sectionProf
                                                            .length == 2 ? (
                                                          <div
                                                            style={{
                                                              pointerEvents:
                                                                "none",
                                                              display: "inline",
                                                            }}
                                                          >
                                                            {prof}&nbsp;
                                                          </div>
                                                        ) : profIndex !=
                                                            section.sectionProf
                                                              .length -
                                                              1 &&
                                                          section.sectionProf
                                                            .length > 2 ? (
                                                          <div
                                                            style={{
                                                              pointerEvents:
                                                                "none",
                                                              display: "inline",
                                                            }}
                                                          >
                                                            {prof},&nbsp;
                                                          </div>
                                                        ) : (
                                                          <div
                                                            style={{
                                                              pointerEvents:
                                                                "none",
                                                              display: "inline",
                                                            }}
                                                          >
                                                            and&nbsp;{prof}
                                                          </div>
                                                        )}
                                                      </div>
                                                    );
                                                  }
                                                )}
                                              </div>
                                            </div>
                                          ) : section.sectionName.includes(
                                              "Section"
                                            ) ? (
                                            <div
                                              class="noSelectText"
                                              style={{
                                                zIndex: 2,
                                                pointerEvents: "none",
                                              }}
                                            >
                                              {section.sectionName} (
                                              {section.sectionNumber})
                                            </div>
                                          ) : (
                                            <div
                                              class="noSelectText"
                                              style={{
                                                zIndex: 2,
                                                pointerEvents: "none",
                                              }}
                                            >
                                              Section {section.sectionName} (
                                              {section.sectionNumber})
                                            </div>
                                          )}
                                          {section.sectionTime != null ? (
                                            <div
                                              class="noSelectText"
                                              style={{
                                                zIndex: 2,
                                                pointerEvents: "none",
                                              }}
                                            >
                                              {section.sectionTime}
                                            </div>
                                          ) : null}
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              );

                              function clickClassSection2(event) {
                                document
                                  .getElementById("classTitleInput2")
                                  .classList.remove("form-error");
                                document.getElementById(
                                  "classSelectHint2"
                                ).style.display = "none";
                                if (
                                  !classSectionsSelected2.includes(
                                    event.target.id
                                  )
                                ) {
                                  document.getElementById(
                                    event.target.id
                                  ).style.backgroundColor = "#3D5AFE";
                                  document.getElementById(
                                    event.target.id
                                  ).style.color = "white";
                                  setClassSectionsSelected2([
                                    ...classSectionsSelected2,
                                    event.target.id,
                                  ]);
                                } else {
                                  document.getElementById(
                                    event.target.id
                                  ).style.backgroundColor = "white";
                                  document.getElementById(
                                    event.target.id
                                  ).style.color = "#575E86";
                                  setClassSectionsSelected2([
                                    ...classSectionsSelected2.slice(
                                      0,
                                      classSectionsSelected2.indexOf(
                                        event.target.id
                                      )
                                    ),
                                    ...classSectionsSelected2.slice(
                                      classSectionsSelected2.indexOf(
                                        event.target.id
                                      ) + 1,
                                      classSectionsSelected2.length
                                    ),
                                  ]);
                                }
                              }

                              function hoverClassSection2(event) {
                                const listener = (event) => {
                                  mouseDown = true;
                                };
                                const listener2 = (event) => {
                                  mouseDown = false;
                                };
                                document.addEventListener(
                                  "mousedown",
                                  listener
                                );
                                document.addEventListener("mouseup", listener2);

                                if (mouseDown == true) {
                                  document
                                    .getElementById("classTitleInput2")
                                    .classList.remove("form-error");
                                  document.getElementById(
                                    "classSelectHint2"
                                  ).style.display = "none";
                                  if (
                                    !classSectionsSelected2.includes(
                                      event.target.id
                                    )
                                  ) {
                                    document.getElementById(
                                      event.target.id
                                    ).style.backgroundColor = "#3D5AFE";
                                    document.getElementById(
                                      event.target.id
                                    ).style.color = "white";
                                    setClassSectionsSelected2([
                                      ...classSectionsSelected2,
                                      event.target.id,
                                    ]);
                                  } else {
                                    document.getElementById(
                                      event.target.id
                                    ).style.backgroundColor = "white";
                                    document.getElementById(
                                      event.target.id
                                    ).style.color = "#575E86";
                                    setClassSectionsSelected2([
                                      ...classSectionsSelected2.slice(
                                        0,
                                        classSectionsSelected2.indexOf(
                                          event.target.id
                                        )
                                      ),
                                      ...classSectionsSelected2.slice(
                                        classSectionsSelected2.indexOf(
                                          event.target.id
                                        ) + 1,
                                        classSectionsSelected2.length
                                      ),
                                    ]);
                                  }
                                }
                              }
                            })}
                            <div
                              id="classSelectHint2"
                              style={{display: "none"}}
                              class="form-hint text-color-error"
                            >
                              Please select the class section(s) you need
                            </div>
                            <div
                              id="classAlreadyHint2"
                              style={{display: "none"}}
                              class="form-hint text-color-error"
                            >
                              You have already gotten notifications for this
                              class.{" "}
                              <a href={`/preferences#${userPhone}`}>Log in</a>{" "}
                              to update your preferences
                            </div>
                          </div>
                        </div>
                      ) : null}

                      {numClasses.length > 2 ? (
                        <div
                          class="tiles-item reveal-from-bottom is-revealed"
                          style={{padding: "0px", marginTop: "1rem"}}
                        >
                          <div
                            class="tiles-item-inner has-shadow"
                            style={{padding: "15px"}}
                          >
                            <div>
                              {consentRequired3 == true ? (
                                <div
                                  style={{
                                    color: "red",
                                    float: "right",
                                    fontSize: "25px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  *
                                </div>
                              ) : null}
                              <div
                                style={{marginTop: "0rem", fontWeight: "bold"}}
                              >
                                Class 3
                                <div
                                  id={"removeButton" + 10}
                                  style={{
                                    float: "right",
                                    marginRight: "0rem",
                                    marginLeft: "auto",
                                    fontStyle: "bold",
                                    textAlign: "center",
                                    borderColor: "rgb(233,236,248)",
                                    borderStyle: "solid",
                                    borderWidth: "1px",
                                    fontWeight: "bold",
                                    cursor: "pointer",
                                    width: "2rem",
                                    display: "inline-block",
                                  }}
                                  className="has-shadow noSelectText"
                                  onClick={(event) => {
                                    removeAClass(event);
                                  }}
                                >
                                  —
                                </div>
                              </div>

                              <input
                                id="classTitleInput3"
                                type="text"
                                autocomplete="off"
                                placeholder="Class title"
                                onKeyDown={selectFirstOption3}
                                onMouseDown={() => {
                                  setClassTextInput3("");
                                  setClassSelected3([]);
                                  setSubjectSelected3("");
                                  setClassSectionsSelected3([]);
                                  setFuseSearch3([]);
                                  setShowFuseList3(true);
                                  setConsentRequired3(false);
                                }}
                                value={classTextInput3}
                                onChange={changeClassTitleInput3}
                                className="form-input"
                                style={{marginTop: "0.5rem"}}
                              />

                              {subjectSelected3 != "" &&
                              currentlyTracking.filter((x) =>
                                subjectSelected3.includes(x)
                              ).length == 0 ? (
                                <div
                                  style={{
                                    display: "inline",
                                    marginTop: "0.5rem",
                                    color: "rgb(218,35,255)",
                                  }}
                                >
                                  No one is tracking this class
                                </div>
                              ) : currentlyTracking.filter((x) =>
                                  subjectSelected3.includes(x)
                                ).length > 0 ? (
                                <div
                                  style={{
                                    marginTop: "0.5rem",
                                    fontSize: "1rem",
                                    color: "rgb(218,35,255)",
                                  }}
                                >
                                  {currentlyTracking.filter((x) =>
                                    subjectSelected3.includes(x)
                                  ).length == 1 ? (
                                    <div
                                      style={{
                                        display: "inline",
                                        color: "rgb(13, 98, 255)",
                                      }}
                                    >
                                      1 person is tracking this class
                                    </div>
                                  ) : currentlyTracking.filter((x) =>
                                      subjectSelected3.includes(x)
                                    ).length > 10 ? (
                                    <div
                                      style={{
                                        display: "inline",
                                        color: "rgb(246, 142, 23)",
                                      }}
                                    >
                                      {
                                        currentlyTracking.filter((x) =>
                                          subjectSelected3.includes(x)
                                        ).length
                                      }{" "}
                                      people are tracking this class
                                    </div>
                                  ) : currentlyTracking.filter((x) =>
                                      subjectSelected3.includes(x)
                                    ).length > 5 ? (
                                    <div
                                      style={{
                                        display: "inline",
                                        color: "rgb(13, 98, 255)",
                                      }}
                                    >
                                      {
                                        currentlyTracking.filter((x) =>
                                          subjectSelected3.includes(x)
                                        ).length
                                      }{" "}
                                      people are tracking this class
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        display: "inline",
                                        color: "rgb(0,194,169)",
                                      }}
                                    >
                                      {
                                        currentlyTracking.filter((x) =>
                                          subjectSelected3.includes(x)
                                        ).length
                                      }{" "}
                                      people are tracking this class
                                    </div>
                                  )}
                                </div>
                              ) : null}

                              {fuseSearch3 != classTitlesNoDuplicates &&
                              fuseSearch3.length != 0 &&
                              showFuseList3 == true ? (
                                <div>
                                  <div style={{height: "0.75rem"}}></div>
                                  {fuseSearch3.map((course, courseIndex) => {
                                    if (courseIndex < 7) {
                                      return (
                                        <div
                                          onClick={(event) => {
                                            clickFuseElement3(course.item);
                                          }}
                                          id={course.item}
                                          class="selectClass"
                                        >
                                          {course.item}
                                        </div>
                                      );
                                    }
                                  })}
                                </div>
                              ) : null}
                            </div>

                            <div
                              style={{
                                display:
                                  classSelected3.length != 0 ? "block" : "none",
                                marginTop: "0.5rem",
                                fontWeight: "bold",
                              }}
                              class="noSelectText"
                            >
                              Select the section(s) you want
                            </div>
                            {classSelected3.map((course, courseIndex) => {
                              return (
                                <div>
                                  {course.sections.map(
                                    (section, sectionIndex) => {
                                      return (
                                        <div
                                          id={section.sectionNumber}
                                          class="slotSelect"
                                          onMouseDown={clickClassSection3}
                                          onMouseOver={hoverClassSection3}
                                          style={{
                                            backgroundColor:
                                              classSectionsSelected3.includes(
                                                section.sectionNumber
                                              )
                                                ? "#3D5AFE"
                                                : "white",
                                            color:
                                              classSectionsSelected3.includes(
                                                section.sectionNumber
                                              )
                                                ? "white"
                                                : "#575E86",
                                            padding: "0.2rem",
                                            marginTop: "0.3rem",
                                            borderStyle: "solid",
                                            borderColor: "black",
                                            borderWidth: "1px",
                                          }}
                                        >
                                          {section.lab == true ? (
                                            <div
                                              style={{
                                                color: "#33CEFF",
                                                float: "right",
                                                fontSize: "16px",
                                                fontWeight: "",
                                                marginRight: "0.2rem",
                                              }}
                                            >
                                              Lab
                                            </div>
                                          ) : null}
                                          {section.sectionProf.length != 0 ? (
                                            <div
                                              class="noSelectText"
                                              style={{
                                                zIndex: 2,
                                                pointerEvents: "none",
                                              }}
                                            >
                                              {section.sectionName.includes(
                                                "Section"
                                              ) ? (
                                                <div
                                                  class="noSelectText"
                                                  style={{
                                                    zIndex: 1,
                                                    pointerEvents: "none",
                                                  }}
                                                >
                                                  {section.sectionName} (
                                                  {section.sectionNumber})
                                                </div>
                                              ) : (
                                                <div
                                                  class="noSelectText"
                                                  style={{
                                                    zIndex: 1,
                                                    pointerEvents: "none",
                                                  }}
                                                >
                                                  Section {section.sectionName}{" "}
                                                  ({section.sectionNumber})
                                                </div>
                                              )}
                                              <div
                                                class="noSelectText"
                                                style={{
                                                  zIndex: 1,
                                                  pointerEvents: "none",
                                                }}
                                              >
                                                {section.sectionProf.map(
                                                  (prof, profIndex) => {
                                                    return (
                                                      <div
                                                        style={{
                                                          pointerEvents: "none",
                                                          display: "inline",
                                                        }}
                                                      >
                                                        {section.sectionProf
                                                          .length == 1 ? (
                                                          <div
                                                            style={{
                                                              pointerEvents:
                                                                "none",
                                                              display: "inline",
                                                            }}
                                                          >
                                                            {prof}
                                                          </div>
                                                        ) : profIndex !=
                                                            section.sectionProf
                                                              .length -
                                                              1 &&
                                                          section.sectionProf
                                                            .length == 2 ? (
                                                          <div
                                                            style={{
                                                              pointerEvents:
                                                                "none",
                                                              display: "inline",
                                                            }}
                                                          >
                                                            {prof}&nbsp;
                                                          </div>
                                                        ) : profIndex !=
                                                            section.sectionProf
                                                              .length -
                                                              1 &&
                                                          section.sectionProf
                                                            .length > 2 ? (
                                                          <div
                                                            style={{
                                                              pointerEvents:
                                                                "none",
                                                              display: "inline",
                                                            }}
                                                          >
                                                            {prof},&nbsp;
                                                          </div>
                                                        ) : (
                                                          <div
                                                            style={{
                                                              pointerEvents:
                                                                "none",
                                                              display: "inline",
                                                            }}
                                                          >
                                                            and&nbsp;{prof}
                                                          </div>
                                                        )}
                                                      </div>
                                                    );
                                                  }
                                                )}
                                              </div>
                                            </div>
                                          ) : section.sectionName.includes(
                                              "Section"
                                            ) ? (
                                            <div
                                              class="noSelectText"
                                              style={{
                                                zIndex: 2,
                                                pointerEvents: "none",
                                              }}
                                            >
                                              {section.sectionName} (
                                              {section.sectionNumber})
                                            </div>
                                          ) : (
                                            <div
                                              class="noSelectText"
                                              style={{
                                                zIndex: 2,
                                                pointerEvents: "none",
                                              }}
                                            >
                                              Section {section.sectionName} (
                                              {section.sectionNumber})
                                            </div>
                                          )}
                                          {section.sectionTime != null ? (
                                            <div
                                              class="noSelectText"
                                              style={{
                                                zIndex: 2,
                                                pointerEvents: "none",
                                              }}
                                            >
                                              {section.sectionTime}
                                            </div>
                                          ) : null}
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              );

                              function clickClassSection3(event) {
                                document
                                  .getElementById("classTitleInput3")
                                  .classList.remove("form-error");
                                document.getElementById(
                                  "classSelectHint3"
                                ).style.display = "none";
                                if (
                                  !classSectionsSelected3.includes(
                                    event.target.id
                                  )
                                ) {
                                  document.getElementById(
                                    event.target.id
                                  ).style.backgroundColor = "#3D5AFE";
                                  document.getElementById(
                                    event.target.id
                                  ).style.color = "white";
                                  setClassSectionsSelected3([
                                    ...classSectionsSelected3,
                                    event.target.id,
                                  ]);
                                } else {
                                  document.getElementById(
                                    event.target.id
                                  ).style.backgroundColor = "white";
                                  document.getElementById(
                                    event.target.id
                                  ).style.color = "#575E86";
                                  setClassSectionsSelected3([
                                    ...classSectionsSelected3.slice(
                                      0,
                                      classSectionsSelected3.indexOf(
                                        event.target.id
                                      )
                                    ),
                                    ...classSectionsSelected3.slice(
                                      classSectionsSelected3.indexOf(
                                        event.target.id
                                      ) + 1,
                                      classSectionsSelected3.length
                                    ),
                                  ]);
                                }
                              }

                              function hoverClassSection3(event) {
                                const listener = (event) => {
                                  mouseDown = true;
                                };
                                const listener2 = (event) => {
                                  mouseDown = false;
                                };
                                document.addEventListener(
                                  "mousedown",
                                  listener
                                );
                                document.addEventListener("mouseup", listener2);

                                if (mouseDown == true) {
                                  document
                                    .getElementById("classTitleInput3")
                                    .classList.remove("form-error");
                                  document.getElementById(
                                    "classSelectHint3"
                                  ).style.display = "none";
                                  if (
                                    !classSectionsSelected3.includes(
                                      event.target.id
                                    )
                                  ) {
                                    document.getElementById(
                                      event.target.id
                                    ).style.backgroundColor = "#3D5AFE";
                                    document.getElementById(
                                      event.target.id
                                    ).style.color = "white";
                                    setClassSectionsSelected3([
                                      ...classSectionsSelected3,
                                      event.target.id,
                                    ]);
                                  } else {
                                    document.getElementById(
                                      event.target.id
                                    ).style.backgroundColor = "white";
                                    document.getElementById(
                                      event.target.id
                                    ).style.color = "#575E86";
                                    setClassSectionsSelected3([
                                      ...classSectionsSelected3.slice(
                                        0,
                                        classSectionsSelected3.indexOf(
                                          event.target.id
                                        )
                                      ),
                                      ...classSectionsSelected3.slice(
                                        classSectionsSelected3.indexOf(
                                          event.target.id
                                        ) + 1,
                                        classSectionsSelected3.length
                                      ),
                                    ]);
                                  }
                                }
                              }
                            })}
                            <div
                              id="classSelectHint3"
                              style={{display: "none"}}
                              class="form-hint text-color-error"
                            >
                              Please select the class section(s) you need
                            </div>
                            <div
                              id="classAlreadyHint3"
                              style={{display: "none"}}
                              class="form-hint text-color-error"
                            >
                              You have already gotten notifications for this
                              class.{" "}
                              <a href={`/preferences#${userPhone}`}>Log in</a>{" "}
                              to update your preferences
                            </div>
                          </div>
                        </div>
                      ) : null}

                      {numClasses.length > 3 ? (
                        <div
                          class="tiles-item reveal-from-bottom is-revealed"
                          style={{padding: "0px", marginTop: "1rem"}}
                        >
                          <div
                            class="tiles-item-inner has-shadow"
                            style={{padding: "15px"}}
                          >
                            <div>
                              {consentRequired4 == true ? (
                                <div
                                  style={{
                                    color: "red",
                                    float: "right",
                                    fontSize: "25px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  *
                                </div>
                              ) : null}
                              <div
                                style={{marginTop: "0rem", fontWeight: "bold"}}
                              >
                                Class 4
                                <div
                                  id={"removeButton" + 10}
                                  style={{
                                    float: "right",
                                    marginRight: "0rem",
                                    marginLeft: "auto",
                                    fontStyle: "bold",
                                    textAlign: "center",
                                    borderColor: "rgb(233,236,248)",
                                    borderStyle: "solid",
                                    borderWidth: "1px",
                                    fontWeight: "bold",
                                    cursor: "pointer",
                                    width: "2rem",
                                    display: "inline-block",
                                  }}
                                  className="has-shadow noSelectText"
                                  onClick={(event) => {
                                    removeAClass(event);
                                  }}
                                >
                                  —
                                </div>
                              </div>

                              <input
                                id="classTitleInput4"
                                type="text"
                                autocomplete="off"
                                placeholder="Class title"
                                onKeyDown={selectFirstOption4}
                                onMouseDown={() => {
                                  setClassTextInput4("");
                                  setClassSelected4([]);
                                  setSubjectSelected4("");
                                  setClassSectionsSelected4([]);
                                  setFuseSearch4([]);
                                  setShowFuseList4(true);
                                  setConsentRequired4(false);
                                }}
                                value={classTextInput4}
                                onChange={changeClassTitleInput4}
                                className="form-input"
                                style={{marginTop: "0.5rem"}}
                              />

                              {subjectSelected4 != "" &&
                              currentlyTracking.filter((x) =>
                                subjectSelected4.includes(x)
                              ).length == 0 ? (
                                <div
                                  style={{
                                    display: "inline",
                                    marginTop: "0.5rem",
                                    color: "rgb(218,35,255)",
                                  }}
                                >
                                  No one is tracking this class
                                </div>
                              ) : currentlyTracking.filter((x) =>
                                  subjectSelected4.includes(x)
                                ).length > 0 ? (
                                <div
                                  style={{
                                    marginTop: "0.5rem",
                                    fontSize: "1rem",
                                    color: "rgb(218,35,255)",
                                  }}
                                >
                                  {currentlyTracking.filter((x) =>
                                    subjectSelected4.includes(x)
                                  ).length == 1 ? (
                                    <div
                                      style={{
                                        display: "inline",
                                        color: "rgb(13, 98, 255)",
                                      }}
                                    >
                                      1 person is tracking this class
                                    </div>
                                  ) : currentlyTracking.filter((x) =>
                                      subjectSelected4.includes(x)
                                    ).length > 10 ? (
                                    <div
                                      style={{
                                        display: "inline",
                                        color: "rgb(246, 142, 23)",
                                      }}
                                    >
                                      {
                                        currentlyTracking.filter((x) =>
                                          subjectSelected4.includes(x)
                                        ).length
                                      }{" "}
                                      people are tracking this class
                                    </div>
                                  ) : currentlyTracking.filter((x) =>
                                      subjectSelected4.includes(x)
                                    ).length > 5 ? (
                                    <div
                                      style={{
                                        display: "inline",
                                        color: "rgb(13, 98, 255)",
                                      }}
                                    >
                                      {
                                        currentlyTracking.filter((x) =>
                                          subjectSelected4.includes(x)
                                        ).length
                                      }{" "}
                                      people are tracking this class
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        display: "inline",
                                        color: "rgb(0,194,169)",
                                      }}
                                    >
                                      {
                                        currentlyTracking.filter((x) =>
                                          subjectSelected4.includes(x)
                                        ).length
                                      }{" "}
                                      people are tracking this class
                                    </div>
                                  )}
                                </div>
                              ) : null}

                              {fuseSearch4 != classTitlesNoDuplicates &&
                              fuseSearch4.length != 0 &&
                              showFuseList4 == true ? (
                                <div>
                                  <div style={{height: "0.75rem"}}></div>
                                  {fuseSearch4.map((course, courseIndex) => {
                                    if (courseIndex < 7) {
                                      return (
                                        <div
                                          onClick={(event) => {
                                            clickFuseElement4(course.item);
                                          }}
                                          id={course.item}
                                          class="selectClass"
                                        >
                                          {course.item}
                                        </div>
                                      );
                                    }
                                  })}
                                </div>
                              ) : null}
                            </div>

                            <div
                              style={{
                                display:
                                  classSelected4.length != 0 ? "block" : "none",
                                marginTop: "0.5rem",
                                fontWeight: "bold",
                              }}
                              class="noSelectText"
                            >
                              Select the section(s) you want
                            </div>
                            {classSelected4.map((course, courseIndex) => {
                              return (
                                <div>
                                  {course.sections.map(
                                    (section, sectionIndex) => {
                                      return (
                                        <div
                                          id={section.sectionNumber}
                                          class="slotSelect"
                                          onMouseDown={clickClassSection4}
                                          onMouseOver={hoverClassSection4}
                                          style={{
                                            backgroundColor:
                                              classSectionsSelected4.includes(
                                                section.sectionNumber
                                              )
                                                ? "#3D5AFE"
                                                : "white",
                                            color:
                                              classSectionsSelected4.includes(
                                                section.sectionNumber
                                              )
                                                ? "white"
                                                : "#575E86",
                                            padding: "0.2rem",
                                            marginTop: "0.3rem",
                                            borderStyle: "solid",
                                            borderColor: "black",
                                            borderWidth: "1px",
                                          }}
                                        >
                                          {section.lab == true ? (
                                            <div
                                              style={{
                                                color: "#33CEFF",
                                                float: "right",
                                                fontSize: "16px",
                                                fontWeight: "",
                                                marginRight: "0.2rem",
                                              }}
                                            >
                                              Lab
                                            </div>
                                          ) : null}
                                          {section.sectionProf.length != 0 ? (
                                            <div
                                              class="noSelectText"
                                              style={{
                                                zIndex: 2,
                                                pointerEvents: "none",
                                              }}
                                            >
                                              {section.sectionName.includes(
                                                "Section"
                                              ) ? (
                                                <div
                                                  class="noSelectText"
                                                  style={{
                                                    zIndex: 1,
                                                    pointerEvents: "none",
                                                  }}
                                                >
                                                  {section.sectionName} (
                                                  {section.sectionNumber})
                                                </div>
                                              ) : (
                                                <div
                                                  class="noSelectText"
                                                  style={{
                                                    zIndex: 1,
                                                    pointerEvents: "none",
                                                  }}
                                                >
                                                  Section {section.sectionName}{" "}
                                                  ({section.sectionNumber})
                                                </div>
                                              )}
                                              <div
                                                class="noSelectText"
                                                style={{
                                                  zIndex: 1,
                                                  pointerEvents: "none",
                                                }}
                                              >
                                                {section.sectionProf.map(
                                                  (prof, profIndex) => {
                                                    return (
                                                      <div
                                                        style={{
                                                          pointerEvents: "none",
                                                          display: "inline",
                                                        }}
                                                      >
                                                        {section.sectionProf
                                                          .length == 1 ? (
                                                          <div
                                                            style={{
                                                              pointerEvents:
                                                                "none",
                                                              display: "inline",
                                                            }}
                                                          >
                                                            {prof}
                                                          </div>
                                                        ) : profIndex !=
                                                            section.sectionProf
                                                              .length -
                                                              1 &&
                                                          section.sectionProf
                                                            .length == 2 ? (
                                                          <div
                                                            style={{
                                                              pointerEvents:
                                                                "none",
                                                              display: "inline",
                                                            }}
                                                          >
                                                            {prof}&nbsp;
                                                          </div>
                                                        ) : profIndex !=
                                                            section.sectionProf
                                                              .length -
                                                              1 &&
                                                          section.sectionProf
                                                            .length > 2 ? (
                                                          <div
                                                            style={{
                                                              pointerEvents:
                                                                "none",
                                                              display: "inline",
                                                            }}
                                                          >
                                                            {prof},&nbsp;
                                                          </div>
                                                        ) : (
                                                          <div
                                                            style={{
                                                              pointerEvents:
                                                                "none",
                                                              display: "inline",
                                                            }}
                                                          >
                                                            and&nbsp;{prof}
                                                          </div>
                                                        )}
                                                      </div>
                                                    );
                                                  }
                                                )}
                                              </div>
                                            </div>
                                          ) : section.sectionName.includes(
                                              "Section"
                                            ) ? (
                                            <div
                                              class="noSelectText"
                                              style={{
                                                zIndex: 2,
                                                pointerEvents: "none",
                                              }}
                                            >
                                              {section.sectionName} (
                                              {section.sectionNumber})
                                            </div>
                                          ) : (
                                            <div
                                              class="noSelectText"
                                              style={{
                                                zIndex: 2,
                                                pointerEvents: "none",
                                              }}
                                            >
                                              Section {section.sectionName} (
                                              {section.sectionNumber})
                                            </div>
                                          )}
                                          {section.sectionTime != null ? (
                                            <div
                                              class="noSelectText"
                                              style={{
                                                zIndex: 2,
                                                pointerEvents: "none",
                                              }}
                                            >
                                              {section.sectionTime}
                                            </div>
                                          ) : null}
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              );

                              function clickClassSection4(event) {
                                document
                                  .getElementById("classTitleInput4")
                                  .classList.remove("form-error");
                                document.getElementById(
                                  "classSelectHint4"
                                ).style.display = "none";
                                if (
                                  !classSectionsSelected4.includes(
                                    event.target.id
                                  )
                                ) {
                                  document.getElementById(
                                    event.target.id
                                  ).style.backgroundColor = "#3D5AFE";
                                  document.getElementById(
                                    event.target.id
                                  ).style.color = "white";
                                  setClassSectionsSelected4([
                                    ...classSectionsSelected4,
                                    event.target.id,
                                  ]);
                                } else {
                                  document.getElementById(
                                    event.target.id
                                  ).style.backgroundColor = "white";
                                  document.getElementById(
                                    event.target.id
                                  ).style.color = "#575E86";
                                  setClassSectionsSelected4([
                                    ...classSectionsSelected4.slice(
                                      0,
                                      classSectionsSelected4.indexOf(
                                        event.target.id
                                      )
                                    ),
                                    ...classSectionsSelected4.slice(
                                      classSectionsSelected4.indexOf(
                                        event.target.id
                                      ) + 1,
                                      classSectionsSelected4.length
                                    ),
                                  ]);
                                }
                              }

                              function hoverClassSection4(event) {
                                const listener = (event) => {
                                  mouseDown = true;
                                };
                                const listener2 = (event) => {
                                  mouseDown = false;
                                };
                                document.addEventListener(
                                  "mousedown",
                                  listener
                                );
                                document.addEventListener("mouseup", listener2);

                                if (mouseDown == true) {
                                  if (
                                    !classSectionsSelected4.includes(
                                      event.target.id
                                    )
                                  ) {
                                    document.getElementById(
                                      event.target.id
                                    ).style.backgroundColor = "#3D5AFE";
                                    document.getElementById(
                                      event.target.id
                                    ).style.color = "white";
                                    setClassSectionsSelected4([
                                      ...classSectionsSelected4,
                                      event.target.id,
                                    ]);
                                  } else {
                                    document.getElementById(
                                      event.target.id
                                    ).style.backgroundColor = "white";
                                    document.getElementById(
                                      event.target.id
                                    ).style.color = "#575E86";
                                    setClassSectionsSelected4([
                                      ...classSectionsSelected4.slice(
                                        0,
                                        classSectionsSelected4.indexOf(
                                          event.target.id
                                        )
                                      ),
                                      ...classSectionsSelected4.slice(
                                        classSectionsSelected4.indexOf(
                                          event.target.id
                                        ) + 1,
                                        classSectionsSelected4.length
                                      ),
                                    ]);
                                  }
                                }
                              }
                            })}
                            <div
                              id="classSelectHint4"
                              style={{display: "none"}}
                              class="form-hint text-color-error"
                            >
                              Please select the class section(s) you need
                            </div>
                            <div
                              id="classAlreadyHint4"
                              style={{display: "none"}}
                              class="form-hint text-color-error"
                            >
                              You have already gotten notifications for this
                              class.{" "}
                              <a href={`/preferences#${userPhone}`}>Log in</a>{" "}
                              to update your preferences
                            </div>
                          </div>
                        </div>
                      ) : null}

                      {numClasses.length > 4 ? (
                        <div
                          class="tiles-item reveal-from-bottom is-revealed"
                          style={{padding: "0px", marginTop: "1rem"}}
                        >
                          <div
                            class="tiles-item-inner has-shadow"
                            style={{padding: "15px"}}
                          >
                            <div>
                              {consentRequired5 == true ? (
                                <div
                                  style={{
                                    color: "red",
                                    float: "right",
                                    fontSize: "25px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  *
                                </div>
                              ) : null}
                              <div
                                style={{marginTop: "0rem", fontWeight: "bold"}}
                              >
                                Class 5
                                <div
                                  id={"removeButton" + 10}
                                  style={{
                                    float: "right",
                                    marginRight: "0rem",
                                    marginLeft: "auto",
                                    fontStyle: "bold",
                                    textAlign: "center",
                                    borderColor: "rgb(233,236,248)",
                                    borderStyle: "solid",
                                    borderWidth: "1px",
                                    fontWeight: "bold",
                                    cursor: "pointer",
                                    width: "2rem",
                                    display: "inline-block",
                                  }}
                                  className="has-shadow noSelectText"
                                  onClick={(event) => {
                                    removeAClass(event);
                                  }}
                                >
                                  —
                                </div>
                              </div>

                              <input
                                id="classTitleInput5"
                                type="text"
                                autocomplete="off"
                                placeholder="Class title"
                                onKeyDown={selectFirstOption5}
                                onMouseDown={() => {
                                  setClassTextInput5("");
                                  setClassSelected5([]);
                                  setSubjectSelected5("");
                                  setClassSectionsSelected5([]);
                                  setFuseSearch5([]);
                                  setShowFuseList5(true);
                                  setConsentRequired5(false);
                                }}
                                value={classTextInput5}
                                onChange={changeClassTitleInput5}
                                className="form-input"
                                style={{marginTop: "0.5rem"}}
                              />

                              {subjectSelected5 != "" &&
                              currentlyTracking.filter((x) =>
                                subjectSelected5.includes(x)
                              ).length == 0 ? (
                                <div
                                  style={{
                                    display: "inline",
                                    marginTop: "0.5rem",
                                    color: "rgb(218,35,255)",
                                  }}
                                >
                                  No one is tracking this class
                                </div>
                              ) : currentlyTracking.filter((x) =>
                                  subjectSelected5.includes(x)
                                ).length > 0 ? (
                                <div
                                  style={{
                                    marginTop: "0.5rem",
                                    fontSize: "1rem",
                                    color: "rgb(218,35,255)",
                                  }}
                                >
                                  {currentlyTracking.filter((x) =>
                                    subjectSelected5.includes(x)
                                  ).length == 1 ? (
                                    <div
                                      style={{
                                        display: "inline",
                                        color: "rgb(13, 98, 255)",
                                      }}
                                    >
                                      1 person is tracking this class
                                    </div>
                                  ) : currentlyTracking.filter((x) =>
                                      subjectSelected5.includes(x)
                                    ).length > 10 ? (
                                    <div
                                      style={{
                                        display: "inline",
                                        color: "rgb(246, 142, 23)",
                                      }}
                                    >
                                      {
                                        currentlyTracking.filter((x) =>
                                          subjectSelected5.includes(x)
                                        ).length
                                      }{" "}
                                      people are tracking this class
                                    </div>
                                  ) : currentlyTracking.filter((x) =>
                                      subjectSelected5.includes(x)
                                    ).length > 5 ? (
                                    <div
                                      style={{
                                        display: "inline",
                                        color: "rgb(13, 98, 255)",
                                      }}
                                    >
                                      {
                                        currentlyTracking.filter((x) =>
                                          subjectSelected5.includes(x)
                                        ).length
                                      }{" "}
                                      people are tracking this class
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        display: "inline",
                                        color: "rgb(0,194,169)",
                                      }}
                                    >
                                      {
                                        currentlyTracking.filter((x) =>
                                          subjectSelected5.includes(x)
                                        ).length
                                      }{" "}
                                      people are tracking this class
                                    </div>
                                  )}
                                </div>
                              ) : null}

                              {fuseSearch5 != classTitlesNoDuplicates &&
                              fuseSearch5.length != 0 &&
                              showFuseList5 == true ? (
                                <div>
                                  <div style={{height: "0.75rem"}}></div>
                                  {fuseSearch5.map((course, courseIndex) => {
                                    if (courseIndex < 7) {
                                      return (
                                        <div
                                          onClick={(event) => {
                                            clickFuseElement5(course.item);
                                          }}
                                          id={course.item}
                                          class="selectClass"
                                        >
                                          {course.item}
                                        </div>
                                      );
                                    }
                                  })}
                                </div>
                              ) : null}
                            </div>

                            <div
                              style={{
                                display:
                                  classSelected5.length != 0 ? "block" : "none",
                                marginTop: "0.5rem",
                                fontWeight: "bold",
                              }}
                              class="noSelectText"
                            >
                              Select the section(s) you want
                            </div>
                            {classSelected5.map((course, courseIndex) => {
                              return (
                                <div>
                                  {course.sections.map(
                                    (section, sectionIndex) => {
                                      return (
                                        <div
                                          id={section.sectionNumber}
                                          class="slotSelect"
                                          onMouseDown={clickClassSection5}
                                          onMouseOver={hoverClassSection5}
                                          style={{
                                            backgroundColor:
                                              classSectionsSelected5.includes(
                                                section.sectionNumber
                                              )
                                                ? "#3D5AFE"
                                                : "white",
                                            color:
                                              classSectionsSelected5.includes(
                                                section.sectionNumber
                                              )
                                                ? "white"
                                                : "#575E86",
                                            padding: "0.2rem",
                                            marginTop: "0.3rem",
                                            borderStyle: "solid",
                                            borderColor: "black",
                                            borderWidth: "1px",
                                          }}
                                        >
                                          {section.lab == true ? (
                                            <div
                                              style={{
                                                color: "#33CEFF",
                                                float: "right",
                                                fontSize: "16px",
                                                fontWeight: "",
                                                marginRight: "0.2rem",
                                              }}
                                            >
                                              Lab
                                            </div>
                                          ) : null}
                                          {section.sectionProf.length != 0 ? (
                                            <div
                                              class="noSelectText"
                                              style={{
                                                zIndex: 2,
                                                pointerEvents: "none",
                                              }}
                                            >
                                              {section.sectionName.includes(
                                                "Section"
                                              ) ? (
                                                <div
                                                  class="noSelectText"
                                                  style={{
                                                    zIndex: 1,
                                                    pointerEvents: "none",
                                                  }}
                                                >
                                                  {section.sectionName} (
                                                  {section.sectionNumber})
                                                </div>
                                              ) : (
                                                <div
                                                  class="noSelectText"
                                                  style={{
                                                    zIndex: 1,
                                                    pointerEvents: "none",
                                                  }}
                                                >
                                                  Section {section.sectionName}{" "}
                                                  ({section.sectionNumber})
                                                </div>
                                              )}
                                              <div
                                                class="noSelectText"
                                                style={{
                                                  zIndex: 1,
                                                  pointerEvents: "none",
                                                }}
                                              >
                                                {section.sectionProf.map(
                                                  (prof, profIndex) => {
                                                    return (
                                                      <div
                                                        style={{
                                                          pointerEvents: "none",
                                                          display: "inline",
                                                        }}
                                                      >
                                                        {section.sectionProf
                                                          .length == 1 ? (
                                                          <div
                                                            style={{
                                                              pointerEvents:
                                                                "none",
                                                              display: "inline",
                                                            }}
                                                          >
                                                            {prof}
                                                          </div>
                                                        ) : profIndex !=
                                                            section.sectionProf
                                                              .length -
                                                              1 &&
                                                          section.sectionProf
                                                            .length == 2 ? (
                                                          <div
                                                            style={{
                                                              pointerEvents:
                                                                "none",
                                                              display: "inline",
                                                            }}
                                                          >
                                                            {prof}&nbsp;
                                                          </div>
                                                        ) : profIndex !=
                                                            section.sectionProf
                                                              .length -
                                                              1 &&
                                                          section.sectionProf
                                                            .length > 2 ? (
                                                          <div
                                                            style={{
                                                              pointerEvents:
                                                                "none",
                                                              display: "inline",
                                                            }}
                                                          >
                                                            {prof},&nbsp;
                                                          </div>
                                                        ) : (
                                                          <div
                                                            style={{
                                                              pointerEvents:
                                                                "none",
                                                              display: "inline",
                                                            }}
                                                          >
                                                            and&nbsp;{prof}
                                                          </div>
                                                        )}
                                                      </div>
                                                    );
                                                  }
                                                )}
                                              </div>
                                            </div>
                                          ) : section.sectionName.includes(
                                              "Section"
                                            ) ? (
                                            <div
                                              class="noSelectText"
                                              style={{
                                                zIndex: 2,
                                                pointerEvents: "none",
                                              }}
                                            >
                                              {section.sectionName} (
                                              {section.sectionNumber})
                                            </div>
                                          ) : (
                                            <div
                                              class="noSelectText"
                                              style={{
                                                zIndex: 2,
                                                pointerEvents: "none",
                                              }}
                                            >
                                              Section {section.sectionName} (
                                              {section.sectionNumber})
                                            </div>
                                          )}
                                          {section.sectionTime != null ? (
                                            <div
                                              class="noSelectText"
                                              style={{
                                                zIndex: 2,
                                                pointerEvents: "none",
                                              }}
                                            >
                                              {section.sectionTime}
                                            </div>
                                          ) : null}
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              );

                              function clickClassSection5(event) {
                                document
                                  .getElementById("classTitleInput5")
                                  .classList.remove("form-error");
                                document.getElementById(
                                  "classSelectHint5"
                                ).style.display = "none";
                                if (
                                  !classSectionsSelected5.includes(
                                    event.target.id
                                  )
                                ) {
                                  document.getElementById(
                                    event.target.id
                                  ).style.backgroundColor = "#3D5AFE";
                                  document.getElementById(
                                    event.target.id
                                  ).style.color = "white";
                                  setClassSectionsSelected5([
                                    ...classSectionsSelected5,
                                    event.target.id,
                                  ]);
                                } else {
                                  document.getElementById(
                                    event.target.id
                                  ).style.backgroundColor = "white";
                                  document.getElementById(
                                    event.target.id
                                  ).style.color = "#575E86";
                                  setClassSectionsSelected5([
                                    ...classSectionsSelected5.slice(
                                      0,
                                      classSectionsSelected5.indexOf(
                                        event.target.id
                                      )
                                    ),
                                    ...classSectionsSelected5.slice(
                                      classSectionsSelected5.indexOf(
                                        event.target.id
                                      ) + 1,
                                      classSectionsSelected5.length
                                    ),
                                  ]);
                                }
                              }

                              function hoverClassSection5(event) {
                                const listener = (event) => {
                                  mouseDown = true;
                                };
                                const listener2 = (event) => {
                                  mouseDown = false;
                                };
                                document.addEventListener(
                                  "mousedown",
                                  listener
                                );
                                document.addEventListener("mouseup", listener2);

                                if (mouseDown == true) {
                                  document
                                    .getElementById("classTitleInput5")
                                    .classList.remove("form-error");
                                  document.getElementById(
                                    "classSelectHint5"
                                  ).style.display = "none";
                                  if (
                                    !classSectionsSelected5.includes(
                                      event.target.id
                                    )
                                  ) {
                                    document.getElementById(
                                      event.target.id
                                    ).style.backgroundColor = "#3D5AFE";
                                    document.getElementById(
                                      event.target.id
                                    ).style.color = "white";
                                    setClassSectionsSelected5([
                                      ...classSectionsSelected5,
                                      event.target.id,
                                    ]);
                                  } else {
                                    document.getElementById(
                                      event.target.id
                                    ).style.backgroundColor = "white";
                                    document.getElementById(
                                      event.target.id
                                    ).style.color = "#575E86";
                                    setClassSectionsSelected5([
                                      ...classSectionsSelected5.slice(
                                        0,
                                        classSectionsSelected5.indexOf(
                                          event.target.id
                                        )
                                      ),
                                      ...classSectionsSelected5.slice(
                                        classSectionsSelected5.indexOf(
                                          event.target.id
                                        ) + 1,
                                        classSectionsSelected5.length
                                      ),
                                    ]);
                                  }
                                }
                              }
                            })}
                            <div
                              id="classSelectHint5"
                              style={{display: "none"}}
                              class="form-hint text-color-error"
                            >
                              Please select the class section(s) you need
                            </div>
                            <div
                              id="classAlreadyHint5"
                              style={{display: "none"}}
                              class="form-hint text-color-error"
                            >
                              You have already gotten notifications for this
                              class.{" "}
                              <a href={`/preferences#${userPhone}`}>Log in</a>{" "}
                              to update your preferences
                            </div>
                          </div>
                        </div>
                      ) : null}

                      {/* //check how many the user has if they are on the standard plan
                      // else if(){

                      //(addDelay on classes this term) && (number of classes the user has vs numClasses.length)



                      // } */}

                      {/* window.sessionStorage.getItem('signupPlan')!='free'?
                      (numClasses.length+(standardDocs.map(a => a.phone==window.sessionStorage.getItem('userPhone')).length))<5? */}

                      {numClasses.length < 5 ? (
                        <div
                          style={{
                            textAlign: "center",
                            borderColor: "rgb(233,236,248)",
                            borderStyle: "solid",
                            borderWidth: "1px",
                            marginTop: "1rem",
                            fontWeight: "bold",
                            cursor: "pointer",
                            width: "330px",
                          }}
                          className="has-shadow noSelectText"
                          onClick={addAClass}
                        >
                          +
                        </div>
                      ) : null}

                      {consentRequired1 == true ||
                      consentRequired2 == true ||
                      consentRequired3 == true ||
                      consentRequired4 == true ||
                      consentRequired5 == true ? (
                        <div>
                          <div
                            style={{
                              textAlign: "left",
                              fontSize: "14px",
                              marginTop: "1rem",
                              padding: "0.2rem",
                            }}
                          >
                            <div
                              style={{
                                color: "red",
                                fontSize: "25px",
                                fontWeight: "bold",
                                display: "inline",
                              }}
                            >
                              *
                            </div>
                            &nbsp;"Consent-Required" course.
                          </div>
                        </div>
                      ) : null}
                    </div>

                    {referrerName != null ? (
                      <div
                        style={{
                          textAlign: "center",
                          fontSize: "16px",
                          marginTop: "1rem",
                          borderStyle: "solid",
                          borderWidth: "0.1rem",
                          borderColor: "rgb(218,35,255)",
                          padding: "0.2rem",
                        }}
                      >
                        20% off your purchase thanks to {referrerName}!
                      </div>
                    ) : null}

                    <div className="mt-24 mb-32">
                      <Button
                        type="button"
                        color="primary"
                        onClick={submitRequest}
                        style={{width: "330px"}}
                      >
                        {isItAddDrop[schools.indexOf(userSchool)] == false ? (
                          <div>
                            <img
                              src="/ellipsis-white.gif"
                              class="noSelectText"
                              style={{
                                height: "48px",
                                marginTop: "-13px",
                                display:
                                  buttonClicked == true ? "block" : "none",
                              }}
                            />
                            <div
                              class="noSelectText"
                              style={{
                                pointerEvents: "none",
                                display:
                                  buttonClicked == false ? "block" : "none",
                              }}
                            >
                              Sign Up
                            </div>
                          </div>
                        ) : freeUsersArray.includes(userPhone) ||
                          window.sessionStorage.getItem("signupPlan") ==
                            "free" ? (
                          <div class="noSelectText">
                            <img
                              src="/ellipsis-white.gif"
                              class="noSelectText"
                              style={{
                                height: "48px",
                                marginTop: "-13px",
                                display:
                                  buttonClicked == true ? "block" : "none",
                              }}
                            />
                            <div
                              class="noSelectText"
                              style={{
                                pointerEvents: "none",
                                display:
                                  buttonClicked == false ? "block" : "none",
                              }}
                            >
                              Launch Notifications
                            </div>
                          </div>
                        ) : (
                          <div>
                            <img
                              src="/ellipsis-white.gif"
                              class="noSelectText"
                              style={{
                                height: "48px",
                                marginTop: "-13px",
                                display:
                                  buttonClicked == true ? "block" : "none",
                              }}
                            />
                            {/* <div class='noSelectText' style={{pointerEvents:'none', display:buttonClicked==false?'block':'none'}}>
                            Buy Notifications {(referrerPhone=='' || referrerPhone==null || referrerPhone==userPhone)&&numClasses.length==1?<div style={{display:'inline'}}>For $10</div>:(referrerPhone=='' || referrerPhone==null || referrerPhone==userPhone)&&numClasses.length==2?<div style={{display:'inline'}}>For $20</div>:(referrerPhone=='' || referrerPhone==null || referrerPhone==userPhone)&&numClasses.length==3?<div style={{display:'inline'}}>For $30</div>:(referrerPhone=='' || referrerPhone==null || referrerPhone==userPhone)&&numClasses.length==4?<div style={{display:'inline'}}>For $40</div>:(referrerPhone=='' || referrerPhone==null || referrerPhone==userPhone)&&numClasses.length==5?<div style={{display:'inline'}}>For $50</div>:(referrerPhone!='' && referrerPhone!=null && referrerPhone!=userPhone)&&numClasses.length==1?<div style={{display:'inline'}}>For $8</div>:(referrerPhone!='' && referrerPhone!=null && referrerPhone!=userPhone)&&numClasses.length==2?<div style={{display:'inline'}}>For $16</div>:(referrerPhone!='' && referrerPhone!=null && referrerPhone!=userPhone)&&numClasses.length==3?<div style={{display:'inline'}}>For $24</div>:(referrerPhone!='' && referrerPhone!=null && referrerPhone!=userPhone)&&numClasses.length==4?<div style={{display:'inline'}}>For $32</div>:(referrerPhone!='' && referrerPhone!=null && referrerPhone!=userPhone)&&numClasses.length==5?<div style={{display:'inline'}}>For $40</div>:<div style={{display:'inline'}}></div>}
                            </div> */}
                            <div
                              class="noSelectText"
                              style={{
                                pointerEvents: "none",
                                display:
                                  buttonClicked == false ? "block" : "none",
                              }}
                            >
                              Subscribe now
                            </div>
                          </div>
                        )}
                      </Button>
                    </div>
                  </fieldset>
                </form>

                <div
                  className="signin-bottom has-top-divider"
                  style={{marginTop: "0.7rem", width: "330px"}}
                >
                  {window.sessionStorage.getItem("userPhone") != null &&
                  window.sessionStorage.getItem("userPhone") != null ? (
                    <div className="pt-32 text-xs center-content text-color-low">
                      Go to&nbsp;
                      <Link
                        to={`/preferences#${window.sessionStorage.getItem(
                          "userPhone"
                        )}`}
                      >
                        my account
                      </Link>
                    </div>
                  ) : (
                    <div className="pt-32 text-xs center-content text-color-low">
                      Already have an account?&nbsp;
                      <Link to="/preferences">Login</Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );

  function addAClass() {
    // numClasses.push(0)
    // setNumClasses(numClasses)
    setNumClasses((a) => [...a, 0]);
  }

  function removeAClass(event) {
    setNumClasses(numClasses.filter((_, i) => i !== numClasses.length - 1));
    if (event.target.id.split("removeButton")[1] < 2) {
      setClassTextInput1(classTextInput2);
      setClassSelected1(classSelected2);
      setSubjectSelected1(subjectSelected2);
      setClassSectionsSelected1(classSectionsSelected2);
      setShowFuseList1(false);
    }
    if (event.target.id.split("removeButton")[1] < 3) {
      setClassTextInput2(classTextInput3);
      setClassSelected2(classSelected3);
      setSubjectSelected2(subjectSelected3);
      setClassSectionsSelected2(classSectionsSelected3);
      setShowFuseList2(false);
    }
    if (event.target.id.split("removeButton")[1] < 4) {
      setClassTextInput3(classTextInput4);
      setClassSelected3(classSelected4);
      setSubjectSelected3(subjectSelected4);
      setClassSectionsSelected3(classSectionsSelected4);
      setShowFuseList3(false);
    }
    if (event.target.id.split("removeButton")[1] < 5) {
      setClassTextInput4(classTextInput5);
      setClassSelected4(classSelected5);
      setSubjectSelected4(subjectSelected5);
      setClassSectionsSelected4(classSectionsSelected5);
      setShowFuseList4(false);
    }
    if (event.target.id.split("removeButton")[1] <= 5) {
      setClassTextInput5("");
      setClassSelected5([]);
      setSubjectSelected5("");
      setClassSectionsSelected5([]);
      setShowFuseList5(false);
    }
  }

  function clickFuseElement1(prop1) {
    setClassTextInput1(prop1);
    setClassSelected1([]);
    setSubjectSelected1("");
    setClassSectionsSelected1([]);
    setShowFuseList1(false);

    let classesFilter = classes.filter(
      (course) =>
        `${course.subject.toLowerCase()} - ${course.name.toLowerCase()}` ==
        prop1.toLowerCase()
    );

    setClassSelected1(classesFilter);

    if (classesFilter.length > 0) {
      setSubjectSelected1(classesFilter[0].subject);
      if (classesFilter[0].consentRequired == true) {
        setConsentRequired1(true);
      }
    }
  }

  function clickFuseElement2(prop1) {
    setClassTextInput2(prop1);
    setClassSelected2([]);
    setSubjectSelected2("");
    setClassSectionsSelected2([]);
    setShowFuseList2(false);

    let classesFilter = classes.filter(
      (course) =>
        `${course.subject.toLowerCase()} - ${course.name.toLowerCase()}` ==
        prop1.toLowerCase()
    );

    setClassSelected2(classesFilter);

    if (classesFilter.length > 0) {
      setSubjectSelected2(classesFilter[0].subject);
      if (classesFilter[0].consentRequired == true) {
        setConsentRequired2(true);
      }
    }
  }

  function clickFuseElement3(prop1) {
    setClassTextInput3(prop1);
    setClassSelected3([]);
    setSubjectSelected3("");
    setClassSectionsSelected3([]);
    setShowFuseList3(false);

    let classesFilter = classes.filter(
      (course) =>
        `${course.subject.toLowerCase()} - ${course.name.toLowerCase()}` ==
        prop1.toLowerCase()
    );

    setClassSelected3(classesFilter);

    if (classesFilter.length > 0) {
      setSubjectSelected3(classesFilter[0].subject);
      if (classesFilter[0].consentRequired == true) {
        setConsentRequired3(true);
      }
    }
  }

  function clickFuseElement4(prop1) {
    setClassTextInput4(prop1);
    setClassSelected4([]);
    setSubjectSelected4("");
    setClassSectionsSelected4([]);
    setShowFuseList4(false);

    let classesFilter = classes.filter(
      (course) =>
        `${course.subject.toLowerCase()} - ${course.name.toLowerCase()}` ==
        prop1.toLowerCase()
    );

    setClassSelected4(classesFilter);

    if (classesFilter.length > 0) {
      setSubjectSelected4(classesFilter[0].subject);
      if (classesFilter[0].consentRequired == true) {
        setConsentRequired4(true);
      }
    }
  }

  function clickFuseElement5(prop1) {
    setClassTextInput5(prop1);
    setClassSelected5([]);
    setSubjectSelected5("");
    setClassSectionsSelected5([]);
    setShowFuseList5(false);

    let classesFilter = classes.filter(
      (course) =>
        `${course.subject.toLowerCase()} - ${course.name.toLowerCase()}` ==
        prop1.toLowerCase()
    );

    setClassSelected5(classesFilter);

    if (classesFilter.length > 0) {
      setSubjectSelected5(classesFilter[0].subject);
      if (classesFilter[0].consentRequired == true) {
        setConsentRequired5(true);
      }
    }
  }

  function changeClassTitleInput1(event) {
    const fuse = new Fuse(classTitlesNoDuplicates, {
      // keys: ['title', 'author.firstName']
      threshold: 0.6,
    });

    setFuseSearch1(fuse.search(event.target.value));
    setClassTextInput1(event.target.value);

    document.getElementById("classTitleInput1").classList.remove("form-error");
    document.getElementById("classSelectHint1").style.display = "none";
    document.getElementById("classAlreadyHint1").style.display = "none";
    window.sessionStorage.removeItem("infoSuccess");
  }

  function changeClassTitleInput2(event) {
    const fuse = new Fuse(classTitlesNoDuplicates, {
      // keys: ['title', 'author.firstName']
      threshold: 0.6,
    });

    setFuseSearch2(fuse.search(event.target.value));
    setClassTextInput2(event.target.value);
    document.getElementById("classTitleInput2").classList.remove("form-error");
    document.getElementById("classSelectHint2").style.display = "none";
    document.getElementById("classAlreadyHint2").style.display = "none";
    window.sessionStorage.removeItem("infoSuccess");
  }

  function changeClassTitleInput3(event) {
    const fuse = new Fuse(classTitlesNoDuplicates, {
      // keys: ['title', 'author.firstName']
      threshold: 0.6,
    });

    setFuseSearch3(fuse.search(event.target.value));
    setClassTextInput3(event.target.value);
    document.getElementById("classTitleInput3").classList.remove("form-error");
    document.getElementById("classSelectHint3").style.display = "none";
    document.getElementById("classAlreadyHint3").style.display = "none";
    window.sessionStorage.removeItem("infoSuccess");
  }

  function changeClassTitleInput4(event) {
    const fuse = new Fuse(classTitlesNoDuplicates, {
      // keys: ['title', 'author.firstName']
      threshold: 0.6,
    });

    setFuseSearch4(fuse.search(event.target.value));
    setClassTextInput4(event.target.value);
    document.getElementById("classTitleInput4").classList.remove("form-error");
    document.getElementById("classSelectHint4").style.display = "none";
    document.getElementById("classAlreadyHint4").style.display = "none";
    window.sessionStorage.removeItem("infoSuccess");
  }

  function changeClassTitleInput5(event) {
    const fuse = new Fuse(classTitlesNoDuplicates, {
      // keys: ['title', 'author.firstName']
      threshold: 0.6,
    });

    setFuseSearch5(fuse.search(event.target.value));
    setClassTextInput5(event.target.value);
    document.getElementById("classTitleInput5").classList.remove("form-error");
    document.getElementById("classSelectHint5").style.display = "none";
    document.getElementById("classAlreadyHint5").style.display = "none";
    window.sessionStorage.removeItem("infoSuccess");
  }

  function selectFirstOption1(event) {
    if (event.key === "Enter") {
      event.preventDefault();
      if (fuseSearch1[0] != null) {
        clickFuseElement1(fuseSearch1[0].item);
      }
      document.getElementById("classTitleInput1").blur();
      document
        .getElementById("classTitleInput1")
        .classList.remove("form-error");
    }
  }
  function selectFirstOption2(event) {
    if (event.key === "Enter") {
      event.preventDefault();
      if (fuseSearch2[0] != null) {
        clickFuseElement2(fuseSearch2[0].item);
      }
      document.getElementById("classTitleInput2").blur();
      document
        .getElementById("classTitleInput2")
        .classList.remove("form-error");
    }
  }
  function selectFirstOption3(event) {
    if (event.key === "Enter") {
      event.preventDefault();
      if (fuseSearch3[0] != null) {
        clickFuseElement3(fuseSearch3[0].item);
      }
      document.getElementById("classTitleInput3").blur();
      document
        .getElementById("classTitleInput3")
        .classList.remove("form-error");
    }
  }
  function selectFirstOption4(event) {
    if (event.key === "Enter") {
      event.preventDefault();
      if (fuseSearch4[0] != null) {
        clickFuseElement4(fuseSearch4[0].item);
      }
      document.getElementById("classTitleInput4").blur();
      document
        .getElementById("classTitleInput4")
        .classList.remove("form-error");
    }
  }
  function selectFirstOption5(event) {
    if (event.key === "Enter") {
      event.preventDefault();
      if (fuseSearch5[0] != null) {
        clickFuseElement5(fuseSearch5[0].item);
      }
      document.getElementById("classTitleInput5").blur();
      document
        .getElementById("classTitleInput5")
        .classList.remove("form-error");
    }
  }
}

GetNotifications.propTypes = propTypes;
GetNotifications.defaultProps = defaultProps;

export default GetNotifications;
