import React from 'react';
import FaqLaunch from '../components/sections/FaqLaunch'
import HeroFull from '../components/sections/HeroFullLaunch';
import PricingLaunchPage from '../components/sections/PricingLaunchPage'
import LaunchingPage from '../components/sections/LaunchingPage'
import FeaturesTabsViz from '../components/sections/NotreDameViz'
import Carousel from '../components/elements/Carousel'
import StripeApp from '../components/sections/StripeApp'
import SignCounter from '../components/sections/SignupCounter';
import FivioHundo from '../components/sections/FivioHundo';
import FreeAccess from '../components/sections/FreeAccess'
// import Clients from '../components/sections/Clients';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import PollinateTestimonial from '../components/sections/PollinateTestimonialsLaunch';
import Pricing from '../components/sections/Pricing2';
import AdvisingPricing from '../components/sections/AdvisingPricing';
import Cta from '../components/sections/CtaLaunch';
import Slider from '../components/sections/Slider';
import GetDataForSS from '../components/sections/GetDataForSS';
import FeaturesSplitConsentRequired from '../components/sections/FeaturesSplitConsentRequired';
import DemoWidget from '../components/sections/DemoWidget';
import ReviewBoard from '../components/sections/ReviewBoardLaunch';
import EmbeddedSite from '../components/sections/EmbeddedSite';

class Home extends React.Component {
  render() {


    return (
      <React.Fragment>
        {/* <GetDataForSS/> */}
        <HeroFull className="illustration-section-01" />

        <DemoWidget/>

        {/* <SignCounter /> */}
        {/* <LaunchingPage /> */}

        {/* <EmbeddedSite /> */}

        <ReviewBoard id='review'/>
        {/* <AdvisingPricing  topDivider pricingSlider className="has-bg-color-cut" /> */}
        <PollinateTestimonial topDivider bottomOuterDivider className="gradient-section" />
        <PricingLaunchPage />
        {/* <LaunchingPage /> */}
        {/* <FreeAccess /> */}
        <FaqLaunch />
        
        {/* <FeaturesTabs /> */}
        {/* {/* <FeaturesTiles topDivider /> */}
        <Cta hasBgColor invertColor split />
      </React.Fragment>
    );
  }
}

export default Home;