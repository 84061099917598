import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Button from '../elements/Button';
import { SectionProps } from '../../utils/SectionProps';
import firebase from 'firebase/compat/app';
import Input from '../elements/Input';

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
}

const defaultProps = {
  ...SectionProps.defaults,
  split: false
}

  function Cta(props) {

    const [allUsers, setAllUsers] = useState([]);

    useEffect(() => {
      const unsubscribe = firebase.firestore().collection('grabLeads').onSnapshot((querySnapshot) => {
        let allUsers = [];
        querySnapshot.forEach((doc) => {
          allUsers.push(doc.data());
        });
        setAllUsers(allUsers);
      });
      return () => {
        unsubscribe();
      };
    }, []);

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      split,
      ...restProps
    } = props;

    const outerClasses = classNames(
      'cta section center-content-mobile',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'cta-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider',
      split && 'cta-split'
    );

    let schools = ['UChicago', 'Notre Dame', 'RPI', 'Dartmouth', 'Northeastern', 'Western University', 'UMass']
    let isItAddDrop = [true, false, true, true, false, false, false]
    let userSchool = window.sessionStorage.getItem('userSchool')

    function clickLaunch() {
      // window.location.href = "#launch";
      if((window.sessionStorage.getItem('userPhone')!=null)&&(window.sessionStorage.getItem('userName')!=null)){
        window.location.href='/getnotifications'
      }else{
        window.location.href='/signup'
      }
    }
    return (
      <section
        {...restProps}
        className={outerClasses}
      >
        <div className="container">
          <div
            className={innerClasses}
          >
            <div style={{marginLeft:'auto', marginRight:'auto'}}>

              <Button tag="a" onClick={clickLaunch} className='cta-button'>
                Sign Up &nbsp;&#10140;
              </Button>
              

            </div>

            <div className="cta-action">
              {/* <Input id="newsletter" type="email" label="Subscribe" labelHidden hasIcon="right" placeholder="Phone number">
                <svg width="16" height="12" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9 5H1c-.6 0-1 .4-1 1s.4 1 1 1h8v5l7-6-7-6v5z" fill="#376DF9" />
                </svg>
              </Input> */}
              {/* <Link
              to="/signup">
              <Button color='secondary'>Go</Button> 
              </Link> */}
            </div>
          </div>
        </div>
      </section>
    );
  }

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;