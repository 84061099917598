import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import App from './App';
import * as serviceWorker from './serviceWorker';

//import './App.css';
import './assets/scss/style.scss';

const history = createBrowserHistory();

async function op(p){
  // const {publishableKey}= await fetch('/config').then(r=>r.json()) 
  const publishableKey=	'pk_live_51JIFr9EyNmg9AFJET5l2PGW1mT1oJA9xdemnneE1A2LmKKYOpPMk8AazhvPyOjlzmAAA4WEOEjPk7IK32mwP99P3000aetg4a7' // stripe live publishable key
  const stripePromise = loadStripe(publishableKey)
  return stripePromise;
}


ReactDOM.render(
  <Router history={history}>
    <Elements stripe={op()}>
    <App />
    </Elements>
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
