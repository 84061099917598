import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {Link} from "react-router-dom";
import Button from "../elements/Button";
import {SectionProps} from "../../utils/SectionProps";
import firebase from "firebase/compat/app";
import Input from "../elements/Input";

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool,
};

const defaultProps = {
  ...SectionProps.defaults,
  split: false,
};

function Cta(props) {
  const [allUsers, setAllUsers] = useState([]);

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection("grabLeads")
      .onSnapshot((querySnapshot) => {
        let allUsers = [];
        querySnapshot.forEach((doc) => {
          allUsers.push(doc.data());
        });
        setAllUsers(allUsers);
      });
    return () => {
      unsubscribe();
    };
  }, []);

  const {
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    split,
    ...restProps
  } = props;

  const outerClasses = classNames(
    "cta section center-content-mobile",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "cta-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider",
    split && "cta-split"
  );

  let freeUsersArray = [
    "+19178168373",
    "+12245091991",
    "+17863505278",
    "+17087108441",
    "+18033711796",
    "+18608374226",
    "+14243929994",
    "+19706857425",
    "+17737987563",
    "+17737888004",
    "+18477906321",
    "+17328815655",
    "+14149165211",
    "+12138148916",
    "+12488029462",
    "+18325422188",
    "+18608636325",
    "+15024456682",
    "+19047902120",
    "+13099896627",
    "+14193200766",
    "+17735104851",
    "+12027632522",
    "+13108099387",
    "+13474098111",
    "+19175289583",
    "+13095306085",
    "+14699997362",
    "+13472057774",
    "+17139279195",
    "+14808866096",
    "+14804344586",
    "+17182003002",
    "+15166972124",
    "+17879402437",
    "+12029998416",
    "+19375578085",
    "+12036130170",
    "+19257259639",
    "+15102489778",
    "+16307439920",
    "+17703095075",
    "+16507769083",
    "+15122307229",
    "+18477785574",
    "+66916963175",
    "+16086300910",
    "+19085653326",
    "+13144794621",
    "+13123752925",
    "+17739529401",
    "+16506002482",
    "+13127186343",
    "+14084422905",
    "+12029265758",
    "+14702345428",
    "+12028137163",
    "+13106999256",
    "+16177758386",
    "+19145485489",
    "+17138909124",
    "+17187043243",
    "+17084913408",
    "+14107035368",
    "+12024598041",
    "+19734204762",
    "+12624758033",
    "+16463509253",
    "+19085176922",
    "+12162690310",
    "+17204417279",
    "+17602201548",
    "+13129786746",
    "+14138013717",
    "+13098572498",
    "+16825530436",
    "+16263532428",
    "+15104074958",
    "+16468412006",
    "+19732168750",
    "+19546818915",
    "+14697664243",
    "+19176484872",
    "+18486675266",
    "+18054555281",
    "+17733068421",
    "+8801765100738",
    "+19257195571",
    "+16502831962",
    "+19738767588",
    "+18608397388",
    "+12032933339",
    "+17736336786",
    "+16023158203",
    "+16154502490",
    "+17735447799",
    "+15736399003",
    "+13802401726",
    "+16126707721",
    "+16128672132",
    "+18184217246",
    //'+17037626410'
  ];

  let schools = [
    "UChicago",
    "Notre Dame",
    "RPI",
    "Dartmouth",
    "Northeastern",
    "Western University",
    "UMass",
  ];
  let isItAddDrop = [true, false, true, true, false, false, false];
  let userSchool = window.sessionStorage.getItem("userSchool");

  return (
    <section {...restProps} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <div style={{marginLeft: "auto", marginRight: "auto"}}>
            {window.sessionStorage.getItem("userSchool") != null ? (
              isItAddDrop[schools.indexOf(userSchool)] == true ? (
                freeUsersArray.includes(
                  window.sessionStorage.getItem("userPhone")
                ) ? (
                  <Button
                    tag="a"
                    href="/getnotifications"
                    className="cta-button"
                  >
                    Launch Notifications &nbsp;&#10140;
                  </Button>
                ) : allUsers
                    .map((a) => a.phone)
                    .includes(window.sessionStorage.getItem("userPhone")) ? (
                  <Button
                    tag="a"
                    href={`/preferences#${window.sessionStorage.getItem(
                      "userPhone"
                    )}`}
                    className="cta-button"
                  >
                    Get Notifications &nbsp;&#10140;
                  </Button>
                ) : (
                  <Button tag="a" href="/signup" className="cta-button">
                    Get Notifications &nbsp;&#10140;
                  </Button>
                )
              ) : (
                <Button tag="a" href="/signup" className="cta-button">
                  Sign Up &nbsp;&#10140;
                </Button>
              )
            ) : (
              <Button tag="a" href="/selectschool" className="cta-button">
                Sign Up &nbsp;&#10140;
              </Button>
            )}
          </div>

          <div className="cta-action">
            {/* <Input id="newsletter" type="email" label="Subscribe" labelHidden hasIcon="right" placeholder="Phone number">
                <svg width="16" height="12" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9 5H1c-.6 0-1 .4-1 1s.4 1 1 1h8v5l7-6-7-6v5z" fill="#376DF9" />
                </svg>
              </Input> */}
            {/* <Link
              to="/signup">
              <Button color='secondary'>Go</Button> 
              </Link> */}
          </div>
        </div>
      </div>
    </section>
  );
}

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;
