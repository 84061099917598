import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Logo from './partials/Logo';
import Button from '../elements/Button';


let clickHome = () => {
  window.sessionStorage.removeItem('signupPlan')
  window.sessionStorage.removeItem('wantsEmails')
  window.sessionStorage.removeItem('subjectSelected1')
  window.sessionStorage.removeItem('classSectionsSelected1')
  window.sessionStorage.removeItem('labSelected1')
  window.location.href='/'

}


let clickBilling = () => {
  window.sessionStorage.removeItem('signupPlan')
  window.sessionStorage.removeItem('wantsEmails')
  window.sessionStorage.removeItem('subjectSelected1')
  window.sessionStorage.removeItem('classSectionsSelected1')
  window.sessionStorage.removeItem('labSelected1')
  window.location.href='https://billing.stripe.com/p/login/9AQ2byep15AL5mo6oo'

}

let clickEmail = () => {
  window.sessionStorage.removeItem('signupPlan')
  window.sessionStorage.removeItem('wantsEmails')
  window.sessionStorage.removeItem('subjectSelected1')
  window.sessionStorage.removeItem('classSectionsSelected1')
  window.sessionStorage.removeItem('labSelected1')
  window.location.href='/emailswidget'

}


let clickLogout = () => {
  window.sessionStorage.removeItem('userPhone')
  window.sessionStorage.removeItem('userName')
  window.sessionStorage.removeItem('signupPlan')
  window.sessionStorage.removeItem('wantsEmails')
  window.sessionStorage.removeItem('subjectSelected1')
  window.sessionStorage.removeItem('classSectionsSelected1')
  window.sessionStorage.removeItem('labSelected1')
  window.sessionStorage.removeItem('hasAccount')
  window.sessionStorage.removeItem('userSchool')
  window.location.href='/'
}

const propTypes = {
  active: PropTypes.bool,
  navPosition: PropTypes.string,
  hideNav: PropTypes.bool,
  hideSignin: PropTypes.bool,
  bottomOuterDivider: PropTypes.bool,
  bottomDivider: PropTypes.bool
}

const defaultProps = {
  active: false,
  navPosition: '',
  hideNav: false,
  hideSignin: false,
  bottomOuterDivider: false,
  bottomDivider: false
}

class HeaderForLogin extends React.Component {

  state = {
    isActive: false
  };

  nav = React.createRef();
  hamburger = React.createRef();

  componentDidMount() {
    this.props.active && this.openMenu();
    document.addEventListener('keydown', this.keyPress);
    document.addEventListener('click', this.clickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyPress);
    document.addEventListener('click', this.clickOutside);
    this.closeMenu();
  }

  openMenu = () => {
    document.body.classList.add('off-nav-is-active');
    this.nav.current.style.maxHeight = this.nav.current.scrollHeight + 'px';
    this.setState({ isActive: true });
  }

  closeMenu = () => {
    document.body.classList.remove('off-nav-is-active');
    this.nav.current && (this.nav.current.style.maxHeight = null);
    this.setState({ isActive: false });
    // window.sessionStorage.removeItem('userPhone')
    // window.sessionStorage.removeItem('userName')
    // window.sessionStorage.removeItem('signupPlan')
    // window.sessionStorage.removeItem('wantsEmails')
    // window.sessionStorage.removeItem('subjectSelected1')
    // window.sessionStorage.removeItem('classSectionsSelected1')
    // window.sessionStorage.removeItem('labSelected1')
  }


  




  keyPress = (e) => {
    this.state.isActive && e.keyCode === 27 && this.closeMenu();
  }

  clickOutside = (e) => {
    if (!this.nav.current) return
    if (!this.state.isActive || this.nav.current.contains(e.target) || e.target === this.hamburger.current) return;
    this.closeMenu();
  }

  render() {
    const {
      className,
      active,
      navPosition,
      hideNav,
      hideSignin,
      bottomOuterDivider,
      bottomDivider,
      ...props
    } = this.props;

    const classes = classNames(
      'site-header',
      bottomOuterDivider && 'has-bottom-divider',
      className
    );

    return (
      <header
        {...props}
        className={classes}
      >
        <div className="container">
          <div className={
            classNames(
              'site-header-inner',
              bottomDivider && 'has-bottom-divider'
            )}>
           <div></div>
            {!hideNav &&
              <React.Fragment>
                <button
                  ref={this.hamburger}
                  className="header-nav-toggle"
                  onClick={this.state.isActive ? this.closeMenu : this.openMenu}
                >
                  <span className="screen-reader">Menu</span>
                  <span className="hamburger">
                    <span className="hamburger-inner"></span>
                  </span>
                </button>
                <nav
                  ref={this.nav}
                  className={
                    classNames(
                      'header-nav',
                      this.state.isActive && 'is-active'
                    )}>
                  <div className="header-nav-inner" >
                    <ul className={
                      classNames(
                        'list-reset text-xxs',
                        navPosition && `header-nav-${navPosition}`
                      )}>
                      {/* <li>
                        <Link to="/secondary/" onClick={this.closeMenu}>Secondary page</Link>
                      </li> */}
                    </ul>
                    {!hideSignin &&
                      <ul
                        className="list-reset header-nav-right"
                      >
                        <li>
                        <Button className="button button-primary button-wide-mobile button-sm adjustLoginHeader2" onClick={clickHome}>
                          Home
                        </Button>
                          {/* <Link to="/" className="button button-primary button-wide-mobile button-sm" onClick={this.closeMenu}>Home</Link> */}
                        </li>
                        <li>





                        {/* <Button className="button button-primary button-wide-mobile button-sm adjustLoginHeader2" style={{marginLeft:'10px', marginRight:'5px'}} onClick={clickEmail}>
                          Email Drafter GPT
                        </Button> */}



                          {/* <Link to="/" className="button button-primary button-wide-mobile button-sm" onClick={this.closeMenu}>Home</Link> */}
                        </li>
                        <li>
                        <Button className="button button-primary button-wide-mobile button-sm adjustLoginHeader2" style={{marginLeft:'5px', marginRight:'10px'}} onClick={clickBilling}>
                          Billing
                        </Button>
                          {/* <Link to="/" className="button button-primary button-wide-mobile button-sm" onClick={this.closeMenu}>Home</Link> */}
                        </li>
                        {window.location.href.includes('#')?
                        <li>

                        <Button className="button button-alternate button-wide-mobile button-sm adjustLoginHeader2" onClick={clickLogout}>
                          Log out
                        </Button>
                          {/* <Link to="/" className="button button-alternate button-wide-mobile button-sm" onClick={this.closeMenu}>Log out</Link> */}
                        </li>
                        :null}

                      </ul>}
                  </div>
                </nav>
              </React.Fragment>}
          </div>
        </div>
      </header>
    )
  }
}

HeaderForLogin.propTypes = propTypes;
HeaderForLogin.defaultProps = defaultProps;

export default HeaderForLogin;