import React from "react";
import classNames from "classnames";
import {SectionProps} from "../../utils/SectionProps";
import Button from "../elements/Button";
import Image from "../elements/Image";
import SectionHeader from "./partials/SectionHeader";
import Accordion from "../elements/Accordion";
import AccordionItem from "../elements/AccordionItem";
import GenericSection from "./GenericSection";
import image from "../../assets/images/ourTeamPollinate.png";

export default function FaqLaunch() {
  const genericSection01Header = {
    title: `FAQ's`,
  };
  return (
    <GenericSection>
      <div>
        <SectionHeader
          data={genericSection01Header}
          className="center-content"
        />
        <Accordion>
          {/* <AccordionItem title="What is Free Access?" id="freeaccess" active>
   The first 10 people to sign up from each school get free access to open seat notifications.
  </AccordionItem> */}
          {/* <AccordionItem title="What does pre-order mean?" id='preorder-meaning' active>
      When you preorder, you are guaranteed one of our limited spots. Our servers currently only have the capacity to serve 500 students. Once pre-registration resolves, we will text you to input the classes you would like to track. If you get all the classes you need or do not input your classes, you will be refunded.
    </AccordionItem> */}
          {/* <AccordionItem title="What is our waitlist?" active>
    Join the waitlist. You’ll be updated on pre-reg deadlines and the first to know when new features are added. 
    </AccordionItem> */}

          <AccordionItem title="How much does this cost?">
            Our class availability monitoring website charges $25 for unlimited
            class tracking per quarter due to the cost of advanced real-time
            monitoring technology. However, the first 100 users who signed up
            receive this service for free for life.
          </AccordionItem>
          <AccordionItem title="How does it work?">
            You will input the class you need into our website. When a spot
            opens up we will send you an automated text message alerting you.
          </AccordionItem>
          <AccordionItem title="How updated is the class availability data?">
            Our software updates the class availability data continuously. It's
            like we are sitting by the computer refreshing every few seconds for
            you :)
          </AccordionItem>
          <AccordionItem title="Who is working on this?">
            We are Jon and Jack, two recent UChicago graduates. We built Class
            Clutch to help students get the classes they need.
            <img
              src={image}
              // style={{marginLeft:'15%'}}
              alt="Surface"
              height={60}
              width={400}
            />
          </AccordionItem>
        </Accordion>
      </div>
    </GenericSection>
  );
}
