import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import Button from '../elements/Button'
import { Link } from 'react-router-dom';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

class FeaturesSplitConsentRequired extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'features-split section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'features-split-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const splitClasses = classNames(
      'split-wrap',
      invertMobile && 'invert-mobile',
      invertDesktop && 'invert-desktop',
      alignTop && 'align-top'
    );

    const sectionHeader = {
      title: "3 Things They Don't Tell You about Consent Required Classes",
      paragraph: "We annoyed our advisors so you didn't have to"
    };

    return (
      <section
        {...props}
        className={outerClasses}
        id='consentRequiredInfo'>
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content reveal-scale-up"/>
            <div className={splitClasses}>

              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-bottom" data-reveal-container=".split-item">
                  Consent Required = Waitlist
                  </h3>
                  <ul>
                    
                  <li className="m-0 reveal-from-bottom" data-reveal-delay="100" data-reveal-container=".split-item">
                  Students can’t add into the consent required classes. The professor needs to approve their request from my.UChicago.
                  </li>
                  <li className="m-0 reveal-from-bottom" data-reveal-delay="100" data-reveal-container=".split-item">
                  The instructors are given a list of students ordered by time — so it pays to request fast.
                  </li>
                </ul>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-scale-up',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200">
                  <Image
                    className="has-shadow"
                    src={require('./../../assets/images/requestButtonScreenshot.png')}
                    alt="Features split 01"
                    width={528}
                    height={396} 
                    style={{borderRadius:'20px'}}/>
                </div>
              </div>

              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-bottom" data-reveal-container=".split-item">
                  Professors Can Over-Enroll Non-Core Classes
                  </h3>
                  <ul>
                  <li className="m-0 reveal-from-bottom" data-reveal-delay="100" data-reveal-container=".split-item">
                    The professor is <strong>100%</strong> responsible for adding  students into their class. They can even add you into a full class.
                  </li>
                  <li className="m-0 reveal-from-bottom" data-reveal-delay="100" data-reveal-container=".split-item">
                  <strong>50.2%</strong> of UChicago classes were over-enrolled last quarter.
                  </li>
                  </ul>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-scale-up',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200">
                  <Image
                    className="has-shadow"
                    src={require('./../../assets/images/SandersonWithStudents.jpg')}
                    alt="Features split 02"
                    height={396}
                    width={528}style={{borderRadius:'50px'}}
               />
                </div>
              </div>

              <div className="split-item" id='weWriteEmails'>
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-bottom" data-reveal-container=".split-item">
                  Professors are Persuaded by Emails
                  </h3>
                  <ul>
                  <li className="m-0 reveal-from-bottom" data-reveal-delay="100" data-reveal-container=".split-item">
                  We’ll draft persuasive emails to any professor.
                  </li>
                  <li className="m-0 reveal-from-bottom" data-reveal-delay="100" data-reveal-container=".split-item">
                  Professors are extremely likely to add you into their class if you email them early in pre-reg.
                  </li>
                  
                  </ul>

                <Link to={window.sessionStorage.getItem('userSchool')!=null?'/emailswidget':'/selectschool'} className="button button-secondary button-wide-mobile button-sm" onClick={this.closeMenu} target="blank" rel="noopener noreferrer">Subscribe Now To Draft Emails</Link>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-scale-up',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200" >
                  <Link to={window.sessionStorage.getItem('userSchool')!=null?'/emailswidget':'/selectschool'} onClick={this.closeMenu}><Image
                    className="has-shadow"
                    src={require('./../../assets/images/emailToSanderson.png')}
                    alt="Features split 03"
                    width={528}
                    height={396} style={{borderRadius:'25px'}}/>
                    </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

FeaturesSplitConsentRequired.propTypes = propTypes;
FeaturesSplitConsentRequired.defaultProps = defaultProps;

export default FeaturesSplitConsentRequired;